// ##############################
// // // Footer styles
// #############################

import {
  defaultFont,
  container,
  containerFluid,
    primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const footerStyle = theme => ({
    block: {},
    left: {
        float: "left!important",
        display: "block"
    },
    right: {
        margin: "0",
        fontSize: "14px",
        float: "right!important",
        padding: "15px"
    },
    footer: {
        bottom: "0",
        //borderTop: "1px solid #e7e7e7",
        padding: "0",
        ...defaultFont,
        zIndex: 1100,
        height: 64,
        backgroundColor: theme.palette.primary.main,
        boxShadow: "0px -2px 4px -1px rgba(0,0,0,0.2), 0px -4px 5px 0px rgba(0,0,0,0.14), 0px -1px 10px 0px rgba(0,0,0,0.12)",
        position:"relative",
    },
    container: {
        zIndex: 3,
        ...container,
        position: "relative"
    },
    containerFluid: {
        zIndex: 3,
        ...containerFluid,
        position: "relative"
    },
    a: {
        color: grayColor,
        textDecoration: "none",
        backgroundColor: "transparent"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0",
        width: "auto"
    },
    whiteColor: {
        "&,&:hover,&:focus": {
            color: "#FFFFFF"
        }
    }
});
export default footerStyle;
