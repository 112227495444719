import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Language from "@material-ui/icons/Language";

// core components
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import CatalogPreview from "components/CatalogPreview";
import PostsPreview from "components/PostsPreview";
import MessagesPreview from "components/MessagesPreview"; 
import TaskPreview from "components/TaskPreview";


import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";


import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';


import {
    successColor,
    tooltip,
    cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";

const us_flag = require("assets/img/flags/US.png");
const de_flag = require("assets/img/flags/DE.png");
const au_flag = require("assets/img/flags/AU.png");
const gb_flag = require("assets/img/flags/GB.png");
const ro_flag = require("assets/img/flags/RO.png");
const br_flag = require("assets/img/flags/BR.png");

var mapData = {
};

const fullHeight = (window.innerHeight - 72.5 - 64);

const dashboardStyle = theme => ({
    ...hoverCardStyle,
    tooltip,
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    cardProductTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px",
        textAlign: "center"
    },
    cardCategory: {
        color: "#999999",
        fontSize: "14px",
        paddingTop: "10px",
        marginBottom: "0",
        marginTop: "0",
        margin: "0"
    },
    cardProductDesciprion: {
        textAlign: "center",
        color: "#999999"
    },
    stats: {
        color: "#999999",
        fontSize: "12px",
        lineHeight: "22px",
        display: "inline-flex",
        "& svg": {
            position: "relative",
            top: "4px",
            width: "16px",
            height: "16px",
            marginRight: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            position: "relative",
            top: "4px",
            fontSize: "16px",
            marginRight: "3px"
        }
    },
    productStats: {
        paddingTop: "7px",
        paddingBottom: "7px",
        margin: "0"
    },
    successText: {
        color: successColor
    },
    upArrowCardCategory: {
        width: 14,
        height: 14
    },
    underChartIcons: {
        width: "17px",
        height: "17px"
    },
    price: {
        color: "inherit",
        "& h4": {
            marginBottom: "0px",
            marginTop: "0px"
        }
    },
    fullHeight: {
        maxHeight: fullHeight,
        minHeight: fullHeight,
        overflow: 'hidden',
        padding: '0 5px',
    },
    halfHeight: {
        maxHeight: fullHeight / 2,
        minHeight: fullHeight / 2,
        overflow: 'hidden',
    }
});

class Dashboard extends React.Component {
    state = {
        value: 0
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Grid container>
                    <Grid item xs={12} sm={4} className={classes.fullHeight}>
                        <CatalogPreview />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.fullHeight}>
                        <Grid item xs={12} className={classes.halfHeight}>
                            <PostsPreview postType={1} label="News" icon={<Icon>event</Icon>} color="success" />
                        </Grid>
                        <Grid item xs={12} className={classes.halfHeight}>
                            <PostsPreview postType={2} label="Adv" icon={<Icon>assignment</Icon>} color="info" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.fullHeight}>
                        <Grid item xs={12} className={classes.halfHeight}>
                            {false && <MessagesPreview label="Messages" icon={<Icon>assignment</Icon>} color="danger" />}
                        </Grid>
                        <Grid item xs={12} className={classes.halfHeight}>
                            {false && <TaskPreview label="Tasks" icon={<Icon>assignment</Icon>} color="info" />}
                        </Grid>
                    </Grid>
                </Grid>
                
            </div>
        );
    }

    oldContent = () => {
        {
            //<GridContainer>
            //    <GridItem xs={12}>
            //        <Card>
            //            <CardHeader color="success" icon>
            //                <CardIcon color="success">
            //                    <Language />
            //                </CardIcon>
            //                <h4 className={classes.cardIconTitle}>
            //                    Vendite per localit&agrave;
            //            </h4>
            //            </CardHeader>
            //            <CardBody>
            //                <GridContainer justify="space-between">
            //                    <GridItem xs={12} sm={12} md={5}>
            //                        <Table
            //                            tableData={[
            //                                [
            //                                    <img src={us_flag} alt="us_flag" />,
            //                                    "USA",
            //                                    "0",
            //                                    "0%"
            //                                ],
            //                                [
            //                                    <img src={de_flag} alt="us_flag" />,
            //                                    "Germany",
            //                                    "0",
            //                                    "0%"
            //                                ],
            //                                [
            //                                    <img src={au_flag} alt="us_flag" />,
            //                                    "Australia",
            //                                    "0",
            //                                    "0%"
            //                                ],
            //                                [
            //                                    <img src={gb_flag} alt="us_flag" />,
            //                                    "United Kingdom",
            //                                    "0",
            //                                    "0%"
            //                                ],
            //                                [
            //                                    <img src={ro_flag} alt="us_flag" />,
            //                                    "Romania",
            //                                    "0",
            //                                    "0%"
            //                                ],
            //                                [
            //                                    <img src={br_flag} alt="us_flag" />,
            //                                    "Brasil",
            //                                    "0",
            //                                    "0%"
            //                                ]
            //                            ]}
            //                        />
            //                    </GridItem>
            //                    <GridItem xs={12} sm={12} md={6}>
            //                        <VectorMap
            //                            map={"world_mill"}
            //                            backgroundColor="transparent"
            //                            zoomOnScroll={false}
            //                            containerStyle={{
            //                                width: "100%",
            //                                height: "280px"
            //                            }}
            //                            containerClassName="map"
            //                            regionStyle={{
            //                                initial: {
            //                                    fill: "#e4e4e4",
            //                                    "fill-opacity": 0.9,
            //                                    stroke: "none",
            //                                    "stroke-width": 0,
            //                                    "stroke-opacity": 0
            //                                }
            //                            }}
            //                            series={{
            //                                regions: [
            //                                    {
            //                                        values: mapData,
            //                                        scale: ["#AAAAAA", "#444444"],
            //                                        normalizeFunction: "polynomial"
            //                                    }
            //                                ]
            //                            }}
            //                        />
            //                    </GridItem>
            //                </GridContainer>
            //            </CardBody>
            //        </Card>
            //    </GridItem>
            //</GridContainer>
            //<GridContainer>
            //    <GridItem xs={12} sm={12} md={4}>
            //        <Card chart className={classes.cardHover}>
            //            <CardHeader color="info" className={classes.cardHeaderHover}>
            //                <ChartistGraph
            //                    className="ct-chart-white-colors"
            //                    data={dailySalesChart.data}
            //                    type="Line"
            //                    options={dailySalesChart.options}
            //                    listener={dailySalesChart.animation}
            //                />
            //            </CardHeader>
            //            <CardBody>
            //                <div className={classes.cardHoverUnder}>
            //                    <Tooltip
            //                        id="tooltip-top"
            //                        title="Refresh"
            //                        placement="bottom"
            //                        classes={{ tooltip: classes.tooltip }}
            //                    >
            //                        <Button simple color="info" justIcon>
            //                            <Refresh className={classes.underChartIcons} />
            //                        </Button>
            //                    </Tooltip>
            //                    <Tooltip
            //                        id="tooltip-top"
            //                        title="Change Date"
            //                        placement="bottom"
            //                        classes={{ tooltip: classes.tooltip }}
            //                    >
            //                        <Button color="transparent" simple justIcon>
            //                            <Edit className={classes.underChartIcons} />
            //                        </Button>
            //                    </Tooltip>
            //                </div>
            //                <h4 className={classes.cardTitle}>Vendite giornalire</h4>
            //                <p className={classes.cardCategory}>
            //                    <span className={classes.successText}>
            //                        <ArrowUpward className={classes.upArrowCardCategory} /> 0
            //                </span>{" "}
            //                    Numero vendite ultima settimana.
            //            </p>
            //            </CardBody>
            //            <CardFooter chart>
            //                <div className={classes.stats}>
            //                    <AccessTime /> aggiornato 0 minuti fa
            //        </div>
            //            </CardFooter>
            //        </Card>
            //    </GridItem>
            //    <GridItem xs={12} sm={12} md={4}>
            //        <Card chart className={classes.cardHover}>
            //            <CardHeader color="warning" className={classes.cardHeaderHover}>
            //                <ChartistGraph
            //                    className="ct-chart-white-colors"
            //                    data={emailsSubscriptionChart.data}
            //                    type="Line"
            //                    options={emailsSubscriptionChart.options}
            //                    responsiveOptions={emailsSubscriptionChart.responsiveOptions}
            //                    listener={emailsSubscriptionChart.animation}
            //                />
            //            </CardHeader>
            //            <CardBody>
            //                <div className={classes.cardHoverUnder}>
            //                    <Tooltip
            //                        id="tooltip-top"
            //                        title="Refresh"
            //                        placement="bottom"
            //                        classes={{ tooltip: classes.tooltip }}
            //                    >
            //                        <Button simple color="info" justIcon>
            //                            <Refresh className={classes.underChartIcons} />
            //                        </Button>
            //                    </Tooltip>
            //                    <Tooltip
            //                        id="tooltip-top"
            //                        title="Change Date"
            //                        placement="bottom"
            //                        classes={{ tooltip: classes.tooltip }}
            //                    >
            //                        <Button color="transparent" simple justIcon>
            //                            <Edit className={classes.underChartIcons} />
            //                        </Button>
            //                    </Tooltip>
            //                </div>
            //                <h4 className={classes.cardTitle}>Visite mensili</h4>
            //                <p className={classes.cardCategory}>
            //                    Visite mensili clienti
            //            </p>
            //            </CardBody>
            //            <CardFooter chart>
            //                <div className={classes.stats}>
            //                    <AccessTime /> aggiornato 0 minuti fa
            //            </div>
            //            </CardFooter>
            //        </Card>
            //    </GridItem>
            //    <GridItem xs={12} sm={12} md={4}>
            //        <Card chart className={classes.cardHover}>
            //            <CardHeader color="danger" className={classes.cardHeaderHover}>
            //                <ChartistGraph
            //                    className="ct-chart-white-colors"
            //                    data={completedTasksChart.data}
            //                    type="Line"
            //                    options={completedTasksChart.options}
            //                    listener={completedTasksChart.animation}
            //                />
            //            </CardHeader>
            //            <CardBody>
            //                <div className={classes.cardHoverUnder}>
            //                    <Tooltip
            //                        id="tooltip-top"
            //                        title="Refresh"
            //                        placement="bottom"
            //                        classes={{ tooltip: classes.tooltip }}
            //                    >
            //                        <Button simple color="info" justIcon>
            //                            <Refresh className={classes.underChartIcons} />
            //                        </Button>
            //                    </Tooltip>
            //                    <Tooltip
            //                        id="tooltip-top"
            //                        title="Change Date"
            //                        placement="bottom"
            //                        classes={{ tooltip: classes.tooltip }}
            //                    >
            //                        <Button color="transparent" simple justIcon>
            //                            <Edit className={classes.underChartIcons} />
            //                        </Button>
            //                    </Tooltip>
            //                </div>
            //                <h4 className={classes.cardTitle}>Vendite mensili</h4>
            //                <p className={classes.cardCategory}>
            //                    Valore mensile delle vendite
            //            </p>
            //            </CardBody>
            //            <CardFooter chart>
            //                <div className={classes.stats}>
            //                    <AccessTime /> aggiornato 0 minuti fa
            //            </div>
            //            </CardFooter>
            //        </Card>
            //    </GridItem>
            //</GridContainer>
        }
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(dashboardStyle)
);

export default enhance(Dashboard);
