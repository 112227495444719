import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import XExpansionPanel from 'components/XExpansionPanel';
import XSwitch from 'components/XSwitch';
import XField from 'components/XField';
import XSelect from 'components/XSelect';

import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Check, FilterRemove, ContentSaveSettings, DeleteSweep } from 'mdi-material-ui';

const styles = theme => ({
    root: {
        //position: "absolute",
        //top: 0,
        //left: 0,
        //right: 0,
        //height: "calc(100vh - 75px)"
    },
    title: {
        //padding: 0,
    },
    appBarWHite: {
        backgroundColor: "#fff",
    },
    filterBlock: {
        padding: "10px 0",
    }
});

//const createSliderWithTooltip = Slider.createSliderWithTooltip;
//const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        //padding: theme.spacing.unit * 2,
        padding: 0,
    },
    buttons: {
        float: "right",
        padding: 7,
    },
    closeButton: {
        //position: 'absolute',
        right: "2px",
        top: "2px",
        //color: theme.palette.grey[500],
        //float: "right",
    },
    title: {
        padding: "21px 16px",
        float: "left",
    },
}))(props => {
    const { children, classes, onClose, onConfirm, onClear, onSaveTemplate, onDeleteTemplate } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography className={classes.title} variant="h6">{children}</Typography>
            <div className={classes.buttons}>
                {onSaveTemplate ? (
                    <IconButton aria-label="Salva template" className={classes.closeButton} onClick={onSaveTemplate}>
                        <ContentSaveSettings />
                    </IconButton>
                ) : null}
                {onDeleteTemplate ? (
                    <IconButton aria-label="Elimina template" className={classes.closeButton} onClick={onDeleteTemplate}>
                        <DeleteSweep />
                    </IconButton>
                ) : null}
                {onClear ? (
                    <IconButton aria-label="Pulisci filtri" className={classes.closeButton} onClick={onClear}>
                        <FilterRemove />
                    </IconButton>
                ) : null}
                {onConfirm ? (
                    <IconButton aria-label="Filtra" className={classes.closeButton} onClick={onConfirm}>
                        <Check />
                    </IconButton>
                ) : null}
                {onClose ? (
                    <IconButton aria-label="Chiudi" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </div>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

//const DialogActions = withStyles(theme => ({
//    root: {
//        borderTop: `1px solid ${theme.palette.divider}`,
//        margin: 0,
//        padding: theme.spacing.unit,
//    },
//}))(MuiDialogActions);

const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <Tooltip
            title={value}
            placement="bottom"
            key={index}
            //open={true}
        >
            <Handle value={value} {...restProps} />
        </Tooltip>
    );
};

class XFilters extends Component {
    constructor(props) {
        super(props);

        this.dialog = React.createRef();
    }
    state = {
        sort:[],
    };

    onSwitchChange = (key) => (_, value) => {
        if (this.props.onFilterChange) {
            this.props.onFilterChange(key, value);
        }
    }
    onSelectChange = (key) => (_, value) => {
        if (this.props.onFilterChange) {
            if (value.length > 0) {
                this.props.onFilterChange(key, value[0].ID);
            }
            else {
                this.props.onFilterChange(key, undefined);
            }
        }
    }
    onSliderChange = (key) => (value) => {
        if (this.props.onFilterChange) {
            this.props.onFilterChange(key, value);
        }
    }
    onTabChange = (item) => (_, value) => {
        if (this.props.onFilterChange) {

            var items = item.items;
            var filterValue = this.state[item.ID]
            if (filterValue) {
                items = items.filter(i => i.Description.toUpperCase().includes(filterValue.toUpperCase()));
            }

            var selectedValue = items[value].ID;
            if (item.Value === selectedValue) {
                this.props.onFilterChange(item.ID, undefined);
            }
            else {
                this.props.onFilterChange(item.ID, selectedValue);
            }
        }
        this.setState(state => {
            state[item.ID] = undefined;
        });
    }
    handleTabFilter = (key) => (event, value) => {
        this.setState(state => {
            state[key] = value;
            return state;
        });
    }

    renderPanel = (filters) => {
        var { classes } = this.props;
        return <Fragment>
            {filters.map((item, key) => {
                var items = (<div></div>);
                switch (item.Type) {
                    case 1://checkbox
                        items = item.items.map((i, k) => {
                            switch (i.Type) {
                                case 1:
                                    return (<Grid item xs={6} sm={3} md={1}><XSwitch key={i.ID} id={i.ID} label={i.Label} value={i.Value} onChange={this.onSwitchChange} /></Grid>);
                                case 2:
                                    return (<Grid item xs={12} sm={6} md={3}><XSelect style={{ zIndex: "0 !important" }} key={i.ID} label={i.Label} id={i.ID} value={i.Value} idKey="ID" valueKey="Label" onChange={this.onSelectChange} urlData="/Base/GetViewGroupType" customData={i.Items} noPortal={true} /></Grid>);
                                default:
                                    return (<div></div>);
                            }

                        });
                        items = (<Grid container spacing={2}>{items}</Grid>);
                        break;
                    case 2://combo
                        var cboitems = item.items;
                        var filterValue = this.state[item.ID]
                        if (filterValue) {
                            cboitems = cboitems.filter(i => i.Description.toUpperCase().includes(filterValue.toUpperCase()));
                        }
                        var currentItem = cboitems.filter(i => i.ID === item.Value)[0];
                        var tabValue = cboitems.indexOf(currentItem);
                        if (tabValue === -1) {
                            tabValue = null;
                        }
                        items = (<AppBar position="relative" color="primary" style={{zIndex:1}} classes={{ colorPrimary: classes.appBarWHite}} >
                            <Toolbar>
                                <XField id={item.ID} value={filterValue} style={{ minWidth: "100px", maxWidth: "100px" }} label={item.Label} onChange={this.handleTabFilter} />
                                <Tabs style={{ width: "calc(100% - 100px)" }} variant="scrollable" scrollButtons="auto" value={tabValue} onChange={this.onTabChange(item)}>
                                    {cboitems.map((i, k) => (<Tab key={k} label={i.Description} />))}
                                </Tabs>
                            </Toolbar>
                        </AppBar>);
                        break;
                    case 3://range
                        var value = item.Value;
                        var min = item.items.min;
                        var max = item.items.max;
                        if (!min) min = 0;
                        if (!max) max = 0;
                        if (!value) {
                            value = [min, max];
                        }
                        var trackStyle = [{
                            backgroundColor: '#E2B231'
                        }];
                        var railStyle = {
                            backgroundColor: '#383b3d'
                        };
                        var defHandleStyle = {
                            border: "1px solid #E2B231"
                        };
                        var handleStyle = [defHandleStyle, defHandleStyle];
                        items = (
                            <Fragment>
                                <Typography gutterBottom>{item.Label}</Typography>
                                <Range handle={handle} trackStyle={trackStyle} railStyle={railStyle} handleStyle={handleStyle} min={min} max={max} defaultValue={value} tipFormatter={value => `${value}`} onAfterChange={this.onSliderChange(item.ID)} />
                            </Fragment>
                        );
                        break;
                    default:
                        break;
                }
                return (
                    <Grid container xs={12} key={key} className={classes.filterBlock}>
                        {items}
                    </Grid>
                );
            })}
        </Fragment>;
    }
    onSelectSortChange = (id) => (event, value) => {
        var { sort, onSortChange } = this.props;
        if (!sort) {
            sort = [];
        }
        //this.setState(state => {

        var item = sort.filter(item => item.ID === id)[0];
        if (value.length > 0) {
            //state.sort[id] = value[0];
            if (item) {
                sort[sort.indexOf(item)] = { ID: id, Value: value[0] };
            }
            else {
                sort.push({ ID: id, Value: value[0], Sort: "ASC" });
            }
        }
        else {
            //state.sort[id] = undefined;
            sort.length = sort.indexOf(item);
            //sort = sort.filter(item => item.ID !== id);
        }
        //state.sort.push(value);
        //return state;
        //});
        if (onSortChange) {
            onSortChange(sort);
        }
    }
    onSelectSortDirectionChange = (id) => (event, value) => {
        var { sort, onSortChange } = this.props;
        //this.setState(state => {
            var tempVal = "ASC";
            if (value.length > 0) {
                //state.sort[id] = value[0];
                tempVal = value[0].ID;
                
            }
            var item = sort.filter(item => item.ID === id)[0];
            if (item) {
                sort[sort.indexOf(item)].Sort = tempVal;
            }
            //state.sort.push(value);
            //return state;
        //});
        if (onSortChange) {
            onSortChange(sort);
        }
    }
    renderPanelSort = () => {
        var { sort } = this.props;
        if (!sort) {
            sort = [];
        }
        var max = sort.length + 1;
        return (
            <Grid container xs={12}>
                {sort.map((item, index) => {
                    console.log(item);
                    return (
                        <Fragment>
                            <Grid key={index} item xs={8} sm={2}>
                                <XSelect style={{ zIndex: "0 !important" }} label={"Campo " + (index + 1)} id={"sort_field_" + (index + 1)} value={item.Value.ID} idKey="ID" valueKey="Label" onChange={this.onSelectSortChange} urlData="/BOP/GetCatalogueSortableFields" noPortal={true} filtersExclude={sort.map(m => ({ value: m.Value.ID, key:'ID' })).filter(f => f.value !== item.Value.ID)} />
                            </Grid>
                            <Grid key={index} item xs={4} sm={1}>
                                <XSelect style={{ zIndex: "0 !important" }} label={"Ordinamento " + (index + 1)} id={"sort_field_" + (index + 1)} value={item.Sort} idKey="ID" valueKey="Label" onChange={this.onSelectSortDirectionChange} customData={[{ ID: "ASC", Label: "Crescente" }, { ID: "DESC", Label: "Decrescente" }]} noPortal={true} />
                            </Grid>
                        </Fragment>
                    );
                })}
                <Grid item xs={8} sm={2}>
                    <XSelect style={{ zIndex: "0 !important" }} label={"Campo " + max} id={"sort_field_" + max} value={null} idKey="ID" valueKey="Label" onChange={this.onSelectSortChange} urlData="/BOP/GetCatalogueSortableFields" noPortal={true} filtersExclude={sort.map(item => ({ value: item.Value.ID, key: 'ID' }))} />
                </Grid>
            </Grid>
        );
    }

    render() {
        var { open, onClose, onFilterSet, onClearFilters, onSaveTemplate, onDeleteTemplate, filters, classes, showOrder } = this.props;
        var panels = [...new Set(filters.map(item => item.Panel))].map(panel => ({
            icon: "code",
            label: panel,
            content: <Grid container xs={12}>{this.renderPanel(filters.filter(i => i.Panel === panel))}</Grid>
        }));
        if (showOrder) {
            panels.push({
                icon: "code",
                label: "Ordinamento",
                content: this.renderPanelSort(),
            });
        }

        var content = <Paper>
            <AppBar position="sticky">
                <DialogTitle className={classes.title} id="customized-dialog-title" onClose={onClose} onConfirm={onFilterSet} onClear={onClearFilters} onSaveTemplate={onSaveTemplate} onDeleteTemplate={onDeleteTemplate}>
                    Filtri avanzati
                </DialogTitle>
            </AppBar>
            <DialogContent>
                <XExpansionPanel panels={panels} />
            </DialogContent>
        </Paper>;
        if (!open) {
            content = false;
        }
        return (
            <Paper className={classes.root}>
                {content}
            </Paper>
        );
    }
}


export default withStyles(styles)(XFilters);