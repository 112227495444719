import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { Close, Check } from 'mdi-material-ui';


import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';
import XDialog from 'components/XDialog';
import XGrid from 'components/XGrid';
import XExpansionPanel from 'components/XExpansionPanel';
import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XSwitch from 'components/XSwitch';
import XUpload from 'components/XUpload';
import XOXLoader from 'components/XOXLoader';
import { CustomField } from 'components/CustomField';


import { getXSelect, getXField, getXSwitch, getXTag, getCookie, setCookie } from 'utils';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';


//import numeral from 'numeral';



const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
    },
});

class BOPEdit extends Component {
    constructor(props) {
        super(props);

        this.grdFiles = React.createRef();
        this.grdPricelists = React.createRef();
        this.grdStores = React.createRef();
        this.tabs = React.createRef();
        this.cboFIDVat = React.createRef();
        //this.handleDataBlur = this.handleDataBlur.bind(this);
        this.handleSaveData = this.handleSaveData.bind(this);
    }
    static contextTypes = {
        router: PropTypes.object
    }
    state = {
        value: 0,
        data: {},
        initialData: {},
        firstLoad: true,
        file: {},
        pricelist: {},
        store: {},
        showMessage: false,
        errorMessage: '',
        dialog: {},
        fields: [],
    };
    componentDidMount() {
        this.clearSession();
        this.loadData();
    }
    loadData = () => {
        if (this.props.match.params.id && parseInt(this.props.match.params.id, 10) > 0) {
            fetch('/BOP/GetBOP/' + this.props.match.params.id)
                .then(this.handleErrors)
                .then(res => res.json())
                .then(data => {
                    this.setState(state => {
                        state.data = data;
                        if (state.firstLoad) {
                            state.initialData = JSON.parse(JSON.stringify(state.data));
                        }
                        state.firstLoad = false;
                        return state;
                    }, () => {
                        this.loadExtras();
                        //this.grdFiles.current.refresh();
                    });
                }).catch(error => { console.log(error) });
        }
        else {
            this.setState(state => {

                if (state.firstLoad) {
                    state.initialData = JSON.parse(JSON.stringify(state.data));
                }
                state.firstLoad = false;
                return state;
            });
        }
    }
    loadExtras = () => {
        var { data } = this.state;
        //var i = 0;
        var params = "";
        if (data.FIDGroup) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "FIDGroup=" + data.FIDGroup;
        }
        if (data.FIDTypology) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "FIDTypology=" + data.FIDTypology;
        }
        if (data.FIDCategory) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "FIDCategory=" + data.FIDCategory;
        }

        fetch('/BOP/GetBOPExtraTabs' + params).then(res => res.json()).then(extratabs => {
            fetch('/BOP/GetBOPExtraGroups' + params).then(res => res.json()).then(extragroups => {
                fetch('/BOP/GetBOPExtras' + params).then(res => res.json()).then(extras => {
                    this.setState({ extratabs, extragroups, extras });
                });
            });
        });
    }
    clearSession = () => {
        fetch('/BOP/ClearSession', {
            method: 'POST'
        }).then(res => {
        });
    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }

    checkForChanges = (event, caller, callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
        fetch('/BOP/HasChanges/', {
            method: 'POST'
        }).then(res => res.json())
            .then(data => {
                if (data.HasChanges || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
                    var buttons = [
                        {
                            label: "SI",
                            action: () => {
                                this.setState({ dialog: {} });
                                callbackYes();

                                //this.saveFile();
                            }
                        },
                        {
                            label: "NO",
                            action: () => {
                                this.setState({ dialog: {} });
                                callbackNo();
                            }
                        },
                        {
                            label: "ANNULLA",
                            action: () => {
                                this.setState({ dialog: {} });
                                callbackCancel();
                            }
                        },
                    ];

                    this.showDialog(buttons, "Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?");
                }
                else {
                    callbackNoChanges();
                }
            });
    }

    doExit = () => {
        fetch('/BOP/ClearSession', {
            method: 'POST'
        }).then(res => {
            this.context.router.history.goBack();
            //debugger;
            //this.context.router.history.push('/catalogue/');
        });
    }
    doNewData = () => {
        fetch('/BOP/ClearSession', {
            method: 'POST'
        }).then(res => {
            this.context.router.history.push('/BOPNew/');
        });
    }
    handleNewData = (event, caller) => {
        this.checkForChanges(event, caller,
            this.doNewData,
            () => {
                this.handleSaveData(event, this, () => {
                    this.doExit();
                });
            },
            this.doNewData,
            () => {
            }
        );
    }

    handleExit = (event) => {
        this.checkForChanges(event, this,
            this.doExit,
            () => {
                this.handleSaveData(event, this, () => {
                    this.doExit();
                });
            },
            this.doExit,
            () => { }
        );
    }
    handleSaveData = (event, caller, callback) => {
        this.doSaveData(callback);
    }
    handleCancelData = (event, caller) => {
        var FIDBOP = this.props.match.params.id;
        if (!FIDBOP) {
            FIDBOP = 0;
        }
        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.setState({ dialog: {} });

                    this.props.setTitle(undefined);
                    fetch('/BOP/DeleteBOP', {
                        method: 'POST',
                        body: JSON.stringify([FIDBOP])
                    }).then(res => {
                        var catData = JSON.parse(getCookie('catalogue_state'));
                        if (catData) {
                            catData.showItem = false;
                            catData.reloadData = true;
                            setCookie('catalogue_state', JSON.stringify(catData));
                        }
                        this.doExit();
                    });
                }
            },
            {
                label: "NO",
                action: () => {
                    this.setState({ dialog: {} });
                }
            },
        ];

        this.showDialog(buttons, "Cancellazione", "Confermi la cancellazione della distinta?");


    }
    doSaveData = (callback) => {
        var currentTab = this.tabs.current.getCurrentTab();
        this.setState({ isLoading: true }, () => {
            var { data } = this.state;
            var FIDBOP = this.props.match.params.id;
            if (!FIDBOP) {
                FIDBOP = 0;
            }
            fetch('/BOP/SaveBOP/' + FIDBOP, {
                body: JSON.stringify(data),
                method: 'POST'
            }).then(this.handleErrors).then(res => res.json()).then((data) => {
                if (this.props.match.params.id === undefined || parseInt(this.props.match.params.id, 10) === 0) {
                    this.context.router.history.push('/BOPEdit/' + data.id);
                    if (callback) {
                        callback();
                    }
                }
                this.setState({ firstLoad: true }, () => {
                    this.loadData();
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                        if (callback) {
                            callback();
                        }
                    });
                });
                
            })
                .catch(error => {
                    console.log(error);
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                    });
                });
        });
    }


    onFileSelectChange = (key) => (event, data) => {
        this.setState(state => {
            if (data.length > 0) {
                state.file[key] = data[0].ID;
            }
            else {
                state.file[key] = undefined;
            }
            return state;
        });
    }
    onFileChange = (key) => (event, data) => {
        this.setState(state => {
            if (data) {
                state.file[key] = data;
            }
            else {
                state.file[key] = undefined;
            }
            return state;
        });
    }

    onStoreSelectChange = (key) => (event, data) => {
        this.setState(state => {
            if (data.length > 0) {
                state.store[key] = data[0].ID;
            }
            else {
                state.store[key] = undefined;
            }
            return state;
        }, () => {
           
        });

    }
    onPricelistSelectChange = (key) => (event, data) => {
        this.setState(state => {
            if (data.length > 0) {
                state.pricelist[key] = data[0].ID;
            }
            else {
                state.pricelist[key] = undefined;
            }
            return state;
        }, () => {
            if (key === "FIDVat") {
                var vat = this.cboFIDVat.current.getSelectedData();
                this.setState(state => {
                    var { pricelist } = state;
                    pricelist.VatPercentage = vat.Percentage;
                    state.pricelist = this.calculateTotals(pricelist);
                    return state;
                })
            }
        });

    }
    calculateTotals = (pricelist) => {

        //price = data.PriceCurrency + " " + numeral(data.Price).format('0,0.00');
        //numeral(data.Price).format('0,0.00')
        pricelist.TotalPrice = (pricelist.TaxablePrice * (100 + parseFloat(pricelist.VatPercentage)) / 100).toFixed(2)
        if (!pricelist.Discount) {
            pricelist.Discount = 0;
        }
        if (pricelist.Discount > 0) {
            pricelist.DiscountedTaxablePrice = (pricelist.TaxablePrice * (100 - pricelist.Discount) / 100).toFixed(2);
            pricelist.DiscountedTotalPrice = (pricelist.DiscountedTaxablePrice * (100 + parseFloat(pricelist.VatPercentage)) / 100).toFixed(2);
        }
        else {
            pricelist.DiscountedTaxablePrice = pricelist.TaxablePrice;
            pricelist.DiscountedTotalPrice = pricelist.TotalPrice;
        }
        return pricelist;
    }
    calculateDiscountedTotals = (pricelist) => {
        if (!pricelist.Discount) {
            pricelist.Discount = 0;
        }
        if (pricelist.Discount > 0) {
            pricelist.DiscountedTaxablePrice = (pricelist.TaxablePrice * (100 - pricelist.Discount) / 100).toFixed(2);
            pricelist.DiscountedTotalPrice = (pricelist.DiscountedTaxablePrice * (100 + parseFloat(pricelist.VatPercentage)) / 100).toFixed(2);
        }
        else {
            pricelist.DiscountedTaxablePrice = pricelist.TaxablePrice;
            pricelist.DiscountedTotalPrice = pricelist.TotalPrice;
        }
        return pricelist;
    }
    calculateDiscountTaxable = (pricelist) => {

        if (!pricelist.Discount) {
            pricelist.Discount = 0;
        }
        pricelist.Discount = ((pricelist.TaxablePrice - pricelist.DiscountedTaxablePrice) * 100 / pricelist.TaxablePrice).toFixed(5);
        pricelist.DiscountedTotalPrice = (pricelist.DiscountedTaxablePrice * (100 + parseFloat(pricelist.VatPercentage)) / 100).toFixed(2);
        if (pricelist.Discount > 0) {
            pricelist.DiscountedTotalPrice = (pricelist.DiscountedTaxablePrice * (100 + parseFloat(pricelist.VatPercentage)) / 100).toFixed(2);
        }
        else {
            pricelist.DiscountedTotalPrice = pricelist.TotalPrice;
        }
        return pricelist;
    }
    calculateDiscountTotal = (pricelist) => {

        if (!pricelist.Discount) {
            pricelist.Discount = 0;
        }
        pricelist.Discount = ((pricelist.TotalPrice - pricelist.DiscountedTotalPrice) * 100 / pricelist.TotalPrice).toFixed(5);
        if (pricelist.Discount > 0) {
            pricelist.DiscountedTaxablePrice = (pricelist.TaxablePrice * (100 - pricelist.Discount) / 100).toFixed(2);
        }
        else {
            pricelist.DiscountedTaxablePrice = pricelist.TaxablePrice;
        }
        return pricelist;
    }
    calculateTaxablePrice = (pricelist) => {

        if (!pricelist.Discount) {
            pricelist.Discount = 0;
        }
        pricelist.TaxablePrice = (pricelist.TotalPrice * 100 / (100 + parseFloat(pricelist.VatPercentage))).toFixed(2);
        if (pricelist.Discount > 0) {
            pricelist.DiscountedTaxablePrice = (pricelist.TaxablePrice * (100 - pricelist.Discount) / 100).toFixed(2);
            pricelist.DiscountedTotalPrice = (pricelist.DiscountedTaxablePrice * (100 + parseFloat(pricelist.VatPercentage)) / 100).toFixed(2);
        }
        else {
            pricelist.DiscountedTaxablePrice = pricelist.TaxablePrice;
            pricelist.DiscountedTotalPrice = pricelist.TotalPrice;
        }
        return pricelist;
    }

    onPricelistChange = (key) => (event, data) => {
        this.setState(state => {
            if (data) {
                state.pricelist[key] = data;
            }
            else {
                state.pricelist[key] = undefined;
            }
            return state;
        }, () => {
                if (key === "TaxablePrice") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateTotals(pricelist);
                        return state;
                    });
                }
                else if (key === "Discount") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateDiscountedTotals(pricelist);
                        return state;
                    });
                }
                else if (key === "DiscountedTaxablePrice") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateDiscountTaxable(pricelist);
                        return state;
                    });
                }
                else if (key === "DiscountedTotalPrice") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateDiscountTotal(pricelist);
                        return state;
                    });
                }
                else if (key === "TotalPrice") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateTaxablePrice(pricelist);
                        return state;
                    });
                }
                else if (key === "VatPercentage") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateTotals(pricelist);
                        return state;
                    });
                }

        });
    }
    handleFileNew = () => {
        this.setState({ isFileEdit: true, file: {} });
    }
    handlePricelistNew = () => {
        this.setState({ isPricelistEdit: true, pricelist: {} });
    }
    handlePricelistCancel = () => {
        this.setState({ isPricelistEdit: false, pricelist: {} });
        this.grdPricelists.current.refresh();
    }
    handleStoreNew = () => {
        this.setState({ isStoreEdit: true, store: {} });
    }
    handleStoreCancel = () => {
        this.setState({ isStoreEdit: false, store: {} });
    }
    handleCancelFile = () => {
        this.setState({ isFileEdit: false, file: {} });
    }
    saveFile = () => {

        var { file } = this.state;

        var FIDBOP = this.props.match.params.id;
        if (!FIDBOP) {
            FIDBOP = 0;
        }

        const data = new FormData();

        data.append('file', file.File, file.File.name);
        data.append('FIDFileTypology', file.FIDFileTypology);
        data.append('Default', file.Default ? true : false);
        axios
            .post('/BOP/UploadFile/' + FIDBOP, data, {
                onUploadProgress: ProgressEvent => {

                },
            })
            .then(res => {
                this.setState({ isFileEdit: false, file: {} });
                this.grdFiles.current.refresh();
            });

    }
    savePricelist = () => {

        var { pricelist } = this.state;

        var FIDBOP = this.props.match.params.id;
        if (!FIDBOP) {
            FIDBOP = 0;
        }

        fetch('/BOP/SavePricelist/' + FIDBOP, {
            body: JSON.stringify(pricelist),
            method: 'POST'
        })
            .then(res => res.json()).then(data => {
                this.setState({ isPricelistEdit: false, pricelist: {} });
                this.grdPricelists.current.refresh();
            });

    }
    saveStore = () => {

        var { store } = this.state;

        var FIDBOP = this.props.match.params.id;
        if (!FIDBOP) {
            FIDBOP = 0;
        }

        fetch('/BOP/SaveStore/' + FIDBOP, {
            body: JSON.stringify(store),
            method: 'POST'
        })
            .then(res => res.json()).then(data => {
                this.setState({ isStoreEdit: false, store: {} });
                this.grdStores.current.refresh();
            });

    }
    handleSaveFile = () => {
        var { file } = this.state;
        if (file.File === {}) {
            return;
        }
        var FIDBOP = this.props.match.params.id;

        if (file.Default) {
            var tempFile = {
                ID: file.ID,
                FIDFileTypology: file.FIDFileTypology
            };
            if (!tempFile.ID) {
                tempFile.ID = 0;
            }
            fetch('/BOP/CheckDefaultFiles/' + FIDBOP, {
                body: JSON.stringify(tempFile),
                method: 'POST'
            }).then(res => res.json())
                .then(result => {
                    if (result.HasDefault) {
                        var buttons = [
                            {
                                label: "SI",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    this.saveFile();
                                }
                            },
                            {
                                label: "NO",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    //this.saveContact(FIDIdentity, isDestination, isRelation, data);
                                }
                            },
                        ];

                        this.showDialog(buttons, "File predefinito", "È già presente un record predefinito con questa tipologia. Confermi inserimento?");
                    }
                    else {
                        this.saveFile();
                    }
                });
        }
        else {
            this.saveFile();
        }

    }

    handlePricelistSave = () => {
        var { pricelist } = this.state;
        var FIDBOP = this.props.match.params.id;

        if (pricelist.Default) {
            var tempPricelist = {
                ID: pricelist.ID,
            };
            if (!tempPricelist.ID) {
                tempPricelist.ID = 0;
            }
            fetch('/BOP/CheckDefaultPricelists/' + FIDBOP, {
                body: JSON.stringify(tempPricelist),
                method: 'POST'
            }).then(res => res.json())
                .then(result => {
                    if (result.HasDefault) {
                        var buttons = [
                            {
                                label: "SI",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    this.savePricelist();
                                }
                            },
                            {
                                label: "NO",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    //this.saveContact(FIDIdentity, isDestination, isRelation, data);
                                }
                            },
                        ];

                        this.showDialog(buttons, "Listino predefinito", "È già presente un listino predefinito. Confermi inserimento?");
                    }
                    else {
                        this.savePricelist();
                    }
                });
        }
        else {
            this.savePricelist();
        }

    }
    handleStoreSave = () => {
        this.saveStore();
    }



    handleDataChange = (event, value, key, data) => {
        this.setState((state, props) => {
            state.data[key] = value;

            state.data = this.collectionsFix(data, state.data, key);
            //if (key === "Denomination" && !state.data["Alias"]) {
            //    state.data["Alias"] == value;
            //}
            return state;
        }, () => {
            if (["FIDGroup", "FIDTypology", "FIDCategory"].includes(key)) {
                this.loadExtras();
            }
        });
    }
    collectionsFix = (data, db, key) => {

        if (["FIDLine", "FIDSeries"].includes(key) && data && data.ID > 0) {
            db["FIDBrand"] = data.FIDBrand;
        }
        if (["FIDSeries"].includes(key) && data && data.ID > 0) {
            db["FIDLine"] = data.FIDLine;
        }
        if (["FIDBrand", "FIDLine"].includes(key)) {
            if (db["FIDSeries"] >= 0)
                db["FIDSeries"] = undefined;
        }
        if (["FIDBrand"].includes(key)) {
            if (db["FIDLine"] >= 0)
                db["FIDLine"] = undefined;
        }
        return db;
    }

    handleChange = (key) => (event, data) => {
        if (data.length > 0) {
            this.handleDataChange(event, data[0].ID, key, data[0]);
        }
        else {
            this.handleDataChange(event, undefined, key);
        }

        //if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
        //    this.setState({ ReloadFields: true });
        //}
    }
    handleFieldChange = (key) => (event, value) => {
        if (this.handleDataChange) {
            this.handleDataChange(event, value, key);
        }
        //if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
        //    this.setState({ ReloadFields: true });
        //}
    }

    renderPanelGeneral = () => {
        var { fields, data } = this.state;
        fields = [
            {
                Field: "FIDGroup",
                Visibility: true,
                Required: 2,
            },
            {
                Field: "FIDTypology",
                Visibility: true,
                Required: 2,
            },
            {
                Field: "FIDCategory",
                Visibility: true,
                Required: 2,
            },
            {
                Field: "TagIds",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "Code",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "CatalogCode",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "FIDAbbreviation",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "Description",
                Visibility: true,
                Required: 2,
            },
            {
                Field: "ExtendedDescription",
                Visibility: true,
                Required: 1,
            },
        ]

        var fieldGroup = getXSelect(data, fields, 12, 3, "FIDGroup", "Gruppo", this.handleChange, "/BOP/Group", "/BOP/Group");
        var fieldTypology = getXSelect(data, fields, 12, 3, "FIDTypology", "Tipologia", this.handleChange, "/BOP/Typology");
        var fieldCategory = getXSelect(data, fields, 12, 3, "FIDCategory", "Tag principale", this.handleChange, "/BOP/Category", "/BOP/Category");
        var fieldTag = getXTag(data, fields, 12, 3, "TagIds", "Tag", this.handleFieldChange, "/BOP/Tag", "/BOP/Tag");

        var fieldCode = getXField(data, fields, 12, 2, "Code", "Codice", this.handleFieldChange);
        var fieldCatalogCode = getXField(data, fields, 12, 2, "CatalogCode", "Codice catalogo", this.handleFieldChange);
        var fieldAbbreviation = getXSelect(data, fields, 12, 2, "FIDAbbreviation", "Sigla", this.handleChange, "/BOP/Abbreviation", "/BOP/Abbreviation");
        var fieldDescription = getXField(data, fields, 12, 3, "Description", "Descrizione", this.handleFieldChange);
        var fieldExtendedDescription = getXField(data, fields, 12, 3, "ExtendedDescription", "Descrizione estesa", this.handleFieldChange, { multiline: true, rows: 2 });

        return (
            <Grid container spacing={2}>
                {fieldTypology}
                {fieldGroup}
                {fieldCategory}
                {fieldTag}
                {fieldCode}
                {fieldCatalogCode}
                {fieldAbbreviation}
                {fieldDescription}
                {fieldExtendedDescription}
            </Grid>
        );
    }
    renderPanelCollections = () => {

        var { fields, data } = this.state;
        fields = [
            {
                Field: "FIDBrand",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "FIDLine",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "FIDSeries",
                Visibility: true,
                Required: 1,
            },
        ]

        var fieldBrand = getXSelect(data, fields, 12, 3, "FIDBrand", "Marca", this.handleChange, "/BOP/Brand", "/BOP/Brand");
        var fieldLine = getXSelect(data, fields, 12, 3, "FIDLine", "Collezione", this.handleChange, "/BOP/Line", "/BOP/Line", { filters: [{ key: "FIDBrand", value: data.FIDBrand }] });
        var fieldSeries = getXSelect(data, fields, 12, 3, "FIDSeries", "Serie", this.handleChange, "/BOP/Series", "/BOP/Series", { filters: [{ key: "FIDBrand", value: data.FIDBrand }, { key: "FIDLine", value: data.FIDLine }] });

        return (
            <Grid container spacing={2}>
                {fieldBrand}
                {fieldLine}
                {fieldSeries}
            </Grid>
        );
    }
    renderPanelMeasureUnits = () => {

        var { fields, data } = this.state;
        fields = [
            {
                Field: "FIDMeasureUnit1",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "MeasureUnitQty1",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "FIDMeasureUnit2",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "MeasureUnitQty2",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "FIDMeasureUnitCost",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "CalculationTypeQty",
                Visibility: true,
                Required: 1,
            },
        ]

        var fieldMeasureUnit1 = getXSelect(data, fields, 12, 2, "FIDMeasureUnit1", "U.M. principale", this.handleChange, "/Base/GetGCMeasureUnits", undefined,{ filtersExclude: [{ key: "ID", value: data.FIDMeasureUnit2 }] });
        var fieldMeasureUnitCost1 = getXField(data, fields, 12, 2, "MeasureUnitQty1", "Q.tà U.M. principale", this.handleFieldChange, { type: "number" });
        var fieldMeasureUnit2 = getXSelect(data, fields, 12, 2, "FIDMeasureUnit2", "U.M. secondaria", this.handleChange, "/Base/GetGCMeasureUnits", undefined, { filtersExclude: [{ key: "ID", value: data.FIDMeasureUnit1 }] });
        var fieldMeasureUnitCost2 = getXField(data, fields, 12, 2, "MeasureUnitQty2", "Q.tà U.M. secondaria", this.handleFieldChange, { type: "number" });
        var fieldMeasureUnitCost = getXSelect(data, fields, 12, 2, "FIDMeasureUnitCost", "Opzione di costo", this.handleChange, "/Base/GetGCMeasureUnits", undefined, { filtersOr: [{ key: "ID", value: data.FIDMeasureUnit1 }, { key: "ID", value: data.FIDMeasureUnit2 }] });
        var fieldCalculationTypeQty = getXSelect(data, fields, 12, 2, "CalculationTypeQty", "Tipo calcolo q.tà", this.handleChange, "/BOP/calculationtypeqty");


        return (
            <Grid container spacing={2}>
                {fieldMeasureUnit1}
                {fieldMeasureUnitCost1}
                {fieldMeasureUnit2}
                {fieldMeasureUnitCost2}
                {fieldMeasureUnitCost}
                {fieldCalculationTypeQty}
            </Grid>
        );
    }

    renderPanelCosts = () => {

        var { fields, data } = this.state;
        fields = [
            {
                Field: "UnitCost",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "TotalCost",
                Visibility: true,
                Required: 1,
            },
            {
                Field: "CalculatedCost",
                Visibility: true,
                Required: 1,
            },
        ]

        var fieldUnitCost = getXField(data, fields, 12, 3, "UnitCost", "Costo unitario", this.handleFieldChange, { type: "number" });
        var fieldTotalCost = getXField(data, fields, 12, 3, "TotalCost", "Costo totale", this.handleFieldChange, { type: "number" });
        var fieldCalculatedCost = getXSwitch(data, fields, 12, 3, "CalculatedCost", "Calcola costo", this.handleFieldChange);


        return (
            <Grid container spacing={2}>
                {fieldUnitCost}
                {fieldTotalCost}
                {fieldCalculatedCost}
            </Grid>
        );
    }

    renderPanelNotes  = () => {

        var { fields, data } = this.state;
        fields = [
            {
                Field: "InternalRemark",
                Visibility: true,
                Required: 3,
            },
            {
                Field: "PublicRemark",
                Visibility: true,
                Required: 3,
            },
        ]

        var fieldInternalRemark = getXField(data, fields, 12, 6, "InternalRemark", "Nota interna", this.handleFieldChange, { multiline: true });
        var fieldPublicRemark = getXField(data, fields, 12, 6, "PublicRemark", "Nota pubblica", this.handleFieldChange, { multiline: true });


        return (
            <Grid container spacing={2}>
                {fieldInternalRemark}
                {fieldPublicRemark}
            </Grid>
        );
    }

    renderTabGeneral() {
        //var { data } = this.state;


        var panels = [];
        panels.push({
            icon: "code",
            label: "Dati generali",
            content: this.renderPanelGeneral(),
        });
        panels.push({
            icon: "code",
            label: "Collezione",
            content: this.renderPanelCollections(),
        });
        panels.push({
            icon: "code",
            label: "Unità di misura",
            content: this.renderPanelMeasureUnits(),
        });
        panels.push({
            icon: "code",
            label: "Costi",
            content: this.renderPanelCosts(),
        });
        panels.push({
            icon: "code",
            label: "Note",
            content: this.renderPanelNotes(),
        });

        var ui = <XExpansionPanel panels={panels} />;

        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
    renderTabFiles() {
        var { file, data } = this.state;

        var fileHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={3}><XSelect label="Tipologia" id="FIDFileTypology" urlData="/Base/GetFileTypologies" urlCreate="/Base/CreateFileTypology" value={file.FIDFileTypology} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onFileSelectChange} /></Grid>
                    <Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={file.Default} onChange={this.onFileChange} /></Grid>
                    <Grid item xs={12} md={3}><XUpload label="File" id="File" value={file.File} onChange={this.onFileChange} /></Grid>
                </Grid>
            </Grid>
        </Grid>);

        var panels = [];
        panels.push({
            icon: "attachment",
            label: "Files",
            content: fileHeadFields,
        });
        var customContent = undefined;

        //var doubleClick = this.handleFileDoubleClick;
        var newClick = this.handleFileNew;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];
        if (this.state.isFileEdit) {
            customContent = (<XExpansionPanel panels={panels} />);
            //doubleClick = undefined;
            newClick = undefined;
            showFilterActivator = false;
            showColumnSelector = false;
            customActions = [
                (
                    <Tooltip title="Salva">
                        <IconButton aria-label="Salva" onClick={this.handleSaveFile} >
                            <Check />
                        </IconButton>
                    </Tooltip>
                ),
                (
                    <Tooltip title="Annulla">
                        <IconButton aria-label="Annulla" onClick={this.handleCancelFile} >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )
            ];
        }
        var customCells = {
            Preview: (data, column) => {
                return <img style={{
                    height: "auto", width: "auto", maxHeight: "103px", maxWidth: column.width - 10
                }} src={"data:image/png;base64, " + data.base64file} alt="" />
            }
        };
        var ui = (
            <XGrid
                innerRef={this.grdFiles}
                dataRoute={"/BOP/GetBOPFiles/" + data.ID + "?grid=tblFiles"}
                dataRouteColumns="/Base/GetColumns?grid=tblFiles"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblFiles"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblFiles"
                //onDoubleClick={doubleClick}
                dataRouteDelete={"/BOP/DeleteFile/" + data.ID}
                onNewButton={newClick}
                //onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
                customCells={customCells}
                rowHeight={105}
            />
        )

        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }

    handlePricelistGridDoubleClick = (event, data) => {
        this.grdPricelists.current.clearSelection();
        this.setState({ pricelist: data, isPricelistEdit: true });
    }
    handleStoreGridDoubleClick = (event, data) => {
        this.grdStores.current.clearSelection();
        this.setState({ store: data, isStoreEdit: true });
    }
    renderTabPricelists = () => {

        var { pricelist, data, isPricelistEdit } = this.state;

        var pricelistHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={3}><XSelect label="Listino" id="FIDPricelist" urlData="/GC/pricelist" value={pricelist.FIDPricelist} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onPricelistSelectChange} /></Grid>
                    <Grid item xs={12} md={3}><XSelect innerRef={this.cboFIDVat} label="IVA" id="FIDVat" urlData="/GC/vat" value={pricelist.FIDVat} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onPricelistSelectChange} /></Grid>
                    <Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={pricelist.Default} onChange={this.onPricelistChange} /></Grid>
                    <Grid item xs={12} md={4}><XField label="Imponibile" type="number" id="TaxablePrice" value={pricelist.TaxablePrice} onChange={this.onPricelistChange} /></Grid>
                    <Grid item xs={12} md={4}><XField label="IVA" type="number" id="VatPercentage" value={pricelist.VatPercentage} onChange={this.onPricelistChange} /></Grid>
                    <Grid item xs={12} md={4}><XField label="Importo totale" type="number" id="TotalPrice" value={pricelist.TotalPrice} onChange={this.onPricelistChange} /></Grid>
                    <Grid item xs={12} md={4}><XField label="Sconto" type="number" id="Discount" value={pricelist.Discount} onChange={this.onPricelistChange} /></Grid>
                    <Grid item xs={12} md={4}><XField label="Imponibile scontato" type="number" id="DiscountedTaxablePrice" value={pricelist.DiscountedTaxablePrice} onChange={this.onPricelistChange} /></Grid>
                    <Grid item xs={12} md={4}><XField label="Totale scontato" type="number" id="DiscountedTotalPrice" value={pricelist.DiscountedTotalPrice} onChange={this.onPricelistChange} /></Grid>
                </Grid>
            </Grid>
        </Grid>);

        var panels = [];
        panels.push({
            icon: "attachment",
            label: "Listino",
            content: pricelistHeadFields,
        });
        var customContent = undefined;

        var doubleClick = this.handlePricelistGridDoubleClick;
        var newClick = this.handlePricelistNew;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];
        if (isPricelistEdit) {
            customContent = (<XExpansionPanel panels={panels} />);
            doubleClick = undefined;
            newClick = undefined;
            showFilterActivator = false;
            showColumnSelector = false;
            customActions = [
                (
                    <Tooltip title="Salva">
                        <IconButton aria-label="Salva" onClick={this.handlePricelistSave} >
                            <Check />
                        </IconButton>
                    </Tooltip>
                ),
                (
                    <Tooltip title="Annulla">
                        <IconButton aria-label="Annulla" onClick={this.handlePricelistCancel} >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )
            ];
        }
        var ui = (
            <XGrid
                innerRef={this.grdPricelists}
                dataRoute={"/BOP/GetBopPricelists/" + data.ID + "?grid=grdBOPPricelists"}
                dataRouteColumns="/Base/GetColumns?grid=grdBOPPricelists"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdBOPPricelists"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdBOPPricelists"
                onDoubleClick={doubleClick}
                dataRouteDelete={"/BOP/DeletePricelist/" + data.ID}
                onNewButton={newClick}
                onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
            />
        )

        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
    renderTabStores = () => {

        var { store, data, isStoreEdit } = this.state;

        var storeHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={4}><XSelect label="Store" id="FIDStore" urlData="/GC/stores" value={store.FIDStore} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></Grid>
                    <Grid item xs={12} md={4}><XSelect label="Area" id="FIDStoreArea" urlData="/GC/storeareas" value={store.FIDStoreArea} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></Grid>
                    <Grid item xs={12} md={4}><XSelect label="Stato" id="Status" urlData="/enums/storepublishtype" value={store.Status} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></Grid>
                </Grid>
            </Grid>
        </Grid>);

        var panels = [];
        panels.push({
            icon: "attachment",
            label: "Store",
            content: storeHeadFields,
        });
        var customContent = undefined;

        var doubleClick = this.handleStoreGridDoubleClick;
        var newClick = this.handleStoreNew;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];
        if (isStoreEdit) {
            customContent = (<XExpansionPanel panels={panels} />);
            doubleClick = undefined;
            newClick = undefined;
            showFilterActivator = false;
            showColumnSelector = false;
            customActions = [
                (
                    <Tooltip title="Salva">
                        <IconButton aria-label="Salva" onClick={this.handleStoreSave} >
                            <Check />
                        </IconButton>
                    </Tooltip>
                ),
                (
                    <Tooltip title="Annulla">
                        <IconButton aria-label="Annulla" onClick={this.handleStoreCancel} >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )
            ];
        }
        var ui = (
            <XGrid
                innerRef={this.grdStores}
                dataRoute={"/BOP/GetBopStores/" + data.ID + "?grid=grdBOPStores"}
                dataRouteColumns="/Base/GetColumns?grid=grdBOPStores"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdBOPStores"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdBOPStores"
                onDoubleClick={doubleClick}
                dataRouteDelete={"/BOP/DeleteStores/" + data.ID}
                onNewButton={newClick}
                onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
            />
        )

        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }

    handleExtraFieldChange = (FIDExtra, isData) => (id) => (event, value) => {
        this.setState((state, props) => {
            var { data, extras } = state;

            var extraField = extras.filter(f => f.ID === FIDExtra)[0];

            var extra = {
                BoolValue: null,
                ComboValue: null,
                DateTimeValue: null,
                ExtraID: FIDExtra,
                ExtraType: extraField.Type,
                GroupValue: null,
                IntValue: null,
                StringValue: null,
                TagValue: null
            };
            if (data.Extras) {
                var extraFieldVal = data.Extras.filter(f => f.ExtraID === FIDExtra);
                if (extraFieldVal.length > 0) {
                    extra = extraFieldVal[0];
                }
            }
            var index = -1;
            if (data.Extras) {
                index = data.Extras.indexOf(extra);
            }
            else {
                data.Extras = [];
            }
            switch (extra.ExtraType) {
                case 0://string
                    extra.StringValue = value;
                    break;
                case 1://integer
                    extra.IntValue = value;
                    break;
                case 2://decimal
                    extra.DecimalValue = value;
                    break;
                case 3://datetime
                    if (value) {
                        extra.DateTimeValue = value.toDate();
                    }
                    else {
                        extra.DateTimeValue = value;
                    }
                    break;
                case 4://boolean
                    extra.BoolValue = value;
                    break;
                case 5://combobox
                    if (value.length > 0) {
                        extra.ComboValue = value[0].ID;
                    }
                    else {
                        extra.ComboValue = null;
                    }
                    break;
                case 6://tagbox
                    extra.TagValue = value;
                    break;
                case 7://component
                    //extra.StringValue = value;
                    break;
                case 8://componentcustomfields
                    //extra.StringValue = value;
                    break;
                default:
                    break;
            }
            if (index >= 0) {
                state.data.Extras[index] = extra;
            }
            else {
                if (!state.data.Extras) {
                    state.data.Extras = [];
                }
                state.data.Extras = state.data.Extras.concat(extra);
            }
            return state;
        });
    }
    renderExtraField = (field, index, saveUrl, loadGridUrl, loadDataUrl, deleteDataUrl) => {
        var { data } = this.state;
        var id = this.props.match.params.id;
        if (!id) {
            id = 0;
        }
        var extra = [];
        if (data.Extras) {
            extra = data.Extras.filter(f => f.ExtraID === field.ID);
        }
        return (<CustomField key={index} field={field} extra={extra} saveUrl={saveUrl} mainId={id} loadGridUrl={loadGridUrl} loadDataUrl={loadDataUrl} deleteDataUrl={deleteDataUrl} handleExtraFieldChange={this.handleExtraFieldChange} />);
    }
    renderExtraGroup = (tab, group) => {
        var { extras } = this.state;
        return (
            <Grid container spacing={2} >
                {extras.filter(f => ((tab && f.FIDExtraTab === tab.ID) || (!tab && !f.FIDExtraTab)) && ((group && f.FIDExtraGroup === group.ID) || (!group && !f.FIDExtraGroup))).map((item, index) => {
                    return this.renderExtraField(item, index, '/BOP/SaveTableCF', '/BOP/GetBOPTableCFData/', '/BOP/GetBOPTableCFRecords/', '/BOP/DeleteBOPTableCFRecords/');
                })}
            </Grid>
        );
    }
    renderExtraTab = (tab) => {
        var { extragroups, extras } = this.state;
        var FIDGroups = extras.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && m.FIDExtraGroup).map(m => m.FIDExtraGroup).filter((value, index, self) => self.indexOf(value) === index);
        var panels = extragroups.filter(f => FIDGroups.includes(f.ID)).map((item, index) => (
            {
                icon: "people",
                label: item.Description,
                content: this.renderExtraGroup(tab, item),
            }
        ));

        var extrasNoGroup = extras.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && !m.FIDExtraGroup);
        if (extrasNoGroup.length > 0) {
            panels = panels.concat({
                icon: "people",
                label: 'Custom',
                content: this.renderExtraGroup(tab, null),
            });
        }

        return (<TabContainer><XExpansionPanel panels={panels} /></TabContainer>);
    };

    render() {
        if (this.state.isLoading) {
            //return (<div>Loading...</div>);
            return <XOXLoader />
        }
        var { classes } = this.props;
        var { extratabs, extras } = this.state;
        var loctabs = [
            {
                tabName: "Generale",
                tabContent: this.renderTabGeneral()
            },
            {
                tabName: "Files",
                tabContent: this.renderTabFiles()
            },
            {
                tabName: "Listini",
                tabContent: this.renderTabPricelists(),
            },
            {
                tabName: "Store",
                tabContent: this.renderTabStores(),
            },
        ];

        if (extratabs) {
            var FIDTabs = extras.map(m => m.FIDExtraTab).filter((value, index, self) => self.indexOf(value) === index);
            loctabs = loctabs.concat(extratabs.filter(f => FIDTabs.includes(f.ID)).map((item, index) => ({
                tabName: item.Description,
                tabContent: this.renderExtraTab(item),
            })));
            var extrasNoTab = extras.filter(m => !m.FIDExtraTab);
            if (extrasNoTab.length > 0) {
                loctabs = loctabs.concat({
                    tabName: 'CUSTOM',
                    tabContent: this.renderExtraTab(null)
                });
            }
        }
        return (<div className={classes.root}>
            <Tabs
                innerRef={this.tabs}
                headerColor="primary"
                plainTabs={true}
                onExitClick={this.handleExit}
                onSaveClick={this.handleSaveData}
                onDeleteClick={this.handleCancelData}
                //onNewClick={this.handleNewData}
                //validateTab={this.validate}
                tabs={loctabs}
            />
            <XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
        </div>);
    }
}


BOPEdit.propTypes = {
    //classes: PropTypes.object.isRequired,
};

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(BOPEdit);