import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IREdit from '../views/IR/IREdit.jsx';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
        position: 'relative',
    },
});

class IdentitiesEdit extends Component {
    state = {
        userID: undefined,
    };
    componentDidMount() {
        var { userProfile } = this.props;
        if (userProfile) {
            fetch('/base/getuserid').then(res => res.json()).then(data => this.setState({ userID: data }));
        }
    }
    render() {
        var { userProfile } = this.props;
        var { userID } = this.state;
        if (userProfile) {
            if (userID) {
                return (<IREdit id={userID} userProfile={userProfile} />);
            }
            return (<div></div>);
        }
        else {
            return (<IREdit id={this.props.match.params.id} />);
        }
    }
}

IdentitiesEdit.propTypes = {
    //classes: PropTypes.object.isRequired,
};

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(IdentitiesEdit);
