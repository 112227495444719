import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { Collage, CalendarRange } from 'mdi-material-ui';
import classNames from 'classnames';

import {
    successColor,
    tooltip,
    cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

const fullHeight = (window.innerHeight - 72.5 - 64);

const styles = theme => ({
    root: {
        maxHeight: fullHeight - 60,
        minHeight: fullHeight - 60,
        //overflow: 'hidden',
    },
    cardBody: {
        maxHeight: 'calc(100% - 56px)',
        overflowY: 'auto',
    },
    cardCategory: {
        color: "#999999",
        fontSize: 26,
        paddingTop: 17,
        marginBottom: "0",
        marginTop: "0",
        margin: "0"
    },
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px"
    },
    stats: {
        color: "#999999",
        fontSize: "12px",
        lineHeight: "22px",
        display: "inline-flex",
        "& svg": {
            position: "relative",
            top: "4px",
            width: "16px",
            height: "16px",
            marginRight: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            position: "relative",
            top: "4px",
            fontSize: "16px",
            marginRight: "3px"
        }
    },
    item: {
        position: 'relative',
        padding: '0 !important',
        cursor: 'pointer',
    },
    itemBackground: {
        paddingTop: 'calc(100% - 10px)',
        background: '#fff',
        border: '1px solid #bbb',
        width: 'calc(100% - 10px)',
        margin: 5
    },
    itemContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        maxWidth: '100%',
        maxHeight: '100%',
        textAlign: 'center',
        fontSize: 20,
        color: '#494949',
        fontWeight: 'bold',
    },
    itemBackgroundAlt: {
        paddingTop: 'calc(100% - 10px)',
        background: '#494949',
        border: '1px solid #bbb',
        width: 'calc(100% - 10px)',
        margin: 5
    },
    itemContentAlt: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        maxWidth: '100%',
        maxHeight: '100%',
        textAlign: 'center',
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
    },
    itemContentWithBackground: {
        top: 'unset',
        bottom: -10,
        background: 'rgba(0,0,0,0.7)',
        width: 'calc(100% - 12px)',
        padding: '5px 0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#fff',
        fontSize: 14
    }
});

const defaultFilters = {
    ShowModels: false,
    ShowStock: false,
    ImageView: 2,
    GroupType: 1,
    Sort: [],
};
GetParam("CatalogueDefaultShowCatalogue").then(result => defaultFilters.ShowModels = result);
GetParam("CatalogueDefaultShowStock").then(result => defaultFilters.ShowStock = result);
GetParam("CatalogueDefaultGroupType").then(result => defaultFilters.GroupType = result);
GetParam("CatalogueDefaultImageView").then(result => defaultFilters.ImageView = result);

async function GetParam(param) {
    return await fetch('/Base/GetParam?param=' + param).then(res => res.json());
}


class CatalogPreview extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    state = {
        data: undefined,
        collections: undefined,
        currentFilters: Object.assign({}, defaultFilters),
        labelKey: undefined,
        paramType: undefined,
        urlImage: undefined,
        backgroundSize: undefined,
    };
    componentDidMount = async () => {
        var { currentFilters } = this.state;
        var showDB = currentFilters.ShowModels ? true : false;
        var showDM = currentFilters.ShowStock ? true : false;
        var groupType = currentFilters.GroupType;
        var imageView = currentFilters.ImageView;

        var prms = '?showDB=' + showDB +
            '&showDM=' + showDM +
            '&groupType=' + groupType +
            '&imageView=' + imageView;

        var data = await fetch('/BOP/GetCatalogueCollections/' + prms).then(res => res.json());
        var viewType = 'brands';
        var url = "/BOP/GetBrandInfo/";
        var urlImage = "/BOP/BrandDefaultImage/";
        var idKey = "FIDBrand";
        var backgroundSize = "contain";
        var labelKey = false;

        var collections = data.brands;
        if (collections.length <= 1) {
            collections = data.lines;
            viewType = 'lines';
            url = "/BOP/GetLineInfo/";
            urlImage = "/BOP/LineDefaultImage/";
            idKey = "FIDLine";
            labelKey = "Line";
            backgroundSize = "cover";
            if (collections.length <= 1) {
                collections = data.series;
                viewType = 'series';
                url = "/BOP/GetSeriesInfo/";
                urlImage = "/BOP/SeriesDefaultImage/";
                idKey = "FIDSeries";
                labelKey = "Series";
                if (collections.length <= 1) {
                    viewType = 'products';
                    url = false;
                }
            }
        }
        //collections = collections.slice(0, 5);
        if (url) {
            for (var i = 0; i < collections.length; i++) {
                var coll = collections[i];
                var info = await fetch(url + coll[idKey]).then(res => res.json());
                collections[i] = info;
            }
        }
        this.setState({ data, collections, labelKey, paramType: viewType, urlImage, backgroundSize, isLoading: false });
    }
    onItemClick = (link) => () => {
        this.context.router.history.push(link);
    }
    renderItem = (label, image, link, id, backgroundSize = "cover") => {
        var { classes } = this.props;
        var style = {};
        var labelClass = "";
        if (image) {
            style = {
                background: 'url(' + image + ')',
                backgroundPosition: 'center',
                backgroundSize: backgroundSize,
                backgroundRepeat: 'no-repeat',
            };
            labelClass = classes.label;
        }
        return (<GridItem key={id} xs={6} className={classes.item} onClick={this.onItemClick(link)}>
            <div className={classes.itemBackground} style={style} />
            <div className={classNames(classes.itemContent, { [classes.itemContentWithBackground]: !!image && !!label})}>
                {!!label && <span className={labelClass}>{label}</span>}
            </div>
            
        </GridItem>);
    }
    renderItemAlt = (label, link) => {
        var { classes } = this.props;
        var labelClass = "";
        return (<GridItem xs={6} className={classes.item} onClick={this.onItemClick(link)}>
            <div className={classes.itemBackgroundAlt} />
            <div className={classes.itemContentAlt}>
                {!!label && <span className={labelClass}>{label}</span>}
            </div>

        </GridItem>);
    }
    renderBody = () => {
        var { data, collections, labelKey, paramType, urlImage, backgroundSize } = this.state;
        if (!collections) {
            return false;
        }
        return (
            <GridContainer>
                {collections.map((item, index) => {
                    var label = !!labelKey && item[labelKey];
                    var url = "/catalogue/?" + paramType + "=" + item.ID;
                    var image = urlImage + item.ID;
                    if (!item.HasImage) {
                        image = false;
                        if (!labelKey) {
                            label = item.Brand;
                        }
                    }
                    return this.renderItem(label, image, url, item.ID, backgroundSize);
                })}
                {this.renderItemAlt("CATALOGO COMPLETO", "/catalogue", 0)}
            </GridContainer>
        );
    }

    render() {
        var { classes } = this.props;
        return <Card className={classes.root}>
            <CardHeader color="info" stats icon>
                <CardIcon color="info">
                    <Collage />
                </CardIcon>
                <p className={classes.cardCategory}>Catalogo</p>
                <h3 className={classes.cardTitle}></h3>
            </CardHeader>
            <CardBody className={classes.cardBody}>
                {this.renderBody()}
            </CardBody>
        </Card>;
    }
}

export default withStyles(styles)(CatalogPreview);