import React, { Component } from 'react';

import PropTypes from 'prop-types';

import XExpansionPanel from 'components/XExpansionPanel';

//import XGrid from '../components/XGrid';
import XTag from '../../components/XTag';
import XSelect from '../../components/XSelect';
//import XSwitch from '../components/XSwitch';
import XField from '../../components/XField';
//import XButton from '../components/XButton';
import Grid from '@material-ui/core/Grid';

import IRContacts from './IRContacts.jsx';


class IRStandardFields extends Component {
    constructor(props) {
        super(props);

        this.contacts = React.createRef();
    }
    state = {
        fields: [],
        ReloadFields: true,
    };
    componentDidMount() {
        this.loadFields();
    }
    componentDidUpdate () {
        if (this.state.ReloadFields) {
            this.setState({ ReloadFields: false });
            this.loadFields();
        }
    }
    getGeoDataID = (FID) => {
        if (FID === "") {
            return undefined;
        }
        return FID;
    }

    handleChange = (key) => (event, data) => {
        if (data.length > 0) {
            this.props.onChange(event, data[0].ID, key, data[0]);
        }
        else {
            this.props.onChange(event, undefined, key);
        }

        if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
            this.setState({ ReloadFields: true });
        }
    }
    handleFieldChange = (key) => (event, value) => {
        if (this.props.onChange) {
            this.props.onChange(event, value, key);
        }
        if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
            this.setState({ ReloadFields: true });
        }
    }

    loadFields = () => {
        var { data } = this.props;
        var i = 0;

        var params = "";
        if (data.FIDGroup) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "FIDGroup=" + data.FIDGroup;
        }
        if (data.TypologyIds) {
            for (i = 0; i < data.TypologyIds.length; i++) {
                if (params !== "") {
                    params += "&";
                }
                else {
                    params += "?";
                }
                params += "TypologyIds[" + i + "]=" + data.TypologyIds[i];
            }
        }
        if (data.CategoryIds) {
            for (i = 0; i < data.CategoryIds.length; i++) {
                if (params !== "") {
                    params += "&";
                }
                else {
                    params += "?";
                }
                params += "CategoryIds[" + i + "]=" + data.CategoryIds[i];
            }
        }

        fetch('/IR/GetFields' + params).then(res => res.json()).then(fields => {
            this.setState({ fields });
        });
    }
    handleFieldBlur = (key) => (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event, key);
        }
    }
    refresh = () => {
        this.contacts.current.refresh();
    }

    getField = (fields, field) => {
        var filtered = fields.filter((item) => item.Field === field);
        if (filtered.length > 0) {
            return filtered[0];
        }

        return undefined;
    }
    validate = () => {
        var { fields } = this.state;
        var { data } = this.props;
        var isValid = true;
        var requiredFields = [];
        var optionalFields = [];

        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            if ((field.Required === 2 || (field.Required === 1 && !field.Validated)) && (!data[field.Field] || data[field.Field].length === 0)) {
                isValid = false;
                if (field.Required === 1) {
                    fields[i].Validated = true;
                    //this.setState((state, props) => {
                    //    debugger;
                    //    state.fields[i].Validated = true;
                    //    return state;
                    //});
                    optionalFields.push(field.Field);
                }
                else {
                    requiredFields.push(field.Field);
                }
            }
        }
        this.setState({ fields });
        return { isValid, requiredFields, optionalFields };
    }

    render() {
        var { data, showCodes } = this.props;
        var { fields } = this.state;


        var fieldGroup = this.getField(fields, "FIDGroup") && this.getField(fields, "FIDGroup").Visibility && (<Grid item xs={12} md={6}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDGroup")} label="Gruppo" id="FIDGroup" urlData="/Base/GetIRGroups" urlCreate="/IR/CreateGroup" value={data.FIDGroup} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldTypology = this.getField(fields, "TypologyIds") && this.getField(fields, "TypologyIds").Visibility && (<Grid item xs={12} md={6}><XTag readOnly={this.props.readOnly} field={this.getField(fields, "TypologyIds")} label="Tipologia" id="TypologyIds" urlData="/Base/GetIRTypologies" value={data.TypologyIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>);
        var fieldCategory = this.getField(fields, "CategoryIds") && this.getField(fields, "CategoryIds").Visibility && (<Grid item xs={12} md={6}><XTag readOnly={this.props.readOnly} field={this.getField(fields, "CategoryIds")} label="Tag" id="CategoryIds" urlData="/Base/GetIRCategories" urlCreate="/IR/CreateCategory" value={data.CategoryIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>);

        var fieldCode = this.getField(fields, "Code") && this.getField(fields, "Code").Visibility && (<Grid item xs={12} md={6}><XField readOnly={this.props.readOnly} field={this.getField(fields, "Code")} label="Codice" id="Code" value={data.Code} onChange={this.handleFieldChange} /></Grid>);
        var fieldDenomination = this.getField(fields, "Denomination") && this.getField(fields, "Denomination").Visibility && (<Grid item xs={12} sm={6} md={3}><XField readOnly={this.props.readOnly} field={this.getField(fields, "Denomination")} label="Denominazione" id="Denomination" value={data.Denomination} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>);
        var fieldAlias = this.getField(fields, "Alias") && this.getField(fields, "Alias").Visibility && (<Grid item xs={12} sm={6} md={3}><XField readOnly={this.props.readOnly} field={this.getField(fields, "Alias")} label="Alias" id="Alias" value={data.Alias} onChange={this.handleFieldChange} /></Grid>);
        var fieldName = this.getField(fields, "Name") && this.getField(fields, "Name").Visibility && (<Grid item xs={12} sm={6} md={3}><XField readOnly={this.props.readOnly} field={this.getField(fields, "Name")} label="Nome" id="Name" value={data.Name} onChange={this.handleFieldChange} /></Grid>);
        var fieldSurname = this.getField(fields, "Surname") && this.getField(fields, "Surname").Visibility && (<Grid item xs={12} sm={6} md={3}><XField readOnly={this.props.readOnly} field={this.getField(fields, "Surname")} label="Cognome" id="Surname" value={data.Surname} onChange={this.handleFieldChange} /></Grid>);
        var fieldVatnumber = this.getField(fields, "Vatnumber") && this.getField(fields, "Vatnumber").Visibility && (<Grid item xs={12} sm={6} md={3}><XField readOnly={this.props.readOnly} field={this.getField(fields, "Vatnumber")} label="P.IVA" id="Vatnumber" value={data.Vatnumber} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>);
        var fieldTaxCode = this.getField(fields, "Taxcode") && this.getField(fields, "Taxcode").Visibility && (<Grid item xs={12} sm={6} md={3}><XField readOnly={this.props.readOnly} field={this.getField(fields, "Taxcode")} label="Codice fiscale" id="Taxcode" value={data.Taxcode} onChange={this.handleFieldChange} /></Grid>);
        var fieldAddress = this.getField(fields, "Address") && this.getField(fields, "Address").Visibility && (<XField readOnly={this.props.readOnly} field={this.getField(fields, "Address")} label="Indirizzo" id="Address" value={data.Address} onChange={this.handleFieldChange} />);
        var fieldCity = this.getField(fields, "FIDCity") && this.getField(fields, "FIDCity").Visibility && (<Grid item xs={12} sm={6} md={3}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDCity")} label="Città" id="FIDCity" urlData="/Base/GetIRCities" urlCreate="/IR/CreateCity" filters={[{ key: "FIDCountry", value: data.FIDCountry }, { key: "FIDRegion", value: data.FIDRegion }, { key: "FIDProvince", value: data.FIDProvince }]} value={data.FIDCity} idKey="ID" valueKey="City" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldZip = this.getField(fields, "FIDZip") && this.getField(fields, "FIDZip").Visibility && (<Grid item xs={4} sm={2} md={2}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDZip")} label="CAP" id="FIDZip" urlData="/Base/GetIRZips" urlCreate="/IR/CreateZip" filters={[{ key: "FIDCountry", value: data.FIDCountry }, { key: "FIDRegion", value: data.FIDRegion }, { key: "FIDProvince", value: data.FIDProvince }, { key: "FIDCity", value: data.FIDCity }]} value={data.FIDZip} idKey="ID" valueKey="Zip" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldProvince = this.getField(fields, "FIDProvince") && this.getField(fields, "FIDProvince").Visibility && (<Grid item xs={8} sm={4} md={2}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDProvince")} label="Provincia" id="FIDProvince" urlData="/Base/GetIRProvinces" urlCreate="/IR/CreateProvince" filters={[{ key: "FIDCountry", value: data.FIDCountry }, { key: "FIDRegion", value: data.FIDRegion }]} value={data.FIDProvince} idKey="ID" valueKey="Province" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldRegion = this.getField(fields, "FIDRegion") && this.getField(fields, "FIDRegion").Visibility && (<Grid item xs={12} sm={6} md={2}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDRegion")} label="Regione" id="FIDRegion" urlData="/Base/GetIRRegions" urlCreate="/IR/CreateRegion" filters={[{ key: "FIDCountry", value: data.FIDCountry }]} value={data.FIDRegion} idKey="ID" valueKey="Region" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldCountry = this.getField(fields, "FIDCountry") && this.getField(fields, "FIDCountry").Visibility && (<Grid item xs={12} sm={6} md={3}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDCountry")} label="Nazione" id="FIDCountry" urlData="/Base/GetIRCountries" urlCreate="/IR/CreateCountry" value={data.FIDCountry} idKey="ID" valueKey="Country" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldInternalRemark = this.getField(fields, "Remarkinternal") && this.getField(fields, "Remarkinternal").Visibility && (<Grid item md={6} xs={12}><XField readOnly={this.props.readOnly} field={this.getField(fields, "Remarkinternal")} label="Nota interna" id="Remarkinternal" value={data.Remarkinternal} multiline={true} onChange={this.handleFieldChange} /></Grid>);
        var fieldPublicRemark = this.getField(fields, "Remarkpublic") && this.getField(fields, "Remarkpublic").Visibility && (<Grid item md={6} xs={12}><XField readOnly={this.props.readOnly} field={this.getField(fields, "Remarkpublic")} label="Nota pubblica" id="Remarkpublic" value={data.Remarkpublic} multiline={true} onChange={this.handleFieldChange} /></Grid>);
        var fieldDistributionLists = this.getField(fields, "DistributionListIds") && this.getField(fields, "DistributionListIds").Visibility && (<Grid item xs={12} md={6}><XTag readOnly={this.props.readOnly} field={this.getField(fields, "DistributionListIds")} label="Liste di distribuzione" id="DistributionListIds" urlData="/IR/DistributionList" urlCreate="/IR/DistributionList" value={data.DistributionListIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>);


        showCodes = true;
        var panels = [];
        if (showCodes) {
            panels.push({
                icon: "code",
                label: "Codifica",
                content: (<Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <img style={{ maxHeight: "145px", width: "auto", maxWidth: "300px", left: "50%", top: "50%", position: "relative", transform: "translate(-50%,-50%)" }} alt="" src={"/IR/DefaultImage/" + data.ID + "?" + (new Date()).getTime()} />
                    </Grid>
                    <Grid item xs={12} sm={6} container>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            {fieldGroup}
                            {fieldTypology}
                        </Grid>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            {fieldCode}
                        </Grid>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            {fieldCategory}
                            {fieldDistributionLists}
                        </Grid>
                    </Grid>
                </Grid>),
            });
        }

        if (fieldDenomination || fieldAlias || fieldName || fieldSurname || fieldVatnumber || fieldTaxCode || fieldAddress || fieldCity || fieldZip || fieldProvince || fieldRegion || fieldCountry) {
            panels.push({
                icon: "perm_identity",
                label: "Dati Anagrafici",
                content: (<Grid container spacing={2} >
                    <Grid item container xs={12} direction="row" spacing={2}>
                        {fieldDenomination}
                        {fieldAlias}
                        {fieldName}
                        {fieldSurname}
                        {fieldVatnumber}
                        {fieldTaxCode}
                    </Grid>
                    <Grid item xs={12}>
                        {fieldAddress}
                    </Grid>
                    <Grid item container xs={12} direction="row" spacing={2}>
                        {fieldCity}
                        {fieldZip}
                        {fieldProvince}
                        {fieldRegion}
                        {fieldCountry}
                    </Grid>
                </Grid>),
            });
        }
        var identityId = this.props.id;
        if (identityId === undefined) {
            identityId = 0;
        }
        panels.push({
            icon: "contact_phone",
            label: "Recapiti",
            content: (<IRContacts innerRef={this.contacts} FIDIdentity={identityId} isDestination={this.props.isDestination} isRelation={this.props.isRelation} />),
        });
        if (fieldInternalRemark || fieldPublicRemark) {
            panels.push({
                icon: "note_add",
                label: "Note",
                content: (<Grid container spacing={2}>
                    {fieldInternalRemark}
                    {fieldPublicRemark}
                </Grid>),
            });
        }
        return (<XExpansionPanel
            panels={panels}
        />);
    }
}



IRStandardFields.propTypes = {
    data: PropTypes.object.isRequired,
    dataKey: PropTypes.string.isRequired,
    id: PropTypes.number,
    caller: PropTypes.object.isRequired,
    showCodes: PropTypes.bool,
    isIdentity: PropTypes.bool,
    isDestination: PropTypes.bool,
    isRelation: PropTypes.bool,
};

export default IRStandardFields;