import React, { Component } from 'react'
import PropTypes from 'prop-types';


import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import XGrid from "components/XGrid"


import "components/XReactDataGrid.css";
import { Printer, ArrowLeft } from 'mdi-material-ui';

class Stock extends Component {
    constructor(props) {
        super(props);
        this.grdStock = React.createRef();
    }
    static contextTypes = {
        router: PropTypes.object
    }
    state = {
        print: false,
        printRows: [],
    };
    print = () => {
        var selectedRows = this.grdStock.current.getSelectedData();
        this.grdStock.current.clearSelection();
        this.setState({ print: true, printRows: selectedRows });
    }
    back = () => {

        this.setState({ print: false, printRows: [] });
    }
    render() {
        var { print, printRows } = this.state;
        var customContent = undefined;
        var showFilterActivator = !print;
        var showColumnsSelector = !print;
        var selectedActions = [<Tooltip title="Stampa">
            <IconButton aria-label="Stampa" onClick={this.print}>
                <Printer />
            </IconButton>
        </Tooltip>];
        var actions = [];

        if (print) {
            var s = "";
            for (var i = 0; i < printRows.length; i++) {
                s += "&FIDBOPs=" + printRows[i];
            }
            var url = "/BOP/PrintBOPTag/?" + s;
            console.log(url);
            customContent = <embed src={url} type="application/pdf" width="100%" height={document.body.offsetHeight - 259} />;
            actions = [<Tooltip title="Indietro">
                <IconButton aria-label="Indietro" onClick={this.back}>
                    <ArrowLeft />
                </IconButton>
            </Tooltip>];
        }


        return (
            <XGrid
                usePrimaryColor={true}
                ref={this.grdStock}
                dataRoute="/BOP/GetStock?grid=tblSTOCK"
                dataRouteColumns="/Base/GetColumns?grid=tblSTOCK"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblSTOCK"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblSTOCK"
                selectedActions={selectedActions}
                customContent={customContent}
                actions={actions}
                showColumnsSelector={showColumnsSelector}
                showFilterActivator={showFilterActivator}
            />
        );
    }
}

export default Stock;