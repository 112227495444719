import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { Star, StarOutline, LockOpenOutline, LockOutline, CartOutline, BellRingOutline, BellOffOutline, StarOff } from 'mdi-material-ui';
import { LockOffOutline, CartOffOutline } from 'components/Icons';


import XButton from 'components/XButton';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';

import {
    grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
    root: {
        width: '100%',
    },
    stars: {
        fontSize: 40,
        cursor: "pointer",
    },
    marginTop: {
        marginTop: "10px",
    },
    buttonsWrapper: {
        width: "100%",
        "& > *": {
            display: "inline-block",
        }
    },
    catalogueButton2: {
        width: 50,
        height: 50,
        padding: 0,
        marginRight: "10px",
        "& > * ": {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
        }
    },
});

class CatalogueActions extends Component {
    state = {
        HoverStar: 0,
    };

    StarEnter = (star) => {
        this.setState({ HoverStar: star });
    }
    StarExit = (star) => {
        setTimeout(() => {
            this.setState(state => {
                if (state.HoverStar === star) {
                    state.HoverStar = undefined;
                }
                return state;
            });
        }, 100);
    }
    StarItem = (star) => {
        var { data, onStarItem } = this.props;
        if (onStarItem) {
            onStarItem(data, star);
        }
    }
    doBuy = () => {
        var { data, onBuyItem } = this.props;
        if (onBuyItem) {
            onBuyItem(data);
        }

    }
    BuyItem = () => {
        var { IsReservedOther, IsBookedOther } = this.state;

        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        this.doBuy();
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato " + (IsReservedOther ? "riservato" : "prenotato") + " da un'altro cliente. Procedere con l'operazione?");
        }
        else {
            this.doBuy();
        }
    }
    NoticeItem = () => {
        var { data, onNoticeItem } = this.props;
        if (onNoticeItem) {
            onNoticeItem(data);
        }
    }
    ReserveItem = () => {
        var { data, onReserveItem } = this.props;
        var { IsReservedOther, IsBookedOther } = this.state;

        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        if (onReserveItem) {
                            onReserveItem(data);
                        }
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato " + (IsReservedOther ? "riservato" : "prenotato") + " da un'altro cliente. Procedere con l'operazione?");
        }
        else {

            if (onReserveItem) {
                onReserveItem(data);
            }
        }

    }
    BookItem = () => {
        var { data, onBookItem } = this.props;
        var { IsReservedOther, IsBookedOther } = this.state;


        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        if (onBookItem) {
                            onBookItem(data);
                        }
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato " + (IsReservedOther ? "riservato" : "prenotato") + " da un'altro cliente. Procedere con l'operazione?");
        }
        else {

            if (onBookItem) {
                onBookItem(data);
            }
        }
    }
    RemoveItem = (FIDCausal) => () => {
        var { data, onRemoveItem } = this.props;
        if (onRemoveItem) {
            onRemoveItem(data, FIDCausal);
        }
    }

    render() {
        var { HoverStar } = this.state;
        var { documents, classes } = this.props;
        var isPresentation = documents.filter(i => i.FIDCausal === this.props.params.DOCPresentationCausalId).length > 0;
        var isCart = documents.filter(i => i.FIDCausal === this.props.params.DOCCartCausalId).length > 0;
        var isNotice = documents.filter(i => i.FIDCausal === this.props.params.DOCNoticeCausalId).length > 0;
        var isReservation = documents.filter(i => i.FIDCausal === this.props.params.DOCReservationCausalId).length > 0;
        var isBooking = documents.filter(i => i.FIDCausal === this.props.params.DOCBookingCausalId).length > 0;
        var color = grayColor;
        var showStars = true;
        if (isPresentation) {
            if (!HoverStar) {
                HoverStar = documents.filter(i => i.FIDCausal === this.props.params.DOCPresentationCausalId)[0].Stars;
            }
            showStars = true;
        }
        switch (HoverStar) {
            case 1:
                color = "#E74C3C";
                break;
            case 2:
                color = "#F39C12";
                break;
            case 3:
                color = "#F7DC6F";
                break;
            case 4:
                color = "#82E0AA";
                break;
            case 5:
                color = "#27AE60";
                break;
            default:
                break;
        }

        return (
            <div className={classes.root}>
                <Grid item container xs={12} className={classes.marginTop}>
                    <div className={classes.buttonsWrapper}>
                        {<Tooltip title={!isPresentation ? "Aggiungi a presentazione" : "Rimuovi da presentazione"}>
                            <XButton className={classes.catalogueButton2} color={!isPresentation ? "primary" : "gray"} onClick={!isPresentation ? () => this.StarItem(-1) : this.RemoveItem(this.props.params.DOCPresentationCausalId)}>{!isPresentation ? <Star /> : <StarOff />}</XButton>
                        </Tooltip>}
                        <Tooltip title={!isNotice ? "Avviso" : "Rimuovi avviso"}>
                            <XButton className={classes.catalogueButton2} color={!isNotice ? "primary" : "gray"} onClick={!isNotice ? this.NoticeItem : this.RemoveItem(this.props.params.DOCNoticeCausalId)}>{!isNotice ? <BellRingOutline /> : <BellOffOutline />}</XButton>
                        </Tooltip>
                        <Tooltip title={!isReservation ? "Riserva" : "Rimuovi da riservati"}>
                            <XButton className={classes.catalogueButton2} color={!isReservation ? "primary" : "gray"} onClick={!isReservation ? this.ReserveItem : this.RemoveItem(this.props.params.DOCReservationCausalId)}>{!isReservation ? <LockOpenOutline /> : <LockOffOutline />}</XButton>
                        </Tooltip>
                        <Tooltip title={!isBooking ? "Prenota" : "Sprenota"}>
                            <XButton className={classes.catalogueButton2} color={!isBooking ? "primary" : "gray"} onClick={!isBooking ? this.BookItem : this.RemoveItem(this.props.params.DOCBookingCausalId)}>{!isBooking ? <LockOutline /> : <LockOffOutline />}</XButton>
                        </Tooltip>
                        <Tooltip title={!isCart ? "Aggiungi al carello" : "Rimuovi dal carelli"}>
                            <XButton className={classes.catalogueButton2} color={!isCart ? "primary" : "gray"} onClick={!isCart ? this.BuyItem : this.RemoveItem(this.props.params.DOCCartCausalId)}>{!isCart ? <CartOutline /> : <CartOffOutline />}</XButton>
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item container xs={12} className={classes.marginTop}>
                    {showStars && <Grid item xs={12} className={classes.stars}>
                        {HoverStar >= 1 ? <Star className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(1)} onMouseLeave={() => this.StarExit(1)} htmlColor={color} onClick={() => this.StarItem(1)} /> : <StarOutline className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(1)} onMouseLeave={() => this.StarExit(1)} htmlColor={color} />}
                        {HoverStar >= 2 ? <Star className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(2)} onMouseLeave={() => this.StarExit(2)} htmlColor={color} onClick={() => this.StarItem(2)} /> : <StarOutline className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(2)} onMouseLeave={() => this.StarExit(2)} htmlColor={color} />}
                        {HoverStar >= 3 ? <Star className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(3)} onMouseLeave={() => this.StarExit(3)} htmlColor={color} onClick={() => this.StarItem(3)} /> : <StarOutline className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(3)} onMouseLeave={() => this.StarExit(3)} htmlColor={color} />}
                        {HoverStar >= 4 ? <Star className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(4)} onMouseLeave={() => this.StarExit(4)} htmlColor={color} onClick={() => this.StarItem(4)} /> : <StarOutline className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(4)} onMouseLeave={() => this.StarExit(4)} htmlColor={color} />}
                        {HoverStar >= 5 ? <Star className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(5)} onMouseLeave={() => this.StarExit(5)} htmlColor={color} onClick={() => this.StarItem(5)} /> : <StarOutline className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(5)} onMouseLeave={() => this.StarExit(5)} htmlColor={color} />}
                    </Grid>}
                </Grid>
            </div>
        );
    }
}

const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(CatalogueActions);