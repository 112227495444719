import React, { Component, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CardMedia from '@material-ui/core/CardMedia';
import XGalleryThumbs from 'components/XGalleryThumbs';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import { compose } from 'redux';
import { PanZoom } from 'react-easy-panzoom'


const styles = theme => ({
    media: {
        height: 0,
        //paddingTop: 'calc(56.25% / 1.5)', // 16:9
        paddingTop: '56%',
        maxHeight: '80vh',
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)',
        backgroundSize: 'contain',
    },
    mediaButtonLeft: {
        position: "absolute",
        left: "0",
        top: "50%",
    },
    mediaButtonRight: {
        position: "absolute",
        right: "0",
        top: "50%",
    },
    zoom: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: window.innerHeight - 72.5 - 64 - 64,
        zIndex: 1099,
        background: '#fff',
    },
    closeButton: {
        position: "absolute",
        right: 13,
        top: 5,
        zIndex: 1099,
    },
    zoomImage: {
        maxWidth: '100%',
        maxHeight: window.innerHeight - 72.5 - 64 - 64,
        width: 'auto',
        height: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',
    }
});



class XGallery extends Component {
    constructor(props) {
        super(props);

        this.Gallery = React.createRef();
    }
    state = {
        imageIndex: 0,
        height: 0,
        viewZoom: false,
    };
    componentDidMount() {
        var height = 300;
        if (this.Gallery.current) {
            height = this.Gallery.current.rootNode.clientHeight;
        }
        this.setState({ height });
    }
    componentWillUnmount() {
        var mc = document.getElementById('mainContent');
        mc.style.overflow = null;
    }
    handleNext = () => {

        var { imageIndex } = this.state;
        var { imageIds } = this.props;
        //var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = imageIds.length;
        if (imageIndex < imagesCount - 1) {
            this.setState({ imageIndex: imageIndex + 1 });
        }
        else {
            this.setState({ imageIndex: 0 });
        }
    }
    handlePrevious = () => {
        var { imageIndex } = this.state;
        var { imageIds } = this.props;
        //var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = imageIds.length;
        if (imageIndex > 0) {
            this.setState({ imageIndex: imageIndex - 1 });
        }
        else {
            this.setState({ imageIndex: imagesCount - 1 });
        }
    }
    handleChangeIndex = (index) => {
        this.setState({ imageIndex: index });
    }
    onMediaClick = () => {
        this.setState({ viewZoom: true }, () => {
            var mc = document.getElementById('mainContent');
            mc.scroll(0, 0);
            mc.style.overflow = 'hidden';
        });
    }
    onCloseZoom = () => {
        this.setState({ viewZoom: false }, () => {
            
            var mc = document.getElementById('mainContent');
            mc.style.overflow = null;
        });
    }
    

    render() {
        var { classes, imageIds, width } = this.props;
        var { imageIndex, height, viewZoom } = this.state;

        var mediaFiles = imageIds.length > 0 ? imageIds.map((file, index) => (<CardMedia onClick={this.onMediaClick} key={index} className={classes.media} image={"/Base/Image/" + file} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        return (
            <Grid container xs={12}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                    <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex} ref={this.Gallery}>
                        {mediaFiles}
                    </SwipeableViews>
                    {imageIds.length > 1 &&
                        (
                            <Fragment>
                                <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                                <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                            </Fragment>
                        )
                    }
                </Grid>
                {imageIds.length > 1 &&
                    <Grid item xs={12}>
                        <XGalleryThumbs imageIds={imageIds} height={height} onChange={this.handleChangeIndex} />
                    </Grid>
                }
                {viewZoom &&
                    (
                        <div className={classes.zoom}>
                            <div className={classes.closeButton} >
                                <IconButton onClick={this.onCloseZoom}>
                                    <CloseIcon />
                                </IconButton>
                        </div>
                        <PanZoom autoCenter={true} maxZoom={4} minZoom={1} enableBoundingBox={true} boundaryRatioHorizontal={0.8} boundaryRatioVertical={0.8} realPinch={true}>
                            <img className={classes.zoomImage} src={"/Base/Image/" + imageIds[imageIndex]} alt="" />
                        </PanZoom>
                        </div>
                    )
                }
            </Grid>
        );
    }
}



const enhance = compose(
    withWidth(),
    withStyles(styles)
);


export default enhance(XGallery);
