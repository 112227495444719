import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CatalogueCard from 'components/CatalogueCard';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
//import { Tabs } from '@material-ui/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';
import TabContainer from 'components/CustomTabs/TabContainer';
import IdentitySells from 'views/IR/IdentitySells.jsx';
import CrossSell from 'views/IR/CrossSell.jsx';

import { compose } from 'redux';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
        '&>header': {
            top: 64,
            zIndex: 1099,
        }
    },
    box: {
    },
    label: {
        display: "block",
        backgroundColor: "#E2B231",
        width: "100%",
        textAlign: "center",
        padding: "1px",
        fontWeight: "bold",
    },
    yearBox: {
        display: "inline-block",
        width: "calc(33.33% - 6px)",
        padding: "45px 0",
        margin: "3px",
        textAlign: "center",
        backgroundColor: "#f5f5f5",
        fontWeight: "bold",
        cursor: "pointer",
    },
    yearBoxActive: {
        display: "inline-block",
        width: "calc(33.33% - 6px)",
        padding: "45px 0",
        margin: "3px",
        textAlign: "center",
        backgroundColor: "#353535",
        color: "#fff",
        fontWeight: "bold",
        cursor: "pointer",
    },
    sellItem: {
        display: "inline-block",
        width: "calc(33.33% - 6px)",
        margin: "3px",
        textAlign: "center",
    },
    sellLabel: {
        display: "block",
        width: "100%",
        fontSize: "10px",
    },
    sellValue: {
        display: "block",
        width: "100%",
        fontSize: "12px",
        fontWeight: "bold",
    },
    tabsPositionSticky: {
        top: 64,
        zIndex: 1099,
    }
});

var storico = {
    2017: [
        { ID: 1,Barcode: '001701845', Position: 'Negozio 1', Price: 8200.00, PriceCurrency: '€', Brand: 'Rolex', Line: 'Oyster Perpetual', Code: '116710BLNR-001', SellDate: new Date(2017, 11, 31), },
        { ID: 2,Barcode: '001701838', Position: 'Negozio 1', Price: 7300.00, PriceCurrency: '€', Brand: 'Rolex', Line: 'Oyster Perpetual', Code: '116710BLNR-003', SellDate: new Date(2017, 10, 29), },
        { ID: 3,Barcode: '001701885', Position: 'Negozio 1', Price: 8500.00, PriceCurrency: '€', Brand: 'Rolex', Line: 'Date', Code: '116710BLNR-003', SellDate: new Date(2017, 11, 30), },
        { ID: 4,Barcode: '001758512', Position: 'Negozio 1', Price: 1300.00, PriceCurrency: '€', Brand: 'Cartier', Line: '', Code: '11235', SellDate: new Date(2017, 11, 24), },
        { ID: 5,Barcode: '001704568', Position: 'Negozio 1', Price: 1500.00, PriceCurrency: '€', Brand: 'Gerbella', Line: '', Code: 'S854D6', SellDate: new Date(2017, 10, 23), },
        { ID: 6,Barcode: '001700056', Position: 'Negozio 1', Price: 700.00, PriceCurrency: '€', Brand: 'Venini', Line: '', Code: '12235-55', SellDate: new Date(2017, 11, 18), },
    ],
    2018: [
        { ID: 6,Barcode: '001801845', Position: 'Negozio 1', Price: 8300.00, PriceCurrency: '€', Brand: 'Rolex', Line: 'Oyster Perpetual', Code: '116710BLNR-003', SellDate: new Date(2018, 11, 24), },
        { ID: 7,Barcode: '001801838', Position: 'Negozio 1', Price: 8300.00, PriceCurrency: '€', Brand: 'Rolex', Line: 'Oyster Perpetual', Code: '116710BLNR-004', SellDate: new Date(2018, 11, 24), },
        { ID: 8,Barcode: '001801885', Position: 'Negozio 1', Price: 7500.00, PriceCurrency: '€', Brand: 'Rolex', Line: 'Date', Code: '116710BLNR-003', SellDate: new Date(2018, 9, 23), },
        { ID: 9,Barcode: '001858512', Position: 'Negozio 1', Price: 1500.00, PriceCurrency: '€', Brand: 'Cartier', Line: '', Code: '11235', SellDate: new Date(2018, 10, 24), },
        { ID: 10,Barcode: '001804568', Position: 'Negozio 1', Price: 1400.00, PriceCurrency: '€', Brand: 'Gerbella', Line: '', Code: 'S854D6', SellDate: new Date(2018, 10, 23), },
        { ID: 11,Barcode: '001800056', Position: 'Negozio 1', Price: 800.00, PriceCurrency: '€', Brand: 'Venini', Line: '', Code: '12235-55', SellDate: new Date(2018, 11, 18), },
        { ID: 12,Barcode: '001800057', Position: 'Negozio 1', Price: 750.00, PriceCurrency: '€', Brand: 'Venini', Line: '', Code: '12235-54', SellDate: new Date(2018, 11, 18), },
    ],
    2019: [
        { ID:13, Barcode: '001901845', Position: 'Negozio 1', Price: 8300.00, PriceCurrency: '€', Brand: 'Rolex', Line: 'Oyster Perpetual', Code: '116710BLNR-002', SellDate: new Date(2019, 3, 24), },
        { ID:14, Barcode: '001901838', Position: 'Negozio 1', Price: 8300.00, PriceCurrency: '€', Brand: 'Rolex', Line: 'Oyster Perpetual', Code: '116710BLNR-002', SellDate: new Date(2019, 3, 24), },
        { ID:15, Barcode: '001958512', Position: 'Negozio 1', Price: 1500.00, PriceCurrency: '€', Brand: 'Cartier', Line: '', Code: '11235', SellDate: new Date(2019, 3, 24), },
        { ID:16, Barcode: '001904568', Position: 'Negozio 1', Price: 1400.00, PriceCurrency: '€', Brand: 'Gerbella', Line: '', Code: 'S854D6', SellDate: new Date(2019, 3, 23), },
        { ID:17, Barcode: '001900056', Position: 'Negozio 1', Price: 800.00, PriceCurrency: '€', Brand: 'Venini', Line: '', Code: '12235-55', SellDate: new Date(2019, 3, 18), },
    ],
};
//var bars = {
//    2017: [
//        { name: "Gennaio",Orologi: 15230, Gioielli: 11000,Argenti: 2501 },
//        { name: "Febbraio",Orologi: 12300, Gioielli: 2560,Argenti: 1202 },
//        { name: "Marzo",Orologi: 9560, Gioielli: 6058,Argenti: 2503 },
//        { name: "Aprile",Orologi: 23600, Gioielli: 5602,Argenti: 5062 },
//        { name: "Maggio",Orologi: 18000, Gioielli: 10000,Argenti: 2100 },
//        { name: "Giugno",Orologi: 25630, Gioielli: 3605,Argenti: 805 },
//        { name: "Luglio",Orologi: 16520, Gioielli: 9805,Argenti: 2305 },
//        { name: "Agosto",Orologi: 9850, Gioielli: 8906,Argenti: 1200 },
//        { name: "Settembre",Orologi: 25600, Gioielli: 6089,Argenti: 1000 },
//        { name: "Ottobre",Orologi: 5600, Gioielli: 8705,Argenti: 350 },
//        { name: "Novembre",Orologi: 13500, Gioielli: 7085,Argenti: 1056 },
//        { name: "Dicembre", Orologi: 29600, Gioielli: 9805, Argenti: 2300 },
//    ],
//    2018: [
//        { name: "Gennaio", Orologi: 15023, Gioielli: 11000, Argenti: 2501 },
//        { name: "Febbraio", Orologi: 12300, Gioielli: 2506, Argenti: 1202 },
//        { name: "Marzo", Orologi: 9056, Gioielli: 6508, Argenti: 2503 },
//        { name: "Aprile", Orologi: 23060, Gioielli: 5062, Argenti: 5602 },
//        { name: "Maggio", Orologi: 18000, Gioielli: 10000, Argenti: 2100 },
//        { name: "Giugno", Orologi: 25063, Gioielli: 3065, Argenti: 805 },
//        { name: "Luglio", Orologi: 16052, Gioielli: 9805, Argenti: 2305 },
//        { name: "Agosto", Orologi: 9805, Gioielli: 8906, Argenti: 1200 },
//        { name: "Settembre", Orologi: 25060, Gioielli: 6089, Argenti: 1000 },
//        { name: "Ottobre", Orologi: 5060, Gioielli: 8075, Argenti: 305 },
//        { name: "Novembre", Orologi: 13050, Gioielli: 7805, Argenti: 1506 },
//        { name: "Dicembre", Orologi: 29060, Gioielli: 9005, Argenti: 2300 },],
//    2019: [
//        { name: "Gennaio", Orologi: 12030, Gioielli: 15020, Argenti: 1000 },
//        { name: "Febbraio", Orologi: 23060, Gioielli: 5000, Argenti: 805 },
//        { name: "Marzo", Orologi: 1052, Gioielli: 4000, Argenti: 506 },
//        { name: "Aprile", Orologi: 9506, Gioielli: 5602, Argenti: 400 },
//        { name: "Maggio", Orologi: 0, Gioielli: 0, Argenti: 0 },
//        { name: "Giugno", Orologi: 0, Gioielli: 0, Argenti: 0 },
//        { name: "Luglio", Orologi: 0, Gioielli: 0, Argenti: 0 },
//        { name: "Agosto", Orologi: 0, Gioielli: 0, Argenti: 0 },
//        { name: "Settembre", Orologi: 0, Gioielli: 0, Argenti: 0 },
//        { name: "Ottobre", Orologi: 0, Gioielli: 0, Argenti: 0 },
//        { name: "Novembre", Orologi: 0, Gioielli: 0, Argenti: 0 },
//        { name: "Dicembre", Orologi: 0, Gioielli: 0, Argenti: 0 },]
//};
const months = [
    '',
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
]
class Profile extends Component {
    constructor(props) {
        super(props);
        this.panel = React.createRef();
    }
    state = {
        year: 2019,
        sells: [],
        values: {
            Watches: {},
            Jewels: {},
            Silvers: {}
        },
        bars:[],
    };
    componentDidMount() {
        this.reloadSells();
    }
    selectYear = (year) => {
        this.setState({ year }, () => {
            this.reloadSells();
        });
    }
    reloadSells = () => {
        var { id } = this.props;
        var { year } = this.state;
        fetch('/DOC/GetHistoricalSellsTotals/' + id + '?year=' + year + '&FIDGroup=' + 10).then(res => res.json()).then(data => this.setState(state => {
            state.values.Watches = data;
            return state;
        }));
        fetch('/DOC/GetHistoricalSellsTotals/' + id + '?year=' + year + '&FIDGroup=' + 12).then(res => res.json()).then(data => this.setState(state => {
            state.values.Jewels = data;
            return state;
        }));
        fetch('/DOC/GetHistoricalSellsTotals/' + id + '?year=' + year + '&FIDGroup=' + 13).then(res => res.json()).then(data => this.setState(state => {
            state.values.Silvers = data;
            return state;
        }));
        fetch('/DOC/GetHistoricalSellsByMonth/' + id + '?year=' + year).then(res => res.json()).then(data => this.setState(state => {
            state.bars = data;
            return state;
        }));
        //fetch('/DOC/GetAlternativeSells/' + id).then(res => res.json()).then(data => {
        //    console.log(data);
        //});
    }
    renderTabStorico = () => {
        var { year } = this.state;
        return (
            <TabContainer>
                <IdentitySells id={this.props.id} width={this.props.width} year={year} />
            </TabContainer>
        );
    }
    renderTabCoerente = () => {
        return (
            <TabContainer>
                <CrossSell id={this.props.id} width={this.props.width} />
            </TabContainer>
        );
    }
    renderTabAlternativo = () => {
        return <div></div>;
    }
    renderTabVisite = () => {
        return <div></div>;
    }
    render() {
        var { classes,id } = this.props;
        var { year, values, bars } = this.state;

        var tabs = [
            {
                tabName: "Storico",
                tabContent: this.renderTabStorico()
            },
            {
                tabName: "Coerente",
                tabContent: this.renderTabCoerente()
            },
            //{
            //    tabName: "Alternativo",
            //    tabContent: this.renderTabAlternativo()
            //},
            //{
            //    tabName: "Presentazioni",
            //    tabContent: this.renderTabVisite()
            //}
        ];
        return (
            <Grid container spacing={2}>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} sm={3} className={classes.box}>
                        <span className={classes.label}>Anno</span>
                        <div onClick={() => { this.selectYear(2017); }} className={year === 2017 ? classes.yearBoxActive : classes.yearBox}>2017</div>
                        <div onClick={() => { this.selectYear(2018); }} className={year === 2018 ? classes.yearBoxActive : classes.yearBox}>2018</div>
                        <div onClick={() => { this.selectYear(2019); }} className={year === 2019 ? classes.yearBoxActive : classes.yearBox}>2019</div>
                    </Grid>
                    <Grid item xs={12} sm={9} className={classes.box}>
                        <span className={classes.label} ref={this.panel}>Importo per Mese e Gruppo</span>
                        <BarChart
                            width={900}
                            height={120}
                            data={bars}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                                <Label value="name" formatter={(value) => { return months[value] }} />
                            </XAxis>
                            <YAxis />
                            <Tooltip formatter={(value, name, props) => { return value.round(2) + " €"; }} labelFormatter={value => months[value]} />
                            <Bar name="Orologi" dataKey="Watches" fill="#8884d8" />
                            <Bar name="Gioielli" dataKey="Jewels" fill="#FFBB28" />
                            <Bar name="Argenti" dataKey="Silvers" fill="#00C49F" />
                        </BarChart>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} sm={3} className={classes.box}>
                        <span className={classes.label}>Totale vendite</span>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Tot. vendita</span>
                            <span className={classes.sellValue}>{`${((values.Watches.Total || 0)+ (values.Jewels.Total || 0) + (values.Silvers.Total || 0)).toFixed(2)} €`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Tot. margine</span>
                            <span className={classes.sellValue}>{`${(((values.Watches.Margin || 0) + (values.Jewels.Margin || 0) + (values.Silvers.Margin || 0)) / 3).toFixed(2)} %`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Sconto medio</span>
                            <span className={classes.sellValue}>{`${(((values.Watches.AvgDiscount || 0) + (values.Jewels.AvgDiscount || 0) + (values.Silvers.AvgDiscount || 0)) / 3).toFixed(2)} %`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Numero pezzi</span>
                            <span className={classes.sellValue}>{((values.Watches.TotItems || 0) + (values.Jewels.TotItems || 0) + (values.Silvers.TotItems || 0))}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Imp. medio</span>
                            <span className={classes.sellValue}>{`${(((values.Watches.Total || 0) + (values.Jewels.Total || 0) + (values.Silvers.Total || 0)) / (((values.Watches.TotItems || 0) + (values.Jewels.TotItems || 0) + (values.Silvers.TotItems || 0) || 1 ))).toFixed(2)} €`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Ultima vendita</span>
                            <span className={classes.sellValue}>{(values.Watches.LastSell && (new Date(values.Watches.LastSell)).toLocaleDateString() || '__/__/____')}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.box}>
                        <span className={classes.label}>Vendita Orologi</span>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Tot. vendita</span>
                            <span className={classes.sellValue}>{`${(values.Watches.Total || 0).toFixed(2)} €`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Tot. margine</span>
                            <span className={classes.sellValue}>{`${(values.Watches.Margin || 0).toFixed(2)} %`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Sconto medio</span>
                            <span className={classes.sellValue}>{`${(values.Watches.AvgDiscount || 0).toFixed(2)} %`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Numero pezzi</span>
                            <span className={classes.sellValue}>{(values.Watches.TotItems || 0)}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Imp. medio</span>
                            <span className={classes.sellValue}>{`${((values.Watches.Total || 0) / (values.Watches.TotItems || 1)).toFixed(2)} €`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Ultima vendita</span>
                            <span className={classes.sellValue}>{(values.Watches.LastSell && (new Date(values.Watches.LastSell)).toLocaleDateString() || '__/__/____')}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.box}>
                        <span className={classes.label}>Vendita Gioielli</span>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Tot. vendita</span>
                            <span className={classes.sellValue}>{`${(values.Jewels.Total || 0).toFixed(2)} €`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Tot. margine</span>
                            <span className={classes.sellValue}>{`${(values.Jewels.Margin || 0).toFixed(2)} %`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Sconto medio</span>
                            <span className={classes.sellValue}>{`${(values.Jewels.AvgDiscount || 0).toFixed(2)} %`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Numero pezzi</span>
                            <span className={classes.sellValue}>{(values.Jewels.TotItems || 0)}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Imp. medio</span>
                            <span className={classes.sellValue}>{`${((values.Jewels.Total ||0) / (values.Jewels.TotItems || 1)).toFixed(2)} €`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Ultima vendita</span>
                            <span className={classes.sellValue}>{(values.Jewels.LastSell && (new Date(values.Jewels.LastSell)).toLocaleDateString() || '__/__/____')}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.box}>
                        <span className={classes.label}>Vendita Argenti</span>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Tot. vendita</span>
                            <span className={classes.sellValue}>{`${(values.Silvers.Total || 0).toFixed(2)} €`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Tot. margine</span>
                            <span className={classes.sellValue}>{`${(values.Silvers.Margin || 0).toFixed(2)} %`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Sconto medio</span>
                            <span className={classes.sellValue}>{`${(values.Silvers.AvgDiscount || 0).toFixed(2)} %`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Numero pezzi</span>
                            <span className={classes.sellValue}>{(values.Silvers.TotItems || 0)}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Imp. medio</span>
                            <span className={classes.sellValue}>{`${((values.Silvers.Total || 0) / (values.Silvers.TotItems || 1)).toFixed(2)} €`}</span>
                        </div>
                        <div className={classes.sellItem}>
                            <span className={classes.sellLabel}>Ultima vendita</span>
                            <span className={classes.sellValue}>{(values.Silvers.LastSell && (new Date(values.Silvers.LastSell)).toLocaleDateString() || '__/__/____')}</span>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} spacing={2}>
                    <div className={classes.root}>
                        <Tabs
                            classes={{
                                root: classes.tabsPositionSticky
                            }}
                            headerColor="primary"
                            plainTabs={true}
                            tabs={tabs}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }
}


Profile.propTypes = {
    classes: PropTypes.object.isRequired,
};


const enhance = compose(
    withWidth(),
    withStyles(styles)
);


export default enhance(Profile);