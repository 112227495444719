import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Document, Page } from 'react-pdf';
//import html2canvas from 'html2canvas';
//import jsPDF from 'jspdf';

const styles = theme => ({
});

class GDPRPDF extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    goToPrevPage = () =>
        this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
    goToNextPage = () =>
        this.setState(state => ({ pageNumber: state.pageNumber + 1 }));


    componentDidMount() {
        //html2canvas(document.body, { width: 595, windowWidth: 595}).then(canvas => {
        //    document.body.appendChild(canvas);
        //    ///this.setState({ image: canvas });
        //    const imgData = canvas.toDataURL('image/png');
        //    //const pdf = new jsPDF();
        //    //pdf.addImage(imgData, 'PNG', 0, 0);
        //    //pdf.save("download.pdf"); 
        //});
    }
    render() {
        var { FIDIdentity,FIDAgreement } = this.props;
        const { pageNumber, numPages } = this.state;
        var url = "/IR/GetGDPRPdf/" + (FIDIdentity || 0);
        if (FIDAgreement) {
            url += "?FIDAgreement=" + FIDAgreement;
        }
        

        //return (<embed src={"/IR/GetGDPRPdf/" + data.ID} type="application/pdf" width="100%" height="100%"/>);
        return (<embed src={url} type="application/pdf" width="100%" height={document.body.offsetHeight - 259} />);
        //return (
        //    <div>
        //        <nav>
        //            {pageNumber > 1 && <button onClick={this.goToPrevPage}>Prev</button>}
        //            {pageNumber < numPages && <button onClick={this.goToNextPage}>Next</button>}
        //        </nav>
        //        <div>
        //            <Document
        //                file={"/IR/GetGDPRPdf/" + data.ID}
        //                onLoadSuccess={this.onDocumentLoadSuccess}
        //            >
        //                <Page pageNumber={pageNumber} />
        //            </Document>
        //            <p>Page {pageNumber} of {numPages}</p>
        //        </div>
        //    </div>
        //);
    }
}



GDPRPDF.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(GDPRPDF);