import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import ColumnsIcon from '@material-ui/icons/Storage';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import ColumnsSelector from './ColumnsSelector';

import { Filter, FilterRemove } from 'mdi-material-ui';
import { Tabs, Tab } from '@material-ui/core';




const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        minWidth: "100%",
    },
    primaryColor: {
        background: theme.palette.primary.main,
        boxShadow:'0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    secondaryColor: {
        background: theme.palette.secondary.main,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                //color: theme.palette.secondary.main,
                //backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                //color: theme.palette.text.primary,
                //backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 20%',
    },
    actions: {
        color: theme.palette.text.secondary,
        //width: "100%",
        textAlign: "right",
        position: "absolute",
        right: 8,
    },
    title: {
        flex: '0 0 auto',
        minWidth: 370,
    },
    table: {
        minWidth: "100%",
    },
});

class EnhancedTableToolbar extends Component {

    handleColumnsButton = (event) => {
        const { onColumnsButton } = this.props;
        if (onColumnsButton) {
            onColumnsButton(event);
        }
    };
    handleFilterButton = (event) => {
        const { onFilterButton } = this.props;
        if (onFilterButton) {
            onFilterButton(event);
        }
    };
    handleAdvancedFilterButton = (event) => {
        const { onAdvancedFilterButton } = this.props;
        if (onAdvancedFilterButton) {
            onAdvancedFilterButton(event);
        }
    };
    handleClearFilterButton = (event) => {
        const { onClearFilterButton } = this.props;
        if (onClearFilterButton) {
            onClearFilterButton(event);
        }
    };
    handleDeleteButton = (event) => {
        const { onDeleteButton } = this.props;
        if (onDeleteButton) {
            onDeleteButton(event);
        }
    };
    handleEditButton = (event) => {
        const { onEditButton } = this.props;
        if (onEditButton) {
            onEditButton(event);
        }
    };
    handleNewButton = (event) => {
        const { onNewButton } = this.props;
        if (onNewButton) {
            onNewButton(event);
        }
    }

    render() {

        const { numSelected, classes, grid, selectedAll, count, showDeleteButton, onAdvancedFilterButton, onClearFilterButton, onFilterButton, actions, onEditButton, label, showFilterActivator, showColumnsSelector } = this.props;
        var { selectedActions, usePrimaryColor, useSecondaryColor, tabs, selectedTab, onTabChange } = this.props;

        var deleteButton = showDeleteButton && (<Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={this.handleDeleteButton} >
                <DeleteIcon />
            </IconButton>
        </Tooltip>);
        var editButton = numSelected === 1 && onEditButton && (
            <Tooltip title="Edit">
                <IconButton aria-label="Edit" onClick={this.handleEditButton} >
                    <EditIcon />
                </IconButton>
            </Tooltip>
        );
        var newButton = this.props.onNewButton && (
            <Tooltip title="New">
                <IconButton aria-label="New" onClick={this.handleNewButton} >
                    <AddIcon />
                </IconButton>
            </Tooltip>
        );



        if (selectedActions) {
            console.log(selectedActions);
            selectedActions = selectedActions.map(item => {
                if (item.component) {
                    if (item.condition(grid.getSelectedData())) {
                        return item.component;
                    }
                    return false;
                }
                else {
                    return item;
                }
            }).filter(item => item !== false);
        }

        var renderedActions = <div className={classes.actions}>
            {numSelected > 0 ? (
                <Fragment>
                    {selectedActions}
                    {deleteButton}
                    {editButton}
                </Fragment>
            ) : (
                    <span>
                        {actions && actions.map(a => a)}
                        {newButton}
                        {grid.state.allColumns && showColumnsSelector && this.props.onColumnsButton && (<Fragment>
                            <Tooltip title="Column selector">
                                <IconButton aria-label="Column selector" onClick={this.handleColumnsButton} >
                                    <ColumnsIcon />
                                </IconButton>
                            </Tooltip>
                            <ColumnsSelector reactGrid={grid} />
                        </Fragment>)}
                        {onClearFilterButton &&
                            (<Tooltip title="Clear filter">
                                <IconButton aria-label="Clear filter" onClick={this.handleClearFilterButton}>
                                    <FilterRemove />
                                </IconButton>
                            </Tooltip>)
                        }
                        {onAdvancedFilterButton &&
                            (<Tooltip title="Advanced filter">
                                <IconButton aria-label="Advanced filter" onClick={this.handleAdvancedFilterButton}>
                                    <Filter />
                                </IconButton>
                            </Tooltip>)
                        }
                        {onFilterButton && showFilterActivator && (
                            <Tooltip title="Filter row">
                                <IconButton aria-label="Filter row" onClick={this.handleFilterButton}>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </span>
                )}
        </div>;

        var tabsUI = undefined;
        if (tabs && tabs.length > 0) {
            tabsUI = (
                <Tabs
                    value={selectedTab}
                    onChange={onTabChange}
                    variant="scrollable"
                    scrollButtons="ok"
                    className={classes.tab}
                >
                    {tabs.map((prop, key) => {
                        return (<Tab key={key} label={prop.tabName} />);
                    })}
                </Tabs>
            );
        }

        var title = <div className={classes.title}>
            {numSelected > 0 ? (
                <Typography color="inherit" variant="subtitle1">
                    {selectedAll ? count : numSelected} selected
                        </Typography>
            ) : (
                    <Typography variant="body1" id="tableTitle">
                        {label}
                    </Typography>
                )}
        </div>;
        var ui = tabsUI || title;

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                    [classes.primaryColor]: !!usePrimaryColor,
                    [classes.secondaryColor]: !!useSecondaryColor,
                })}
            >
                {ui}   
                <div className={classes.spacer} />
                {renderedActions}
            </Toolbar>
        );
    }
}
EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onFilterButton: PropTypes.func.isRequired,
    onColumnsButton: PropTypes.func,
    grid: PropTypes.object.isRequired,
    selectedAll: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
};
EnhancedTableToolbar.defaultProps = {
    showColumnsSelector: true,
    showFilterActivator: true,
}
export default withStyles(toolbarStyles)(EnhancedTableToolbar);