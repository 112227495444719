import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';


import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';
import XExpansionPanel from 'components/XExpansionPanel';
import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';

import { getXSelect, getXField, getXSwitch, getXDateField, handleErrors, popupBOPSearch, getBOPSearchContent, parseParam } from 'utils';

import { ArrowLeft, Check, Pencil } from 'mdi-material-ui';
import DocumentRowEditor from '../../components/DocumentRowEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const styles = theme => ({
});

const defaultFields = [
    {
        Field: "FIDCycle",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDDocumentType",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDDocumentSubtype",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDCausal",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "Description",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDRegister",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "Number",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "DocumentDate",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDIdentity",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDDestination",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "ShowAllDestinations",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "InternalRemarks",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "PublicRemarks",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDCurrency",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "CurrencyChange",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDVat",
        Visibility: true,
        Required: 3,
    },
];


class DOCEdit extends Component {
    constructor(props) {
        super(props);
        this.tabs = React.createRef();
        this.MessageBox = React.createRef();
        this.grdDOCBops = React.createRef();
        this.grdDOCRows = React.createRef();
        this.DocumentRowEditor = React.createRef();
        this.cboVat = React.createRef();
        this.grdDOCBopsSearch = React.createRef();
    }
    static contextTypes = {
        router: PropTypes.object
    }
    state = {
        fields: defaultFields,
        data: {},
        initialData: {},
        firstLoad: true,
        selectedRows: [],
        addRows: false,
    };

    componentDidMount() {
        this.clearSession();
        this.loadData();
    }

    loadData = () => {
        if (this.props.match.params.id && parseInt(this.props.match.params.id, 10) > 0) {
            fetch('/DOC/GetDOC/' + this.props.match.params.id)
                .then(handleErrors)
                .then(res => res.json())
                .then(data => {
                    this.setState(state => {
                        state.data = data;
                        if (state.firstLoad) {
                            state.initialData = JSON.parse(JSON.stringify(state.data));
                        }
                        state.firstLoad = false;
                        return state;
                    });
                })
                .catch(error => { console.log(error) });
        }
        else {
            this.setState(state => {
                if (state.firstLoad) {
                    state.initialData = JSON.parse(JSON.stringify(state.data));
                }
                state.firstLoad = false;
                return state;
            });
        }
    }

    clearSession = () => {
        fetch('/DOC/ClearSession', {
            method: 'POST'
        })
            .then(handleErrors).then(res => {
            })
            .catch(error => { console.log(error) });
    }

    checkForChanges = (event, caller, callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
        fetch('/DOC/HasChanges/', {
            method: 'POST'
        }).then(res => res.json())
            .then(data => {
                if (data.HasChanges || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
                    this.MessageBox.current.showMessageYesNoCancel("Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?", callbackYes, callbackNo, callbackCancel);
                }
                else {
                    callbackNoChanges();
                }
            });
    }

    handleExit = (event) => {
        this.checkForChanges(event, this,
            this.doExit,
            () => {
                this.handleSaveData(event, this, () => {
                    this.doExit();
                });
            },
            this.doExit,
            () => { }
        );
    }
    doExit = () => {
        fetch('/DOC/ClearSession', {
            method: 'POST'
        }).then(res => {
            this.context.router.history.push('/documents/');
        });
    }
    handleSaveData = (event, caller, callback) => {
        this.doSaveData(callback);
    }
    doSaveData = (callback) => {
        var currentTab = this.tabs.current.getCurrentTab();
        this.setState({ isLoading: true }, () => {
            var { data } = this.state;
            var FIDDOC = this.props.match.params.id;
            if (!FIDDOC) {
                FIDDOC = 0;
            }
            fetch('/DOC/SaveDOC/' + FIDDOC, {
                body: JSON.stringify(data),
                method: 'POST'
            }).then(handleErrors).then(res => res.json()).then((data) => {
                if (this.props.match.params.id === undefined || parseInt(this.props.match.params.id, 10) === 0) {
                    this.context.router.history.push('/doc/edit/' + data.ID);
                    if (callback) {
                        callback();
                    }
                }
                this.setState({ firstLoad: true }, () => {
                    this.loadData();
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                        if (callback) {
                            callback();
                        }
                    });
                });

            })
                .catch(error => {
                    console.log(error);
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                    });
                });
        });
    }

    handleDataChange = (event, value, key, dt) => {
        this.setState(state => {
            state.data[key] = value;
            state.data = this.relationsFix(dt, state.data, key);
            if (["FIDCausal"].includes(key) /*&& !state.data.Description*/) {
                state.data.Description = dt.Denomination;
            }
            if (["FIDCurrency"].includes(key)) {
                state.data.CurrencyChange = dt.Change;
            }
            if (["FIDVat"].includes(key)) {
                state.data.VatPerc = dt.Percentage;
            }
            return state;
        });
    }
    relationsFix = (data, db, key) => {
        if (["FIDCausal", "FIDDocumentSubtype"].includes(key) && data && data.ID > 0) {
            db["FIDDocumentType"] = data.FIDDocumentType;
        }
        if (["FIDCausal"].includes(key) && data && data.ID > 0) {
            db["FIDDocumentSubtype"] = data.FIDDocumentSubtype;
        }
        if (["FIDDocumentType", "FIDDocumentSubtype"].includes(key) && db["FIDCausal"] >= 0) {
            db["FIDCausal"] = undefined;
        }
        if (["FIDDocumentType"].includes(key) && db["FIDDocumentSubtype"] >= 0) {
            db["FIDDocumentSubtype"] = undefined;
        }
        return db;
    }
    handleSelectChange = (key) => (event, value) => {
        console.log([key, event, value]);
        if (value.length > 0) {
            this.handleDataChange(event, value[0].ID, key, value[0]);
        }
        else {
            this.handleDataChange(event, undefined, key);
        }
    }
    handleFieldChange = (key) => (event, value) => {
        this.handleDataChange(event, value, key);
    }
    handleDataFieldChange = (key) => (value) => {
        this.handleDataChange(null, value, key);
    }
    renderPanelGeneral = () => {
        var { data, fields } = this.state;
        var fieldCycle = getXSelect(data, fields, 12, 3, "FIDCycle", "Ciclo", this.handleSelectChange, "/doc/cycle");
        var fieldDocumentType = getXSelect(data, fields, 12, 3, "FIDDocumentType", "Tipo", this.handleSelectChange, "/doc/documenttype");
        var fieldDocumentSubtype = getXSelect(data, fields, 12, 3, "FIDDocumentSubtype", "Sottotipo", this.handleSelectChange, "/doc/documentsubtype", "", { filters: [{ key: "FIDDocumentType", value: data.FIDDocumentType }] });
        var fieldCausal = getXSelect(data, fields, 12, 3, "FIDCausal", "Causale", this.handleSelectChange, "/doc/causal", "", { filters: [{ key: "FIDDocumentType", value: data.FIDDocumentType }, { key: "FIDDocumentSubtype", value: data.FIDDocumentSubtype }] });
        var fieldDescription = getXField(data, fields, 12, 3, "Description", "Descrizione", this.handleFieldChange);
        var fieldRegister = getXSelect(data, fields, 12, 3, "FIDRegister", "Registro", this.handleSelectChange, "/doc/register");
        var fieldNumber = getXField(data, fields, 12, 3, "Number", "Numero", this.handleFieldChange);
        var fieldDate = getXDateField(data, fields, 12, 3, "DocumentDate", "Data", this.handleDataFieldChange);
        var fieldIdentity = getXSelect(data, fields, 12, 3, "FIDIdentity", "Anagrafica", this.handleSelectChange, "/IR/GetIRSelect");
        var fieldDestination = getXSelect(data, fields, 12, 3, "FIDDestination", "Destinazione", this.handleSelectChange, "/IR/GetIRDestinationSelect", "", data.ShowAllDestinations ? undefined : { filters: [{ key: "FIDIdentity", value: data.FIDIdentity }] });
        var fieldShowDestinations = getXSwitch(data, fields, 12, 3, "ShowAllDestinations", "Mostra tutte le destinazioni", this.handleFieldChange);
        return (
            <Grid container spacing={2}>
                {fieldCycle}
                {fieldDocumentType}
                {fieldDocumentSubtype}
                {fieldCausal}
                {fieldDescription}
                {fieldRegister}
                {fieldNumber}
                {fieldDate}
                {fieldIdentity}
                {fieldDestination}
                {fieldShowDestinations}
            </Grid>
        );
    }

    renderPanelNotes = () => {
        var { fields, data } = this.state;

        var fieldInternalRemark = getXField(data, fields, 12, 6, "InternalRemarks", "Nota interna", this.handleFieldChange, { multiline: true });
        var fieldPublicRemark = getXField(data, fields, 12, 6, "PublicRemarks", "Nota pubblica", this.handleFieldChange, { multiline: true });
        return (
            <Grid container spacing={2}>
                {fieldInternalRemark}
                {fieldPublicRemark}
            </Grid>
        );
    }
    renderTabGeneral = () => {
        var panels = [];
        panels.push({
            icon: "code",
            label: "Dati generali",
            content: this.renderPanelGeneral(),
        });
        panels.push({
            icon: "code",
            label: "Note",
            content: this.renderPanelNotes(),
        });

        var ui = <XExpansionPanel panels={panels} />;
        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
    renderPanelDefaults = () => {
        var { fields, data } = this.state;

        var fieldCurrency = getXSelect(data, fields, 12, 3, "FIDCurrency", "Valuta", this.handleSelectChange, "/gc/currency");
        var fieldCurrencyChange = getXField(data, fields, 12, 3, "CurrencyChange", "Cambio", this.handleFieldChange);
        var fieldVat = getXSelect(data, fields, 12, 3, "FIDVat", "IVA", this.handleSelectChange, "/gc/vat", "", { innerRef: this.cboVat});
        return (
            <Grid container spacing={2}>
                {fieldCurrency}
                {fieldCurrencyChange}
                {fieldVat}
            </Grid>
        );
    }
    renderTabDefaults = () => {
        var panels = [];
        panels.push({
            icon: "code",
            label: "Dati predefiniti",
            content: this.renderPanelDefaults(),
        });

        var ui = <XExpansionPanel panels={panels} />;
        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
    handleNewRow = () => {
        this.setState({ selectedRows:[], addRows: true });
    }
    editRows = () => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }

        var selectedRows = this.grdDOCBops.current.getSelectedData();
        if (selectedRows.length === 1) {
            this.grdDOCBops.current.clearSelection();
            this.getRowData(selectedRows[0]).then(data => {
                this.setState({ selectedRows, addRows: true, selectedData: data });
            });
            
        }
        
    }
    getRowData = async (id) => {
        return await fetch('/DOC/GetBOPData/' + id).then(res => res.json());
    }
    addRows = async () => {
        var { data } = this.state;
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }

        var selectedRows = this.grdDOCBops.current.getSelectedData();
        for (var i = 0; i < selectedRows.length; i++) {
            var dataRow = await this.getRowData(selectedRows[i]); //this.grdDOCBops.current.getRowData(selectedRows[i]);
            dataRow.Qty1 = 1;
            dataRow.FIDBOP = dataRow.ID;
            dataRow.ID = 0;
            dataRow.FIDVat = data.FIDVat;
            dataRow.VatPerc = data.VatPerc;
            dataRow.FIDCausal = data.FIDCausal;
            dataRow.FIDCycle = data.FIDCycle;
            if (!dataRow.Discount) {
                dataRow.Discount = 0;
            }

            if (data.FIDCycle === 3) {//passivo
                dataRow.SellPrice = dataRow.TaxablePrice;
                dataRow.RetailDiscount = this.props.params.RETAIL_DISCOUNT;
                dataRow.TaxablePrice = (dataRow.SellPrice * (100 - dataRow.RetailDiscount) / 100).toFixed(2);
            }

            if (dataRow.MeasureUnitQty2) {
                dataRow.Qty2 = dataRow.MeasureUnitQty2 * dataRow.Qty1 / dataRow.MeasureUnitQty1;
            }

            dataRow.TotalPrice = (dataRow.TaxablePrice * (100 + dataRow.VatPerc) / 100).toFixed(2);
            dataRow.DiscountedTaxablePrice = (dataRow.TaxablePrice * (100 - dataRow.Discount) / 100).toFixed(2);
            dataRow.DiscountedTotalPrice = (dataRow.TotalPrice * (100 + dataRow.Discount) / 100).toFixed(2);

            dataRow.TotalTaxableAmount = (dataRow.TaxablePrice * dataRow.Qty1).toFixed(2);
            dataRow.TotalAmount = (dataRow.TotalPrice * dataRow.Qty1).toFixed(2);
            dataRow.TotalDiscountedTaxableAmount = (dataRow.DiscountedTaxablePrice * dataRow.Qty1).toFixed(2);
            dataRow.TotalDiscountedAmount = (dataRow.DiscountedTotalPrice * dataRow.Qty1).toFixed(2);

            await fetch('/DOC/SaveDocumentRow/' + FIDDOC, {
                method: 'POST',
                body: JSON.stringify(dataRow)
            }).then(handleErrors).then(res => res.json()).then(data => {
            }).catch(error => {
                console.log(error);
            });
        }
        this.grdDOCBops.current.clearSelection();
        this.setState({ selectedRows, addRows: false, addNewRow: false });
    }
    onSaveRow = () => {
        var data = this.DocumentRowEditor.current.getData();
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        data.ID = 0;
        this.setState({ selectedRows: [], addRows: false, addNewRow: false }, () => {
            fetch('/DOC/SaveDocumentRow/' + FIDDOC, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(handleErrors).then(res => res.json()).then(data => {
                this.grdDOCRows.current.refresh();
            }).catch(error => {
                console.log(error);
            });
        });
    }
    onCancelAddRow = () => {
        this.setState({ selectedRows:[], addRows: false });
    }
    onCodeSearch = () => {
        this.setState({ grdDOCBopsSearch: true, grdDOCBopsType: "code", grdDOCBopsSearchValue:"" });
    }
    onBarcodeSearch = () => {
        this.setState({ grdDOCBopsSearch: true, grdDOCBopsType: "barcode", grdDOCBopsSearchValue: "" });
    }
    onSerialSearch = () => {
        this.setState({ grdDOCBopsSearch: true, grdDOCBopsType: "serial", grdDOCBopsSearchValue: "" });
    }
    onSearchCancel = () => {
        this.setState({ grdDOCBopsSearch: false, grdDOCBopsType: "", grdDOCBopsSearchValue: "" });
    }
    onSearchConfirm = () => {
        this.doSearchConfirm();
    }
    onSearchBlur = (id) => (event) => {
        console.log(this.grdDOCBopsSearch.current);
        this.grdDOCBopsSearch.current.focus();
    }
    onSearchChange = (id) => (event, value) => {
        this.setState({ grdDOCBopsSearchValue: value });
    }
    onSearchKeyDown = (id) => (event, value) => {
        if (event.keyCode === 13) {
            this.doSearchConfirm();
        }
    }
    doSearchConfirm = () => {
        var { grdDOCBopsType, grdDOCBopsSearchValue } = this.state;

        var key = "";
        switch (grdDOCBopsType) {
            case "code":
                key = "Code";
                break;
            case "barcode":
                key = "Barcode";
                break;
            case "serial":
                key = "SerialNumber";
                break;
            default:
                break;
        }

        this.grdDOCBops.current.search(key, grdDOCBopsSearchValue, () => {
            var rows = this.grdDOCBops.current.countRows();
            if (rows === 1) {
                this.grdDOCBops.current.setState(state => ({ selected: state.data.map(n => n.ID), filters: [] }), () => {
                    this.addRows();
                    this.grdDOCBops.current.refresh();
                });
            }
        });
        
        this.setState({ grdDOCBopsSearch: false, grdDOCBopsType: "", grdDOCBopsSearchValue: "" });
    }

    renderPanelBops = () => {
        var { addRows, selectedData, data, grdDOCBopsSearch, grdDOCBopsType, grdDOCBopsSearchValue } = this.state;
        var rowData = {};
        var customContent = undefined;
        var showFilterActivator = !addRows;
        var showColumnsSelector = !addRows;
        var actions = [];
        var handleNewRow = this.handleNewRow;

        //if (selectedRows.length > 0 && this.grdDOCBops.current) {
        if (selectedData) {
            rowData = Object.assign({}, selectedData);
            rowData.FIDBOP = rowData.ID;
            rowData.ID = 0;

            
            rowData.FIDVat = data.FIDVat;
            rowData.VatPerc = data.VatPerc;
            rowData.FIDCausal = data.FIDCausal;
            rowData.FIDCycle = data.FIDCycle;
            if (data.FIDCycle === 3) {//passivo
                rowData.SellPrice = rowData.TaxablePrice;
                rowData.RetailDiscount = this.props.params.RETAIL_DISCOUNT;
                rowData.TaxablePrice = (rowData.SellPrice * (100 - rowData.RetailDiscount) / 100).toFixed(2);
            }

            rowData.Qty1 = 1;
            if (rowData.MeasureUnitQty2) {
                rowData.Qty2 = rowData.MeasureUnitQty2 * rowData.Qty1 / rowData.MeasureUnitQty1;
            }
        }
        else if(addRows) {
            rowData.FIDVat = data.FIDVat;
            rowData.VatPerc = data.VatPerc;
            rowData.FIDCausal = data.FIDCausal;
            rowData.FIDCycle = data.FIDCycle;
            if (data.FIDCycle === 3) {//passivo
                rowData.RetailDiscount = this.props.params.RETAIL_DISCOUNT;
            }
            rowData.FIDMeasureUnit1 = this.props.params.DEF_UM;
            rowData.FIDPricelist = this.props.params.DEF_PRICELIST;
            rowData.Qty1 = 1;
        }
        ({ handleNewRow, rowData, customContent, actions } = this.renderEditor(addRows, handleNewRow, rowData, customContent, this.onSaveRow, this.onCancelAddRow));


        if (!addRows && !grdDOCBopsSearch) {
            actions.push(<Tooltip title="Annulla">
                <IconButton aria-label="Annulla" onClick={this.cancelAddNewRow}>
                    <ArrowLeft />
                </IconButton>
            </Tooltip>);
            var searchActions = popupBOPSearch(this.onCodeSearch, this.onBarcodeSearch, this.onSerialSearch);
            actions = actions.concat(searchActions);
        }
        var selectedActions = [];
        selectedActions.push(
            <Tooltip title="Aggiungi">
                <IconButton aria-label="Aggiungi" onClick={this.addRows}>
                    <Check />
                </IconButton>
            </Tooltip>
        );
        selectedActions.push(
            {
                component: <Tooltip title="Modifica">
                    <IconButton aria-label="Modifica" onClick={this.editRows}>
                        <Pencil />
                    </IconButton>
                </Tooltip>,
                condition: (selectedRows) => {
                    console.log(selectedRows);
                    return selectedRows.length === 1;
                }
            }
        );
        if (grdDOCBopsSearch) {
            var searchContent = getBOPSearchContent(this.grdDOCBopsSearch, grdDOCBopsType, grdDOCBopsSearchValue, this.onSearchBlur, this.onSearchKeyDown, this.onSearchChange, this.onSearchCancel, this.onSearchConfirm)
            customContent = searchContent.customContent;
            showFilterActivator = searchContent.showFilterActivator;
            showColumnsSelector = searchContent.showColumnsSelector;
            handleNewRow = searchContent.handleNewRow;
            actions = searchContent.actions;
        }
        return (
            <XGrid
                key={"tblDOCBops"}
                ref={this.grdDOCBops}
                dataRoute="/DOC/GetBops?grid=tblDOCBops"
                dataRouteColumns="/Base/GetColumns?grid=tblDOCBops"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblDOCBops"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblDOCBops"
                selectedActions={selectedActions}
                customContent={customContent}
                showColumnsSelector={showColumnsSelector}
                showFilterActivator={showFilterActivator}
                actions={actions}
                onNewButton={handleNewRow}
                rowsVisible={5}
            />
        );
    }
    handleEditRow = (event, data) => {
        this.grdDOCRows.current.clearSelection();
        this.setState({ editRow: true, rowData: data });
    }
    onCancelEditRow = () => {
        this.setState({ editRow: false, rowData: {} });
    }
    onSaveEditRow = () => {

        var data = this.DocumentRowEditor.current.getData();
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        //data.ID = 0;
        this.setState({ editRow: false, addNewRow: false, rowData: {} }, () => {
            fetch('/DOC/SaveDocumentRow/' + FIDDOC, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(handleErrors).then(res => res.json()).then(data => {
                this.grdDOCRows.current.refresh();
            }).catch(error => {
                console.log(error);
            });
        });
    }
    addNewRow = () => {
        this.setState({ addNewRow: true });
    }
    cancelAddNewRow = () => {
        this.setState({ addNewRow: false });
    }
    renderPanelRows = () => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }

        var { editRow, rowData, addNewRow } = this.state;

        var customContent = undefined;
        var showFilterActivator = !editRow;
        var showColumnsSelector = !editRow;
        var actions = [];
        var handleNewRow = this.addNewRow;
        var selectedActions;



        ({ handleNewRow, rowData, customContent, actions } = this.renderEditor(editRow, handleNewRow, rowData, customContent, this.onSaveEditRow, this.onCancelEditRow));
        if (addNewRow) {
            return this.renderPanelBops();
        }

        return (
            <XGrid
                key={"grdDOCRows"}
                ref={this.grdDOCRows}
                dataRoute={"/DOC/GetDOCRows/" + FIDDOC + "?grid=grdDOCRows"}
                dataRouteColumns="/Base/GetColumns?grid=grdDOCRows"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdDOCRows"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdDOCRows"
                dataRouteDelete={"/DOC/DeleteRows/" + FIDDOC}
                onEditButton={this.handleEditRow}
                selectedActions={selectedActions}
                customContent={customContent}
                showColumnsSelector={showColumnsSelector}
                showFilterActivator={showFilterActivator}
                actions={actions}
                onNewButton={handleNewRow}
                rowsVisible={5}
            />
        );
    }
    renderTabRows = () => {
        var panels = [];
        //panels.push({
        //    icon: "code",
        //    label: "Distinte",
        //    content: this.renderPanelBops(),
        //});
        panels.push({
            icon: "code",
            label: "Righe documento",
            content: this.renderPanelRows(),
        });

        var ui = <XExpansionPanel panels={panels} />;
        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );    }
    renderTabs = () => {
        var tabs = [];
        tabs.push(
            {
                tabName: "Generale",
                tabContent: this.renderTabGeneral()
            }
        );
        tabs.push(
            {
                tabName: "Dati predefiniti",
                tabContent: this.renderTabDefaults()
            }
        );
        tabs.push(
            {
                tabName: "Righe",
                tabContent: this.renderTabRows()
            }
        );

        return tabs;
    }


    renderEditor(addRows, handleNewRow, rowData, customContent, onSave, onCancel) {
        var actions = [];
        if (addRows) {
            handleNewRow = undefined;
            customContent = <DocumentRowEditor innerRef={this.DocumentRowEditor} data={rowData} onSave={onSave} onCancel={onCancel} />;
            actions = [<Tooltip title="Annulla">
                <IconButton aria-label="Annulla" onClick={onCancel}>
                    <ArrowLeft />
                </IconButton>
            </Tooltip>,
            <Tooltip title="Salva">
                <IconButton aria-label="Salva" onClick={onSave}>
                    <Check />
                </IconButton>
            </Tooltip>];
        }
        return { handleNewRow, rowData, customContent, actions };
    }

    render() {
        var { classes } = this.props;
        return (
            <div className={classes.root}>
                <Tabs
                    innerRef={this.tabs}
                    headerColor="primary"
                    plainTabs={true}
                    onExitClick={this.handleExit}
                    onSaveClick={this.handleSaveData}
                    tabs={this.renderTabs()}
                />
                <XMessageBox ref={this.MessageBox} />
            </div>
        );
    }
}




const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(DOCEdit);