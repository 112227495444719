import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { IconButton, Tooltip } from "@material-ui/core";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import logo from "assets/img/logo-white.svg";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Auth';
import { TextboxPassword, LockReset } from "mdi-material-ui";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: "cardHidden",
            username: "",
            password: "",
            offices: [],
            selectOffice: false,
        };
        this.onLogin = this.onLogin.bind(this);
    }
    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        //fetch('Base/IsLoggedIn', {
        //    method: 'POST'
        //}).then(res => res.json())
        //    .then(data => {
        //        if (data.success) {
        //            this.props.loginSuccess(data.user, "");
        //        }
        //    })
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }
    onUsernameChange = (event) => {
        this.setState({ username: event.target.value });
    }
    onPasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }
    onLogin = (event) => {
        var { loginPIN, username, password } = this.state;
        if (this.props.login) {
            //this.props.login(username, password);
            if (loginPIN) {
                fetch('/Base/LoginPIN', {
                    method: 'POST',
                    body: JSON.stringify({ Username: username, PIN: password })
                }).then(res => res.json()).then(data => {
                    if (data.success) {
                        this.onLoginSucces(data, password);
                    }
                    else {
                        this.props.loginFailure('', password, data);
                    }
                });
            }
            else {
                fetch('/Base/Login', {
                    method: 'POST',
                    body: JSON.stringify({ Username: username, Password: password })
                }).then(res => res.json()).then(data => {
                    if (data.success) {
                        this.onLoginSucces(data, password);
                    }
                    else {
                        this.props.loginFailure(username, password, data);
                    }
                });
            }
        }
    }

    onLoginSucces = (data, password) => {
        if (data.office) {
            this.props.loginSuccess(data.user, password);
        }
        else {
            this.setState({ user: data.user, password: password, offices: data.offices, selectOffice: true });
        }
    }
    onKeyDown = (event) => {
        if (event.keyCode === 13) {
            this.onLogin(event);
        }
    }
    onLoginType = (event) => {
        this.setState(state => ({ loginPIN: !state.loginPIN }));
    }
    onOfficeSelect = (FIDOffice) => () => {
        fetch('/Base/OfficeSelect', {
            method: 'POST',
            body: JSON.stringify(FIDOffice)
        }).then(res => res.json()).then(data => {
            if (data.success) {
                this.props.loginSuccess(this.state.user, this.state.password);
            }
        });
    }
    render() {
        const { classes } = this.props;
        const { loginPIN, offices,selectOffice } = this.state;


        var errMsg = this.props.error && (<p>{this.props.error}</p>);

        var ui = (
            <Fragment>
                <CardBody>
                    {!loginPIN &&
                        <CustomInput
                            labelText="Username"
                            id={"email" + (new Date()).getTime()}
                            formControlProps={{
                                fullWidth: true
                            }}
                            value={this.state.username}
                            onChange={this.onUsernameChange}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Face className={classes.inputAdornmentIcon} />
                                    </InputAdornment>
                                ),
                                autoComplete: "off",
                                onKeyDown: this.onKeyDown
                            }}
                        />
                    }
                    <CustomInput
                        labelText={loginPIN ? "PIN" : "Password"}
                        id={"password" + (new Date()).getTime()}
                        formControlProps={{
                            fullWidth: true
                        }}
                        value={this.state.password}
                        onChange={this.onPasswordChange}
                        onKeyDown={this.onKeyDown}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className={classes.inputAdornmentIcon}>
                                        lock_outline
                                                    </Icon>
                                </InputAdornment>
                            ),
                            type: 'password',
                            autoComplete: "off",
                            onKeyDown: this.onKeyDown
                        }}
                    />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                    <Button color="primary" size="lg" block onClick={this.onLogin}>Login</Button>
                </CardFooter>
                <CardFooter className={classes.justifyContentCenter}>
                    {loginPIN && <Tooltip title="Login con password">
                        <IconButton onClick={this.onLoginType}>
                            <TextboxPassword />
                        </IconButton>
                    </Tooltip>}
                    {!loginPIN && <Tooltip title="Login con PIN">
                        <IconButton onClick={this.onLoginType}>
                            <LockReset />
                        </IconButton>
                    </Tooltip>}
                </CardFooter>

            </Fragment>
        );

        if (selectOffice) {
            ui = (
                <Fragment>
                    <CardBody>
                        <List>
                            {offices.map((item, index) => (
                                <ListItem button onClick={this.onOfficeSelect(item.id)}>
                                    <ListItemText key={item.id}
                                        primary={item.alias || item.denomination}
                                        secondary={item.address}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </CardBody>
                </Fragment>
            );
        }

        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}><form>
                        <Card login className={classes[this.state.cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="gray"
                            >
                                <img src={logo} alt="logo" style={{ width: "67%" }} />
                            </CardHeader>
                            {ui}

                            <CardFooter className={classes.justifyContentCenter}>
                                {errMsg}
                            </CardFooter>
                        </Card>
                    </form>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};


const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    withStyles(loginPageStyle)
);

export default enhance(LoginPage);
