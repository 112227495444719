import React, { Component, Fragment } from 'react';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import AppBar from '@material-ui/core/AppBar';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';


import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { Check, DeleteSweep, AlphaBcircleOutline, AlphaLcircleOutline, AlphaPcircleOutline, AlphaScircleOutline, Star, BellRingOutline, LockOpenOutline, LockOutline, CartOutline } from 'mdi-material-ui';

//import XDialog from 'components/XDialog';
import XOXLoader from 'components/XOXLoader';
import IdentitySelector from 'components/IdentitySelector';
import CausalSelector from 'components/CausalSelector';


import CatalogueCard from 'components/CatalogueCard';
import CatalogueItem from 'components/CatalogueItem';
import CollectionCard from 'components/CollectionCard';
import XFilters from 'components/XFilters';
import XField from 'components/XField';
import XMessageBox from 'components/XMessageBox';

import EnhancedTableToolbar from 'components/EnhancedTableToolbar';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';
import XNavigation from '../components/XNavigation';
import { getCookie, setCookie, deleteCookie, popupBOPSearch, getBOPSearchContent, getScrollbarWidth } from 'utils';
//import { Typography } from '@material-ui/core';

import { Collection } from 'react-virtualized';

const styles = theme => ({
    root: {
        position: 'relative'
    },
    
});
const defaultFilters = {
    ShowModels: false,
    ShowStock: false,
    ImageView: 2,
    GroupType: 1,
    Sort: [],
};
GetParam("CatalogueDefaultShowCatalogue").then(result => defaultFilters.ShowModels = result);
GetParam("CatalogueDefaultShowStock").then(result => defaultFilters.ShowStock = result);
GetParam("CatalogueDefaultGroupType").then(result => defaultFilters.GroupType = result);
GetParam("CatalogueDefaultImageView").then(result => defaultFilters.ImageView = result);


async function GetParam(param) {
    return await fetch('/Base/GetParam?param=' + param).then(res => res.json());
}

class Catalogue extends Component {
    constructor(props) {
        super(props);
        this.MessageBox = React.createRef();
        this.CatalogueItem = React.createRef();
        this.CardsCollection = React.createRef();
        this.BLSCollection = React.createRef();
        this.bopsSearch = React.createRef();
    }
    resizeObserver;
    state = {
        data: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
        count: 0,
        page: 1,
        itemsperPage: 24,
        showFilters: false,
        showFiltersRow: false,
        currentFilters: Object.assign({}, defaultFilters),
        tempFilters: Object.assign({}, defaultFilters),
        oldFilters: undefined,
        oldTempFilters: undefined,
        filtersData: {
            ShowModels: true,
            ShowStock: true,
            categories: [],
            groups: [],
            brands: [],
            prices: [],
            lines: [],
            series: [],
            positions: [],
            templates: [],
        },
        currentItem: {},
        showItem: false,
        stars: undefined,
        itemStar: {},
        document: {},
        documentStep: 1,
        FIDIdentity: undefined,
        isLoading: true,
        isLoadingPage: false,
        dialog: {},
        showSort: false,
        sortColumns: [],
        viewType: null,
        scrollTop: false,
        contentWidth: 100,
        rowsMaxHeight: {},
        firstLoad: true,
    };
    async componentDidMount() {
        if (window.ResizeObserver !== undefined) {
            this.resizeObserver = new window.ResizeObserver((entries) => {
                this.setState({
                    contentWidth: entries[0].contentRect.width,
                    lastWidthChange: Date.now()
                }, () => {
                    setTimeout(() => {
                        if (this.CardsCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                            this.CardsCollection.current.recomputeCellSizesAndPositions();
                        }
                        if (this.BLSCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                            this.BLSCollection.current.recomputeCellSizesAndPositions();
                        }
                    }, 100);
                });
            });
            this.resizeObserver.observe(document.getElementById('mainContent'));
        }
        else {
            this.setState({ contentWidth: document.getElementById('mainContent').clientWidth });
            window.addEventListener('resize', this.mainContentResize);
        }
        var tempState = getCookie('catalogue_state');
        if (tempState) {
            var newState = JSON.parse(tempState);
            this.setState(state => {
                state.currentFilters = Object.assign({}, newState.currentFilters);
                state.tempFilters = Object.assign({}, newState.tempFilters);
                state = newState;
                return state;
            }, () => {
                deleteCookie('catalogue_state');
                if (newState.reloadData) {
                    this.loadData();
                }
            });
        }
        else {

            var { advancedNavigation } = this.props;
            defaultFilters.ShowModels = await GetParam("CatalogueDefaultShowCatalogue");
            defaultFilters.ShowStock = await GetParam("CatalogueDefaultShowStock");



            this.setState(state => {
                state.currentFilters = Object.assign({}, defaultFilters);
                state.tempFilters = Object.assign({}, defaultFilters);
                if (this.props.CatalogFIDCausal) {
                   state.FIDCausal = this.props.CatalogFIDCausal;
                }
                return state;
            }, () => {
                if (this.props.match.params.id) {
                    advancedNavigation = false;
                }
                if (advancedNavigation) {

                    this.getCollections();
                }
                else {
                    this.loadData();
                }
                this.loadFiltersData();
                if (this.props.CatalogFIDIdentity) {
                    this.reloadIdentityInfo(this.props.CatalogFIDIdentity);
                }
            });
        }
    }
    componentDidUpdate = () => {
        //if (true) {
        //    window.onbeforeunload = () => true;
        //}
        //else {
        //    window.onbeforeunload = undefined;
        //}
    }
    componentWillUnmount() {
        this.props.setTitle(undefined);
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        window.removeEventListener('resize', this.mainContentResize);
    }
    mainContentResize = () => {
        var element = document.getElementById('mainContent');

        this.setState({
            contentWidth: element.clientWidth,
            lastWidthChange: Date.now()
        }, () => {
            setTimeout(() => {
                if (this.CardsCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                    this.CardsCollection.current.recomputeCellSizesAndPositions();
                }
                if (this.BLSCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                    this.BLSCollection.current.recomputeCellSizesAndPositions();
                }
            }, 100);
        });
    }
    getCollections = () => {
        var { currentFilters } = this.state;

        var showDB = currentFilters.ShowModels ? true : false;
        var showDM = currentFilters.ShowStock ? true : false;
        var groupType = currentFilters.GroupType;
        var imageView = currentFilters.ImageView;

        var prms = '?showDB=' + showDB +
            '&showDM=' + showDM +
            '&groupType=' + groupType +
            '&imageView=' + imageView;

        fetch('/BOP/GetCatalogueCollections/' + prms).then(res => res.json()).then(data => {
            this.setState({ collections: data, isLoading: false }, () => {
                if (this.state.firstLoad) {
                    this.setState({ firstLoad: false }, () => {
                        //check if has params
                        var url_string = window.location.href;
                        var url = new URL(url_string);
                        var brands = url.searchParams.get('brands');
                        var lines = url.searchParams.get('lines');
                        var series = url.searchParams.get('series');
                        if (!!brands) {
                            this.onCollectionCardClick('brand', { FIDBrand: brands });
                        }
                        else if (!!lines) {
                            this.onCollectionCardClick('line', { FIDLine: lines });
                        }
                        else if (!!series) {
                            this.onCollectionCardClick('series', { FIDSeries: series });
                        }
                    });
                }
                
            });
        });
    }
    loadData = (callback) => {
        var { page, itemsperPage, currentFilters, FIDIdentity, FIDCausalView, viewType } = this.state;
        if (viewType !== 'product') {
            currentFilters = {};
        }


        var filters = this.getFiltersArray(currentFilters);

        

        var showDB = currentFilters.ShowModels ? true : false;
        var showDM = currentFilters.ShowStock ? true : false;
        var groupType = currentFilters.GroupType;
        var imageView = currentFilters.ImageView;

        var IdentityFilter = "";
        if (FIDIdentity) {
            IdentityFilter = "&FIDIdentity=" + FIDIdentity;
        }
        var CausalFilter = "";
        if (FIDCausalView) {
            CausalFilter = "&FIDCausal=" + FIDCausalView;
        }
        var documentFilter = "";
        if (this.props.match.params.id) {
            documentFilter = "&FIDDocument=" + this.props.match.params.id;
        }
        if (!currentFilters.Sort) {
            currentFilters.Sort = [];
        }
        var sort = currentFilters.Sort.map(item => ({ Field: item.Value.ID, Direction: item.Sort }));
        this.setState({ isLoadingPage: true }, () => {
            var link = '/BOP/GetCatalogue?sortColumn=Code&sortDirection=ASC&skip=' + ((page - 1) * itemsperPage) +
                '&items=' + itemsperPage +
                '&filters=' + JSON.stringify(filters) +
                '&showDB=' + showDB +
                '&showDM=' + showDM +
                '&groupType=' + groupType +
                '&imageView=' + imageView +
                '&sort=' + JSON.stringify(sort) +
                IdentityFilter + CausalFilter + documentFilter;
            fetch(link).then(res => res.json())
                .then(data => {
                    this.setState({ data: data.data, count: data.count, isLoadingPage: false, isLoading: false }, () => {
                        if (callback) {
                            callback();
                        }
                    });
                });
        });
    }
    getFiltersArray = (tempFilters, noPrice = false) => {
        var filters = [];
        if (tempFilters.FIDBrand) {
            filters.push({
                key: "FIDBrand",
                term: tempFilters.FIDBrand,
                comparison: "=",
                type: "int"
            });
        }
        if (tempFilters.FIDGroup) {
            filters.push({
                key: "FIDGroup",
                term: tempFilters.FIDGroup,
                comparison: "=",
                type: "int"
            });
        }
        if (tempFilters.FIDCategory) {
            filters.push({
                key: "FIDCategory",
                term: tempFilters.FIDCategory,
                comparison: "=",
                type: "int",
            });
        }
        if (tempFilters.FIDLine) {
            filters.push({
                key: "FIDLine",
                term: tempFilters.FIDLine,
                comparison: "=",
                type: "int",
            });
        }
        if (tempFilters.FIDSeries) {
            filters.push({
                key: "FIDSeries",
                term: tempFilters.FIDSeries,
                comparison: "=",
                type: "int",
            });
        }
        if (tempFilters.FIDPosition) {
            filters.push({
                key: "FIDPosition",
                term: tempFilters.FIDPosition,
                comparison: "=",
                type: "int",
            });
        }
        if (!noPrice && tempFilters.Price && tempFilters.Price.length === 2) {
            filters.push({
                key: "price",
                term: tempFilters.Price,
                type: 'limits_int'
            });
        }
        var likeSearch = ["Group", "Brand", "Category", "Typology", "Code", "Description", "SerialNumber", "Barcode", "Position", "Line"];
        for (var i = 0; i < likeSearch.length; i++) {
            var key = likeSearch[i];
            if (tempFilters[key]) {
                filters.push({
                    key: key,
                    term: tempFilters[key],
                    type: 'text',
                    comparison: 'like'
                });
            }
        }
        return filters;
    }
    loadFiltersData = () => {

        var { tempFilters, FIDIdentity, FIDCausalView } = this.state;
        var filters = this.getFiltersArray(tempFilters);
        var filtersNoPrice = this.getFiltersArray(tempFilters, true);

        var showDB = tempFilters.ShowModels ? true : false;
        var showDM = tempFilters.ShowStock ? true : false;
        var imageView = tempFilters.ImageView;

        var IdentityFilter = "";
        if (FIDIdentity) {
            IdentityFilter = "&FIDIdentity=" + FIDIdentity;
        }

        var CausalFilter = "";
        if (FIDCausalView) {
            CausalFilter = "&FIDCausal=" + FIDCausalView;
        }
        var documentFilter = "";
        if (this.props.match.params.id) {
            documentFilter = "&FIDDocument=" + this.props.match.params.id;
        }


        var paramsNoPrice = '?filters=' + JSON.stringify(filtersNoPrice) + '&showDB=' + showDB + '&showDM=' + showDM + '&imageView=' + imageView + IdentityFilter + CausalFilter + documentFilter;
        var params = '?filters=' + JSON.stringify(filters) + '&showDB=' + showDB + '&showDM=' + showDM + '&imageView=' + imageView + IdentityFilter + CausalFilter + documentFilter;




        fetch('/BOP/GetBOPGroups' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.groups = data;
                    return state;
                })
            });

        fetch('/gc/template/catalogue/').then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.templates = data.map(item => ({ ID: item.ID, Label: item.Name }));
                    return state;
                })
            });
        fetch('/BOP/GetBOPCategories' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.categories = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPBrands' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.brands = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPPrices' + paramsNoPrice).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.prices = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPLines' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.lines = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPSeries' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.series = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPPositions' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.positions = data;
                    return state;
                })
            });
    }
    previousPage = () => {
        this.setState((state, props) => {
            state.page--;
            return state;
        }, this.loadData);
    }
    nextPage = () => {
        this.setState((state, props) => {
            state.page++;
            return state;
        }, this.loadData);
    }
    handleFilterButton = (event) => {
        this.setState(state => {
            state.showFiltersRow = !state.showFiltersRow;
            return state;
        });
    }
    onAdvancedFilterButton = (event) => {
        //this.setState({ showFilters: true });
        this.setState(state => {
            state.showFilters = true;
            state.tempFilters = state.currentFilters;
            return state;
        });
    }
    onClearFilterButton = (event) => {
        this.setState(state => {
            state.tempFilters = Object.assign({}, defaultFilters);
            state.currentFilters = Object.assign({}, defaultFilters);
            state.page = 1;
            state.viewType = undefined;
            return state;
        }, () => {
            this.loadData();
                this.loadFiltersData();
                this.setCollectionTitle();
        });
    }

    onClearTempFilterButton = (event) => {
        this.setState(state => {
            state.tempFilters = Object.assign({}, defaultFilters);
            return state;
        }, () => {
            this.loadFiltersData();
        });
    }
    onTemplateNameChange = (id) => (event, value) => {
        console.log([id, event, value]);
        this.setState(state => {
            state.tempFilters[id] = value;
            return state;
        });
    }
    onSaveTemplate = (event) => {
        var { tempFilters } = this.state;
        var filters = tempFilters;
        if (filters.Template) {
            //conferma aggiornamento
            this.MessageBox.current.showMessageYesNo("Template ricerca", "Confermi aggiornamento template?", this.saveTemplate);
        }
        else {
            //inserimento nuovo
            this.MessageBox.current.showInputMessage('Template ricerca', "", "Nome template", "Salva", "Annulla", () => {
                var templateName = this.MessageBox.current.getInputValue();
                this.setState(state => {
                    state.tempFilters.TemplateName = templateName;
                    return state;
                }, () => {
                    this.saveTemplate();
                });
            });
        }
        console.log(filters);
    }
    onDeleteTemplate = (event) => {
        this.deleteTemplate();
    }
    onFilterClose = () => {
        var filters = this.state.currentFilters;
        this.setState(state => {
            state.showFilters = false;
            state.tempFilters = Object.assign({}, filters);
            return state;
        }, () => {
            this.loadFiltersData();
        });
    }
    onSetFilters = () => {
        var filters = this.state.tempFilters
        this.setState(state => {
            state.showFilters = false;
            state.currentFilters = Object.assign({}, filters);
            state.page = 1;
            state.viewType = "product";
            return state;
        }, () => {
            this.getCollections();
            this.loadData();
        });
    }
    onSortChange = (newSort) => {
        this.setState(state => {
            state.tempFilters.Sort = newSort;
            return state;
        });
    }
    onFilterChange = async (key, value) => {
        if (key === "Template") {
            fetch('/gc/template/catalogue/' + value).then(res => res.json()).then(data => {
                this.setState(state => {
                    state.tempFilters = data;
                    state.tempFilters[key] = value;

                    return state;
                }, () => {
                    this.loadFiltersData();
                });
            });
        }
        else {
            this.setState(state => {
                state.tempFilters[key] = value;
                return state;
            }, () => {
                this.loadFiltersData();
            });
        }
    }
    onFieldFilterChange = (key) => (event, value) => {
        this.setState(state => {
            state.currentFilters[key] = value;
            state.tempFilters[key] = value;
            state.filterChanged = true;
            return state;
        }, () => {
            //this.loadData();
            //this.loadFiltersData();
        });
    }
    onFieldFilterBlur = (id) => () => {
        if (this.state.filterChanged) {
            this.setState({ filterChanged: false }, () => {
                this.loadData();
                this.loadFiltersData();
            });
        }
    }
    onFieldFilterKeyDown = (id) => (event) => {
        if (event.keyCode === 13) {
            this.setState({ filterChanged: false }, () => {
                this.loadData();
                this.loadFiltersData();
            });
        }
    }
    onItemClick = (index) => (item) => {
        this.setState({ currentIndex:index, currentItem: item, showItem: true/*, scrollTop: this.CardsCollection.current.scrollTop /*document.getElementById('mainPanel').scrollTop*/ });
    }
    handleCatalgoItemClose = () => {

        this.setState({ currentItem: {}, itemStar: {}, showItem: false }, () => {
            document.getElementById('mainPanel').scrollTo(0, this.state.scrollTop);
        });
    }
    onShowRemarks = () => {
        this.setState({ showRemarks: true });
    }
    onHideRemarks = () => {
        this.setState({ showRemarks: false });
    }

    onNoteItem = (item, callback) => {
        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
        if (FIDIdentity) {
            this.saveDocument(-1, item, FIDIdentity, this.props.params.DOCPresentationCausalId,1, callback);
        }
        else {
            this.setState({
                stars: -1, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCPresentationCausalId, noteCallback: callback, rowQty: 1 });
        }
    }
    onStarItem = (item, stars) => {
        if (this.props.match.params.id) {
            return;
        }
        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
        if (FIDIdentity) {
            this.saveDocument(stars, item, FIDIdentity, this.props.params.DOCPresentationCausalId,1);
        }
        else {
            this.setState({ stars, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCPresentationCausalId, rowQty: 1 });
        }
    }
    onBuyItem = (item,qty=1, catalogCausal=false) => {
        if (this.props.match.params.id) {
            return;
        }
        console.log(item);
        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
        var FIDCausal = this.props.params.DOCCartCausalId;
        if (catalogCausal) {
            FIDCausal = this.props.CatalogFIDCausal;
            if (!FIDCausal) {
                if (this.props.params.DOCCatalogCausals && this.props.params.DOCCatalogCausals.length === 1) {
                    FIDCausal = this.props.params.DOCCatalogCausals[0];
                    this.props.setCatalogCausal(FIDCausal);
                }
            }
        }

        if (FIDIdentity && FIDCausal) {
            this.saveDocument(6, item, FIDIdentity, FIDCausal, qty);
        }
        else {
            this.setState({ stars: 6, itemStar: Object.assign({}, item), FIDCausal: FIDCausal, rowQty: qty });
        }
    }

    onNoticeItem = (item) => {
        if (this.props.match.params.id) {
            return;
        }
        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
        if (FIDIdentity) {
            this.saveDocument(-1, item, FIDIdentity, this.props.params.DOCNoticeCausalId,1);
        }
        else {
            this.setState({ stars: -1, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCNoticeCausalId, rowQty: 1 });
        }
    }
    onReserveItem = (item) => {
        if (this.props.match.params.id) {
            return;
        }
        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }


        if (FIDIdentity) {
            this.saveDocument(-1, item, FIDIdentity, this.props.params.DOCReservationCausalId,1);
        }
        else {
            this.setState({ stars: -1, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCReservationCausalId, rowQty: 1 });
        }
    }
    onBookItem = (item) => {
        if (this.props.match.params.id) {
            return;
        }
        var { FIDIdentity } = this.state; if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
        if (FIDIdentity) {
            this.saveDocument(-1, item, FIDIdentity, this.props.params.DOCBookingCausalId,1);
        }
        else {
            this.setState({ stars: -1, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCBookingCausalId, rowQty: 1 });
        }
    }
    onRemoveItem = (item, FIDCausal) => {
        //if (this.props.match.params.id) {
        //    return;
        //}
        this.setState({ isLoading: true, itemStar: Object.assign({}, item) });
        var { FIDIdentity } = this.state; if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
        if (FIDIdentity) {
            this.removeFromDocument(item, FIDIdentity, FIDCausal);
        }
        else if (this.props.match.params.id) {

            this.removeFromDocument(item, undefined);
        }
    }
    goBack = () => {

        if (this.props.match.params.id) {
            this.context.router.history.push('/Documents/');
        }
    }
    removeFromDocument = (item, FIDIdentity, FIDCausal) => {
        
        var document = {
            FIDBOP: item.ID,
            IsCatalog: this.props.isCatalog,
            Price: item.Price,
            BOP: item
        };
        var url = '/IR/RemoveFromDocument/' + FIDIdentity + '?FIDCausal=' + FIDCausal;
        if (this.props.match.params.id) {
            url = '/IR/RemoveFromDocumentWithId/' + this.props.match.params.id;
        }
        fetch(url, {
            body: JSON.stringify(document),
            method: 'POST'
        }).then(res => res.json()).then(data => {
            if (this.props.match.params.id) {
                if (data.deletedHeader) {
                    this.goBack();
                }
                this.setState({ showItem: false });
                this.loadData();
                //this.onDocumentComplete(FIDIdentity, undefined, undefined);
            }
            else {
                this.onDocumentComplete(FIDIdentity, undefined, undefined);
            }
        });
    }
    saveDocumentNew = (stars, item, FIDCausal) => (FIDIdentity) => {
        var callback = undefined;
        if (this.state.noteCallback) {
            callback = this.state.noteCallback;
            
        }

        this.saveDocument(stars, item, FIDIdentity, FIDCausal, this.state.rowQty, callback);
    }
    saveDocument = (stars, item, FIDIdentity, FIDCausal, qty, callback) => {
        var { isCatalog } = this.props;
        var document = {
            IsCatalog: isCatalog,
            Stars: stars,
            FIDBOP: item.ID,
            Price: item.Price,
            Discount: item.Discount,
            TotalPrice: item.TotalPrice,
            FIDCausal: FIDCausal,
            BOP: item,
            InternalRemark: item.internalRemark,
            PublicRemark: item.publicRemark,
            Qty: qty,
        };

        this.setState({ isLoading: true, itemStar: Object.assign({},item) }, () => {
            //var FIDCausal = stars === 6 ? 10 : 11;
            fetch('/IR/SaveDocument/' + FIDIdentity, {
                body: JSON.stringify(document),
                method: 'POST'
            }).then(res => {
                this.onDocumentComplete(FIDIdentity, stars, FIDCausal, callback);
            });
        });
        
    }

    onDocumentComplete = (FIDIdentity, stars, FIDCausal, callback) => {
        //this.setState({ stars: undefined, itemStar: {} });
        this.setState(state => {
            state.stars = undefined;
            //state.itemStar = {};

            if (JSON.stringify(state.itemStar) !== JSON.stringify({})) {
                state.currentItem = Object.assign({}, state.itemStar);
            }
            state.itemStar = {};
            state.currentItem.Qty2 = stars === 6 ? 1 : stars;
            state.currentItem.FIDCausal = FIDCausal;
            state.FIDIdentity = FIDIdentity;
            //if (state.FIDIdentity !== FIDIdentity) {
            //    state.FIDIdentity = FIDIdentity;
            //    this.reloadIdentityInfo(FIDIdentity);
            //}

            return state;
        }, () => {
            
                this.reloadIdentityInfo(FIDIdentity, callback);
        });
    }
    reloadIdentityInfo = (FIDIdentity, callback) => {
        fetch('/IR/CatalogueInfo/' + FIDIdentity).then(res => res.json()).then(data => {
            this.setState(state => {
                state.IRAlias = data.Denomination;
                state.HasPresentations = data.HasPresentations;
                state.HasCart = data.HasCart;
                state.HasReservations = data.HasReservations;
                state.HasNotice = data.HasNotice;
                state.HasBookings = data.HasBookings;
                state.FIDIdentity = FIDIdentity;

                var label = "Presentazione - ";
                if (this.props.params.DOCCatalogCausals.includes(state.FIDCausal)) {
                    if (state.FIDCausal === this.props.params.DOCOrderCausalId) {
                        label = "Ordine - ";
                    }
                    else {
                        label = "Preventivo - ";
                    }
                }

                this.props.setTitle(label + data.Denomination);
                this.props.setCatalogIdentity(FIDIdentity);
                if (!this.props.match.params.id) {
                    if (!state.HasCart && !state.HasPresentations && !state.HasReservations && !state.HasNotice && !state.HasBookings) {
                        state.IRAlias = undefined;
                        state.FIDIdentity = undefined;
                        state.FIDCausalView = undefined;
                        this.props.setTitle(undefined);
                        this.props.setCatalogIdentity(undefined);
                        this.props.setCatalogCausal(undefined);
                    }
                }
                else {
                    if (this.props.params.DOCCatalogCausals.includes(state.FIDCausal)) {
                        state.HasCart = true;
                    }
                    else {
                        state.HasPresentations = true;
                    }
                }
                return state;
            }, () => {
                this.loadData(callback);
            });
        });
    }

    ViewPresentation = () => {
        if (this.state.FIDCausalView === this.props.params.DOCPresentationCausalId) {
            return;
        }
        this.setState(state => {
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.oldFilters = Object.assign({}, state.currentFilters);
            state.oldTempFilters = Object.assign({}, state.tempFilters);
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = this.props.params.DOCPresentationCausalId
            state.viewType = "product";
            state.showItem = false;
            return state;
        }, this.loadData);
    }
    ViewCart = () => {
        if (this.state.FIDCausalView === this.props.CatalogFIDCausal) {
            return;
        }
        this.setState(state => {
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.oldFilters = Object.assign({}, state.currentFilters);
            state.oldTempFilters = Object.assign({}, state.tempFilters);
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = this.props.CatalogFIDCausal;
            state.viewType = "product";
            state.showItem = false;
            return state;
        }, this.loadData);
    }
    ViewNotice = () => {
        if (this.state.FIDCausalView === this.props.params.DOCNoticeCausalId) {
            return;
        }
        this.setState(state => {
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.oldFilters = Object.assign({}, state.currentFilters);
            state.oldTempFilters = Object.assign({}, state.tempFilters);
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = this.props.params.DOCNoticeCausalId
            state.viewType = "product";
            state.showItem = false;
            return state;
        }, this.loadData);
    }
    ViewReservation = () => {
        if (this.state.FIDCausalView === this.props.params.DOCReservationCausalId) {
            return;
        }
        this.setState(state => {
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.oldFilters = Object.assign({}, state.currentFilters);
            state.oldTempFilters = Object.assign({}, state.tempFilters);
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = this.props.params.DOCReservationCausalId
            state.viewType = "product";
            state.showItem = false;
            return state;
        }, this.loadData);
    }
    ViewBooking = () => {
        if (this.state.FIDCausalView === this.props.params.DOCBookingCausalId) {
            return;
        }
        this.setState(state => {
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.oldFilters = Object.assign({}, state.currentFilters);
            state.oldTempFilters = Object.assign({}, state.tempFilters);
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = this.props.params.DOCBookingCausalId
            state.viewType = "product";
            state.showItem = false;
            return state;
        }, this.loadData);
    }
    ViewAll = () => {
        this.setState(state => {
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = undefined
            return state;
        }, this.loadData);
    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }
    doConfirmDocument = (callback) => {
        var { FIDIdentity } = this.state;
        //fetch('/IR/ConfirmDocument/' + FIDIdentity + "?FIDCausal=" + FIDCausalView).then(res => {
        fetch('/IR/ConfirmDocument/' + FIDIdentity + '?IsCatalog=' + this.props.isCatalog).then(res => {
            this.setState({ FIDCausalView: undefined }, () => {
                this.reloadIdentityInfo(FIDIdentity, callback);
            });
        });
    }
    doDeleteDocument = (callback) => {
        var { FIDIdentity } = this.state;
        var params = "";
        if (this.props.match.params.id) {
            params = "&FIDDocument=" + this.props.match.params.id;
        }
        fetch('/IR/DeleteDocument/' + FIDIdentity + '?IsCatalog=' + this.props.isCatalog + params).then(res => {
            this.setState({ FIDCausalView: undefined }, () => {
                if (this.props.match.params.id) {
                    this.goBack();
                }
                else {
                    this.reloadIdentityInfo(FIDIdentity, callback);
                }
            });
        });
    }
    handleDeleteDocument = () => {

        this.MessageBox.current.showMessageYesNo("Presentazione", "Procedere con l'eliminazione di questa presentazione?", this.doDeleteDocument);
    }
    handleConfirmDocument = () => {

        this.MessageBox.current.showMessageYesNo("Presentazione", "Procedere con la conferma di questa presentazione?", this.doConfirmDocument);
    }
    handleSort = () => {
        this.setState(sort => {
            sort.showSort = !sort.showSort;
            return sort;
        });
    }

    handlePageExit = (location, action) => {
        console.log({ location, action });
        if (location.pathname.startsWith("/BOPEdit/")) {
            return true;
        }
        
        if (!this.props.match.params.id && (this.state.HasPresentations || this.state.HasNotice || this.state.HasReservations || this.state.HasBookings || this.state.HasCart)) {
            var buttons = [
                {
                    label: "CONFERMA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        this.doConfirmDocument(() => {
                            switch (action) {
                                case "push":
                                default:
                                    this.props.history.push(location.pathname);
                                    break;
                            }
                        });
                    }
                },
                {
                    label: "ELIMINA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        this.doDeleteDocument(() => {
                            switch (action) {
                                case "push":
                                default:
                                    this.props.history.push(location.pathname);
                                    break;
                            }
                        });
                    }
                },
                {
                    label: "RIMANI",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                    }
                }
            ];
            this.MessageBox.current.showDialog(buttons, "Presentazione", "Desideri confermare questa presentazione?");
            return false;
        }
        
        return true;
    }

    static contextTypes = {
        router: PropTypes.object
    }

    renderLoading = () => {
        return <XOXLoader />
    }
    renderMobileStepper = (stepperVariant, pages, page) => {
        return (<MobileStepper
            variant={stepperVariant}
            steps={pages}
            position="static"
            activeStep={page - 1}
            backButton={
                <Button size="small" onClick={this.previousPage} disabled={page === 1 || this.state.isLoadingPage}>
                    <KeyboardArrowLeft />
                </Button>
            }
            nextButton={
                <Button size="small" onClick={this.nextPage} disabled={page === pages || this.state.isLoadingPage}>
                    <KeyboardArrowRight />
                </Button>
            }

        />)
    }
    saveTemplate = () => {
        var { tempFilters, filtersData } = this.state;
        var data = {
            Name: 'Template',
            Json: JSON.stringify(tempFilters)
        };
        var url = '/gc/template/catalogue';
        if (tempFilters.Template) {
            url += "/" + tempFilters.Template;
            data.Name = filtersData.templates.filter(f => f.ID === tempFilters.Template)[0].Label;
        }
        else {
            data.Name = tempFilters.TemplateName;
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => res.json()).then(data => {
            this.loadFiltersData();
        });
    }
    deleteTemplate = () => {
        var { tempFilters } = this.state;
        if (tempFilters.Template) {
            this.MessageBox.current.showMessageYesNo("Template ricerca", "Confermi la cancellazione del template selezionato?", () => {
                fetch('/gc/template/catalogue/' + tempFilters.Template, {
                    method: "DELETE"
                }).then(res => {
                    this.setState(state => {
                        state.tempFilters.Template = undefined;
                        state.tempFilters.TemplateName = undefined;
                        return state;
                    }, this.loadFiltersData);
                });
            });
        }
    }
    showBrands = () => {
        if (this.state.viewType === "brand") {
            return;
        }
        this.setState(state => {
            state.viewType = "brand";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.currentFilters = Object.assign({}, defaultFilters);
            state.currentFilters.FIDBrand = undefined;
            state.currentFilters.FIDLine = undefined;
            state.currentFilters.FIDSeries = undefined;

            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.showItem = false;

            state.page = 1;
            return state;
        }, () => {
            this.loadFiltersData();
            this.loadData();
            this.setCollectionTitle();
        });
    }
    showLines = () => {
        if (this.state.viewType === "line") {
            return;
        }
        this.setState(state => {
            state.viewType = "line";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            var FIDBrand = state.currentFilters.FIDBrand;
            state.currentFilters = Object.assign({}, defaultFilters);
            state.currentFilters.FIDBrand = FIDBrand;
            state.currentFilters.FIDLine = undefined;
            state.currentFilters.FIDSeries = undefined;
            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.page = 1;
            return state;
        }, () => {
            this.loadFiltersData();
            this.loadData();
            this.setCollectionTitle();
        });
    }
    showSeries = () => {
        if (this.state.viewType === "series") {
            return;
        }
        this.setState(state => {
            state.viewType = "series";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;

            var FIDBrand = state.currentFilters.FIDBrand;
            var FIDLine = state.currentFilters.FIDLine;

            state.currentFilters = Object.assign({}, defaultFilters);
            state.currentFilters.FIDBrand = FIDBrand;
            state.currentFilters.FIDLine = FIDLine;
            state.currentFilters.FIDSeries = undefined;
            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.page = 1;
            state.showItem = false;
            return state;
        }, () => {
            this.loadFiltersData();
            this.loadData();
            this.setCollectionTitle();
        });
    }
    showProducts = () => {
        if (this.state.viewType === "product" && !this.state.FIDCausalView) {
            return;
        }
        this.setState(state => {
            state.viewType = "product";
            state.page = 1;
            state.FIDCausalView = undefined;
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.showItem = false;
        }, () => {
            this.loadData();
        });
    }
    onItemEditClick = (data) => {
        //save cookies
        setCookie('catalogue_state', JSON.stringify(this.state));
        this.context.router.history.push('/BOPEdit/' + data.ID);
    }
    onCollectionCardClick = (viewType, data) => {
        var { collections } = this.state;
        switch (viewType) {
            case "brand":
                this.setState(state => {
                    var count = collections.lines.filter(item => item.FIDBrand === data.FIDBrand).length;
                    if (count <= 1) {
                        state.viewType = "product";
                    }
                    else {
                        state.viewType = "line";
                    }
                    state.currentFilters.FIDBrand = data.FIDBrand;
                    state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData();
                    }
                    this.setCollectionTitle();
                });
                break;
            case "line":
                this.setState(state => {
                    var count = collections.series.filter(item => item.FIDLine === data.FIDLine).length;
                    if (count <= 1) {
                        state.viewType = "product";
                    }
                    else {
                        state.viewType = "series";
                    }
                    state.currentFilters.FIDLine = data.FIDLine;
                    state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData();
                    }
                    this.setCollectionTitle();
                });
                break;
            case "series":
                this.setState(state => {
                    console.log(data);
                    state.viewType = "product";
                    state.currentFilters.FIDSeries = data.FIDSeries;
                    state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData();
                    }
                    this.setCollectionTitle();
                });
                break;
            default:
                break;
        }
    }
    setCollectionTitle = async () => {
        var { currentFilters } = this.state;
        var title = "";
        if (currentFilters.FIDBrand) {
            var brandInfo = await fetch('/BOP/GetBrandInfo/' + currentFilters.FIDBrand).then(res => res.json());
            console.log(brandInfo);
            title = brandInfo.Brand;
        }
        if (currentFilters.FIDLine) {
            var lineInfo = await fetch('/BOP/GetLineInfo/' + currentFilters.FIDLine).then(res => res.json());
            if (title !== "") {
                title += " / ";
            }
            title += lineInfo.Line;
        }
        if (currentFilters.FIDSeries) {
            var seriesInfo = await fetch('/BOP/GetSeriesInfo/' + currentFilters.FIDSeries).then(res => res.json());
            if (title !== "") {
                title += " / ";
            }
            title += seriesInfo.Series;
        }
        if (title !== "") {
            this.props.setTitle(title);
        }
        else {
            this.props.setTitle(undefined);
        }
    }
    getFilters = () => {
        var { advancedNavigation } = this.props;
        var { tempFilters, filtersData, viewType, collections } = this.state;
        viewType = "product";
        if (!viewType) {
            if (collections && advancedNavigation) {
                if (collections.brands.length > 1) {
                    viewType = "brand";
                }
                else if (collections.lines.length > 1) {
                    viewType = "line";
                }
                else if (collections.series.length > 1) {
                    viewType = "series";
                }
            }
            else {
                viewType = "product";
            }
        }


        var filter0Items = [
            {
                Type: 2,
                Label: "Template",
                ID: "Template",
                Value: tempFilters.Template,
                Items: filtersData.templates
            }
        ];
        console.log(filter0Items);
        var filter1Items = [{ Type: 1, ID: "ShowModels", Label: 'Catalogo', Value: tempFilters.ShowModels }, { Type: 1, ID: "ShowStock", Label: 'Stock', Value: tempFilters.ShowStock }];

        if (tempFilters.ShowStock || (!tempFilters.ShowModels && !tempFilters.ShowStock)) {
            filter1Items.push({
                Type: 2,
                ID: "GroupType",
                Label: "Visualizzazione",
                Value: tempFilters.GroupType,
                Items: [{ ID: 1, Label: "Visualizzazione analitica" }, { ID: 2, Label: "Visualizzazione raggruppata per codice" }, { ID: 3, Label: "Visualizzazione raggruppata per codice e posizione" }, { ID: 4, Label: "Visualizzazione raggruppata per variante" }]
            });
        }
        filter1Items.push({
            Type: 2,
            ID: "ImageView",
            Label: "Immagini",
            Value: tempFilters.ImageView,
            Items: [{ ID: 1, Label: "Tutto" }, { ID: 2, Label: "Solo distinte con immagini" }, { ID: 3, Label: "Solo distinte senza immagini" }],
        });
        var filters = [];
        if (viewType === "product") {
            filters.push({ Panel: "Visualizzazione", Type: 1, Label: 'Template ricerca', items: filter0Items });
        }
        filters.push({ Panel: "Visualizzazione", Type: 1, Label: 'Visualizza', items: filter1Items });
        if (viewType === "product") {
            if (filtersData.groups.length > 0) {
                filters.push({ Panel: "Criteri di ricerca", Type: 2, Label: 'Gruppo', ID: "FIDGroup", Value: tempFilters.FIDGroup, items: filtersData.groups });
            }
            if (filtersData.categories.length > 0) {
                filters.push({ Panel: "Criteri di ricerca", Type: 2, Label: 'Tag', ID: "FIDCategory", Value: tempFilters.FIDCategory, items: filtersData.categories });
            }
            if (filtersData.brands.length > 0) {
                filters.push({ Panel: "Criteri di ricerca", Type: 2, Label: 'Marca', ID: "FIDBrand", Value: tempFilters.FIDBrand, items: filtersData.brands });
            }
            if (filtersData.lines.length > 0) {
                filters.push({ Panel: "Criteri di ricerca", Type: 2, Label: 'Collezione', ID: "FIDLine", Value: tempFilters.FIDLine, items: filtersData.lines });
            }
            if (filtersData.series.length > 0) {
                filters.push({ Panel: "Criteri di ricerca", Type: 2, Label: 'Serie', ID: "FIDSeries", Value: tempFilters.FIDSeries, items: filtersData.series });
            }
            if (filtersData.prices.length > 0) {
                filters.push({ Panel: "Criteri di ricerca", Type: 3, Label: 'Prezzo', ID: "Price", Value: tempFilters.Price, items: { min: filtersData.prices[0], max: filtersData.prices[1] } });
            }
            if (filtersData.positions.length > 0) {
                filters.push({ Panel: "Punti vendita", Type: 2, Label: 'Filiali', ID: "FIDPosition", Value: tempFilters.FIDPosition, items: filtersData.positions });
            }
        }
        return filters;
    }
    onExpand = (index) => (isExpanded) => {
        this.setState(state => {
            state.data[index].cardIsExpanded = isExpanded;
            return state;
        }, () => {

            this.CardsCollection.current.recomputeCellSizesAndPositions();
                console.log(this.state.data[index]);
                index++;
                console.log(this.getRowTop((index - (index % 1)), 1, index))
        });
    }
    getRowTop = (row, cols, index) => {
        var { data } = this.state;
        //574.23
        var sum = 0;

        var { height } = this.getCardSize();
        var maxRow = height;
        var expPanel = 139.62;
        var lastIndex = (index - (index % cols));
        for (var i = 0; i < lastIndex; i++) {
            if (data[i].cardIsExpanded) {
                maxRow = height + expPanel;
            }
            if (i % cols === 0) {
                sum += maxRow;
                maxRow = height;
            }
        }
        sum += maxRow - height;
        
        return sum;
    }
    getNumberOfCols = () => {
        var cols = 4;
        switch (this.props.width) {
            case "xl":
                cols = 4;
                break;
            case "lg":
                cols = 3;
                break;
            case "md":
            case "sm":
                cols = 2;
                break;
            case "xs":
                cols = 1;
                break;
            default:
                break;
        }
        return cols;
    }
    getCardSize = () => {
        var { contentWidth } = this.state;
        var cols = this.getNumberOfCols();
        var tempWidth = 405.75;
        var tempHeight = 434.61;
        var width = (contentWidth - getScrollbarWidth()) / cols;
        
        var height = width * tempHeight / tempWidth;
        if (!this.props.showExpansionPanel) {
            height = height - 48;
        }
        return { width, height };
    }
    getStandardContent = () => {
        var { data, currentFilters, showFiltersRow, FIDIdentity, contentWidth } = this.state;

        var height = 100;
        var width = contentWidth;
        var mainContent = document.getElementById('mainContent');
        
        if (mainContent) {
            height = mainContent.clientHeight - 64;
            //width = mainContent.clientWidth;
        }
       
        height = window.innerHeight - 72.5 - 64 - 64;
        var collection = <Collection
            onScroll={event => {
                //this.setState({ scrollTop: event.scrollTop });
            }}
            //scrollTop={this.state.scrollTop}
            scrollToCell={this.state.currentIndex}
            ref={this.CardsCollection}
            cellCount={data.length}
            cellRenderer={({ index, key, style }) => {
                var item = data[index];
                key = item.ID;
                if (!key) {
                    key = index;
                }
                var tempItem = { };
                if (!this.state.isLoadingPage) {
                    tempItem = item;
                }
                style.margin = 8;
                style.height = undefined;
                return (
                    <CatalogueCard showExpansionPanel={this.props.showExpansionPanel} isExpanded={tempItem.cardIsExpanded} style={style} key={key} isLoading={this.state.isLoadingPage} data={tempItem} onItemClick={this.onItemClick(index)} FIDIdentity={FIDIdentity} FIDDocument={this.props.match.params.id} onStarItem={this.onStarItem} onBuyItem={this.onBuyItem} onNoticeItem={this.onNoticeItem} onReserveItem={this.onReserveItem} onBookItem={this.onBookItem} onRemoveItem={this.onRemoveItem} onExpand={this.onExpand(index)}/>
                );
            }}
            cellSizeAndPositionGetter={({ index }) => {
                var cols = this.getNumberOfCols();

                //var tempWidth = 405.75;
                //var tempHeight = 434.61;
                //var width = (contentWidth - getScrollbarWidth()) / cols;
                //var height = width * tempHeight / tempWidth;
                var { width, height } = this.getCardSize();
                var top = this.getRowTop((index - (index % cols)), cols, index);
                return {
                    height: height - 16,
                    width: width - 16,
                    x: (index % cols) * width,
                    y: top,//((index - (index % cols)) / cols) * height
                };
            }}
            height={height}
            width={width}
        />;

        //var cards = <Grid container spacing={16} style={{ padding: "10px" }}>
        //    {data.map((item, index) => {
        //        var key = item.ID;
        //        if (!key) {
        //            key = index;
        //        }
        //        var tempItem = {};
        //        if (!this.state.isLoadingPage) {
        //            tempItem = item;
        //        }
        //        return (
        //            <Grid key={key} item xs={12} sm={6} md={6} lg={4} xl={3}>
        //                <CatalogueCard isLoading={this.state.isLoadingPage} data={tempItem} onItemClick={this.onItemClick} FIDIdentity={FIDIdentity} FIDDocument={this.props.match.params.id} onStarItem={this.onStarItem} onBuyItem={this.onBuyItem} onNoticeItem={this.onNoticeItem} onReserveItem={this.onReserveItem} onBookItem={this.onBookItem} onRemoveItem={this.onRemoveItem} />
        //            </Grid>
        //        );
        //    })}
        //</Grid>;
        return (<Fragment>

            {showFiltersRow && (
                <Paper style={{ padding: "10px", position: "sticky", zIndex: 1050, top: 62, left: 0 }}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Group} label="Gruppo" id="Group" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Typology} label="Tipologia" id="Typology" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Category} label="Tag" id="Category" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Brand} label="Marca" id="Brand" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Line} label="Collezione" id="Line" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Code} label="Referenza" id="Code" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Description} label="Descrizione" id="Description" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.SerialNumber} label="Seriale" id="SerialNumber" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Barcode} label="Barcode" id="Barcode" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Position} label="Punto vendita" id="Position" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                    </Grid>
                </Paper>
            )}
            {
                //this.renderMobileStepper(stepperVariant, pages, page)
            }
            {
                //cards
            }
            {
                collection
            }
            {
                //this.renderMobileStepper(stepperVariant, pages, page)
            }
        </Fragment>);
    }
    getSingleItemContent = () => {
        var { classes } = this.props;
        var { FIDIdentity, currentItem, itemStar } = this.state;
        var onItemEditClick = this.onItemEditClick;
        if (!this.props.menus.includes("bopedit")) {
            onItemEditClick = undefined;
        }
        
        if (JSON.stringify(itemStar) !== JSON.stringify({})) {
            currentItem = Object.assign({}, itemStar);
        }
        
        var content = (
            <Paper className={classes.root}>
                <CatalogueItem ref={this.CatalogueItem} isCatalog={this.props.isCatalog} onEditClick={onItemEditClick} FIDIRIdentity={FIDIdentity} FIDDocument={this.props.match.params.id} data={currentItem} open={this.state.showItem} onClose={this.handleCatalgoItemClose} onStarItem={this.onStarItem} onBuyItem={this.onBuyItem} onNoticeItem={this.onNoticeItem} onReserveItem={this.onReserveItem} onBookItem={this.onBookItem} onRemoveItem={this.onRemoveItem} onNoteItem={this.onNoteItem} onShowRemarks={this.onShowRemarks} showRemarks={this.state.showRemarks} onHideRemarks={this.onHideRemarks} />
            </Paper>
        );
        return content;
    }
    getIdentitySelectorContent = () => {
        var { classes } = this.props;
        var { stars, itemStar, FIDCausal} = this.state;
        var content = (
            <Paper className={classes.root}>
                <IdentitySelector onIdentitySelected={this.saveDocumentNew(stars, itemStar, FIDCausal)} onCancel={() => { this.setState({ stars: undefined }) }} />
            </Paper>
        );
        return content;
    }
    onCausalSelected = (FIDCausal) => {
        var { stars, itemStar, FIDIdentity, rowQty } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }

        if (FIDIdentity) {
            this.props.setCatalogCausal(FIDCausal);
            this.saveDocument(stars, itemStar, FIDIdentity, FIDCausal, rowQty);
        }
        else {
            this.setState({ FIDCausal: FIDCausal }, () => {
                this.props.setCatalogCausal(FIDCausal);
            });
        }
    }
    getCausalSelectorContent = () => {
        var { classes } = this.props;
        var { stars, itemStar, FIDCausal } = this.state;
        var content = (
            <Paper className={classes.root}>
                <CausalSelector causals={this.props.params.DOCCatalogCausals} onCausalSelected={this.onCausalSelected} onCancel={() => { this.setState({ stars: undefined }) }} />
            </Paper>
        );
        return content;
    }
    getFiltersContent = (viewType) => {
        var { showFilters, tempFilters } = this.state;
        var filters = this.getFilters();
        var content = <XFilters open={showFilters} showOrder={true} filters={filters} sort={tempFilters.Sort} onClose={this.onFilterClose} onFilterChange={this.onFilterChange} onFilterSet={this.onSetFilters} onClearFilters={this.onClearTempFilterButton} onSaveTemplate={this.onSaveTemplate} onDeleteTemplate={this.onDeleteTemplate} onSortChange={this.onSortChange} />;
        return content;
    }
    getCollectionContent = (viewType) => {
        var { collections, currentFilters, contentWidth } = this.state;

        var data = [];
        var showLabel = false;
        var key = viewType;
        switch (viewType) {
            case "series":
                
                data = collections.series;
                showLabel = true;
                break;
            case "line":
                data = collections.lines;
                showLabel = true;
                break;
            case "brand":
            default:
                data = collections.brands;
                break;
        }

        if (currentFilters.FIDBrand) {
            data = data.filter(i => i.FIDBrand === currentFilters.FIDBrand);
        }
        if (currentFilters.FIDLine) {
            data = data.filter(i => i.FIDLine === currentFilters.FIDLine);
        }
        var height = window.innerHeight - 72.5 - 64 - 64;
        var width = contentWidth;

        var collection = <Collection
            key={key}
            ref={this.BLSCollection}
            cellCount={data.length}
            cellRenderer={({ index, key, style }) => {
                var item = data[index];
                key = item.ID;
                if (!key) {
                    key = index;
                }
                var tempItem = item;
                //if (!this.state.isLoadingPage) {
                //    tempItem = item;
                //}
                style.margin = 8;
                return (
                    <CollectionCard key={key} style={style} showLabel={showLabel} viewType={viewType} isLoading={this.state.isLoadingPage} data={tempItem} onClick={this.onCollectionCardClick} />
                );
            }}
            cellSizeAndPositionGetter={({ index }) => {

                var cols = 4;
                switch (this.props.width) {
                    case "xl":
                        cols = 4;
                        break;
                    case "lg":
                        cols = 4;
                        break;
                    case "md":
                    case "sm":
                        cols = 3;
                        break;
                    case "xs":
                        cols = 2;
                        break;
                    default:
                        cols = 4;
                        break;
                }

                var width = (contentWidth - getScrollbarWidth()) / cols;
                var height = width;
                return {
                    height: height - 16,
                    width: width - 16,
                    x: (index % cols) * width,
                    y: ((index - (index % cols)) / cols) * height
                };
            }}
            height={height}
            width={width}
        />;

        return (<Fragment>
            {collection}
        </Fragment>);
    }

    onCodeSearch = () => {
        this.setState({ bopsSearch: true, bopsSearchType: "code", bopsSearchValue: "" });
    }
    onBarcodeSearch = () => {
        this.setState({ bopsSearch: true, bopsSearchType: "barcode", bopsSearchValue: "" });
    }
    onSerialSearch = () => {
        this.setState({ bopsSearch: true, bopsSearchType: "serial", bopsSearchValue: "" });
    }
    onSearchCancel = () => {
        this.setState({ bopsSearch: false, bopsSearchType: "", bopsSearchValue: "" });
    }
    onSearchConfirm = () => {
        this.doSearchConfirm();
    }
    onSearchBlur = (id) => (event) => {
        this.bopsSearch.current.focus();
    }
    onSearchChange = (id) => (event, value) => {
        this.setState({ bopsSearchValue: value });
    }
    onSearchKeyDown = (id) => (event, value) => {
        if (event.keyCode === 13) {
            this.doSearchConfirm();
        }
    }
    doSearchConfirm = () => {
        var { bopsSearchType, bopsSearchValue } = this.state;

        var key = "";
        switch (bopsSearchType) {
            case "code":
                key = "Code";
                break;
            case "barcode":
                key = "Barcode";
                break;
            case "serial":
                key = "SerialNumber";
                break;
            default:
                break;
        }

        var currentFilters = Object.assign({}, defaultFilters);
        var tempFilters = Object.assign({}, defaultFilters);
        currentFilters[key] = bopsSearchValue;
        tempFilters[key] = bopsSearchValue;
        this.setState({ currentFilters, tempFilters, filterChanged: true, viewType: 'product' }, () => {
            this.loadData();
            this.loadFiltersData();
            this.setState({ bopsSearch: false, bopsSearchType: "", bopsSearchValue: "" });
        });
    }

    render() {
        var { width, classes, advancedNavigation } = this.props;
        var { count, showFilters, currentFilters, stars, itemStar, isLoading, collections, viewType, bopsSearch, bopsSearchType, bopsSearchValue, FIDCausal } = this.state;

        if (isLoading) {
            //return this.renderLoading();
        }

        var filtersArray = this.getFiltersArray(currentFilters);


        var filterButtonHandler = this.handleFilterButton;
        if (["xs", "sm"].includes(width)) {
            filterButtonHandler = undefined;
        }

        var customActions = [];
       
        var content = this.getStandardContent();

        var showButtons = true;
        var showAppBar = true;
        if (this.props.match.params.id) {
            advancedNavigation = false;
        }

        var navActions = [];
        if (collections && advancedNavigation) {
            if (collections.brands.length > 1) {
                if (!viewType) {
                    viewType = "brand";
                }
                navActions.push({ name: this.props.labels.brands, icon: <AlphaBcircleOutline />, action: this.showBrands, selected: viewType === "brand" });
            }
            var linesCount = collections.lines.filter(item => {
                var result = true;
                if (this.state.currentFilters.FIDBrand)
                    result = result && item.FIDBrand === this.state.currentFilters.FIDBrand;
                if (this.state.oldFilters && this.state.oldFilters.FIDBrand)
                    result = result && item.FIDBrand === this.state.oldFilters.FIDBrand;
                return result;
            }).length;
            if (linesCount > 1) {
                if (!viewType) {
                    viewType = "line";
                }
                navActions.push({ name: this.props.labels.lines, icon: <AlphaLcircleOutline />, action: this.showLines, selected: viewType === "line" });
            }

            var seriesCount = collections.series.filter(item => {
                var result = true;
                if (this.state.currentFilters.FIDBrand)
                    result = result && item.FIDBrand === this.state.currentFilters.FIDBrand;
                if (this.state.currentFilters.FIDLine)
                    result = result && item.FIDLine === this.state.currentFilters.FIDLine;
                if (this.state.oldFilters && this.state.oldFilters.FIDBrand)
                    result = result && item.FIDBrand === this.state.oldFilters.FIDBrand;
                if (this.state.oldFilters && this.state.oldFilters.FIDLine)
                    result = result && item.FIDLine === this.state.oldFilters.FIDLine;
                return result;
            }).length;
            if (seriesCount > 1) {
                if (!viewType) {
                    viewType = "series";
                }
                navActions.push({ name: this.props.labels.series, icon: <AlphaScircleOutline />, action: this.showSeries, selected: viewType === "series" });
            }
            if (navActions.length >= 1) {
                var isSelected = viewType === "product" && !this.state.FIDCausalView;
                navActions.push({ name: this.props.labels.products, icon: <AlphaPcircleOutline />, action: this.showProducts, selected: isSelected });
            }
        }
        else {
            if (!viewType) {
                viewType = "product";
            }
        }
        if (this.state.IRAlias) {
            if (this.state.HasPresentations) navActions.push({ name: "Presentazione", icon: <Star />, action: this.ViewPresentation, selected: this.state.FIDCausalView === this.props.params.DOCPresentationCausalId });
            if (this.state.HasNotice) navActions.push({ name: "Avvisi", icon: <BellRingOutline />, action: this.ViewNotice, selected: this.state.FIDCausalView === this.props.params.DOCNoticeCausalId });
            if (this.state.HasReservations) navActions.push({ name: "Riservati", icon: <LockOpenOutline />, action: this.ViewReservation, selected: this.state.FIDCausalView === this.props.params.DOCReservationCausalId });
            if (this.state.HasBookings) navActions.push({ name: "Prenotati", icon: <LockOutline />, action: this.ViewBooking, selected: this.state.FIDCausalView === this.props.params.DOCBookingCausalId });
            if (this.state.HasCart) navActions.push({ name: "Carrello", icon: <CartOutline />, action: this.ViewCart, selected: this.state.FIDCausalView === this.props.params.DOCCartCausalId });
            //if (this.state.FIDCausalView) navActions.push({ name: "Prodotti", icon: <AlphaP />, action: this.ViewAll, selected: false });
        }
        if (viewType !== "product") {
            content = this.getCollectionContent(viewType);
        }



        if (showFilters) {
            showAppBar = false;
            content = this.getFiltersContent(viewType);
        }
        if (this.state.showItem) {
            showButtons = false;
            content = this.getSingleItemContent();
        }
        if (stars && itemStar && FIDCausal) {
            showAppBar = false;
            content = this.getIdentitySelectorContent();
        }
        if (stars && itemStar && !FIDCausal) {
            showAppBar = false;
            content = this.getCausalSelectorContent();
        }
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }

        var label = <Fragment><XNavigation actions={navActions} direction={direction} /></Fragment>;
        if (showButtons && (this.state.HasPresentations || this.state.HasNotice || this.state.HasReservations || this.state.HasBookings || this.state.HasCart)) {
            customActions = [];
            if (!this.props.match.params.id) {
                customActions.push(<Tooltip title="Conferma presentazione">
                    <IconButton aria-label="Conferma presentazione" onClick={this.handleConfirmDocument} >
                        <Check />
                    </IconButton>
                </Tooltip>);
            }
            customActions.push(<Tooltip title="Elimina presentazione">
                <IconButton aria-label="Elimina presentazione" onClick={this.handleDeleteDocument} >
                    <DeleteSweep />
                </IconButton>
            </Tooltip>);
        }
        if (showButtons) {
            var showCode = this.props.catalogueButtons.includes("code");
            var showBarcode = this.props.catalogueButtons.includes("barcode");
            var showSerial = this.props.catalogueButtons.includes("serial");
            var searchActions = popupBOPSearch(showCode && this.onCodeSearch, showBarcode && this.onBarcodeSearch, showSerial && this.onSerialSearch);
            customActions = customActions.concat(searchActions);
        }
        if (bopsSearch) {
            //content = getSearchContent();
            var { customContent, actions } = getBOPSearchContent(this.bopsSearch, bopsSearchType, bopsSearchValue, this.onSearchBlur, this.onSearchKeyDown, this.onSearchChange, this.onSearchCancel, this.onSearchConfirm)
            content = customContent;
            customActions = actions;
        }
        if (!this.state.showItem && isLoading) {
            content = this.renderLoading();
        }
        return (

            <Paper className={classes.root}>
                <Prompt when={true} message={this.handlePageExit} />
                <XMessageBox ref={this.MessageBox} />
                {showAppBar && <AppBar position="sticky">
                    <EnhancedTableToolbar
                        onFilterButton={showButtons && filterButtonHandler}
                        onAdvancedFilterButton={showButtons && this.onAdvancedFilterButton}
                        onClearFilterButton={showButtons && filtersArray && filtersArray.length > 0 && this.onClearFilterButton}
                        numSelected={0}
                        selectedAll={false}
                        count={count}
                        actions={customActions}
                        grid={this}
                        label={label}
                    />
                </AppBar>}
                {content}
            </Paper>
        );
    }
}
const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withWidth(),
    withStyles(styles)
);


export default enhance(Catalogue);