import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import XOXLoader from './XOXLoader';

const styles = theme => ({
    root: {
        width: "calc(100% - 10px)",
        position: "relative",
        margin: 5,
    },
    content: {
        position: "relative",
        width: "100%",
        //paddingTop: "calc(100% - 10px)",
        height: "100%",
        padding: 0,
    },
    logo: {
        width: "calc(100% - 0px)",
        height: "calc(100% - 0px)",
        position: "absolute",
        objectFit: "scale-down",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        cursor: "pointer",
    },
    label: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    },
    labelSpan: {
        position: "absolute",
        bottom: 0,
        left: 0,
        display: "block",
        width: "100%",
        textAlign: "center",
        padding: 10,
        background: "rgba(0,0,0,0.7)",
        color: "#fff",
        fontWeight: "bold",
    }
});

class CollectionCard extends Component {
    state = {
        data: {},
        isLoading: true,
    };
    componentDidMount() {
        this.reloadData();
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.reloadData();
        }
    }
    reloadData = () => {
        var { classes, viewType, data } = this.props;
        if (!data) {
            this.setState({ isLoading: true });
            return;
        }
        var id = undefined;
        var url = "";
        switch (viewType) {
            case "brand":
                id = data.FIDBrand;
                url = "/BOP/GetBrandInfo/" + id;
                break;
            case "line":
                id = data.FIDLine;
                url = "/BOP/GetLineInfo/" + id;
                break;
            case "series":
                id = data.FIDSeries;
                url = "/BOP/GetSeriesInfo/" + id;
                break;
            default:
                id = 0;
                break;
        }
        if (!id) {
            return;
        }

        this.setState({ isLoading: true }, () => {
            var { classes, viewType, data } = this.props;
            var id = 0;
            
            if (url !== "") {
                fetch(url).then(res => res.json()).then(data => {
                    this.setState({ data, isLoading: false });
                });
            }
        });
    }
    onClick = () => {
        var { onClick, data, viewType } = this.props;
        console.log(data);
        if (onClick && data) {
            onClick(viewType, data);
        }
    }
    render() {
        var { classes, viewType, onClick, showLabel } = this.props;
        var { data, isLoading } = this.state;

        var onClick = this.onClick;
        var ui = false;
        var isLoadingCheck = false;
        if (isLoading || !data || !this.props.data) {
            isLoadingCheck = true;
            ui = <XOXLoader onlyO={true} />;
            onClick = undefined;
        }
        else {
            var url = "";
            var label = "";
            var id = 0;
            switch (viewType) {
                case "brand":
                    id = data.ID;
                    url = "/BOP/BrandDefaultImage/" + id;
                    label = data.Brand;
                    break;
                case "line":
                    id = data.ID;
                    url = "/BOP/LineDefaultImage/" + id;
                    label = data.Line;
                    break;
                case "series":
                    id = data.ID;
                    url = "/BOP/SeriesDefaultImage/" + id;
                    label = data.Series;
                    break;
                default:
                    label = "";
                    break;
            }
            ui = <object className={classes.logo} data={url} type="image/jpg">
                {!showLabel && <Typography className={classes.label}>{label}</Typography>}
            </object>;
        }

        return (
            <Card style={this.props.style} className={classes.root}>
                <CardContent className={classes.content} onClick={onClick}>
                    {ui}
                    {showLabel && !isLoadingCheck && <span className={classes.labelSpan}>{label}</span>}
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(CollectionCard);