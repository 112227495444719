import {
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
    grayCardHeader,
} from "assets/jss/material-dashboard-pro-react.jsx";
const cardIconStyle = {
    cardIcon: {
        "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$grayCardHeader": {
            borderRadius: "3px",
            backgroundColor: "#999",
            padding: "12px",
            marginTop: "-20px",
            marginRight: "15px",
            float: "left",
            lineHeight: "12px",
        }
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
    grayCardHeader,
};

export default cardIconStyle;
