import React, {Component} from 'react';

import IREdit from 'views/IR/IREdit';
import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { ArrowRight, ArrowLeft } from 'mdi-material-ui';

const styles = theme => ({
});

class IdentitySelector extends Component {
    constructor(props) {
        super(props);

        this.IREdit = React.createRef();
        this.grdIR = React.createRef();
        this.MessageBox = React.createRef();
    }
    state = {
        data: {},
        newIdentity: false,
    };

    identitySelected = () => {
        var { onIdentitySelected } = this.props;
        var { data } = this.state;
        var FIDIdentity = data.ID;
        if (FIDIdentity && onIdentitySelected) {
            onIdentitySelected(FIDIdentity);
        }
    }
    saveIdentity = async () => {
        var saved = await this.IREdit.current.save(undefined, () => { });
        if (saved) {
            this.setState({ data: saved, newIdentity: false }, this.identitySelected);
        }
    }
    loadIdentity = () => {
        var selected = this.grdIR.current.getSelectedData();
        if (selected.length > 1) {
            this.MessageBox.current.showMessageOk("Selezione anagrafiche", <Typography>Selezionate più anagrafiche. Per continuare selezionare una sola anagrafica.</Typography>);
            return;
        }
        else {
            var selectedData = this.grdIR.current.getRowData(this.grdIR.current.getSelectedData()[0]);
            this.setState({ data: selectedData, newIdentity: false }, this.identitySelected);
        }
    }
    selectIdentity = () => {
        var { newIdentity } = this.state;
        if (newIdentity) {
            this.saveIdentity();
        }
        else {
            this.loadIdentity();
        }
    }
    handleNewIdentity = () => {
        this.setState({ newIdentity: true });
    }

    render() {
        var { classes, onCancel, showRelations } = this.props;
        var { data, newIdentity } = this.state;
        var previousStep = undefined;
        if (onCancel) {
            previousStep = <Tooltip title="Indietro">
                <IconButton aria-label="Indietro" onClick={onCancel} >
                    <ArrowLeft />
                </IconButton>
            </Tooltip>;
        } 
        var nextStep = <Tooltip title="Avanti">
            <IconButton aria-label="Avanti" onClick={this.selectIdentity} >
                <ArrowRight />
            </IconButton>
        </Tooltip>;


        var customActions = [previousStep];
        var selectedActions = [nextStep];
        var dataRoute = "/IR/GetData?grid=tblIR";
        var dataRouteColumns = "/Base/GetColumns?grid=tblIR";
        var dataRouteAvailableColumns = "/Base/GetAvailableColumns?grid=tblIR";
        var dataRouteUpdateColumns = "/Base/UpdateColumns?grid=tblIR";
        var onDoubleClick = undefined;
        var customContent = undefined;
        var showFilterActivator = true;
        var showColumnsSelector = true;
        var newAction = this.handleNewIdentity;

        if (showRelations) {
            dataRoute += "&showRelations=true";
        }
        
        var label = "Seleziona un'anagrafica";
        if (newIdentity) {
            label = "Inserimento nuova anagrafica";
            previousStep = <Tooltip title="Indietro">
                <IconButton aria-label="Indietro" onClick={() => { this.setState({ newIdentity: false }) }} >
                    <ArrowLeft />
                </IconButton>
            </Tooltip>;
            customActions = [previousStep,nextStep];
            customContent = (<IREdit id={data.ID} innerRef={this.IREdit} topMargin="0px" headerColor="info" noRedirect={true} showExit={false} showSave={false} showDelete={false} showNew={false} />);
            showFilterActivator = false;
            showColumnsSelector = false;
            newAction = undefined;
        }

        return (
            <div className={classes.root}>
                <XGrid
                    innerRef={this.grdIR}
                    label={label}
                    dataRoute={dataRoute}
                    dataRouteColumns={dataRouteColumns}
                    dataRouteAvailableColumns={dataRouteAvailableColumns}
                    dataRouteUpdateColumns={dataRouteUpdateColumns}
                    showFilterActivator={showFilterActivator}
                    showColumnsSelector={showColumnsSelector}
                    onNewButton={newAction}
                    //dataRouteDelete={""}
                    actions={customActions}
                    selectedActions={selectedActions}
                    customContent={customContent}
                    onDoubleClick={onDoubleClick}
                />
                <XMessageBox ref={this.MessageBox} />
            </div>
        );
    }
}
export default withStyles(styles)(IdentitySelector);