import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';


import { Close, Check} from 'mdi-material-ui';


import XSelect from 'components/XSelect';
import XSwitch from 'components/XSwitch';
import XUpload from 'components/XUpload';
import XGrid from 'components/XGrid';
import XExpansionPanel from 'components/XExpansionPanel';
import TabContainer from 'components/CustomTabs/TabContainer';

import axios, { CancelToken } from 'axios';

const style = () => ({
});

class XFiles extends Component {
    constructor(props) {
        super(props);

        this.grdFiles = React.createRef();
    }
    state = {
        file: {},
    };

    onFileSelectChange = (key) => (event, data) => {
        //alert(key);
        //alert(JSON.stringify(data));

        this.setState(state => {
            if (data.length > 0) {
                state.file[key] = data[0].ID;
            }
            else {
                state.file[key] = undefined;
            }
            return state;
        });
    }
    onFileChange = (key) => (event, data) => {
        this.setState(state => {
            if (data) {
                state.file[key] = data;
            }
            else {
                state.file[key] = undefined;
            }
            return state;
        });
    }
    handleFileNew = () => {
        this.setState({ isFileEdit: true, file: {} });
    }
    handleCancelFile = () => {
        this.setState({ isFileEdit: false, file: {} });
    }
    saveFile = () => {

        var { FID, urlUploadFile } = this.props;
        var { file } = this.state;

        if (!FID) {
            FID = 0;
        }

        this.setState({ isUploading: true, uploadProgress: 0 });

        const data = new FormData();

        data.append('file', file.File, file.File.name);
        data.append('FIDFileTypology', file.FIDFileTypology);
        data.append('Default', file.Default ? true : false);
        axios
            .post(urlUploadFile + FID, data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({ uploadProgress: (ProgressEvent.loaded / ProgressEvent.total * 100) });
                },
                cancelToken: new CancelToken(c => {
                    this.cancelUpload = c;
                })
            })
            .then(res => {
                this.setState({ isFileEdit: false, file: {}, isUploading: false });
                this.grdFiles.current.refresh();
            })
            .catch(err => {
                this.setState({ isUploading: false });
            });

    }
    handleSaveFile = () => {

        var { FID, urlCheckDefaultFiles } = this.props;
        var { file } = this.state;
        if (file.File === {}) {
            return;
        }
        if (!FID) {
            FID = 0;
        }

        if (file.Default) {
            var tempFile = {
                ID: file.ID,
                FIDFileTypology: file.FIDFileTypology
            };
            if (!tempFile.ID) {
                tempFile.ID = 0;
            }
            fetch(urlCheckDefaultFiles + FID, {
                body: JSON.stringify(tempFile),
                method: 'POST'
            }).then(res => res.json())
                .then(result => {
                    if (result.HasDefault) {
                        var buttons = [
                            {
                                label: "SI",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    this.saveFile();
                                }
                            },
                            {
                                label: "NO",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    //this.saveContact(FIDIdentity, isDestination, isRelation, data);
                                }
                            },
                        ];

                        this.showDialog(buttons, "File predefinito", "È già presente un record predefinito con questa tipologia. Confermi inserimento?");
                    }
                    else {
                        this.saveFile();
                    }
                });
        }
        else {
            this.saveFile();
        }

    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }

    render() {
        var { urlGetFiles, urlDeleteFiles, FID } = this.props;
        var { file } = this.state;

        var fileHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={3}><XSelect label="Tipologia" id="FIDFileTypology" urlData="/Base/GetFileTypologies" urlCreate="/Base/CreateFileTypology" value={file.FIDFileTypology} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onFileSelectChange} /></Grid>
                    <Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={file.Default} onChange={this.onFileChange} /></Grid>
                    <Grid item xs={12} md={3}><XUpload label="File" id="File" value={file.File} onChange={this.onFileChange} /></Grid>
                </Grid>
            </Grid>
        </Grid>);

        var panels = [];
        panels.push({
            icon: "attachment",
            label: "Files",
            content: fileHeadFields,
        });
        var customContent = undefined;

        //var doubleClick = this.handleFileDoubleClick;
        var newClick = this.handleFileNew;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];
        if (this.state.isFileEdit) {
            customContent = (<XExpansionPanel panels={panels} />);
            //doubleClick = undefined;
            newClick = undefined;
            showFilterActivator = false;
            showColumnSelector = false;
            customActions = [
                (
                    <Tooltip title="Salva">
                        <IconButton aria-label="Salva" onClick={this.handleSaveFile} >
                            <Check />
                        </IconButton>
                    </Tooltip>
                ),
                (
                    <Tooltip title="Annulla">
                        <IconButton aria-label="Annulla" onClick={this.handleCancelFile} >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )
            ];
        }
        var customCells = {
            Preview: (data, column) => {
                return <img style={{
                    height: "auto", width: "auto", maxHeight: "103px", maxWidth: column.width - 10
                }} src={"data:image/png;base64, " + data.base64file} alt="" />
            }
        };
        var ui = (
            <XGrid
                innerRef={this.grdFiles}
                dataRoute={urlGetFiles + FID + "?grid=tblFiles"}
                dataRouteColumns="/Base/GetColumns?grid=tblFiles"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblFiles"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblFiles"
                //onDoubleClick={doubleClick}
                dataRouteDelete={urlDeleteFiles + FID}
                onNewButton={newClick}
                //onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
                customCells={customCells}
                rowHeight={105}
            />
        )

        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
}
export default withStyles(style)(XFiles);