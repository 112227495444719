import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import XOXLoader from 'components/XOXLoader';
import SwipeableViews from 'react-swipeable-views';

import { withStyles} from '@material-ui/styles';

const styles = theme => ({
    card: {
        position: "relative",
        margin: 5,
    },
    slider: {
        //maxHeight: 220,
        //minHeight: 220,
        overflow: "hidden !important",
        position: "relative",
        width: "100%",
        cursor: "pointer",
    },
    actions: {
        display: 'flex',
    },
    media: {
        height: 0,
        paddingTop: '75%', // 16:9
        backgroundSize: 'contain',
    },
    code: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        textAlign: 'center',
        background: 'rgba(0,0,0,0.7)',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 12,
    }
});

class RelatedCard extends Component {

    render() {
        var { classes, onItemClick, data } = this.props;
        var content = <XOXLoader onlyO={true} height="100%" />;
        if (data) {
            var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
            if (files.length > 1) {
                files = [files[0]];
            }
            var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?size=S"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
            if (data.File64) {
                mediaFiles = [(<CardMedia className={classes.media} image={data.File64} />)];
            }


            content = <div className={classes.slider} onClick={() => { if (onItemClick) onItemClick(data) }}>
                <SwipeableViews axis="x" resistance={false} index={0}>
                    {mediaFiles}
                </SwipeableViews>
                <span className={classes.code}>{data.CatalogCode}</span>
            </div>;
        }
        
        return (
            <Card className={classes.card}>
                {content}
            </Card>
        );
    }
}

export default withStyles(styles)(RelatedCard);