import React, { Component } from 'react';
import PropTypes from 'prop-types';
import XGrid from "../components/XGrid"

import "../components/XReactDataGrid.css";



export class Identities extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    handleGridDoubleClick = (event, data) => {
        this.context.router.history.push('/IREdit/' + data.ID);
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/IRNew');
    }
    render() {
        return (
            <XGrid
                usePrimaryColor={true}
                dataRoute="/IR/GetData?grid=tblIR"
                dataRouteColumns="/Base/GetColumns?grid=tblIR"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIR"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIR"
                dataRouteDelete={"/IR/DeleteIR/"}
                onDoubleClick={this.handleGridDoubleClick}
                onEditButton={this.handleGridDoubleClick}
                onNewButton={this.handleGridNew}
            />
        );
    }
}
