import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx"

class XSwitch extends Component {
    handleChange = (event, value) => {
        var { id, onChange } = this.props;

        if (onChange) {
            onChange(id)(event, value);
        }

        //var { data } = caller.state;

        //data[id] = value;
        //caller.setState({ data });
    }
    render() {
        var { classes, id, value, label } = this.props;
        if (!value) {
            value = false;
        };
        return (
            <FormControlLabel
                checked={value}
                onChange={this.handleChange}
                control={
                    <Switch
                        value={id}
                        color="primary"
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            //icon: classes.switchIcon,
                            //iconChecked: classes.switchIconChecked,
                            //bar: classes.switchBar
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    //labelPlacementTop: classes.labelPlacementTop,
                }}
                labelPlacement="top"
                label={label}
            />
        );
    }
}

export default withStyles(styles)(XSwitch);