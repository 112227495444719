import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import styles from "assets/jss/material-dashboard-pro-react/customTextFieldStyle.jsx"

//const fieldStyles = theme => ({
//    container: {
//        display: 'flex',
//        flexWrap: 'wrap',
//    },
//    textField: {
//        marginLeft: theme.spacing.unit,
//        marginRight: theme.spacing.unit,
//        marginTop: 8,
//        width: "100%",
//    },
//    dense: {
//        marginTop: 19,
//    },
//    marginNormal: {
//        marginTop: "8px",
//    },
//    menu: {
//        width: 200,
//    },
//});

class XField extends Component {

    handleChange = (event, value) => {
        var { id, onChange } = this.props;

        if (onChange) {
            onChange(id)(event, event.target.value);
        }
        //var { id, caller, dataKey } = this.props;
        //var data = caller.state[dataKey];
        //data[id] = event.target.value;
        //caller.setState((state, props) => {
        //    state[dataKey] = data;
        //    return state;
        //});
        //caller.setState({ data });
    }
    handleBlur = (event) => {
        var { id,onBlur } = this.props;
        if (onBlur) {
            onBlur(id)(event);
        }
    }
    handleKeyDown = (event) => {
        var { id, onKeyDown } = this.props;
        if (onKeyDown) {
            onKeyDown(id)(event);
        }
    }
    render() {
        var { classes, id, value, label, multiline, type, field, style, rowsMax, rows, hasError, onBlur,onKeyDown, onChange, nativeRef, ...other } = this.props;

        if (value === undefined || value === null) {
            value = "";
        }
        var error = false;
        var errorClass = classes.error;
        if (field) {
            if ((field.Required === 2 || (field.Required === 1)) && !value) {
                error = true;
                if (field.Required === 1) {
                    errorClass = classes.errorOptional;
                }
            }
        }
        if (hasError) {
            error = hasError;
            errorClass = classes.error;
        }

        return (
            <TextField
                ref={nativeRef}
                id={id}
                name={id}
                label={label}
                multiline={multiline}
                rowsMax={rowsMax || "6"}
                rows={rows || "6"}
                fullWidth
                error={error}
                InputLabelProps={{
                    shrink: true,
                    classes: {
                        root: classes.textFieldLabel,
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.textFieldInput,
                        error: errorClass
                    }
                }}
                className={classes.textField}
                value={value}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onKeyDown={this.handleKeyDown}
                margin="normal"
                type={type}
                style={style}
                {...other}
            />
        );
    }
}
XField.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
};
export default withStyles(styles)(XField);