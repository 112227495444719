import React, { Component,Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import XDialog from './XDialog';
import XGridRow from './XGridRow';

import XOXLoader from 'components/XOXLoader';

import { List } from 'react-virtualized';


const styles = theme => ({
    root: {
        width: '100%',
        //marginTop: theme.spacing(3),
        fontSize: '1em',
    },
    table: {
        minWidth: "100%",
        fontSize: '1em',
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'hidden',
        fontSize: '14px',
    },
    tableCell: {
        fontSize: '0.8em',
        padding: "0 10px 0",
    },
    searchField: {
        margin: '5px',
    },
});


class XGrid extends Component {
    constructor(props) {
        super(props);
        this.rowsContainer = React.createRef();
    }
    state = {
        order: 'asc',
        orderBy: 'ID',
        selected: [],
        data: [],
        count: 0,
        loadedPage: 0,
        itemsPerPage: 1000000,
        lastPage: false,
        finishLoading: true,
        showColumnsSelector: false,
        columns: [],
        allColumns: [],
        page: 0,
        rowsPerPage: 5,
        sortColumn: "",
        sortDirection: "",
        lastFilterType: 0,
        showFilters: false,
        filters: {},
        selectAll: false,
        isMounted: true,
        firstLoad: true,
        dialog: {},
    };

    componentDidMount() {
        this.getColumns();
        if (this.props.dataRouteAvailableColumns) {
            fetch(this.props.dataRouteAvailableColumns)
                .then(res => res.json())
                .then(columns => this.setState(() => {
                    return { allColumns: columns, isMounted: true };
                }));
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.dataRouteColumns !== this.props.dataRouteColumns) {
            this.getColumns();
        }
        if (prevProps.dataRouteAvailableColumns !== this.props.dataRouteAvailableColumns) {
            if (this.props.dataRouteAvailableColumns) {
                fetch(this.props.dataRouteAvailableColumns)
                    .then(res => res.json())
                    .then(columns => this.setState(() => {
                        return { allColumns: columns, isMounted: true };
                    }));
            }
        }
    }
    componentWillUnmount() {
        this.setState({ isMounted: false });
    }
    saveColumsConfiguration() {
        var { columns } = this.state;
        fetch(this.props.dataRouteUpdateColumns, {
            body: JSON.stringify(columns),
            method: 'POST'
        });
    }
    getColumns() {
        if (this.props.dataRouteColumns) {
            fetch(this.props.dataRouteColumns)
                .then(res => res.json())
                .then(config => this.setState((prev, props) => {
                    var filters = {};
                    if (config.filters !== "") {
                        JSON.parse(config.filters).map((item) => {
                            if (item && ((typeof item.term === "string" && item.term !== "") || typeof item.term !== "string")) {
                                filters[item.key] = item;
                            }
                            return item;
                        });
                    }
                    var sortColumn = config.sortColumn;
                    var sortDirection = config.sortDirection;

                    if (sortColumn === null) {
                        sortColumn = "";
                    }
                    if (sortDirection === null) {
                        sortDirection = "";
                    }
                    var showFilters = prev.showFilters;
                    if (prev.firstLoad && Object.keys(filters).length > 0) {
                        showFilters = true;
                    }
                    return { columns: JSON.parse(config.columns), filters: filters, sortColumn: sortColumn, sortDirection: sortDirection, showFilters: showFilters, firstLoad: false };
                })).then(() => {

                    let skip = this.state.loadedPage * this.state.itemsPerPage;
                    let items = this.state.itemsPerPage;
                    this.loadRows(skip, items);
                });
        }
    }
    loadRows(skip, items, callback) {
        if (!this.state.isMounted || !this.props.dataRoute) {
            return;
        }
        skip = 0;


        this.setState((prev, props) => {
            return { finishLoading: false };
        }, () => {
            let tempFilters = this.state.filters;
            let filters = encodeURIComponent(JSON.stringify(Object.keys(tempFilters).map(function (s) { return tempFilters[s]; }).filter(f => f.term !== "")));
            fetch(this.props.dataRoute + "&skip=" + skip + "&items=" + items + "&sortColumn=" + this.state.sortColumn + "&sortDirection=" + this.state.sortDirection + "&filters=" + filters)
                .then(res => res.json())
                .then(json => this.setState((prev, props) => {
                    var newBops = json.data;
                    //var empty = new Array(json.count - newBops.length);
                    //empty.fill({});
                    //var rows = newBops.concat(empty);
                    var lastPage = json.data.length < this.state.itemsPerPage;
                    var selected = this.state.selectAll ? newBops.map(n => n.ID) : this.state.selected;
                    return { data: newBops, selected: selected, count: json.count, loadedPage: this.state.loadedPage + 1, lastPage: lastPage, finishLoading: true };
                }, callback));
        });
    }
    handleScroll = (event) => {
        if (!this.state.finishLoading) {
            return;
        }

        //let skip = this.state.loadedPage * this.state.itemsPerPage;
        //let items = this.state.itemsPerPage;
        //this.loadRows(skip, items);
    }
    handleFilterButton = (event) => {
        if (!this.state.isMounted) {
            return;
        }
        this.setState({ showFilters: !this.state.showFilters });
    }
    handleColumnsButton = (event) => {
        if (!this.state.isMounted) {
            return;
        }
        if (this.state.anchorColumns) {
            this.setState({ anchorColumns: null });
        }
        else {
            this.setState({ showColumnsSelector: true });
        }
    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }
    doDelete = () => {
        var { selected } = this.state;
        var { dataRouteDelete } = this.props;
        fetch(dataRouteDelete, {
            body: JSON.stringify(selected),
            method: 'POST'
        }).then(res => {
            this.setState({ selected: [] });
            this.refresh();
        });
    }
    handleDeleteButton = (event) => {
        if (!this.state.isMounted) {
            return;
        }

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.setState({ dialog: {} });

                    this.doDelete();
                }
            },
            {
                label: "NO",
                action: () => {
                    this.setState({ dialog: {} });
                }
            },
        ];

        this.showDialog(buttons, "Cancellazione", "Confermi la cancellazione dei record selezionati?");
        
    }
    handleRequestSort = (event, property) => {
        if (!this.state.isMounted) {
            return;
        }
        const { sortDirection, sortColumn } = this.state;
        const orderBy = property;
        let order = 'desc';

        if (sortColumn === property && sortDirection === 'desc') {
            order = 'asc';
        }

        //this.setState({ order, orderBy });

        this.setState((prev, props) => {
            return { data: [], sortColumn: orderBy, sortDirection: order, lastPage: false, loadedPage: 0, page: 0 };
        }, () => {
            this.loadRows(0, this.state.itemsPerPage);
        });
    };
    handleFilterChange = (event, property) => {
        if (!this.state.isMounted) {
            return;
        }
        var timestamp = new Date().getTime();
        var grid = this;
        var value = event.target.value;
        this.setState((prev, props) => {
            return { lastFilterType: timestamp };
        }, () => {
            setTimeout(function () {
                if (grid.state.lastFilterType === timestamp) {
                    var filters = grid.state.filters;
                    filters[property] = { key: property, term: value };
                    grid.setState((prev, props) => {
                        return { data: [], lastPage: false, loadedPage: 0, filters: filters, page: 0 };
                    }, () => {
                        grid.loadRows(0, grid.state.itemsPerPage);
                    });
                }
            }, 500);
        });
    }
    clearFilters = () => {
        this.setState({ data: [], lastPage: false, loadedPage: 0, filters: {}, page: 0 }, () => {
            this.loadRows(0, this.state.itemsPerPage);
        });
    }

    handleRowCheckboxChange = id => event => {
        if (!this.state.isMounted) {
            return;
        }
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        this.setState({ selected: newSelected });
    }
    handleClick = (event, id) => {
        if (event.target.id === "sel_" + id) {
            return;
        }
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (event.shiftKey || event.ctrlKey || selected.length > 0) {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
        }
        else {
            //newSelected = [id];
            newSelected = selected;
        }


        this.setState({ selected: newSelected });
    };
    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.data.map(n => n.ID), selectAll:true }));
            return;
        }
        this.setState({ selected: [], selectAll:false });
    };
    handleChangePage = (event, page) => {
        this.setState({ page });

        if (!this.state.finishLoading) {
            return;
        }

        //let skip = this.state.loadedPage * this.state.itemsPerPage;
        //let items = this.state.itemsPerPage;
        //this.loadRows(skip, items);
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });

        if (!this.state.finishLoading) {
            return;
        }

        //let skip = this.state.loadedPage * this.state.itemsPerPage;
        //let items = this.state.itemsPerPage;
        //this.loadRows(skip, items);
    };
    handleDoubleClick = data => event => {
        if (this.props.onDoubleClick) {
            this.props.onDoubleClick(event, data);
        }
    };
    handleEditButton = event => {

        var { selected, data } = this.state;
        if (selected.length !== 1) {
            return;
        }
        var item = data.filter(s => s.ID === selected[0]);
        if (item.length > 0) {
            this.props.onEditButton(event, item[0]);
        }
    };
    search = (key, value, callback) => {
        var filters = this.state.filters;
        filters = {};
        filters[key] = { key: key, term: value };
        this.setState((prev, props) => {
            return { data: [], lastPage: false, loadedPage: 0, filters: filters, page: 0 };
        }, () => {
            this.loadRows(0, this.state.itemsPerPage, callback);
        });
    }
    refresh = () => {
        this.setState((prev, props) => {
            return { data: [], lastPage: false, loadedPage: 0, page: 0 };
        }, () => {
            this.loadRows(0, this.state.itemsPerPage);
        });
    };
    countRows = () => {
        return this.state.count;
    }
    getSelectedData = () => {
        var { selected } = this.state;
        return selected;
    }
    getRowData = (id) => {
        const { data } = this.state;
        var result = data.filter(item => item.ID === id);
        if (result.length > 0) {
            return result[0];
        }
        else {
            return undefined;
        }
    }
    clearSelection = () => {
        this.setState({ selected: [] });
    }
    isSelected = id => this.state.selected.indexOf(id) !== -1;
    render() {
        const { classes, dataRouteDelete, actions, selectedActions, onEditButton, customContent, label, showFilterActivator, showColumnsSelector, customCells, rowHeight } = this.props;
        var { rowsVisible, usePrimaryColor, useSecondaryColor, tabs, selectedTab, onTabChange } = this.props;
        var { data, sortDirection, sortColumn, selected, count, columns, filters, showFilters, selectAll, finishLoading } = this.state;
        var handleEditBtn = onEditButton && this.handleEditButton;


        var colwidth = "100%";
        if (columns.map(c => c.width).length > 0) {
            colwidth = columns.map(c => c.width).reduce((a, b) => a + b) + 42;
        }
        //var rowsVisible = 10;
        var height = undefined;
        
        var mainContent = document.getElementById('mainContent');
        if (mainContent) {
            var containerHeight = mainContent.clientHeight;
            height = containerHeight - 64 - 42 - (showFilters ? 42 : 0);
        }
        if (height === undefined) {
            rowsVisible = 10;
        }
        if (rowsVisible && rowsVisible > 0) {
            height = rowHeight * rowsVisible;
        }
        if (data.length * rowHeight < height) {
            height = data.length * rowHeight;
        }
        //height -= 17;
        var jFilters = JSON.stringify(Object.keys(filters).map(s => { return filters[s]; }).filter(f => f.term !== ""));
        console.log(jFilters);
        var filterClear = jFilters !== JSON.stringify([]) && this.clearFilters;
        var table = customContent !== undefined ? customContent :
            (
                <Fragment>
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={sortDirection}
                        orderBy={sortColumn}
                        rowCount={data.length}
                        columns={columns}
                        onRequestSort={this.handleRequestSort}
                        onFilterChange={this.handleFilterChange}
                        onSelectAllClick={this.handleSelectAllClick}
                        showFilters={showFilters}
                        filters={filters}
                        new={true}
                        grid={this}
                    />
                    <div ref={this.rowsContainer} style={{ minWidth: "100%", width: colwidth }}>
                        <List
                            style={{ minWidth: '100%', maxWidth: '100%' }}
                            rowCount={data.length}
                            rowHeight={rowHeight}
                            height={height}
                            width={this.rowsContainer.current ? this.rowsContainer.current.clientWidth : colwidth}
                            rowRenderer={({ key, index, isScrolling, isVisible, style }) => {
                                return (
                                    <XGridRow
                                        style={style}
                                        key={key}
                                        width={colwidth}
                                        data={data[index]} columns={columns}
                                        isSelected={this.isSelected(data[index].ID)}
                                        onClick={this.handleClick}
                                        onDoubleClick={this.handleDoubleClick}
                                        onSelectAll={this.handleRowCheckboxChange}
                                        customCells={customCells}
                                        rowHeight={rowHeight}
                                    />
                                );
                            }}
                        />
                        {!finishLoading && false && <XOXLoader onlyO={true} height={height + "px"} />}
                    </div>
                </Fragment>
            );

        return (
            <Paper className={classes.root}>
                <EnhancedTableToolbar
                    onFilterButton={this.handleFilterButton}
                    onColumnsButton={this.handleColumnsButton}
                    onDeleteButton={this.handleDeleteButton}
                    onEditButton={handleEditBtn}
                    onNewButton={this.props.onNewButton}
                    numSelected={selected.length}
                    selectedAll={selectAll}
                    count={count}
                    grid={this}
                    showDeleteButton={dataRouteDelete !== undefined}
                    actions={actions}
                    selectedActions={selectedActions}
                    label={label}
                    showFilterActivator={showFilterActivator}
                    showColumnsSelector={showColumnsSelector}
                    usePrimaryColor={usePrimaryColor}
                    useSecondaryColor={useSecondaryColor}
                    tabs={tabs}
                    selectedTab={selectedTab}
                    onTabChange={onTabChange}
                    onClearFilterButton={filterClear}
                />
                <div className={classes.tableWrapper}>
                    {table}
                </div>

                <XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
            </Paper>
        );
    }
}
XGrid.propTypes = {
    //classes: PropTypes.object.isRequired,
    dataRoute: PropTypes.string,
    dataRouteColumns: PropTypes.string,
    dataRouteAvailableColumns: PropTypes.string,
    onDoubleClick: PropTypes.func,
    dataRouteUpdateColumns: PropTypes.string,
    dataRouteDelete: PropTypes.string,
};
XGrid.defaultProps = {
    rowHeight: 42
}

export default withStyles(styles)(XGrid);