import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function TabContainer(props) {

    var { padding } = props;
    if (!padding && padding !== 0) {
        padding = 8 * 3;
    }
    return (
        <Typography component="div" style={{ padding: padding }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

export default TabContainer;
