import React from 'react';
import Dashboard from "views/Dashboard/Dashboard.jsx";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import CatalogueIcon from "@material-ui/icons/Style";
import { Identities } from "layouts/Identities.jsx";
import { IRUsers } from "layouts/IRUsers.jsx";
import Documents from "layouts/Documents.jsx";
import { CustomFields } from "layouts/CustomFields.jsx";
import IdentitiesEdit from "layouts/IdentitiesEdit.jsx";
import BOPEdit from "layouts/BOPEdit.jsx";
import CustomField from "layouts/CustomField.jsx";
//import ToConfig from "layouts/ToConfig.jsx";
import Catalogue from "layouts/Catalogue.jsx";
import ImportBOP from "layouts/ImportBOP.jsx";
import ImportIR from "layouts/ImportIR.jsx";
import Stock from "layouts/Stock.jsx";
import GDPR from "views/IR/GDPR.jsx";
import DOCEdit from "views/DOC/DOCEdit.jsx";
import Agreements from "views/IR/Agreements.jsx";

import Post from "views/CRM/Post.jsx";

import Brands from "views/BOP/Brands.jsx";
import Brand from "views/BOP/Brand.jsx";
import Lines from "views/BOP/Lines.jsx";
import Line from "views/BOP/Line.jsx";

import DistributionLists from "views/IR/DistributionLists.jsx";
import DistributionList from "views/IR/DistributionList.jsx";

import { Package, Newspaper, BookOpen } from 'mdi-material-ui';

var customFields = [
    {
        path: "/CF",
        name: "Ricerca",
        mini: "RI",
        component: CustomFields,
        requireLogin: true,
    },
    {
        path: "/CFNew",
        name: "Nuovo campo custom",
        mini: "NU",
        component: CustomField,
        redirect: true,
        requireLogin: true,
    },
    {
        path: "/CFEdit/:id",
        name: "Modifica campo custom",
        mini: "MO",
        component: CustomField,
        redirect: true,
        requireLogin: true,
    }
];
var tools = [

    {
        path: '/importbop',
        name: 'Import bop',
        mini: "BOP",
        component: ImportBOP,
        requireLogin: true,
    },
    {
        path: '/importir',
        name: 'Import Anagrafiche',
        mini: "IR",
        component: ImportIR,
        requireLogin: true,
    },
];

function Catalog(props) {
    return (<Catalogue advancedNavigation={true} showExpansionPanel={false} isCatalog={true} {...props} />);
}
function Presentation(props) {
    return (<Catalogue advancedNavigation={true} showExpansionPanel={true} isCatalog={false} {...props} />);
}
function Profile(props) {
    return (<IdentitiesEdit userProfile={true} {...props} />);
}

function News(props) {
    return (<Post postType={1} {...props} />);
}
function Adv(props) {
    return (<Post postType={2} {...props} />);
}
var dashRoutes = [
    {
        path: "/home",
        name: "Home",
        icon: DashboardIcon,
        component: Dashboard,
        requireLogin: true,
    },
    //{
    //    collapse: true,
    //    path: "-ir",
    //    name: "Anagrafiche",
    //    state: "openIdentities",
    //    icon: DashboardIcon,
    //    //component: Identities
    //    views: identities,
    //    requireLogin: true,
    //},
    {
        id: "irnew",
        path: "/IRNew",
        name: "Nuovo inserimento",
        icon: DashboardIcon,
        component: IdentitiesEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"ir",
        path: "/IR",
        name: "Anagrafiche",
        icon: DashboardIcon,
        component: Identities,
        requireLogin: true,
    },
    {
        id: "usersir",
        path: "/Users",
        name: "Utenti",
        icon: DashboardIcon,
        component: IRUsers,
        requireLogin: true,
    },
    {
        id:"iredit",
        path: "/IREdit/:id",
        name: "Modifica anagrafica",
        icon: DashboardIcon,
        component: IdentitiesEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        path: "/profile/",
        name: "Profilo",
        icon: DashboardIcon,
        component: Profile,
        requireLogin: true,
        hidden: true,
    },
    //{
    //    collapse: true,
    //    path: "-ag",
    //    name: "Contratti",
    //    state: "openAgreements",
    //    icon: DashboardIcon,
    //    //component: Identities
    //    views: agreements,
    //    requireLogin: true,
    //},

    {
        id:"agreements",
        path: "/Agreements",
        name: "Contratti",
        icon: DashboardIcon,
        component: Agreements,
        requireLogin: true,
    },
    {
        id:"gdpr",
        path: "/Customer/GDPR",
        name: "Contratti",
        icon: DashboardIcon,
        component: GDPR,
        requireLogin: true,
        hidden: true,
    },
    //{
    //    path: "/Supplier/GDPR",
    //    name: "GDPR fornitori",
    //    icon: DashboardIcon,
    //    component: ToConfig,
    //    requireLogin: true,
    //},
    {
        id:"documents",
        path: '/documents',
        name: 'Documenti',
        icon: CatalogueIcon,
        component: Documents,
        requireLogin: true,
    },
    {
        id:"docnew",
        path: '/doc/new',
        name: 'Documenti',
        icon: CatalogueIcon,
        component: DOCEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"docedit",
        path: '/doc/edit/:id',
        name: 'Documenti',
        icon: CatalogueIcon,
        component: DOCEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"catalogueedit",
        path: '/catalogue/:id',
        name: 'Catalogo',
        icon: CatalogueIcon,
        component: Catalog,
        requireLogin: true,
        hidden: true,
    },
    {
        id: "catalogueedit",
        path: '/presentation/:id',
        name: 'Presentazione',
        icon: CatalogueIcon,
        component: Presentation,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"presentation",
        path: '/presentation',
        name: 'Presentazione',
        icon: CatalogueIcon,
        component: Presentation,
        requireLogin: true,
    },
    {
        id:"catalogue",
        path: '/catalogue',
        name: 'Catalogo',
        icon: CatalogueIcon,
        component: Catalog,
        requireLogin: true,
    },
    {
        id:"stock",
        path: '/stock',
        name: 'Magazzino',
        icon: Package ,
        component: Stock,
        requireLogin: true,
    },
    {
        id: "news",
        path: "/news",
        name: "News",
        icon: Newspaper,
        component: News,
        requireLogin: true,
    },
    {
        id: "advertising",
        path: "/advertising",
        name: "Advertising",
        icon: BookOpen,
        component: Adv,
        requireLogin: true,
    },
    {
        id: "messages",
        path: "/messages",
        name: "Messages",
        icon: DashboardIcon,
        component: Dashboard,
        requireLogin: true,
    },
    {
        id: "tasks",
        path: "/Tasks",
        name: "Tasks",
        icon: DashboardIcon,
        component: Dashboard,
        requireLogin: true,
    },
    {
        id:"bopedit",
        path: "/BOPEdit/:id",
        name: 'Modifica distinta',
        icon: Package,
        component: BOPEdit,
        hidden: true,
        requireLogin: true,
    },
    {
        id:"customfields",
        collapse: true,
        path: "-cf",
        name: "Campi custom",
        state: "openCustomFields",
        icon: DashboardIcon,
        //component: Identities
        views: customFields,
        requireLogin: true,
    },
    {
        id:"admintools",
        collapse: true,
        path: "-cf",
        name: "Tools",
        state: "openTools",
        icon: DashboardIcon,
        //component: Identities
        views: tools,
        requireLogin: true,
    },
    {
        id:"brands",
        path: '/brands',
        name: 'Ricerca brand',
        icon: CatalogueIcon,
        component: Brands,
        requireLogin: true,
    },
    {
        id:"brandnew",
        path: '/brand/new',
        name: 'Brand',
        icon: CatalogueIcon,
        component: Brand,
        hidden: true,
        requireLogin: true,
    },
    {
        id:"brandedit",
        path: '/brand/:id',
        name: 'Brand',
        icon: CatalogueIcon,
        component: Brand,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "lines",
        path: '/lines',
        name: 'Ricerca linea',
        icon: CatalogueIcon,
        component: Lines,
        requireLogin: true,
    },
    {
        id: "linenew",
        path: '/line/new',
        name: 'Line',
        icon: CatalogueIcon,
        component: Line,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "lineedit",
        path: '/line/:id',
        name: 'Line',
        icon: CatalogueIcon,
        component: Line,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "distributionlists",
        path: '/distributionlists',
        name: 'Liste distribuzione',
        icon: CatalogueIcon,
        component: DistributionLists,
        requireLogin: true,
    },
    {
        id: "distributionlistnew",
        path: '/distributionlist/new',
        name: 'Lista distribuzione',
        icon: CatalogueIcon,
        component: DistributionList,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "distributionlistedit",
        path: '/distributionlist/:id',
        name: 'Lista distribuzione',
        icon: CatalogueIcon,
        component: DistributionList,
        hidden: true,
        requireLogin: true,
    },

    { redirect: true, path: "/", pathTo: "/home", name: "Home" }
];
export default dashRoutes;
