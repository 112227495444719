import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardIcon from "components/Card/CardIcon.jsx";
import Typography from '@material-ui/core/Typography';

import Icon from "@material-ui/core/Icon";


import { primaryColor, colorForPrimaryBackground } from '../assets/jss/material-dashboard-pro-react';

var styles = theme => ({
    root: {
        width:"100%",
    },
    summaryExpanded: {
        margin: "12px 0 !important",
    },
    expansionSummary: {
        minHeight: "46px !important",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    },
    expansionSummaryFocused: {
        backgroundColor: theme.palette.primary.main,
        color: colorForPrimaryBackground,
        minHeight: "46px !important",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    },
    expansionIcon: {
        color: "#fff",
    },
    expansionIconExpanded: {
        margin: "0 !important",
    }
});

class XExpansionPanel extends Component {
    state = {
        exp1: Array(this.props.panels.length).fill(true),
        exp1Focus: null,
    };
    handleExp1Change = panel => (event, expanded) => {
        var { exp1 } = this.state;
        if (exp1.length > 1) {
            exp1[panel] = expanded;
        }
        this.setState({ exp1 });
    }
    handleExp1Focus = panel => (event) => {
        //alert("FOCUS PANEL" + panel);
        this.setState({ exp1Focus: panel });
    }
    handleExp1Blur = panel => (event) => {
        //alert("FOCUS PANEL" + panel);
        this.setState({ exp1Focus: null });
    }
    render() {
        var { exp1, exp1Focus } = this.state;
        var { classes, panels } = this.props;
        return (
            <div className={classes.root}>
                {panels.map((panel, index) => (
                    <ExpansionPanel key={index} expanded={exp1[index]} onChange={this.handleExp1Change(index)} onFocus={this.handleExp1Focus(index)} onBlur={this.handleExp1Blur(index)}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon classes={{ root: classes.expansionIconExpanded }} />} classes={{ root: (exp1Focus === index && exp1[index] ? classes.expansionSummaryFocused : classes.expansionSummary), focused: classes.expansionSummaryFocused, expanded: classes.summaryExpanded, expandIcon: classes.expansionIconExpanded }}>
                            <CardIcon color={(exp1Focus === index && exp1[index] ? "gray" : "primary")}>
                                <Icon classes={{ root: classes.expansionIcon }}>{panel.icon}</Icon>
                            </CardIcon>
                            <Typography>{panel.label}</Typography>
                        </ExpansionPanelSummary>
                        {(exp1[index]) &&
                            (
                                <ExpansionPanelDetails tabIndex={index}>
                                    {panel.content}
                                </ExpansionPanelDetails>
                            )
                        }
                        
                    </ExpansionPanel>
                ))}
            </div>
            
        )
    }
}

export default withStyles(styles)(XExpansionPanel);