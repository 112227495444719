import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import XGridCell from './XGridCell';
import withStyles from 'react-jss';

import color from 'color';
import classNames from 'classnames';

import { grayColorLight } from '../assets/jss/material-dashboard-pro-react';


const rowStyle = {
    selected: {
        backgroundColor: color(grayColorLight).hsl().alpha(0.2).string() + " !important",
    },
    root: {
        minWidth: props => props.width,
        height: props => props.rowHeight,//"42px",
        lineHeight: props => props.rowHeight + "px",//"42px",
        '&:nth-child(odd)': {
            backgroundColor: color(grayColorLight).hsl().alpha(0.05).string()
        },
        '&:after': {
            content: '" "',
            display: "block",
            width: "100%",
            height: "1px",
            backgroundColor: color(grayColorLight).hsl().alpha(0.2).string(),
        },
        '&:hover': {
            backgroundColor: color(grayColorLight).hsl().alpha(0.15).string() + " !important",
        }
    }
};

class XGridRow extends Component {
    render() {
        var { data, columns, isSelected, classes, onDoubleClick, onClick, onSelectAll, width, customCells, rowHeight, style } = this.props;
        return (
            <div style={style} key={data.ID} className={classNames({ [classes.selected]: isSelected, [classes.root]: true })} width={width} onDoubleClick={onDoubleClick(data)} onClick={event => onClick(event, data.ID)}>
                <span style={{ minWidth: "42px", maxWidth: "42px", display: "inline-block", overflow: "hidden", maxHeight: rowHeight, minHeight: rowHeight, verticalAlign: "middle" }}>
                    <Checkbox id={"sel_" + data.ID} checked={isSelected} onChange={onSelectAll(data.ID)} />
                </span>
                {columns.map(column => {
                    var value = data[column.key];
                    if ("boolean" === typeof value) {
                        value = value ? "SI" : "NO";
                    }
                    if (customCells) {
                        var customCell = customCells[column.key];
                        if (customCell) {
                            value = customCell(data, column);
                        }
                    }
                    else {
                        if (value) {
                            switch (column.type) {
                                case "date":
                                    value = (new Date(value)).toLocaleDateString();
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    //return (<XGridCell value={value} column={column} width={column.width} totalWidth={width} rowHeight={rowHeight} />);
                    return (<span style={{ width: column.width, height: rowHeight, display: 'inline-block', verticalAlign: "top", overflow: "hidden", paddingLeft: 10 }}>{value}</span>);
                })}
            </div>
        );
    }
}
export default withStyles(rowStyle)(XGridRow);