import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


import Tabs from "components/CustomTabs/CustomTabs.jsx";
import XField from 'components/XField';
import XExpansionPanel from 'components/XExpansionPanel';
import XFiles from 'components/XFiles';
import XDialog from 'components/XDialog';

const style = theme => ({
});


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}


class GenericEditor extends Component {
    constructor(props) {
        super(props);
        this.tabs = React.createRef();
    }
    static contextTypes = {
        router: PropTypes.object
    }

    state = {
        data: {},
        dialog: {},
        firstLoad: true,
    };
    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        var { id } = this.props;
        if (id && parseInt(id, 10) > 0) {
            fetch(this.props.urlGetData + id)
                .then(res => res.json())
                .then(data => {
                    //this.setState({ data });
                    this.setState(state => {
                        state.data = data;
                        if (state.firstLoad) {
                            state.initialData = JSON.parse(JSON.stringify(state.data));
                        }
                        state.firstLoad = false;
                        return state;
                    });
                });
        }
        else {
            this.setState(state => {

                if (state.firstLoad) {
                    state.initialData = JSON.parse(JSON.stringify(state.data));
                }
                state.firstLoad = false;
                return state;
            });
        }
    }
    handleFieldChange = (key) => (event, value) => {
        this.setState((state, props) => {
            if (value !== undefined || value !== null || !isNaN(value)) {
                state.data[key] = value;
            }
            else {
                state.data[key] = undefined;
            }
            return state;
        });
    }
    handleSaveData = (event, caller, callback) => {
        this.doSaveData(callback);
    }
    doSaveData = (callback) => {
        var currentTab = this.tabs.current.getCurrentTab();
        if (!this.state.data.DescriptionStr) {
            return;
        }
        this.setState({ isLoading: true }, () => {
            var { data } = this.state;
            var { id, urlSave, urlDelete, urlRedirect } = this.props;
            if (!id) {
                id = 0;
            }
            fetch(urlSave + id, {
                body: JSON.stringify(data),
                method: 'POST'
            }).then(this.handleErrors).then(res => res.json()).then((data) => {
                if (id === undefined || parseInt(id, 10) === 0) {
                    var newId = data.id;
                    if (!newId) {
                        newId = data.ID;
                    }
                    this.context.router.history.push(urlRedirect + newId);
                    if (callback) {
                        callback();
                    }
                }
                this.setState({ firstLoad: true }, () => {
                    this.loadData();
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                        if (callback) {
                            callback();
                        }
                    });
                });
                
            })
                .catch(error => {
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                    });
                });
        });
    }
    hideDialog = () => {
        this.setState({ dialog: {} });
    }
    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }

    doExit = () => {
        if (!this.props.urlClearSession) {
            this.context.router.history.push(this.props.urlExit);
        }
        //fetch('/BOP/ClearSession', {
        fetch(this.props.urlClearSession, {
            method: 'POST'
        }).then(res => {
            //this.props.setTitle(undefined);
            this.context.router.history.push(this.props.urlExit);
        });
    }
    doNew = () => {
        if (!this.props.urlClearSession) {
            this.context.router.history.push(this.props.urlNew);
        }
        fetch(this.props.urlClearSession, {
            method: 'POST'
        }).then(res => {
            //this.props.setTitle(undefined);
            this.context.router.history.push(this.props.urlNew);
        });
    }
    save = (event, callback) => {
        return new Promise(resolve => {

            this.handleSaveData(event, this, (data) => {
                callback();
                resolve(data);
            });
        });
    }
    handleExit = (event) => {
        this.checkForChanges(event, this,
            this.doExit,
            () => {
                this.save(event, this.doExit);
            },
            this.doExit,
            () => { }
        );
    }
    handleNewData = (event) => {
        this.checkForChanges(event, this,
            this.doNew,
            () => {
                this.save(event, this.doNew);
            },
            this.doNew,
            () => { }
        );
    }
    checkForChanges = (event, caller, callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
        if ((JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
            this.manageHasChanges(callbackYes, callbackNo, callbackCancel);
        }
        else if (this.props.urlCheckChanges) {
            fetch(this.props.urlCheckChanges, {
                method: 'POST'
            }).then(res => res.json())
                .then(data => {
                    if (data.HasChanges || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
                        this.manageHasChanges(callbackYes, callbackNo, callbackCancel);
                    }
                    else {
                        callbackNoChanges();
                    }
                });
        }
        else {
            callbackNoChanges();
        }
    }
    manageHasChanges(callbackYes, callbackNo, callbackCancel) {
        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.setState({ dialog: {} });
                    callbackYes();
                    //this.saveFile();
                }
            },
            {
                label: "NO",
                action: () => {
                    this.setState({ dialog: {} });
                    callbackNo();
                }
            },
            {
                label: "ANNULLA",
                action: () => {
                    this.setState({ dialog: {} });
                    callbackCancel();
                }
            },
        ];
        this.showDialog(buttons, "Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?");
    }


    renderTabGeneral = () => {
        var { data } = this.state;
        var panels = [
            {
                icon: "people",
                label: "Dati generali",
                content: (<Grid container spacing={2}>
                    <Grid item container xs={12} direction="row" spacing={2}>
                        <Grid item xs={12} sm={6} md={3}><XField label="Sigla" id="AbbreviationStr" value={data.AbbreviationStr} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XField label="Descrizione" id="DescriptionStr" value={data.DescriptionStr} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
                    </Grid>
                </Grid>),
            }];
       
        return (<TabContainer><XExpansionPanel panels={panels} /></TabContainer>);
    }
    renderTabFiles = () => {
        var { urlUploadFiles, urlGetFiles, urlDeleteFiles, urlCheckDefaultFiles, id } = this.props;
        return <XFiles urlUploadFile={urlUploadFiles} urlDeleteFiles={urlDeleteFiles} urlGetFiles={urlGetFiles} urlCheckDefaultFiles={urlCheckDefaultFiles} FID={id} />;
    }
    

    render() {
        var { classes, onDeleteClick, urlUploadFiles, urlGetFiles, urlDeleteFiles, customTabs } = this.props;
        var loctabs = [
            {
                tabName: "Generale",
                tabContent: this.renderTabGeneral(),

            },
        ];
        if (urlGetFiles && urlUploadFiles && urlDeleteFiles) {
            loctabs.push(
                {
                    tabName: "Files",
                    tabContent: this.renderTabFiles(),

                });
        }
        if (customTabs) {
            loctabs = loctabs.concat(customTabs);
        }
        return (
            <div className={classes.root}>
                <Tabs
                    innerRef={this.tabs}
                    headerColor="primary"
                    plainTabs={true}
                    onExitClick={this.handleExit}
                    onSaveClick={this.handleSaveData}
                    onDeleteClick={onDeleteClick}
                    onNewClick={this.handleNewData}
                    tabs={loctabs}
                />

                <XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
            </div>
        );
    }
}

export default withStyles(style)(GenericEditor);