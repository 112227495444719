import React, { Component } from 'react';
import PropTypes from 'prop-types';


import Grid from '@material-ui/core/Grid';
import XField from 'components/XField';
import GDPRPDF from 'layouts/GDPRPDF';
import XMessageBox from 'components/XMessageBox';
import XSwitch from 'components/XSwitch';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';


import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { ArrowLeft, ArrowRight, FountainPen, ContentSave, NotePlus, ExitToApp } from 'mdi-material-ui';

import XGrid from 'components/XGrid';
import IdentitySelector from 'components/IdentitySelector';


import SignatureCanvas from 'react-signature-canvas'

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
    },
    button: {
        marginTop: 16,
        marginBottom: 14,
    },
    signature: {
        border: "1px solid black"
    },
    mainGrid: {
        padding: "24px",
    }
});
//const FieldLabels = {
//    FIDGroup: "Gruppo",
//    TypologyIds: "Tipologia",
//    CategoryIds: "Tag",
//    Code: "Codice",
//    Denomination: "Denominazione",
//    Alias: "Alias",
//    Name: "Nome",
//    Surname: "Cognome",
//    Vatnumber: "P.IVA",
//    Taxcode: "Codice fiscale",
//    Address: "Indirizzo",
//    FIDCity: "Città",
//    FIDZip: "CAP",
//    FIDProvince: "Provincia",
//    FIDRegion: "Regione",
//    FIDCountry: "Nazione",
//    Remarkinternal: "Nota interna",
//    Remarkpublic: "Nota pubblica"
//};


class GDPR extends Component {
    constructor(props) {
        super(props);

        this.data = React.createRef();
        this.MessageBox = React.createRef();
        this.grdIR = React.createRef();
        this.sigCanvas = React.createRef();
    }
    static contextTypes = {
        router: PropTypes.object
    }
    state = {
        step: 1,
        data: {},
        fields: [],
        ReloadFields: true,
    }

    componentDidMount() {
        this.loadFields();
    }
    componentDidUpdate() {
        if (this.state.ReloadFields) {
            this.setState({ ReloadFields: false });
            this.loadFields();
        }
    }

    loadFields = () => {
        var { data } = this.state;
        var i = 0;

        var params = "";
        if (data.FIDGroup) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "FIDGroup=" + data.FIDGroup;
        }
        if (data.TypologyIds) {
            for (i = 0; i < data.TypologyIds.length; i++) {
                if (params !== "") {
                    params += "&";
                }
                else {
                    params += "?";
                }
                params += "TypologyIds[" + i + "]=" + data.TypologyIds[i];
            }
        }
        if (data.CategoryIds) {
            for (i = 0; i < data.CategoryIds.length; i++) {
                if (params !== "") {
                    params += "&";
                }
                else {
                    params += "?";
                }
                params += "CategoryIds[" + i + "]=" + data.CategoryIds[i];
            }
        }

        fetch('/IR/GetFields' + params).then(res => res.json()).then(fields => {
            this.setState({ fields });
        });
    }
    handleSaveData = (callback) => {
        var { data } = this.state;
        var FIDIdentity = data.ID;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        fetch('/IR/SaveIR/' + FIDIdentity, {
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json()).then((data) => {
            callback(data);
        });
    }
    handleSaveAgreement = (callback) => {
        var { data } = this.state;
        var FIDIdentity = data.ID;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        var agreement = {
            FIDAgreement: data.FIDAgreement,
            Acceptance1: data.Acceptance1 || false,
            Acceptance2: data.Acceptance2 || false,
            SignPlace: data.SignPlace
        };
        if (this.sigCanvas.current && !this.sigCanvas.current.isEmpty()) {
            agreement.Signature = this.sigCanvas.current.toDataURL("image/png");
        }
        fetch('/IR/SaveAgreement/' + FIDIdentity, {
            body: JSON.stringify(agreement),
            method: 'POST'
        }).then(res => res.json()).then((data) => {
            callback(data);
        });
    }

    onIdentitySelect = (FIDIdentity) => {
        console.log(FIDIdentity);
        this.setState({ data: { ID: FIDIdentity }, step: 3 });
    }
    handleNextStep = async () => {
        var { data,step } = this.state;
        if (step === 2 && data.FIDType === 1) {
            var saved = await this.data.current.save(undefined, () => { });
            if (saved) {
                this.setState(state => {
                    state.step++;
                    state.data = saved;
                    state.data.FIDType = data.FIDType;

                    return state;
                });
            }
        }
        if (step === 2 && data.FIDType === 2) {
            var selected = this.grdIR.current.getSelectedData();
            if (selected.length > 1) {
                this.MessageBox.current.showMessageOk("Selezione anagrafiche", <Typography>Selezionate più anagrafiche. Per continuare selezionare una sola anagrafica.</Typography>);
                return;
            }
        }
        if (step === 3) {
            if (data.SignPlace) {

                this.handleSaveAgreement((savedData) => {
                    this.setState(state => {
                        state.step++;
                        state.data.FIDAgreement = savedData.ID;
                        return state;
                    });
                });
            }
            else {
                this.MessageBox.current.showMessageOk("Compilazione campi", <Typography>Compilare il luogo prima di procedere.</Typography>);
                return;
            }
        }
        if (step === 5) {
            if (this.sigCanvas.current.isEmpty()) {
                this.MessageBox.current.showMessageOk("Firma", <Typography>Firmare prima di procedere.</Typography>);
                return;
            }
            else {
                this.handleSaveAgreement((savedData) => {
                    this.setState(state => {
                        state.step++;
                        return state;
                    });
                });
            }
        }

        this.setState(state => {

            switch (state.step) {
                case 1:
                    if (state.data.FIDType) {
                        state.step++;
                    }
                    break;
                case 2:
                    if (state.data.FIDType === 2) {
                        var selected = this.grdIR.current.getRowData(this.grdIR.current.getSelectedData()[0]);
                        state.data = selected;
                        state.data.FIDType = data.FIDType;
                        if (typeof state.data.CategoryIds === "string") {
                            state.data.CategoryIds = state.data.CategoryIds.split(",").map(item => parseInt(item, 10));
                        }
                        if (typeof state.data.TypologyIds === "string") {
                            state.data.TypologyIds = state.data.TypologyIds.split(",").map(item => parseInt(item, 10));
                        }
                        if (state.data.ID) {
                            this.grdIR.current.clearSelection();
                            state.step++;
                        }
                    }
                    break;
                case 4:
                    state.step++;
                    break;
                case 5:
                    break;
                default:
                    break;
            }


            //if ((state.step === 1 && state.data.FIDType) || state.step > 1) {
            //    state.step++;
            //}
            return state;
        });
    }
    handlePreviousStep = () => {
        this.setState(state => {
            state.step--;
            if (state.step === 2) {
                state.step = 1;
            }
            if (state.step === 1) {
                var FIDType = state.data.FIDType;
                state.data = {};
                state.data.FIDType = FIDType;
            }
            return state;
        });
    }
    handleNewStep = () => {
        this.setState(state => {
            state.step = 1;
            state.data = {}
            return state;
        });
    }
    handleExitStep = () => {
        this.context.router.history.push('/Agreements/');
    }
    onSelectChange = (key) => (event, data) => {
        this.setState(state => {
            if (data.length > 0) {
                state.data[key] = data[0].ID;
            }
            else {
                state.data[key] = undefined;
            }
            return state;
        }, () => {
            if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
                this.setState({ ReloadFields: true });
            }
        });
    }
    onTagChange = (key) => (event, data) => {
        this.setState(state => {
            state.data[key] = data;
            return state;
        }, () => {
            if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
                this.setState({ ReloadFields: true });
            }
        });
    }
    onFieldChange = (key) => (event, data) => {
        this.setState(state => {
            state.data[key] = data;
            return state;
        });
    }

    onFieldBlur = (key) => (event) => {
        if (key === "Denomination" && !this.state.data.Alias) {
            this.setState((state) => {
                state.data.Alias = state.data.Denomination;
                return state;
            });
        }
        else if (key === "Vatnumber" && !this.state.data.Taxcode) {
            this.setState((state) => {
                state.data.Taxcode = state.data.Vatnumber;
                return state;
            });
        }
    }

    geographicAreaFix = (data, db, key) => {

        if (["FIDZip", "FIDCity", "FIDProvince", "FIDRegion"].includes(key) && data && data.ID > 0) {
            db["FIDCountry"] = data.FIDCountry;
        }
        if (["FIDZip", "FIDCity", "FIDProvince"].includes(key) && data && data.ID > 0) {
            db["FIDRegion"] = data.FIDRegion;
        }
        if (["FIDZip", "FIDCity"].includes(key) && data && data.ID > 0) {
            db["FIDProvince"] = data.FIDProvince;
        }
        if (["FIDZip"].includes(key) && data && data.ID > 0) {
            db["FIDCity"] = data.FIDCity;
        }
        if (["FIDCity", "FIDProvince", "FIDRegion", "FIDCountry"].includes(key)) {
            if (db["FIDZip"] >= 0)
                db["FIDZip"] = undefined;
        }
        if (["FIDProvince", "FIDRegion", "FIDCountry"].includes(key)) {
            if (db["FIDCity"] >= 0)
                db["FIDCity"] = undefined;
        }
        if (["FIDRegion", "FIDCountry"].includes(key)) {
            if (db["FIDProvince"] >= 0)
                db["FIDProvince"] = undefined;
        }
        if (["FIDCountry"].includes(key)) {
            if (db["FIDRegion"] >= 0)
                db["FIDRegion"] = undefined;
        }
        return db;
    }
    handleFieldChange = (key) => (event, value) => {
        this.handleChange(event, value, key);
    }
    handleChange = (event, value, key, data) => {
        this.setState((state, props) => {
            state.data[key] = value;

            state.data = this.geographicAreaFix(data, state.data, key);

            //if (key === "Denomination" && !state.data["Alias"]) {
            //    state.relation["Alias"] == value;
            //}
            return state;
        });
    }
    handleBlur = (event, key) => {
        if (key === "Denomination" && !this.state.data.Alias) {
            this.setState((state) => {
                state.data.Alias = state.data.Denomination;
                return state;
            });
        }
        else if (key === "Vatnumber" && !this.state.data.Taxcode) {
            this.setState((state) => {
                state.data.Taxcode = state.data.Vatnumber;
                return state;
            });
        }
    }

    getField = (fields, field) => {
        var filtered = fields.filter((item) => item.Field === field);
        if (filtered.length > 0) {
            return filtered[0];
        }
        return undefined;
    }
    render() {
        var { classes } = this.props;
        var { step, data } = this.state;
        var customActions = [];
        var selectedActions = [];
        var customContent = undefined;
        var showFilterActivator = false;
        var showColumnsSelector = false;
        var dataRoute = "";
        var dataRouteColumns = "";
        var dataRouteAvailableColumns = "";
        var dataRouteUpdateColumns = "";
        var onDoubleClick = undefined;

        var nextStep = (
            <Tooltip title="Avanti">
                <IconButton aria-label="Avanti" onClick={this.handleNextStep} >
                    <ArrowRight />
                </IconButton>
            </Tooltip>
        );
        var previousStep = (
            <Tooltip title="Indietro">
                <IconButton aria-label="Indietro" onClick={this.handlePreviousStep} >
                    <ArrowLeft />
                </IconButton>
            </Tooltip>
        );

        var newStep = (
            <Tooltip title="Nuovo">
                <IconButton aria-label="Nuovo" onClick={this.handleNewStep} >
                    <NotePlus />
                </IconButton>
            </Tooltip>
        );
        var exitStep = (
            <Tooltip title="Exit">
                <IconButton aria-label="Exit" onClick={this.handleExitStep} >
                    <ExitToApp />
                </IconButton>
            </Tooltip>
        );


        /*if (step === 1) {
            customActions = [nextStep];
            customContent = (
                <Grid container spacing={2} style={{ width: "100%" }} className={classes.mainGrid}>
                    <Grid item xs={12} container>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            <Grid item xs={12} md={6}><XSelect label="Tipo" id="FIDType" value={data.FIDType} idKey="ID" valueKey="Item" onChange={this.onSelectChange} customData={[{ ID: 1, Item: 'Anagrafica nuova' }, { ID: 2, Item: 'Anagrafica esistente' }]} /></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
            
        }
        else if (step === 2) {
            if (data.FIDType === 1) {
                customActions = [previousStep, nextStep];
                //customContent = (<IRStandardFields ref={this.data} data={data} dataKey="data" id={data.ID} caller={this} showCodes={true} isRelation={false} onChange={this.handleChange} onBlur={this.handleBlur} />);
                customContent = (<IREdit id={data.ID} innerRef={this.data} topMargin="0px" headerColor="info" noRedirect={true} showExit={false} showSave={false} showDelete={false} showNew={false} />);
            }
            else if (data.FIDType === 2) {

                customActions = [previousStep];
                selectedActions = [nextStep];
                dataRoute = "/IR/GetData?grid=tblIR";
                dataRouteColumns = "/Base/GetColumns?grid=tblIR";
                dataRouteAvailableColumns = "/Base/GetAvailableColumns?grid=tblIR";
                dataRouteUpdateColumns = "/Base/UpdateColumns?grid=tblIR";
                onDoubleClick = undefined;
            }
        }
        else*/
            if (step === 3) {
            customActions = [previousStep, nextStep];
            customContent = (
                <Grid container spacing={2} style={{ width: "100%" }} className={classes.mainGrid}>
                    <Grid item xs={12} container>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            <Grid item xs={12}><XSwitch label="Accetta invio di proposte e di comunicazioni commerciali a mezzo posta elettronica o SMS o fax, da parte sia di BARTORELLI 1882 S.P.A., sia di aziende o professionisti partner?" id="Acceptance1" value={data.Acceptance1} onChange={this.handleFieldChange} /></Grid>
                            <Grid item xs={12}><XSwitch label="Accetta indagini di mercato e statistiche, marketing e referenze su comunicazioni pubblicitarie (stampa, radio, TV, internet, ecc.), preferenze sui prodott?" id="Acceptance2" value={data.Acceptance2} onChange={this.handleFieldChange} /></Grid>

                            <Grid item xs={12} sm={6} md={3}><XField label="Luogo" id="SignPlace" value={data.SignPlace} onChange={this.handleFieldChange} /></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        else if (step === 4) {
            nextStep = (
                <Tooltip title="Firma">
                    <IconButton aria-label="Firma" onClick={this.handleNextStep} >
                        <FountainPen />
                    </IconButton>
                </Tooltip>
            );
            customActions = [previousStep, nextStep];
            customContent = (<GDPRPDF FIDIdentity={data.ID} FIDAgreement={data.FIDAgreement} />);
        }
        else if (step === 5) {
            nextStep = (
                <Tooltip title="Salva">
                    <IconButton aria-label="Salva" onClick={this.handleNextStep} >
                        <ContentSave />
                    </IconButton>
                </Tooltip>
            );
            customActions = [previousStep, nextStep];
            customContent = (<SignatureCanvas ref={this.sigCanvas} penColor='black'
                canvasProps={{ width: 500, height: 200, className: classes.signature }} />);
        }
        else if (step === 6) {
            customActions = [newStep, exitStep];
            //customContent = (<GDPRViewer data={data} />);
            customContent = (<GDPRPDF FIDIdentity={data.ID} FIDAgreement={data.FIDAgreement} />);
        }
        var content = <XGrid
            innerRef={this.grdIR}
            label={"Step " + + step}
            dataRoute={dataRoute}
            dataRouteColumns={dataRouteColumns}
            dataRouteAvailableColumns={dataRouteAvailableColumns}
            dataRouteUpdateColumns={dataRouteUpdateColumns}
            showFilterActivator={showFilterActivator}
            showColumnsSelector={showColumnsSelector}
            //dataRouteDelete={""}
            actions={customActions}
            selectedActions={selectedActions}
            customContent={customContent}
            onDoubleClick={onDoubleClick}
        />;
        if (step === 1) {
            content = <IdentitySelector showRelations={true} onIdentitySelected={this.onIdentitySelect} />;
        }
        return (
            <div className={classes.root}>
                {content}
                <XMessageBox ref={this.MessageBox} />
            </div>
        );
    }
}


GDPR.propTypes = {
    classes: PropTypes.object.isRequired,
};



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(GDPR);