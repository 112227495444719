import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

// core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

function Header({ ...props }) {
    function makeBrand() {
        var { title } = props;
        if (title) {
            return title;
        }
        var name;
        props.routes.map((prop, key) => {
            if (prop.collapse) {
                prop.views.map((prop, key) => {
                    var str = props.location.pathname;
                    var match = prop.path;
                    var replace = match.match(/:id/);
                    if (replace) {
                        match = match.replace(new RegExp(replace[0]), '');
                        var res = str.match(new RegExp(match + '\\d*'));
                        if (res) {
                            if (res[0] === props.location.pathname) {
                                name = prop.name;
                            }
                        }
                    }
                    if (prop.path === props.location.pathname) {
                        name = prop.name;
                    }
                    return null;
                });
            }
            if (prop.path === props.location.pathname) {
                name = prop.name;
            }
            return null;
        });
        if (name) {
            return name;
        } else {
            return "";
        }
        return '';
    }
    const { classes, color, rtlActive, title } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });
    const sidebarMinimize =
        classes.sidebarMinimize +
        " " +
        cx({
            [classes.sidebarMinimizeRTL]: rtlActive
        });
    return (
        <AppBar className={classes.appBar + appBarClasses} position="sticky">
            <Toolbar className={classes.container}>
                <Hidden smDown implementation="css">
                    <div className={sidebarMinimize}>
                        {props.miniActive ? (
                            <Button
                                justIcon
                                round
                                color="transparent"
                                onClick={props.sidebarMinimize}
                            >
                                <ViewList className={classes.sidebarMiniIcon} />
                            </Button>
                        ) : (
                                <Button
                                    justIcon
                                    round
                                    color="transparent"
                                    onClick={props.sidebarMinimize}
                                >
                                    <MoreVert className={classes.sidebarMiniIcon} />
                                </Button>
                            )}
                    </div>
                </Hidden>
                <div className={classes.flex}>
                    {/* Here we create navbar brand, based on route name */}
                    <Button className={classes.title} color="transparent">
                        {makeBrand()}
                    </Button>
                </div>
                <Hidden smDown implementation="css">
                    <HeaderLinks rtlActive={rtlActive} />
                </Hidden>
                <Hidden mdUp implementation="css">
                    <Button
                        className={classes.appResponsive}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </Button>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
};

export default withStyles(headerStyle)(Header);
