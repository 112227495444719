import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Tabs from '../components/CustomTabs/CustomTabs';
import XMessageBox from 'components/XMessageBox';
import XProgress from 'components/XProgress';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}


class ImportIR extends Component {
    constructor(props) {
        super(props);
        this.MessageBox = React.createRef();
    }
    state = {
        selectedFile: null,
        loaded: 0,
        progress: 0,
        total: 100,
        isImporting: false,
        stopImporting: false,
    }
    cancelImport = () => {
        if (this.interval)
            window.clearInterval(this.interval);
        //this.setState({ isImporting: false, stopImporting: true });
        fetch('/IR/CancelImport', { method: "POST" }).then(res => res.text()).then(data => { this.setState({ progress: 100, total: 100, stopImporting: true, isImporting: false }) })
    }
    
    handleselectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }
    
    handleUpload = async () => {
        const data = new FormData()
        data.append('file', this.state.selectedFile, this.state.selectedFile.name)


        var json = await axios
            .post('/IR/CheckImport', data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({
                        loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                    })
                },
            })
            .then(res => res.data);

        if (json) {
            var buttons = [
                {
                    label: "AGGIORNA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        this.doImport(data, true);
                    }
                },
                {
                    label: "SALTA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        this.doImport(data, false);
                    }
                },
                {
                    label: "ANNULLA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                    }
                }
            ];
            this.MessageBox.current.showDialog(buttons, "IMPORTAZIONE", "Rilevati codici distinte già esistenti. Cliccando 'AGGIORNA', le distinte saranno aggiornate con i nuovi dati. Come si desidera procedere?");
        } else {
            this.doImport(data);
        }
    }

    doCheck = () => {
        fetch('/IR/CheckImportProgress').then(res => res.json()).then(data => { this.setState({ progress: data.progress, total: data.count, stopImporting: data.stopImporting }) })
    }

    interval = undefined;
    doImport = async (data, replace) => {
        this.setState({ isImporting: true, progress: 0, total: 100, stopImporting: false }, async () => {
            this.interval = setInterval(this.doCheck, 500);
            await axios
                .post('/IR/Import?replace=' + replace, data, {
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                        })
                    },
                })
                .then(res => {
                    console.log(res.statusText)
                });

            if (this.interval)
                window.clearInterval(this.interval);

            this.setState({ isImporting: false, progress: 0, total: 100 });
        });
    }

    render() {
        var { classes } = this.props;

        var locTabs = [
            {
                tabName: "Importazione anagrafiche",
                tabContent: (
                    <TabContainer>
                        <input type="file" name="" id="" onChange={this.handleselectedFile} />
                        <button onClick={this.handleUpload}>Upload</button>
                        <div>{this.state.loaded > 0 && this.state.loaded < 100 && "Caricamento..."}</div>
                    </TabContainer>
                )
            },
        ];

        var labelProgres;
        var onCancel = () => { };
        var showProgress = false;
        if (this.state.isImporting) {
            labelProgres = "Importazione anagrafiche"
            onCancel = this.cancelImport;
            showProgress = true;
        }

        return (
            <div className={classes.root}>
                <Tabs
                    headerColor="primary"
                    plainTabs={true}
                    tabs={locTabs}
                />
                <XProgress progress={this.state.progress} total={this.state.total} label={labelProgres} onCancel={onCancel} cancelLabel="Interrompi" show={showProgress} />
                <XMessageBox ref={this.MessageBox} />
            </div>
        );
    }
}


const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(ImportIR);
