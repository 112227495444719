import React, { Component } from 'react';
import PropTypes from 'prop-types';
import XGrid from "../components/XGrid"

import "../components/XReactDataGrid.css";
import { Tooltip, IconButton } from '@material-ui/core';
import { AccountBadgeOutline } from 'mdi-material-ui';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';


class Documents extends Component {
    constructor(props) {
        super(props);
        this.grdDocuments = React.createRef();
    }
    static contextTypes = {
        router: PropTypes.object
    }
    handleGridDoubleClick = (event, data) => {
        if (!this.props.menus.includes("docedit")) {
            var checkArray = [this.props.params.DOCCartCausalId, this.props.params.DOCPresetationCausalId];
            checkArray = checkArray.concat(this.props.params.DOCCatalogCausals);
            if (checkArray.includes(data.FIDCausal)) {
                this.doShowPresentation(data);
            }
        }
        else {
            this.context.router.history.push('/doc/edit/' + data.ID);
        }
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/doc/new');
    }
    handleShowPresentation = (event) => {
        var selectedRows = this.grdDocuments.current.getSelectedData();
        var data = this.grdDocuments.current.getRowData(selectedRows[0]);
        this.doShowPresentation(data);
    }
    doShowPresentation = (data) => {
        this.props.setCatalogCausal(data.FIDCausal);
        this.props.setCatalogIdentity(data.FIDIdentity);
        if (data.FIDCausal === this.props.params.DOCPresetationCausalId) {
            this.context.router.history.push('/presentation/' + data.ID);
        }
        else {
            this.context.router.history.push('/catalogue/' + data.ID);
        }
    }
    render() {
       
        var selectedActions = [];
        selectedActions.push(
            {
                component: < Tooltip title="Presentazione" >
                    <IconButton aria-label="Presentazione" onClick={this.handleShowPresentation} >
                        <AccountBadgeOutline />
                    </IconButton>
                </Tooltip>,
                condition: (selectedRows) => {
                    if (selectedRows.length === 1) {
                        var data = this.grdDocuments.current.getRowData(selectedRows[0]);
                        console.log(data);

                        var checkArray = [this.props.params.DOCCartCausalId,this.props.params.DOCPresetationCausalId];
                        checkArray = checkArray.concat(this.props.params.DOCCatalogCausals);
                        if (checkArray.includes(data.FIDCausal)) {
                            return true;
                        }
                    }
                    return false;
                }
            });

        var handleGridNew = this.handleGridNew;
        var handleGridEdit = this.handleGridDoubleClick;
        var gridDOubleClick = this.handleGridDoubleClick;
        var deleteRoute = '/DOC/DeleteDocument/';
        if (!this.props.menus.includes("docedit")) {
            handleGridEdit = undefined;
        }
        if (!this.props.menus.includes("docnew")) {
            handleGridNew = undefined;
        }
        if (!this.props.menus.includes("docdelete")) {
            deleteRoute = undefined;
        }


        return (
            <XGrid
                usePrimaryColor={true}
                innerRef={this.grdDocuments}
                dataRoute="/DOC/GetDocuments?grid=tblDocuments"
                dataRouteColumns="/Base/GetColumns?grid=tblDocuments"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblDocuments"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblDocuments"
                dataRouteDelete={deleteRoute}
                onDoubleClick={gridDOubleClick}
                onEditButton={handleGridEdit}
                onNewButton={handleGridNew}
                selectedActions={selectedActions}
            />
        );
    }
}



const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    )
);

export default enhance(Documents);