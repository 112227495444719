import React, {Component, Fragment} from 'react';

import XDialog from 'components/XDialog';
import XField from 'components/XField';
import { InputLabel } from '@material-ui/core';

export default class XMessageBox extends Component {
    state = {
        dialog: {}
    };
    showMessageYesNo = (title, message, callbackYes, callbackNo) => {

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.setState({ dialog: {} });
                    if (callbackYes) {
                        callbackYes();
                    }
                }
            },
            {
                label: "NO",
                action: () => {
                    this.setState({ dialog: {} });
                    if (callbackNo) {
                        callbackNo();
                    }
                }
            }
        ];
        this.showDialog(buttons, title, message);
    }
    showMessageYesNoCancel = (title, message, callbackYes, callbackNo, callbackCancel) => {

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.setState({ dialog: {} });
                    callbackYes();
                }
            },
            {
                label: "NO",
                action: () => {
                    this.setState({ dialog: {} });
                    callbackNo();
                }
            },
            {
                label: "Annulla",
                action: () => {
                    this.setState({ dialog: {} });
                    callbackCancel();
                }
            }
        ];
        this.showDialog(buttons, title, message);
    }
    showMessageOk = (title, message, callback) => {
        if (!callback) {
            callback = () => {
                this.setState({ dialog: {} });
            }
        }
        var buttons = [
            {
                label: "OK",
                action: () => {
                    callback();
                }
            }
        ];
        this.showDialog(buttons, title, message);
    }
    showInputMessage = (title, message, inputLabel, labelOk, labelCancel, callbackOk, callbackCancel) => {
        var buttons = [
            {
                label: labelOk,
                action: () => {
                    this.setState({ dialog: {} });
                    callbackOk();
                }
            },
            {
                label: labelCancel,
                action: () => {
                    this.setState({ dialog: {} });
                    callbackCancel();
                }
            }
        ];
        var dialog = {
            message,
            title,
            buttons,
            show: true,
            isInput: true,
            inputLabel
        };
        this.setState({ dialog });
    }
    showMessage = (title, message, buttons) => {
        this.showDialog(buttons, title, message);
    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }
    hideDialog = () => {
        this.setState({ dialog: {} });
    }
    onMsgInputChange = (id) => (event, value) => {
        this.setState({ MsgInput: value });
    }
    getInputValue = () => {
        return this.state.MsgInput;
    }
    render() {
        var message = this.state.dialog.message;
        if (this.state.dialog.isInput) {

            message = <Fragment>
                {message}
                <XField label={this.state.dialog.inputLabel} id="MsgInput" value={this.state.MsgInput} onChange={this.onMsgInputChange} />
            </Fragment>;
        }
        return (
            <XDialog message={message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />);
    }
}
