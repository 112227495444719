import React, {Component} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



class XDialog extends Component {

    render() {
        var { title, message, buttons, open } = this.props;
        if (open === undefined) {
            open = false;
        }
        return (
            <div>
                <Dialog
                    open={open}
                    //onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {buttons && buttons.map((item, key) => (<Button key={key} onClick={item.action} color="primary">{item.label}</Button>))}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default XDialog;