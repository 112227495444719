import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
//import PerfectScrollbar from "perfect-scrollbar";
//import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "routes/dashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-3.jpg";
import logo from "assets/img/logo-white.svg";

import Login from 'views/Pages/LoginPage';

import { parseParam } from 'utils';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from '../store/Auth';
import { actionCreators as globalCreators } from '../store/Global';

//var ps;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            miniActive: false,
            menus: []
        };
        this.resizeFunction = this.resizeFunction.bind(this);
    }
    componentWillMount() {
        this.props.checkLogin();

        fetch('/Base/GetMenus').then(res => res.json()).then(data => {
            this.setState({ menus: data });
            this.props.setMenus(data);
        });
    }
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            //ps = new PerfectScrollbar(this.refs.mainPanel, {
            //    suppressScrollX: true,
            //    suppressScrollY: false
            //});
            document.body.style.overflow = "auto";
        }
        window.addEventListener("resize", this.resizeFunction);
        this.loadParams();
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            //ps.destroy();
        }
        window.removeEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    loadParams = () => {

        fetch('/GC/params').then(res => res.json()).then(data => {
            Object.keys(data).map(function (key, index) { data[key] = JSON.parse(data[key]) })
            this.props.setParams(data);
        });
        fetch('/Base/GetCurrentFIDIdentity').then(res => res.json()).then(data => {
            this.props.setIdentity(data);
        });
        fetch('/Base/GetCatalogueButtons').then(res => res.json()).then(data => {
            this.props.setCatalogueButtons(data);
        });
        fetch('/Base/GetLabels').then(res => res.json()).then(data => {
            this.props.setLabels(data);
        });
    }
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    sidebarMinimize() {
        this.setState({ miniActive: !this.state.miniActive });
    }
    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    }
    loggedIn = () => {
        //this.props.checkLogin();
        var username = getCookie("username");
        if (username) {
            return true;
        }
        return this.props.isLoggedIn;
    };
    switchRoutes = (isLoggedIn) => (
        <Switch>
            {dashboardRoutes.map((prop, key) => {
                if (prop.id && !this.state.menus.includes(prop.id)) {
                    return <Redirect from={prop.path} to="/home" key={key} />;
                }
                if (prop.redirect)
                    return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                if (prop.collapse) {
                    return prop.views.map((prop, key) => {
                        var collapseComponent = ((prop.requireLogin && isLoggedIn) || !prop.requireLogin) ? prop.component : Login;
                        if ((prop.requireLogin && isLoggedIn) || !prop.requireLogin) {
                            return (
                                <Route path={prop.path} component={collapseComponent} key={key} />
                            );
                        }
                        else {
                            return <Redirect from={prop.path} to="/auth/login" key={key} />;
                        }
                    });
                }
                var component = ((prop.requireLogin && isLoggedIn) || !prop.requireLogin) ? prop.component : Login;
                if ((prop.requireLogin && isLoggedIn) || !prop.requireLogin) {
                    return <Route path={prop.path} component={component} key={key} />;
                }
                else {
                    return <Redirect from={prop.path} to="/auth/login" key={key} />;
                }
            })}
        </Switch>
    );
    render() {
        const { classes, ...rest } = this.props;
        var isLoggedIn = this.loggedIn();
        const mainPanel =
            classes.mainPanel +
            " " +
            cx({
                [classes.mainPanelSidebarMini]: this.state.miniActive,
                [classes.mainPanelWithPerfectScrollbar]:
                    navigator.platform.indexOf("Win") > -1
            });
        return (
            <div className={classes.wrapper}>
                <Sidebar
                    menus={this.state.menus}
                    routes={dashboardRoutes}
                    logoText={"Xtra Ordinary Xperience"}
                    logo={logo}
                    image={image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color="primary"
                    bgColor="black"
                    miniActive={this.state.miniActive}
                    username={this.props.username}
                    logout={this.props.logout}
                    {...rest}
                />
                <div id="mainPanel" className={mainPanel} ref="mainPanel">
                    <Header
                        menus={this.state.menus}
                        sidebarMinimize={this.sidebarMinimize.bind(this)}
                        miniActive={this.state.miniActive}
                        routes={dashboardRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        title={this.props.title}
                        {...rest}
                    />

                    <div id="mainContent" className={classes.content}>
                        <div className={classes.container}>{this.switchRoutes(isLoggedIn)}</div>
                    </div>

                    <Footer fluid />
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(appStyle)
);

//export default withStyles(appStyle)(Dashboard);
export default enhance(Dashboard);
