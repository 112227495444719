import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
    colorDefault: {
        backgroundColor: '#fff',
    },
    secondaryAppBar: {
        top: 88,
    }
});

class CustomAppBar extends Component {
    render() {
        var { classes, children, headerColor, isSecondary, ...props } = this.props;
        var color = "primary";
        switch (headerColor) {
            case "default":
                color = "default";
                break;
            case "secondary":
                color = "secondary";
                break;
            default:
                color = "primary";
                break;
        }
        return <AppBar className={classNames({ [classes.secondaryAppBar]: isSecondary})} color={color} classes={classes} {...props}>
            {children}
        </AppBar>;
    }
}

export default withStyles(styles)(CustomAppBar);