import React, { Component } from 'react';
import XGridFilterCell from './XGridFilterCell';
import { withStyles } from '@material-ui/core/styles';

import color from 'color';

import { grayColorLight } from '../assets/jss/material-dashboard-pro-react';


const rowStyle = theme => ({
    root: {
        minWidth: props => props.width,
        height: "42px",
        lineHeight: "42px",
        background: color(theme.palette.secondary.main).hsl().alpha(0.75).string(),
        color: '#fff',
        '&:after': {
            content: '" "',
            display: "block",
            width: "100%",
            height: "1px",
            backgroundColor: color(grayColorLight).hsl().alpha(0.2).string(),
        },
        '&:hover': {
            //backgroundColor: color(grayColorLight).hsl().alpha(0.1).string()
        }
    },
    filterCell: {

    }
});

class XGridRowFilters extends Component {
    render() {
        var { columns, classes, width, filters, filterHandler } = this.props;
        //var totalWidth = columns.map(c => c.width).reduce((a, b) => a + b) + 42;
        return (
            <div className={classes.root} width={width} >
                <span style={{ minWidth: "42px", maxWidth: "42px", display: "inline-block", overflow: "hidden", maxHeight: "42px", minHeight: "42px", verticalAlign: "middle" }}>
                    
                </span>
                {columns.map(column => {
                    var defValue = "";
                    if (filters[column.key]) {
                        defValue = filters[column.key].term;
                    }
                    return (<XGridFilterCell value={defValue} column={column} width={column.width} totalWidth={width} filterHandler={filterHandler} />);
                    
                })}
            </div>
        );
    }
}
export default withStyles(rowStyle)(XGridRowFilters);