import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import authRoutes from "routes/auth.jsx";

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Auth';

class Auth extends React.Component {

    loggedIn = () => {
        return this.props.isLoggedIn;
    };
    switchRoutes = (isLoggedIn) => (
        <Switch>
            {authRoutes.map((prop, key) => {
                if (prop.redirect)
                    return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                if (prop.collapse) {
                    return prop.views.map((prop, key) => {
                        
                        if (!isLoggedIn) {
                            return (
                                <Route path={prop.path} component={prop.component} key={key} />
                            );
                        }
                        else {
                            return <Redirect from={prop.path} to="/home" key={key} />;
                        }
                    });
                }
                if (!isLoggedIn) {
                    return <Route path={prop.path} component={prop.component} key={key} />;
                }
                else {
                    return <Redirect from={prop.path} to="/home" key={key} />;
                }
            })}
        </Switch>
    );

    render() {
        return (<div>{this.switchRoutes(this.loggedIn())}</div>);
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    //withStyles(appStyle)
);

//export default withStyles(appStyle)(Dashboard);
export default enhance(Auth);