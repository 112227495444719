import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
});

class Camera extends Component {
    render() {
        return <SvgIcon {...this.props}>
            <g>
	<path fill="#393A3E" d="M410.953,302.708c2.308,0,4.616,0,6.935,0c0.107,0,0.205,0.025,0.303,0.07
		c0.936,0.509,1.924,0.74,2.986,0.705c0.917-0.027,1.773-0.268,2.574-0.696c0.099-0.054,0.206-0.079,0.321-0.079
		c2.256,0,4.52,0,6.774,0c0.08,0,0.098,0.025,0.098,0.098c0,0.668,0,1.346,0,2.015c0,0.267-0.224,0.49-0.49,0.49
		c-6.337,0-12.665,0-19.002,0c-0.196,0-0.384-0.126-0.463-0.312c-0.01-0.027-0.01-0.062-0.036-0.081
		C410.953,304.186,410.953,303.447,410.953,302.708z"/>
	<path fill="#393A3E" d="M430.535,289.97c0.08,0.036,0.159,0.072,0.222,0.125c0.116,0.107,0.18,0.232,0.18,0.393
		c0,0.668,0,1.328,0,1.996c0,0.071-0.019,0.09-0.09,0.09c-2.228,0-4.448,0-6.677,0c-0.177,0-0.329-0.027-0.498-0.116
		c-0.98-0.517-2.032-0.731-3.138-0.65c-0.803,0.054-1.551,0.267-2.256,0.65c-0.16,0.089-0.32,0.124-0.498,0.124
		c-2.236-0.008-4.465,0-6.703,0c-0.045,0-0.088,0-0.133,0c0-0.73,0-1.452,0-2.184c0.062-0.214,0.205-0.355,0.418-0.418
		C417.763,289.97,424.144,289.97,430.535,289.97z M429.688,291.272c0.009-0.312-0.267-0.605-0.57-0.614
		c-0.365-0.01-0.651,0.24-0.659,0.596c-0.01,0.348,0.258,0.625,0.605,0.633C429.411,291.896,429.688,291.62,429.688,291.272z"/>
	<path fill="#393A3E" d="M410.953,293.847c0.019,0,0.045,0,0.062,0c1.8,0,3.609,0,5.41,0c0.026,0,0.054,0,0.106,0
		c-0.927,1.105-1.408,2.362-1.408,3.798c0,1.435,0.473,2.691,1.408,3.805c-1.871,0-3.726,0-5.579,0
		C410.953,298.911,410.953,296.379,410.953,293.847z"/>
	<path fill="#393A3E" d="M425.418,301.441c0.91-1.113,1.391-2.369,1.391-3.806c0-1.435-0.489-2.683-1.391-3.796
		c0.045,0,0.071,0,0.099,0c1.773,0,3.547,0,5.321,0c0.08,0,0.099,0.018,0.099,0.098c0,2.469,0,4.938,0,7.407
		c0,0.078-0.019,0.097-0.099,0.097c-1.774,0-3.548,0-5.321,0C425.499,301.441,425.473,301.441,425.418,301.441z"/>
	<path fill="#393A3E" d="M421.88,302.004c0.179-0.357,0.152-0.704-0.08-1.043c0.205-0.062,0.401-0.116,0.598-0.196
		c1.114-0.463,1.835-1.284,2.103-2.46c0.223-0.99-0.026-1.898-0.65-2.692c-0.615-0.784-1.443-1.22-2.424-1.336
		c-1.248-0.144-2.317,0.231-3.183,1.149c-0.543,0.57-0.837,1.266-0.873,2.059c-0.045,0.928,0.258,1.729,0.883,2.407
		c0.498,0.543,1.123,0.891,1.844,1.069c-0.098,0.16-0.178,0.321-0.195,0.507c-0.018,0.188,0.027,0.357,0.107,0.536
		c-0.16-0.019-0.295-0.054-0.429-0.09c-1.479-0.454-2.565-1.372-3.075-2.842c-0.569-1.65-0.213-3.139,0.973-4.413
		c0.721-0.775,1.641-1.229,2.682-1.399c1.454-0.223,2.772,0.107,3.913,1.033c0.883,0.714,1.427,1.641,1.578,2.773
		c0.178,1.363-0.215,2.558-1.158,3.556c-0.696,0.74-1.552,1.194-2.55,1.391c-0.009,0-0.027,0-0.035,0.009
		C421.898,302.004,421.889,302.004,421.88,302.004z"/>
	<path fill="#393A3E" d="M420.401,301.584c0-0.284,0.241-0.507,0.544-0.507c0.312,0,0.543,0.223,0.543,0.517
		c0,0.285-0.24,0.517-0.543,0.517C420.633,302.11,420.392,301.879,420.401,301.584z"/>
</g>
            </SvgIcon>
    }
}
export default withStyles(style)(Camera);