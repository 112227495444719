import React, { Component, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';

import SwipeableViews from 'react-swipeable-views';
import CatalogueCard from 'components/CatalogueCard';

import PropTypes from 'prop-types';

import { Pencil, ShareVariant, MessageBulleted, Check, ChevronLeft, ChevronRight } from 'mdi-material-ui';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import numeral from 'numeral';
import { Typography, Badge } from '@material-ui/core';
import XGallery from 'components/XGallery';
import XButton from 'components/XButton';
import XField from 'components/XField';
import XDialog from 'components/XDialog';
import CatalogueActions from 'components/CatalogueActions';
import RelatedCard from 'components/RelatedCard';
import XSelect from 'components/XSelect';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';

import { getXSelect } from 'utils';
import {
    grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";
import XExpansionPanel from './XExpansionPanel';

const styles = theme => ({
    card: {
        margin: "8px",
    },
    cardContent: {
        paddingTop: 55,
    },
    header: {
        maxHeight: "90px",
        overflow: "hidden",
    },
    media: {
        height: 0,
        paddingTop: 'calc(56.25% / 1.5)', // 16:9
        maxHeight: '80vh',
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)',
        backgroundSize: 'contain',
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: theme.palette.primary,
    },
    price: {
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "right",
    },
    priceText: {
        fontSize: "24px",
        fontWeight: "bold",
    },
    mediaButtonLeft: {
        position: "absolute",
        left: "0",
        top: "50%",
    },
    mediaButtonRight: {
        position: "absolute",
        right: "0",
        top: "50%",
    },
    closeButton: {
        position: "absolute",
        right: 13,
        top: 5,
        zIndex: 1099,
    },
    leftButton: {
        position: "absolute",
        left: 13,
        top: 5,
        zIndex: 1099,
    },
    brandTitle: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    codeTitle: {
        fontSize: "16px",
        marginBottom: "50px",
        fontWeight: 'bold',
    },
    descriptionTitle: {
        fontSize: "16px",
        marginBottom: "50px",
    },
    attrLabel: {
        fontWeight: 'bold',
    },
    marginTop: {
        marginTop: "30px",
        marginBottom: "30px",
    },
    brandLogo: {
        maxWidth: "300px",
        maxHeight: "100px",
    },
    center: {
        left: "50%",
        transform: "translateX(-50%)",
    },
    centerText: {
        textAlign: "center",
    },
    catalogueButton: {
        width: 50,
        height: 50,
        display: "inline-block",
        verticalAlign: "top",
        position: "relative",
        background: "#ccc",
        marginLeft: 5,
        transitionDelay: "0s, 0s",
        transitionDuration: "0.2s, 0.2s",
        transitionProperty: "box-shadow, background-color",
        transitionTimingFunction: "cubic-bezier(0.4, 0, 1, 1), cubic-bezier(0.4, 0, 0.2, 1)",
        willChange: "box-shadow, transform",
        "&:hover": {
            boxShadow: "0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)",
        },
        "& > * ": {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
        }
    },
    catalogueButton2: {
        width: 50,
        height: 50,
        padding: 0,
        marginRight: "10px",
        "& > * ": {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
        }
    },
    ribbon: {
        width: "150px",
        height: "150px",
        overflow: "hidden",
        position: "absolute",
        top: -5,
        left: 2,
        "&:before": {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: "block",
            border: "5px solid #2980b9",
            borderTopColor: "transparent",
            borderLeftColor: "transparent",
            top: 0,
            right: 0,
        },
        "&:after": {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: "block",
            border: "5px solid #2980b9",
            borderTopColor: "transparent",
            borderLeftColor: "transparent",
            bottom: 0,
            left: 0,
        },
        "& span": {
            position: "absolute",
            display: "block",
            width: 225,
            padding: "15px 0",
            backgroundColor: theme.palette.secondary.main,
            boxShadow: "0 5px 10px rgba(0,0,0,.1)",
            color: "#fff",
            font: "700 18px/1 'Lato', sans-serif",
            textShadow: "0 1px 1px rgba(0,0,0,.2)",
            textTransform: "uppercase",
            textAlign: "center",
            right: -25,
            top: 30,
            transform: "rotate(-45deg)",
        },
        stars: {
            fontSize: 30,
        }
    },
    buttonsWrapper: {
        width: "100%",
        "& > *": {
            display: "inline-block",
        }
    },
    popup: {
        position: "absolute",
        maxWidth: 350,
        width: "100%",
        left: "50%",
        top: "50%",
        zIndex: "10000",
        background: "#fff",
        boxShadow: "0px 1px 7px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
        //border: "1px solid",
        padding: "10px",
        transform: "translate(-50%,-50%)",
        
    },
    popupBack: {
        position: "fixed",
        display: "block",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "#999",
        opacity: 0.8,
        zIndex: "9999",
    },
    badgeSmall: {
        "& > span": {
            width: 10,
            height: 10,
            top: -5,
            right: -5,
        },
    },
    serialNumber: {
        fontWeight: "normal",
    },
    slider: {
        //maxHeight: 220,
        //minHeight: 220,
        overflow: "hidden !important",
        position: "relative",
        width: "100%",
        cursor: "pointer",
    },
    slide: {
        width: 'calc(100% / 3)',
    },
    btnLeft: {
        position: 'absolute',
        left: 0,
        top: '50%',
        //transform:'translateY(-50%)',
    },
    btnRight: {
        position: 'absolute',
        right: 0,
        top: '50%',
        //transform: 'translateY(-50%)',
    }
});

async function GetParam(param) {
    return await fetch('/Base/GetParam?param=' + param).then(res => res.json());
}

class CatalogueItem extends Component {
    constructor(props) {
        super(props);

        this.btnPres = React.createRef();
        this.btnCart = React.createRef();
    }
    static contextTypes = {
        router: PropTypes.object
    }
    state = {
        HoverStar: undefined,
        btnPresWidth: 100,
        btnCartWidth: 100,
        discount: 0,
        totalPrice: 0,
        documents: [],
        IsReserved: false,
        IsBooked: false,
        IsReservedOther: false,
        IsBookedOther: false,
        dialog: {},
        qty: 0,
        relatedPage: 0,
    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }

    componentDidMount() {
        if (this.btnPres.current) {
            this.setState({ btnPresWidth: this.btnPres.current.clientWidth });
        }
        if (this.btnCart.current) {
            this.setState({ btnCartWidth: this.btnCart.current.clientWidth });
        }
        this.setState({ data: this.props.data }, () => {
            this.loadData();
            this.getItemStatus();
            this.getItemHistory();
            this.getItemRelateds();
        });
    }
    loadData = async () => {
        var { data } = this.state;
        if (data.IsVariation) {
            var result = await fetch('/BOP/GetVariationByCatalogCode/?CatalogCode=' + data.CatalogCode).then(res => res.json());
            this.setState({ variations: result.variations, extras: result.extras });
        }
    }
    reloadData = async (ID, callback) => {
        if (ID > 0) {
            var result1 = await fetch('/BOP/GetCatalogueData/' + ID).then(res => res.json());
            this.setState({ data: result1, relateds:[], isLoading: false },() =>{
                if (callback) {
                    callback();
                }
                this.getItemStatus();
                this.getItemHistory();
                this.getItemRelateds();
            });
        }
    }
    getItemStatus = async () => {
        var { data } = this.state;
        var { FIDIRIdentity, FIDDocument, isCatalog } = this.props;
        var result = await fetch('/IR/BOPStatus/' + data.ID + '?FIDIdentity=' + FIDIRIdentity + '&FIDDocument=' + FIDDocument).then(res => res.json());
        this.setState(state => {
            state.qty = 0;
            state.discount = 0;
            state.IsBooked = result.IsBooked;
            state.IsReserved = result.IsReserved;
            state.IsBookedOther = result.IsBookedOther;
            state.IsReservedOther = result.IsReservedOther;
            state.documents = result.documents;
            var FIDCausal = this.props.params.DOCCartCausalId;
            if (isCatalog) {
                FIDCausal = this.props.CatalogFIDCausal;
            }
            var cart = state.documents.filter(f => f.FIDCausal === FIDCausal)[0];
            if (cart) {
                if (cart.Discount && cart.Discount > 0) {
                    state.discount = cart.Discount;
                    state.showPrices = false;
                }
                state.qty = cart.Qty;
            }
            state.totalPrice = (data.Price * (100 - state.discount) / 100).round(2);
            if (result.documents && result.documents.length > 0) {
                state.internalRemark = result.documents[0].InternalRemarks;
                state.publicRemark = result.documents[0].PublicRemarks;
            }
            return state;
        });
    }
    getItemHistory = async () => {
        if (this.props.showHistory) {
            var { data } = this.state;
            var { FIDIRIdentity } = this.props;
            var result = await fetch('/DOC/GetBOPHistory/' + data.ID + '?FIDIdentity=' + FIDIRIdentity).then(res => res.json());
            this.setState({ history: result });
        }
    }
    getItemRelateds = async () => {
        var { data } = this.state;

        var url = '/BOP/GetBOPRelateds/' + data.ID;

        var result = await fetch(url).then(res => res.json());
        this.setState({
            relateds: result.data
        });
    }

    onItemEditClick = (data) => {
        var { onEditClick } = this.props;
        if (onEditClick) {
            onEditClick(data);
        }
    }
    StarEnter = (star) => {
        this.setState({ HoverStar: star });
    }
    StarExit = (star) => {
        setTimeout(() => {
            this.setState(state => {
                if (state.HoverStar === star) {
                    state.HoverStar = undefined;
                }
                return state;
            });
        }, 100);
    }
    StarItem = (star) => {
        var { onStarItem } = this.props;
        var { data, internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;
        if (onStarItem) {
            onStarItem(data, star);
        }
    }
    doBuy = () => {
        var { onBuyItem } = this.props;
        var { data, discount, totalPrice } = this.state;
        var { internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;
        console.log(data);
        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = data.Price;
        }
        data.Discount = discount;
        data.TotalPrice = totalPrice;
        if (onBuyItem) {
            onBuyItem(data);
        }

    }
    BuyItem = () => {
        var { IsReservedOther, IsBookedOther } = this.state;

        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        this.doBuy();
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato " + (IsReservedOther ? "riservato" : "prenotato") + " da un'altro cliente. Procedere con l'operazione?");
        }
        else {
            this.doBuy();
        }
    }
    NoticeItem = () => {
        var { onNoticeItem } = this.props;
        var { data, internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;
        if (onNoticeItem) {
            onNoticeItem(data);
        }
    }
    ReserveItem = () => {
        var { onReserveItem } = this.props;
        var { data, IsReservedOther, IsBookedOther } = this.state;
        var { internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;

        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        if (onReserveItem) {
                            onReserveItem(data);
                        }
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato "+ (IsReservedOther ? "riservato" : "prenotato") +" da un'altro cliente. Procedere con l'operazione?");
        }
        else {

            if (onReserveItem) {
                onReserveItem(data);
            }
        }

    }
    BookItem = () => {
        var { onBookItem } = this.props;
        var { data, IsReservedOther, IsBookedOther } = this.state;
        var { internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;


        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        if (onBookItem) {
                            onBookItem(data);
                        }
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato " + (IsReservedOther ? "riservato" : "prenotato") + " da un'altro cliente. Procedere con l'operazione?");
        }
        else {

            if (onBookItem) {
                onBookItem(data);
            }
        }
    }
    showShare = () => {
        var buttons = [
            {
                label: "OK",
                action: () => {
                    this.setState({ dialog: {} });
                }
            },
        ];

        this.showDialog(buttons, "Condivisione", "Funzionalità non configurata");
    }
    showRemarks = () => {
        var { FIDIRIdentity, onNoteItem, onShowRemarks } = this.props;
        var { data, documents } = this.state
        if (!FIDIRIdentity || (!documents || documents.length === 0)) {
            if (onNoteItem) {
                onNoteItem(data, onShowRemarks);

            }
        }
        else {
            if (onShowRemarks) {
                onShowRemarks();
            }
        }
        
    }
    RemoveItem = (FIDCausal) => () => {
        var { onRemoveItem } = this.props;
        var { data } = this.state;
        if (onRemoveItem) {
            onRemoveItem(data, FIDCausal);
        }
    }
    showPrices = () => {
        this.setState({ showPrices: true });
    }
    discountChange = (id) => (event, value) => {
        this.setState((state, props) => {

            state.discount = value;
            state.totalPrice = (state.data.Price * (100 - state.discount) / 100).round(2);
            return state;
        });
    }
    remarksChange = (id) => (event, value) => {
        this.setState((state, props) => {

            state[id] = value;
            return state;
        });
    }
    priceChange = (id) => (event, value) => {
        this.setState((state, props) => {
            state.totalPrice = value;
            state.discount = (100 - (state.totalPrice * 100 / state.data.Price)).round(4);
            return state;
        });
    }
    renderEditPrice = (discount, totalPrice) => {
        var { classes } = this.props;
        return (
            <Fragment>
                {this.state.showPrices && <span className={classes.popupBack}></span>}
                {this.state.showPrices &&
                    <div className={classes.popup}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}><XField label="Sc. %" id="discount" value={discount} onChange={this.discountChange} /></Grid>
                            <Grid item xs={12}><XField label="Totale" id="totalPrice" value={totalPrice} onChange={this.priceChange} /></Grid>
                            <Fragment>
                                <IconButton onClick={() => this.setState({ showPrices: false })}>
                                    <Check />
                                </IconButton>
                                <IconButton onClick={() => this.setState({ showPrices: false, discount: 0, totalPrice: this.state.data.Price })}>
                                    <CloseIcon />
                                </IconButton>
                            </Fragment>
                        </Grid>
                    </div>}
            </Fragment>
        );
    }
    handleSaveRemarks = () => {
        var { FIDIRIdentity, onNoteItem, onHideRemarks } = this.props;
        var { data, documents, internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;

        if (FIDIRIdentity) {
            if (documents && documents.length > 0) {
                var docIds = documents.map(d => d.ID);

                var dataUpdate = {
                    documents: docIds,
                    internalRemark,
                    publicRemark
                };

                fetch('/IR/UpdateRemarks', {
                    method: "POST",
                    body: JSON.stringify(dataUpdate)
                }).then(res => {

                });
            }
        }
        else {
            if (onNoteItem) {
                onNoteItem(data);
            }
        }
        if (onHideRemarks) {
            onHideRemarks();
        }
    }
    renderEditRemarks = () => {
        var { classes } = this.props;
        var { internalRemark, publicRemark } = this.state;
        return (
            <Fragment>
                {this.props.showRemarks && <span className={classes.popupBack}></span>}
                {this.props.showRemarks &&
                    <div className={classes.popup}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}><XField label="Nota interna" multiline={true} id="internalRemark" value={internalRemark} onChange={this.remarksChange} /></Grid>
                            <Grid item xs={12}><XField label="Nota pubblica" multiline={true} id="publicRemark" value={publicRemark} onChange={this.remarksChange} /></Grid>
                            <Fragment>
                                <IconButton onClick={this.handleSaveRemarks}>
                                    <Check />
                                </IconButton>
                                <IconButton onClick={() => this.setState({ showRemarks: false, internalRemark: undefined, publicRemark: undefined }, this.handleSaveRemarks)}>
                                    <CloseIcon />
                                </IconButton>

                            </Fragment>
                        </Grid>
                    </div>}
            </Fragment>
        );
    }
    onItemClose = () => {
        var { onClose } = this.props;
        if (onClose) {
            onClose();
        }
    }
    render() {
        var { data, HoverStar, showPrices, discount, totalPrice, documents } = this.state;
        var { classes, onClose, open, width, isCatalog } = this.props;
        if (!data) {
            return (<div></div>);
        }

        var isPresentation = documents.filter(i => i.FIDCausal === this.props.params.DOCPresentationCausalId).length > 0;
        var isCart = documents.filter(i => i.FIDCausal === this.props.params.DOCCartCausalId).length > 0;
        var isNotice = documents.filter(i => i.FIDCausal === this.props.params.DOCNoticeCausalId).length > 0;
        var isReservation = documents.filter(i => i.FIDCausal === this.props.params.DOCReservationCausalId).length > 0;
        var isBooking = documents.filter(i => i.FIDCausal === this.props.params.DOCBookingCausalId).length > 0;

        if (discount === 0) {
            totalPrice = data.Price;
        }

        var subTitle = "";
        if (data.Brand) {
            subTitle = data.Brand;
        }
        if (data.Line) {
            if (subTitle !== "") {
                subTitle += " - ";
            }
            subTitle += data.Line
        }
        var price = "";
        if (data.Price) {
            price = data.PriceCurrency + " " + numeral(data.Price).format('0,0.00');
        }
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, this.props.params.DOCCartCausalId)) : [];
        //var actionAreaStyle = {
        //    width: "100%"
        //};
        if (!open) {
            return (<div></div>);
        }
        var color = grayColor;
        var showStars = false;
        //var cartButton = <XButton color="primary" size="small" onClick={this.BuyItem} buttonRef={this.btnCart}>Aggiungi al carrello</XButton>;//<Cart color="primary" style={{ cursor: 'pointer' }} onClick={this.BuyItem} />;
        if (isPresentation) {
            if (!HoverStar) {
                HoverStar = documents.filter(i => i.FIDCausal === this.props.params.DOCPresentationCausalId)[0].Stars;
            }
            showStars = true;
        }
        else if (isCart) {
            //showStars = false;
            //cartButton = <XButton color="primary" size="small" onClick={this.RemoveItem} buttonRef={this.btnCart}>Rimuovi dal carrello</XButton>;//<CartOff color="primary" style={{ cursor: 'pointer' }} onClick={this.RemoveItem} />;
        }


        switch (HoverStar) {
            case 1:
                color = "#E74C3C";
                break;
            case 2:
                color = "#F39C12";
                break;
            case 3:
                color = "#F7DC6F";
                break;
            case 4:
                color = "#82E0AA";
                break;
            case 5:
                color = "#27AE60";
                break;
            default:
                break;
        }
        //var header = (<CardHeader
        //    className={classes.header}
        //    //title={data.Code}
        //    //subheader={subTitle}badgeSmall
        //    action={
        //        <Fragment>
        //            <IconButton onClick={() => { this.onItemEditClick(data) }}>
        //                <Pencil />
        //            </IconButton>
        //            <IconButton onClick={onClose}>
        //                <CloseIcon />
        //            </IconButton>
        //        </Fragment>
        //    }
        ///>);
        
        return (

            <Card className={classes.card}>

                {this.renderEditPrice(discount, totalPrice)}
                {this.renderEditRemarks()}
                <CardContent className={classes.cardContent}>
                    <div className={classes.leftButton} >
                        <IconButton onClick={this.showShare}>
                            <ShareVariant />
                        </IconButton>
                        <IconButton onClick={this.showRemarks}>
                            {(this.state.internalRemark || this.state.publicRemark) ?
                                <Badge className={classes.badgeSmall} badgeContent="" color="primary">
                                    <MessageBulleted />
                                </Badge>
                                : <MessageBulleted />
                            }
                            
                        </IconButton>
                    </div>
                    <div className={classes.closeButton} >
                        {this.props.onEditClick && <IconButton onClick={() => { this.onItemEditClick(data) }}>
                            <Pencil />
                        </IconButton>}
                        <IconButton onClick={this.onItemClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Grid container spacing={16}>
                        <Grid container xs={12}>
                            <Grid container xs={12} md={6} className={classes.centerText} alignItems="flex-start" justify="center">
                                <div style={{ width: "100%" }}>
                                    <Grid item xs={12} className={classes.brandTitle}>
                                        <object className={classes.brandLogo} data={"/BOP/BrandDefaultImage/" + data.FIDBrand} type="image/jpg">
                                            {data.Brand}
                                        </object>
                                    </Grid>
                                    <Grid item xs={12} className={classes.codeTitle}>
                                        <div>Ref. {data.Code}</div>
                                        <div className={classes.serialNumber}>{data.SerialNumber}</div>
                                    </Grid>

                                    <Grid item xs={12} className={classes.descriptionTitle}>
                                        {data.Description}
                                    </Grid>

                                    {
                                        //IsReserved && <div class={classes.ribbon}><span>RISERVATO</span></div>
                                    }
                                    {
                                        //IsBooked && <div class={classes.ribbon}><span>PRENOTATO</span></div>
                                    }

                                    {data.Brand && this.props.labels.brand && (<Fragment><Grid item xs={12} className={classes.attrLabel}>{this.props.labels.brand}</Grid> <Grid item xs={12}>{data.Brand}</Grid></Fragment>)}
                                    {data.Line && (<Fragment><Grid item xs={12} className={classes.attrLabel}>{this.props.labels.line}</Grid> <Grid item xs={12}>{data.Line}</Grid></Fragment>)}
                                    {data.Series && (<Fragment><Grid item xs={12} className={classes.attrLabel}>{this.props.labels.series}</Grid> <Grid item xs={12}>{data.Series}</Grid></Fragment>)}

                                    {width !== "xs" &&
                                        this.renderPriceButtons(classes, price, showPrices, discount, totalPrice, isPresentation, isNotice, isReservation, isBooking, isCart, showStars, HoverStar, color, documents)
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <XGallery imageIds={files} />
                            </Grid>
                            {width === "xs" &&
                                this.renderPriceButtons(classes, price, showPrices, discount, totalPrice, isPresentation, isNotice, isReservation, isBooking, isCart, showStars, HoverStar, color, documents)
                            }
                            {this.renderVariations()}
                            {this.renderRelateds()}
                            {this.renderHistory()}
                        </Grid>
                    </Grid>
                </CardContent>

                <XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
            </Card>
        );
    }
    renderHistory = () => {
        if (!this.props.showHistory) {
            return false;
        }

        var { history } = this.state;
        return <div>
            {history && history.map((item, index) => (<div key={index}><span>{item.DocumentDate}</span><span>{item.Pricelist}</span><span>{item.Discount}</span><span>{item.SellPrice}</span></div>))}
        </div>;
    }
    onRelatedClick = (item) => async () => {
        var result1 = await fetch('/BOP/GetCatalogueData/' + item.ID).then(res => res.json());
        this.setState({ data: result1, relateds:[] }, () => {
            this.loadData();
            this.getItemStatus();
            this.getItemHistory();
            this.getItemRelateds();
        });
    }
    onRelatedLeft = () => {
        this.setState(state => {
            var items = 4;
            if (this.props.width === "xs") {
                items = 1;
            }
            if (state.relatedPage >= Math.ceil(state.relateds.length / items)) {
                state.relatedPage = Math.ceil(state.relateds.length / items) - 1;
            }
            state.relatedPage--;
            if (state.relatedPage < 0) {
                state.relatedPage = 0;
            }
            return state;
        });
    }
    onRelatedRight= () => {
        this.setState(state => {
            state.relatedPage++;

            var items = 4;
            if (this.props.width === "xs") {
                items = 1;
            }
            if (state.relatedPage >= Math.ceil(state.relateds.length / items)) {
                state.relatedPage = Math.ceil(state.relateds.length / items) - 1;
            }
            return state;
        });
    }
    renderRelateds = () => {
        var { classes } = this.props;
        var { data, variations, extras, relateds, relatedPage } = this.state;
        if (!relateds || relateds.length === 0) {
            return false;
        }
        var md = 6;
        if ((!variations || variations.length === 0 || !extras || extras.length === 0)) {
            md = 12;
        }

        

        //var collection = (
        //    <Grid xs={12} container>
        //        {
        //            relateds.map((item, index) => {
        //                var key = item.ID;
        //                if (!key) {
        //                    key = index;
        //                }
        //                return (
        //                    <RelatedCard data={item} />
        //                );
        //            })
        //        }
        //    </Grid>
        //);

        var items = 4;
        if (this.props.width === "xs") {
            items = 1;
        }
        if (relatedPage >= Math.ceil(relateds.length / items)) {
            relatedPage = Math.ceil(relateds.length / items) - 1;
        }
        var collection = (

            <Grid xs={12} container style={{ padding:'0 20px'}}>
                {
                    relateds.slice(relatedPage*items, (relatedPage*items)+items).map((item, index) => {
                        var key = item.ID;
                        if (!key) {
                            key = index;
                        }
                        return (
                            <Grid xs={12} md={3}>
                                <RelatedCard data={item} onItemClick={this.onRelatedClick(item)} />
                            </Grid>
                        );
                    })
                }
                {relateds.length > 4 &&
                    <Fragment>
                        <IconButton className={classes.btnLeft} onClick={this.onRelatedLeft}><ChevronLeft /></IconButton>
                        <IconButton className={classes.btnRight} onClick={this.onRelatedRight}><ChevronRight /></IconButton>
                    </Fragment>
                }
            </Grid>
        );

        var panels = [
            {
                icon: "code",
                label: "Prodotti correlati",
                content: collection
            }
        ];

        return <Grid container xs={12} md={md} spacing={2}><XExpansionPanel panels={panels} /></Grid>;
    }
    renderVariations = () => {
        var { data, variations, extras, relateds } = this.state;
        if (!variations || variations.length === 0 || !extras || extras.length === 0) {
            return false;
        }
        else {
            var allExtras = extras.map(item => {
                var values = variations.filter(f => f.FIDExtra === item.ID && !!f.Value).map(i => {
                    return {
                        ID: i.Value,
                        Abbreviation: "",
                        Denomination: i.Value
                    };
                }).map(i => JSON.stringify(i)).filter((value, index, self) => self.indexOf(value) === index).map(i => JSON.parse(i));
                if (!values || values.length <= 1) {
                    return false;
                }
                var val = undefined;
                var currItem = variations.filter(f => f.FIDExtra === item.ID && f.FIDBop === data.ID)[0]
                if (currItem) {
                    val = currItem.Value;

                }

                var ui = undefined;
                switch (item.VariationViewType) {
                    case 0:
                        ui = <XSelect
                            label={item.Label}
                            id={item.ID}
                            customData={values}
                            value={val}
                            idKey="ID"
                            valueKey="Denomination"
                            abbreviationKey="Abbreviation"
                            onChange={this.onVariationChange}
                        />;
                        break;
                    case 1:
                        ui = <div>
                            {//values.map
                            }
                        </div>;
                        break;
                    default:
                        break;
                }

                return (
                    <Grid key={item.ID} item xs={12} md={3}>
                        {ui}
                    </Grid>
                )
            }).filter(f => f !== false);
            if (allExtras.length === 0) {
                
                return false;
            }
            var ui = <Grid container xs={12} spacing={2} style={{ minHeight: 151 }}>
                {allExtras}
            </Grid>;
            var panels = [
                {
                    icon: "code",
                    label: "Varianti",
                    content: ui
                }
            ];
            var md = 12;
            if (relateds && relateds.length > 0) {
                md = 6
            }

            return <Grid container xs={12} md={md} spacing={2}><XExpansionPanel panels={panels} /></Grid>;
        }
    }
    onVariationChange = (key) => (event, value) => {
        if (value.length === 0) {
            return;
        }
        var { variations, data } = this.state;
        var currentVars = variations.filter(i => i.FIDBop === data.ID).map(i => {
            var newVal = i.Value;
            if (i.FIDExtra === key) {
                newVal = value[0].ID;
            }
            return { FIDExtra: i.FIDExtra, Value: newVal };
        });
        var filter = {};
        for (var i = 0; i < currentVars.length; i++) {
            var item = currentVars[i];
            filter["e_" + item.FIDExtra] = item.Value;
        }
        var bopVariants = [];
        var FIDBOPS = variations.map(i => i.FIDBop).filter((value, index, self) => self.indexOf(value) === index);

        var variationsMap = variations.map(i => {
            var newVal = i.Value;
            return { FIDExtra: i.FIDExtra, Value: newVal, FIDBOP: i.FIDBop }
        }).reduce((r, a) => {
            r[a.FIDBOP] = r[a.FIDBOP] || [];
            r[a.FIDBOP].push(a);
            return r;
        }, Object.create(null));

        for (var b = 0; b < FIDBOPS.length; b++) {
            var FIDBOP = FIDBOPS[b];
            var v = variationsMap[FIDBOP];
            var tempF = {};
            for (var i2 = 0; i2 < v.length; i2++) {
                var item2 = v[i2];
                tempF["e_" + item2.FIDExtra] = item2.Value;
            }
            bopVariants.push({ key: FIDBOP, value: tempF });
        }
        var selVar = bopVariants.filter(i => JSON.stringify(i.value) === JSON.stringify(filter));
        if (selVar.length === 1) {
            this.reloadData(selVar[0].key, this.loadData);
        }
        
        //var selectedVar = variations.filter(i => i.FIDExtra === key && i.Value === value[0].ID)[0];
        //if (selectedVar) {
        //    this.reloadData(selectedVar.FIDBop, this.loadData);
        //}
    }
    updateDocument = () => {
        var { onBuyItem, onRemoveItem } = this.props;
        var { data, discount, totalPrice, qty } = this.state;
        if (qty === 0) {
            if (onRemoveItem) {
                onRemoveItem(data, this.props.CatalogFIDCausal);
            }
        }
        else {
            if (!discount || discount === 0) {
                discount = 0;
                totalPrice = data.Price;
            }
            data.Discount = discount;
            data.TotalPrice = totalPrice;
            if (onBuyItem) {
                onBuyItem(data, qty, true);
            }
        }
    }
    renderPriceButtons(classes, price, showPrices, discount, totalPrice, isPresentation, isNotice, isReservation, isBooking, isCart, showStars, HoverStar, color, documents) {
        if (this.state.discount > 0) {
            console.log(this.state.totalPrice);
        }
        var { isCatalog } = this.props;
        var { data, internalRemark, publicRemark, discount, totalPrice } = this.state;
        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = data.Price;
        }
        data.Discount = discount;
        data.TotalPrice = totalPrice;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;
        var ui = <CatalogueActions data={data} documents={documents} onNoticeItem={this.props.onNoticeItem} onStarItem={this.props.onStarItem} onBookItem={this.props.onBookItem} onReserveItem={this.props.onReserveItem} onBuyItem={this.props.onBuyItem} onRemoveItem={this.props.onRemoveItem} />
        if (isCatalog) {
            ui = <div style={{ width: "100%", marginTop: 40}}>
                <button style={{ border: "1px solid #aaa", background: "#eee", margin: 2, padding: 15, lineHeight: "5px" }} onClick={() => {
                    this.setState(state => {
                        if (!state.qty) state.qty = 1;
                        state.qty--;
                        return state;
                    }, this.updateDocument)}}>-</button>
                <input style={{ border: "1px solid #aaa", margin: 2, padding: 10, width: 80 }} type="number" value={this.state.qty} onChange={(event) => {
                    var n = parseInt(event.target.value);
                    if (n < 0) {
                        n = 0;
                    }
                    this.setState({ qty: n }, this.updateDocument);
                }} />
                <button style={{ border: "1px solid #aaa", background: "#eee", margin: 2, padding: 15, lineHeight: "5px" }} onClick={() => {
                    this.setState(state => {
                        if (!state.qty) state.qty = 0;
                        state.qty++;
                        return state;
                    }, this.updateDocument)
                }}>+</button>
            </div>;
        }
        return (<Grid container xs={12} className={classes.marginTop + " " + classes.centerText}>
            <Grid item container xs={12} className={classes.marginTop}>
                <Grid item xs={12}>
                    <Typography classes={{ root: classes.priceText }} style={this.state.discount > 0 ? { textDecoration: "line-through" } : {}} color={this.state.discount > 0 ? "gray" : "primary"} onClick={this.showPrices}>{price}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {this.state.discount > 0 && <Typography classes={{ root: classes.priceText }} color="primary" onClick={this.showPrices}>{this.state.discount} %</Typography>}
                </Grid>
                <Grid item xs={12}>
                    {this.state.discount > 0 && <Typography classes={{ root: classes.priceText }} color="primary" onClick={this.showPrices}>{data.PriceCurrency + " " + numeral(parseFloat(this.state.totalPrice)).format('0,0.00')}</Typography>}
                </Grid>
                {
                    //showPrices && <Fragment>
                    //<Grid container item xs={12}>
                    //    <Grid item xs={4}><Typography classes={{ root: classes.priceText }} color="primary">Sc. %</Typography></Grid>
                    //    <Grid item xs={8}><XField id="discount" value={discount} onChange={this.discountChange} /></Grid>
                    //    <Grid item xs={12}><XField id="totalPrice" value={totalPrice} onChange={this.priceChange} /></Grid>
                    //</Grid>
                    //</Fragment>
                }
            </Grid>
            {ui}
        </Grid>);
    }
}
const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withWidth(),
    withStyles(styles)
);


export default enhance(CatalogueItem);