import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import { Collection, WindowScroller } from 'react-virtualized';
import HistorySellCard from '../../components/HistorySellCard';
import { getScrollbarWidth } from 'utils';

const styles = theme => ({
    root: {

    }
});

class IdentitySells extends Component {
    constructor(props) {
        super(props);

        this.Collection = React.createRef();

        this.state = {
            data: [],
            contentWidth: 100,
        };
    }
    componentDidMount() {
        this.handleWidth();
        this.loadData();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.year !== this.props.year) {
            this.loadData();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.mainContentResize);
    }
    loadData = () => {
        var FIDIdentity = this.props.id;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        fetch('/DOC/GetHistoricalSells/' + FIDIdentity + '?year=' + this.props.year).then(res => res.json()).then(data => {
            this.setState({ data });
        });
    }
    handleWidth = () => {
        if (window.ResizeObserver !== undefined) {
            this.resizeObserver = new window.ResizeObserver((entries) => {
                this.setState({
                    contentWidth: entries[0].contentRect.width,
                    lastWidthChange: Date.now()
                }, () => {
                    setTimeout(() => {
                        if (this.Collection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                            this.Collection.current.recomputeCellSizesAndPositions();
                        }
                    }, 100);
                });
            });
            this.resizeObserver.observe(document.getElementById('mainContent'));
        }
        else {
            this.setState({ contentWidth: document.getElementById('mainContent').clientWidth });
            window.addEventListener('resize', this.mainContentResize);
        }
    }

    mainContentResize = () => {
        var element = document.getElementById('mainContent');

        this.setState({
            contentWidth: element.clientWidth,
            lastWidthChange: Date.now()
        }, () => {
            setTimeout(() => {
                if (this.Collection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                    this.Collection.current.recomputeCellSizesAndPositions();
                }
            }, 100);
        });
    }

    getNumberOfCols = () => {
        var cols = 4;
        switch (this.props.width) {
            case "xl":
                cols = 4;
                break;
            case "lg":
                cols = 3;
                break;
            case "md":
            case "sm":
                cols = 2;
                break;
            case "xs":
                cols = 1;
                break;
            default:
                break;
        }
        return cols;
    }

    getCardSize = () => {
        var { contentWidth } = this.state;
        var cols = this.getNumberOfCols();
        var tempWidth = 386.75;
        var tempHeight = 480.55;
        var width = (contentWidth - 96 - getScrollbarWidth()) / cols;

        var height = width * tempHeight / tempWidth;
       
        return { width, height };
    }
    getRowTop = (row, cols, index) => {
        var { data } = this.state;
        //574.23
        var sum = 0;

        var { height } = this.getCardSize();
        var maxRow = height;
        var lastIndex = (index - (index % cols));
        for (var i = 0; i < lastIndex; i++) {
            if (i % cols == 0) {
                sum += maxRow;
                maxRow = height;
            }
        }
        sum += maxRow - height;

        return sum;
    }
    onItemClick = (index) => (item) => {
        this.setState({ currentIndex: index, currentItem: item, showItem: true });
    }
    handleCatalgoItemClose = () => {

        this.setState({ currentItem: {}, itemStar: {}, showItem: false }, () => {
            //document.getElementById('mainPanel').scrollTo(0, this.state.scrollTop);
        });
    }

    render() {
        var { classes, id } = this.props;
        var { data, contentWidth, showItem } = this.state;
        //var height = window.innerHeight - 72.5 - 64 - 64;
        var width = contentWidth - 96;

        var collection = <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
            <Collection
                autoHeight
                cellCount={data.length}
                cellRenderer={({ index, key, style }) => {
                    var item = data[index];
                    key = item.ID;
                    if (!key) {
                        key = index;
                    }

                    style.margin = 8;
                    style.height = undefined;
                    console.log(key);

                    return (<HistorySellCard showExpansionPanel={false} isExpanded={false} style={style} key={key} isLoading={false} data={item} onItemClick={this.onItemClick(index)} FIDIdentity={id} />);
                }}
                cellSizeAndPositionGetter={({ index }) => {
                    var cols = this.getNumberOfCols();
                    var { width, height } = this.getCardSize();
                    var top = this.getRowTop((index - (index % cols)), cols, index);
                    var result = {
                        height: height - 16,
                        width: width - 16,
                        x: (index % cols) * width,
                        y: top,//((index - (index % cols)) / cols) * height
                    };
                    return result;
                }}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                scrollTop={scrollTop}
                height={height}
                width={width}
            />)}
        </WindowScroller>;


        return <div className={classes.root}>
            {collection}
        </div>;
    }
}

export default withStyles(styles)(IdentitySells);