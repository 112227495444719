import React from 'react';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XTag from 'components/XTag';
import XSwitch from 'components/XSwitch';
import XDateField from 'components/XDateField';
import XDateTimeField from 'components/XDateTimeField';


import { Barcode, CodeString, Alphabetical, Check, ArrowLeft } from 'mdi-material-ui';


const getField = (fields, field) => {
    var filtered = fields.filter((item) => item.Field === field);
    if (filtered.length > 0) {
        return filtered[0];
    }

    return undefined;
}

const getXSelect = (data, fields, xs, md, id, label, handleChange, urlData,urlCreate, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XSelect field={getField(fields, id)} label={label} id={id} urlData={urlData} urlCreate={urlCreate} value={data[id]} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={handleChange} {...params} /></Grid>);
}
const getXField = (data, fields, xs, md, id, label, handleChange, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XField field={getField(fields, id)} label={label} id={id} value={data[id]} onChange={handleChange} {...params}/></Grid>);
}
const getXSwitch = (data, fields, xs, md, id, label, handleChange, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XSwitch field={getField(fields, id)} label={label} id={id} value={data[id]} onChange={handleChange} {...params} /></Grid>);
}
const getXTag = (data, fields, xs, md, id, label, handleChange, urlData, urlCreate, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XTag field={getField(fields, id)} label={label} id={id} urlData={urlData} urlCreate={urlCreate} value={data[id]} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={handleChange} {...params} /></Grid>);
}
const getXDateField = (data, fields, xs, md, id, label, handleChange, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XDateField field={getField(fields, id)} label={label} id={id} value={data[id]} onChange={handleChange} {...params} /></Grid>);
}
const getXDateTimeField = (data, fields, xs, md, id, label, handleChange, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XDateTimeField field={getField(fields, id)} label={label} id={id} value={data[id]} onChange={handleChange} {...params} /></Grid>);
}

const handleErrors = (response) => {
    if (!response.ok) {
        response.body.getReader().read().then(({ done, value }) => { console.log([done, value]); });
        throw Error(response);
    }
    return response;
}

const getCookie = (cname) => {
    return window.localStorage.getItem(cname);
    //var name = cname + "=";
    //var decodedCookie = document.cookie;//decodeURIComponent(document.cookie);
    //var ca = decodedCookie.split(';');
    //for (var i = 0; i < ca.length; i++) {
    //    var c = ca[i];
    //    while (c.charAt(0) === ' ') {
    //        c = c.substring(1);
    //    }
    //    if (c.indexOf(name) === 0) {
    //        return c.substring(name.length, c.length);
    //    }
    //}
    //return "";
}
const setCookie = (cname, cvalue) => {
    window.localStorage.setItem(cname, cvalue);
//    var d = new Date();
//    d.setTime(d.getTime() + (60 * 60 * 1000));
//    document.cookie = cname + "=" + cvalue + ";expires=" + d.toUTCString() + ";path=/";
}

const deleteCookie = (cname) => {
    window.localStorage.removeItem(cname);
    //var d = new Date();
    //d.setTime(d.getTime() - (1000 * 24 * 60 * 60 * 1000));
    //document.cookie = cname + "=;expires=" + d.toUTCString() + ";path=/";
}

const popupBOPSearch = (codeEvent,barcodeEvent,serialEvent) => {
    
    var actions = [];
    if (codeEvent) {
        actions.push(<Tooltip title="Referenza">
            <IconButton aria-label="Referenza" onClick={codeEvent}>
                <Alphabetical />
            </IconButton>
        </Tooltip>);
    }
    if (barcodeEvent) {
        actions.push(<Tooltip title="Barcode">
            <IconButton aria-label="Barcode" onClick={barcodeEvent}>
                <Barcode />
            </IconButton>
        </Tooltip>);
    }
    if (serialEvent) {
        actions.push(<Tooltip title="Seriale">
            <IconButton aria-label="Seriale" onClick={serialEvent}>
                <CodeString />
            </IconButton>
        </Tooltip>);
    }

    return actions;
}
const getBOPSearchContent = (ref, bopSearchType, bopSearchValue, onSearchBlur, onSearchKeyDown, onSearchChange, onSearchCancel, onSearchConfirm) => {
    var label = "";
    switch (bopSearchType) {
        case "code":
            label = "Referenza";
            break;
        case "barcode":
            label = "Barcode";
            break;
        case "serial":
            label = "Seriale";
            break;
        default:
            break;
    }
    var customContent = <div style={{ padding: "10px 20% 40px" }}>
        <XField autoFocus inputRef={ref} label={label} id="grdDOCBopsSearch" value={bopSearchValue} onBlur={onSearchBlur} onKeyDown={onSearchKeyDown} onChange={onSearchChange} />
    </div>;
    var showFilterActivator = false;
    var showColumnsSelector = false;
    var handleNewRow = undefined;
    var actions = [<Tooltip title="Annulla">
        <IconButton aria-label="Annulla" onClick={onSearchCancel}>
            <ArrowLeft />
        </IconButton>
    </Tooltip>,
    <Tooltip title="Cerca">
        <IconButton aria-label="Cerca" onClick={onSearchConfirm}>
            <Check />
        </IconButton>
        </Tooltip>];
    return { customContent, showFilterActivator, showColumnsSelector, handleNewRow, actions };
}

function getScrollbarWidth() {

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    outer.style.width = 100;
    outer.style.height = 100;
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    inner.style.width = 200;
    inner.style.height = 200;
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;

}
function parseParam(json) {
    if (json) {
        try {
            return JSON.parse(json);
        }
        catch{
            return undefined;
        }
    }
    return undefined;
}


export { getXSelect, getXField, getXSwitch, getXTag, getXDateField, getXDateTimeField, handleErrors, getCookie, setCookie, deleteCookie, popupBOPSearch, getBOPSearchContent, getScrollbarWidth, parseParam };