
import DashboardIcon from "@material-ui/icons/Dashboard";
import Login from 'views/Pages/LoginPage';
import Lock from 'views/Pages/LockScreenPage';
import GDPRPDF from "layouts/GDPRPDF.jsx";



var dashRoutes = [
    {
        path: "/auth/login",
        name: "Login",
        icon: DashboardIcon,
        component: Login,
        requireLogin: false,
    },
    {
        path: "/auth/lock",
        name: "Lock",
        icon: DashboardIcon,
        component: Lock,
        requireLogin: false,
    },
    {
        path: '/auth/GDPRPDF/:id',
        name: 'GDPR',
        icon: DashboardIcon,
        component: GDPRPDF,
        requireLogin: false,
    },
];
export default dashRoutes;