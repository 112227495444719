const SET_TITLE = "SET_TITLE";
const SET_IDENTITY = "SET_IDENTITY";
const SET_PARAMS = "SET_PARAMS";
const SET_MENUS = "SET_MENUS";
const SET_CATALOG_IDENTITY = "SET_CATALOG_IDENTITY";
const SET_CATALOG_FIDCAUSAL = "SET_CATALOG_FIDCAUSAL";
const SET_CATALOG_BUTTONS = "SET_CATALOG_BUTTONS";
const SET_LABELS = "SET_LABELS";

const initialState = {
    title: undefined, FIDIdentity: undefined, CatalogFIDIdentity: undefined, CatalogFIDCausal: undefined, params: {}, catalogueButtons: [], menus: [], labels: {}
};

export const actionCreators = {
    setTitle: (title) => {
        return { type: SET_TITLE, title: title }
    },
    setIdentity: (FIDIdentity) => {
        return { type: SET_IDENTITY, FIDIdentity: FIDIdentity }
    },
    setParams: (params) => {
        return { type: SET_PARAMS, params: params }
    },
    setMenus: (menus) => {
        return { type: SET_MENUS, menus: menus }
    },
    setCatalogIdentity: (FIDIdentity) => {
        return { type: SET_CATALOG_IDENTITY, CatalogFIDIdentity: FIDIdentity }
    },
    setCatalogCausal: (FIDCausal) => {
        return { type: SET_CATALOG_FIDCAUSAL, CatalogFIDCausal: FIDCausal }
    },
    setCatalogueButtons: (buttons) => {
        return { type: SET_CATALOG_BUTTONS, catalogueButtons: buttons }
    },
    setLabels: (labels) => {
        return { type: SET_LABELS, labels: labels }
    },
}

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === SET_TITLE) {
        return { ...state, title: action.title };
    }
    if (action.type === SET_IDENTITY) {
        return { ...state, FIDIdentity: action.FIDIdentity };
    }
    if (action.type === SET_PARAMS) {
        return { ...state, params: action.params };
    }
    if (action.type === SET_MENUS) {
        return { ...state, menus: action.menus };
    }
    if (action.type === SET_CATALOG_FIDCAUSAL) {
        return { ...state, CatalogFIDCausal: action.CatalogFIDCausal };
    }
    if (action.type === SET_CATALOG_IDENTITY) {
        return { ...state, CatalogFIDIdentity: action.CatalogFIDIdentity };
    }
    if (action.type === SET_CATALOG_BUTTONS) {
        return { ...state, catalogueButtons: action.catalogueButtons };
    }
    if (action.type === SET_LABELS) {
        return { ...state, labels: action.labels };
    }

    
    return state;
}
