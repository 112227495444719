import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
});

class LockOffOutline extends Component {
    render() {
        return <SvgIcon {...this.props}>
            <path d="M10,15c0,1.1,0.89,2,2,2c1.104,0,2-0.896,2-2s-0.896-2-2-2C10.89,13,10,13.891,10,15z" />
            <path d="M12,3c1.656,0,3,1.343,3,3v2h-3.252l1.999,2H18v4.256l2,2.001V10c0-1.104-0.896-2-2-2h-1V6c0-2.761-2.238-5-5-5 c-1.963,0-3.644,1.142-4.462,2.788l1.543,1.544C9.386,3.998,10.574,3,12,3z" />
            <path d="M18,20H6V10h5.202l-2-2H9V7.798L7.019,5.817C7.016,5.879,7,5.938,7,6v2H6c-1.11,0-2,0.89-2,2v10c0,1.1,0.89,2,2,2h12 c1.104,0,2-0.896,2-2v-1.201l-2-2V20z" />
            <polygon points="21.418,22.734 1.688,3.004 2.969,1.734 22.688,21.465 21.418,22.734 " />
            </SvgIcon>
    }
}

export default withStyles(style)(LockOffOutline);