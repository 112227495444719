import React, { Component } from 'react';
import PropTypes from 'prop-types';
import XGrid from "../components/XGrid"

import "../components/XReactDataGrid.css";

const gridName = "tblCustomFields";

export class CustomFields extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    handleGridDoubleClick = (event, data) => {
        this.context.router.history.push('/CFEdit/' + data.ID);
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/CFNew');
    }
    render() {
        return (
            <XGrid
                usePrimaryColor={true}
                dataRoute={"/Base/GetCustomFields?grid=" + gridName}
                dataRouteColumns={"/Base/GetColumns?grid=" + gridName}
                dataRouteAvailableColumns={"/Base/GetAvailableColumns?grid=" + gridName}
                dataRouteUpdateColumns={"/Base/UpdateColumns?grid=" + gridName}
                onDoubleClick={this.handleGridDoubleClick}
                onNewButton={this.handleGridNew}
            />
        );
    }
}
