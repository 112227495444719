import React, { Component,Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import XGridRowHeader from './XGridRowHeader';
import XGridRowFilters from './XGridRowFilters';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        fontSize: '1em',
    },
    table: {
        minWidth: "100%",
        fontSize: '1em',
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'hidden',
        fontSize: '1em',
    },
    tableCell: {
        fontSize: '0.8em',
    },
    searchField: {
        margin: '5px',
    }
});

class EnhancedTableHead extends Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };
    createFilterHandler = property => event => {
        this.props.onFilterChange(event, property);
    };

    render() {
        const { onSelectAllClick, orderBy, numSelected, rowCount, columns, filters, classes, showFilters,grid } = this.props;
        var { order } = this.props;
        if (!["asc", "desc"].includes(order)) {
            order = "asc";
        }
        var totalWidth = "100%";
        if (columns.length > 0) {
            totalWidth = columns.map(c => c.width).reduce((a, b) => a + b) + 42;
        }

        return (
            <Fragment>
                <XGridRowHeader
                    width={totalWidth}
                    columns={columns}
                    isIndeterminate={numSelected > 0 && numSelected < rowCount}
                    isSelected={numSelected === rowCount}
                    orderBy={orderBy}
                    order={order}
                    onSelectAll={onSelectAllClick}
                    onSort={this.createSortHandler}
                    reactGrid={grid}
                />
                {showFilters && (
                    <XGridRowFilters
                        width={totalWidth}
                        columns={columns}
                        isIndeterminate={numSelected > 0 && numSelected < rowCount}
                        isSelected={numSelected === rowCount}
                        onSelectAll={onSelectAllClick}
                        filters={filters}
                        filterHandler={this.createFilterHandler}
                    />
                )}
            </Fragment>
        );
    }
}
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    showFilters: PropTypes.bool.isRequired,
};
export default withStyles(styles)(EnhancedTableHead);