import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import Typography from '@material-ui/core/Typography';

import Icon from "@material-ui/core/Icon";

import XButton from 'components/XButton';
import IRStandardFields from 'views/IR/IRStandardFields.jsx';
import IdentitySells from 'views/IR/IdentitySells.jsx';
import Profile from 'views/IR/Profile.jsx';
import XGrid from 'components/XGrid';
import XDialog from 'components/XDialog';
import Grid from '@material-ui/core/Grid';

import XExpansionPanel from 'components/XExpansionPanel';
import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XSwitch from 'components/XSwitch';
import XUpload from 'components/XUpload';
import XProgress from 'components/XProgress';
import XDateField from 'components/XDateField';
import { CustomField } from 'components/CustomField';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogContentText';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import axios, { CancelToken } from 'axios';

import { HomePlus, Close, Check, AccountMultiplePlus, ArrowLeft, ArrowRight, Download, TrashCan  } from 'mdi-material-ui';
import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';

import { getXSelect, getXField, getXSwitch, getXDateField, handleErrors, popupBOPSearch, getBOPSearchContent, parseParam } from 'utils';

import {
    primaryColor,
    primaryBoxShadow,
    primaryColorLight,
    dangerColor,
    colorForPrimaryBackground
} from "assets/jss/material-dashboard-pro-react.jsx";
import XOXLoader from 'components/XOXLoader';
import XTag from '../../components/XTag';
import XMessageBox from 'components/XMessageBox';
import { Save } from '@material-ui/icons';
import moment from 'moment';

import TabContainer from 'components/CustomTabs/TabContainer';
import { getXTag } from '../../utils';
//import moment = require('moment');


const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
        position: 'relative',
    },
});
class SimpleDialog extends React.Component {
    handleClose = () => {
        this.props.onClose(this.props.selectedValue);
    };

    handleListItemClick = value => {
        this.props.onClose(value);
    };

    render() {
        const { classes, onClose, message, title, ...other } = this.props;

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

SimpleDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    selectedValue: PropTypes.string,
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);
const FieldLabels = {
    FIDGroup: "Gruppo",
    TypologyIds: "Tipologia",
    CategoryIds: "Tag",
    Code: "Codice",
    Denomination: "Denominazione",
    Alias: "Alias",
    Name: "Nome",
    Surname: "Cognome",
    Vatnumber: "P.IVA",
    Taxcode: "Codice fiscale",
    Address: "Indirizzo",
    FIDCity: "Città",
    FIDZip: "CAP",
    FIDProvince: "Provincia",
    FIDRegion: "Regione",
    FIDCountry: "Nazione",
    Remarkinternal: "Nota interna",
    Remarkpublic: "Nota pubblica",
    FIDRelation: "Relazione",
    FIDDestinationType: "Tipologia indirizzo",
};


const getExtras = async (FIDGroup,TypologyIds,CategoryIds) =>{
    var i = 0;
    var params = "";
    if (FIDGroup) {
        if (params !== "") {
            params += "&";
        }
        else {
            params += "?";
        }
        params += "FIDGroup=" + FIDGroup;
    }
    if (TypologyIds) {
        for (i = 0; i < TypologyIds.length; i++) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "TypologyIds[" + i + "]=" + TypologyIds[i];
        }
    }
    if (CategoryIds) {
        for (i = 0; i < CategoryIds.length; i++) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "CategoryIds[" + i + "]=" + CategoryIds[i];
        }
    }

    var extratabs = await fetch('/IR/GetIRExtraTabs' + params).then(res => res.json());
    var extragroups = await fetch('/IR/GetIRExtraGroups' + params).then(res => res.json());
    var extras = await fetch('/IR/GetIRExtras' + params).then(res => res.json());


    return { extratabs, extragroups, extras };


    //fetch('/IR/GetIRExtraTabs' + params).then(res => res.json()).then(extratabs => {
    //    fetch('/IR/GetIRExtraGroups' + params).then(res => res.json()).then(extragroups => {
    //        fetch('/IR/GetIRExtras' + params).then(res => res.json()).then(extras => {
    //            this.setState({ extratabs, extragroups, extras });
    //        });
    //    });
    //});
}

class IREdit extends Component {
    constructor(props) {
        super(props);

        this.grdDestinations = React.createRef();
        this.grdDestinationsRelation = React.createRef();
        this.grdExistingDestinations = React.createRef();
        this.grdExistingDestinationsRelation = React.createRef();
        this.grdExistingRelations = React.createRef();
        this.grdRelations = React.createRef();
        this.grdFiles = React.createRef();
        this.grdFilesRelation = React.createRef();
        this.grdAgreements = React.createRef();
        this.grdAgreementsRelation = React.createRef();
        this.grdUsers = React.createRef();
        this.destination = React.createRef();
        this.destinationRelation = React.createRef();
        this.relation = React.createRef();
        this.standardFields = React.createRef();
        this.MessageBox = React.createRef();
        this.tabs = React.createRef();

        this.handleDataBlur = this.handleDataBlur.bind(this);
        this.handleSaveData = this.handleSaveData.bind(this);
    }
    static contextTypes = {
        router: PropTypes.object
    }
    state = {
        value: 0,
        data: {},
        initialData: {},
        firstLoad: true,
        office: {},
        officeRelation: {},
        relation: {},
        file: {},
        fileRelation: {},
        user: {},
        fields: [],
        showMessage: false,
        errorMessage: '',
        dialog: {},
        FIDIdentity: undefined,
        isUploading: false,
        isUploadingRelation: false,
        uploadProgress: 0,
        uploadProgressRelation: 0,
        usersRefreshed: false,
    };
    cancelUpload = undefined;
    cancelUploadRelation = undefined;

    componentDidMount() {
        
        if (this.props.id > 0) {
            this.setState({ FIDIdentity: this.props.id }, () => {
                this.clearSession();
                this.loadData();
            });
        }
        else if (this.props.id > 0) {
            this.setState({ FIDIdentity: this.props.id }, () => {
                this.clearSession();
                this.loadData();
            });
        }
        else {
            this.clearSession();
            this.loadData();
        }
        if (this.isUsers() && this.grdUsers.current) {
            setTimeout(this.grdUsers.current.refresh, 2000);
        }
    }
    componentDidUpdate() {
        if (this.isUsers() && !this.state.usersRefreshed && this.grdUsers.current && this.state.data.ID) {
            this.setState({ usersRefreshed: true }, this.grdUsers.current.refresh);
        }
    }

    componentWillUnmount() {
        this.props.setTitle(undefined);
    }
    loadData = () => {
        if (this.state.FIDIdentity && parseInt(this.state.FIDIdentity, 10) > 0) {
            fetch('/IR/GetIR/' + this.state.FIDIdentity)
                .then(this.handleErrors)
                .then(res => res.json())
                .then(data => {
                    var office = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, FiscalNumber: data.FiscalNumber };
                    this.setState((state) => {
                        state.data = data;
                        state.office = office;

                        if (state.firstLoad) {
                            state.initialData = JSON.parse(JSON.stringify(state.data));
                        }
                        state.firstLoad = false;
                        return state;
                    }, () => {
                        this.loadExtras();
                        if (this.standardFields.current) {
                            this.standardFields.current.loadFields();
                        }
                        });
                    this.props.setTitle(data.Denomination);
                }).catch(error => { console.log(error) });
            
        }
        else {
            this.setState(state => {
                
                if (state.firstLoad) {
                    state.initialData = JSON.parse(JSON.stringify(state.data));
                }
                state.firstLoad = false;
                return state;
            });
        }
    }
    clearSession = (callback) => {
        fetch('/IR/ClearSession', {
            method: 'POST'
        }).then(res => {
            if (callback) {
                callback();
            }
        });
    }

    loadExtrasRelation = () => {
        var { relation } = this.state;
        getExtras(relation.FIDGroup, relation.TypologyIds, relation.CategoryIds).then(({ extratabs, extragroups, extras }) => {
            this.setState({ extratabsRelations: extratabs, extragroupsRelation: extragroups, extrasRelation: extras });
        });
    }

    loadExtras = () => {
        var { data } = this.state;
        getExtras(data.FIDGroup, data.TypologyIds, data.CategoryIds).then(({ extratabs, extragroups, extras }) => {
            console.log({ extratabs, extragroups, extras });
            this.setState({ extratabs, extragroups, extras });
        });
    }
    handleChange = (event, value) => {
        if (this.validate(this.state.value, value)) {
            this.setState({ value });
        }
    };

    validate = (tab, newTab, callback) => {
        if (tab !== 0) {
            return true;
        }
       
        var { isValid, requiredFields, optionalFields } = this.standardFields.current.validate();
        if (!isValid) {
            this.showErrorMessage(requiredFields, optionalFields, callback);
        }
        return isValid;
    }

    showErrorMessage = (requiredFields, optionalFields, callback) => {

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.setState({ dialog: {} });
                    callback();
                }
            },
            {
                label: "NO",
                action: () => {
                    this.setState({ dialog: {} });
                }
            }
        ];
        if (!callback || (requiredFields.length > 0)) {
            buttons = [
                {
                    label: "OK",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                }
            ];
        }
        var msg = (
            <Fragment>
                {requiredFields.length > 0 && (<Typography variant="subtitle2">Campi obbligatori:</Typography>)}
                {requiredFields.map((item, index) => (<Typography key={index}>{FieldLabels[item]}</Typography>))}
                {optionalFields.length > 0 && (<Typography variant="subtitle2">Campi raccomandati (opzionali):</Typography>)}
                {optionalFields.map((item, index) => (<Typography key={index}>{FieldLabels[item]}</Typography>))}

                {requiredFields.length == 0 && optionalFields.length > 0 && (<Typography variant="subtitle2">Procedere senza compilare questi campi?</Typography>)}

                {requiredFields.length > 0 && (<Typography variant="subtitle2">Compilare i campi obbligatori prima di procedere.</Typography>)}
                
            </Fragment>
        );

        this.showDialog(buttons, "Alcuni campi non sono stati compilati.", msg);



        
        //this.setState({ showMessage: true, errorMessage: msg });
    }

    renderTab1 = () => {
        var { data } = this.state;
        
        return (
            <TabContainer>
                <IRStandardFields ref={this.standardFields} readOnly={this.props.userProfile} data={data} dataKey="data" id={parseInt(this.state.FIDIdentity, 10)} caller={this} showCodes={true} onChange={this.handleDataChange} onBlur={this.handleDataBlur} />
            </TabContainer>
        );
    }
    handleCancelOffice = (event, caller) => {
        
        var { data, office } = this.state;
        var props = Object.getOwnPropertyNames(office);
        for (var i = 0; i < props.length; i++) {
            if (typeof office[props[i]] === "string") {
                office[props[i]] = "";
            }
            else {
                office[props[i]] = undefined;
            }
        }
        this.setState({ office: office, isOfficeEdit: false }, () => {
            var newoffice = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, FiscalNumber: data.FiscalNumber };
            this.setState({ office: newoffice }, () => {
                window.scrollTo(0, 0);
            });
        });

    }
    handleCancelOfficeRelation = (event, caller) => {

        var { relation, officeRelation } = this.state;
        var props = Object.getOwnPropertyNames(officeRelation);
        for (var i = 0; i < props.length; i++) {
            if (typeof officeRelation[props[i]] === "string") {
                officeRelation[props[i]] = "";
            }
            else {
                officeRelation[props[i]] = undefined;
            }
        }
        this.setState({ officeRelation: officeRelation, isOfficeEditRelation: false }, () => {
            var newoffice = { Denomination: relation.Denomination, Alias: relation.Alias, Vatnumber: relation.Vatnumber, FiscalNumber: relation.FiscalNumber };
            this.setState({ officeRelation: newoffice }, () => {
                window.scrollTo(0, 0);
            });
        });

    }
    doSaveOffice = (callback) => {

        var { data, office } = this.state;
        var FIDIdentity = this.state.FIDIdentity;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }

        if (office.TypologyIds && typeof office.TypologyIds === "string") {
            office.TypologyIds = office.TypologyIds.split(",").map(Number);
        }
        if (office.CategoryIds && typeof office.CategoryIds === "string") {
            office.CategoryIds = office.CategoryIds.split(",").map(Number);
        }
        if (office.DistributionListIds && typeof office.DistributionListIds === "string") {
            office.DistributionListIds = office.DistributionListIds.split(",").map(Number);
        }


        fetch('/IR/SaveDestination/' + FIDIdentity, {
            body: JSON.stringify(office),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                var props = Object.getOwnPropertyNames(office);
                for (var i = 0; i < props.length; i++) {
                    if (typeof office[props[i]] === "string")
                        office[props[i]] = "";
                    else
                        office[props[i]] = undefined;
                }
                this.setState({ office: office, isOfficeEdit: false }, () => {
                    var newoffice = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, FiscalNumber: data.FiscalNumber };
                    this.setState({ office: newoffice }, () => {
                        //this.grdDestinations.current.refresh();
                        //this.destination.current.refresh();
                    });
                    window.scrollTo(0, 0);
                });
                if (callback) {
                    callback();
                }
            });
    }
    doSaveOfficeRelation = (callback) => {

        var { relation, officeRelation } = this.state;
        var FIDIdentity = relation.ID;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }

        if (officeRelation.TypologyIds && typeof officeRelation.TypologyIds === "string") {
            officeRelation.TypologyIds = officeRelation.TypologyIds.split(",").map(Number);
        }
        if (officeRelation.CategoryIds && typeof officeRelation.CategoryIds === "string") {
            officeRelation.CategoryIds = officeRelation.CategoryIds.split(",").map(Number);
        }
        if (officeRelation.DistributionListIds && typeof officeRelation.DistributionListIds === "string") {
            officeRelation.DistributionListIds = officeRelation.DistributionListIds.split(",").map(Number);
        }


        fetch('/IR/SaveDestination/' + FIDIdentity + '?isRelation=true', {
            body: JSON.stringify(officeRelation),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                var props = Object.getOwnPropertyNames(officeRelation);
                for (var i = 0; i < props.length; i++) {
                    if (typeof officeRelation[props[i]] === "string")
                        officeRelation[props[i]] = "";
                    else
                        officeRelation[props[i]] = undefined;
                }
                this.setState({ officeRelation: officeRelation, isOfficeEditRelation: false }, () => {
                    var newoffice = { Denomination: relation.Denomination, Alias: relation.Alias, Vatnumber: relation.Vatnumber, FiscalNumber: relation.FiscalNumber };
                    this.setState({ officeRelation: newoffice }, () => {
                        //this.grdDestinations.current.refresh();
                        //this.destination.current.refresh();
                    });
                    window.scrollTo(0, 0);
                });
                if (callback) {
                    callback();
                }
            });
    }
    handleSaveOffice = (event, caller, callback) => {
        if (this.destination.current) {
            var { isValid, requiredFields, optionalFields } = this.destination.current.validate();
            if (!this.state.office.FIDDestinationType) {
                isValid = false;
                requiredFields.unshift("FIDDestinationType");
            }
            if (!isValid) {
                this.showErrorMessage(requiredFields, optionalFields, () => {
                    this.doSaveOffice(callback);
                });
                return;
            }
            else {
                this.doSaveOffice(callback);
            }
        }
    }
    handleSaveOfficeRelation = (event, caller, callback) => {
        if (this.destinationRelation.current) {
            var { isValid, requiredFields, optionalFields } = this.destinationRelation.current.validate();

            if (!this.state.officeRelation.FIDDestinationType) {
                isValid = false;
                requiredFields.unshift("FIDDestinationType");
            }
            if (!isValid) {
                this.showErrorMessage(requiredFields, optionalFields, () => {
                    this.doSaveOfficeRelation(callback);
                });
                return;
            }
            else {
                this.doSaveOfficeRelation(callback);
            }
        }
    }
    handleOfficeDoubleClick = (event, data) => {
        this.setState({ office: data, isOfficeEdit:true }, () => {
            //this.grdDestinations.current.refresh();
            //this.destination.current.refresh();
        });
    }
    handleOfficeDoubleClickRelation = (event, data) => {
        this.setState({ officeRelation: data, isOfficeEditRelation: true }, () => {
            //this.grdDestinations.current.refresh();
            //this.destination.current.refresh();
        });
    }


    handleCancelRelation = (event, caller) => {

        var { relation } = this.state;
        var props = Object.getOwnPropertyNames(relation);
        for (var i = 0; i < props.length; i++) {
            if (typeof relation[props[i]] === "string") {
                relation[props[i]] = "";
            }
            else {
                relation[props[i]] = undefined;
            }
        }
        this.setState({ relation: relation, isRelationEdit: false, extratabsRelations: 0 }, () => {
            //var newoR = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, FiscalNumber: data.FiscalNumber };
            //this.setState({ office: newoffice }, () => {
            //});
        });
    }
    handleErrors = (response) => {
        if (!response.ok) {
            response.body.getReader().read().then(({ done, value }) => { console.log([done, value]); });
            throw Error(response);
        }
        return response;
    };
    doSaveRelation = (callback) => {
        var { relation } = this.state;
        var FIDIdentity = this.state.FIDIdentity;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        if (relation.TypologyIds && typeof relation.TypologyIds === "string") {
            relation.TypologyIds = relation.TypologyIds.split(",").map(Number);
        }
        if (relation.CategoryIds && typeof relation.CategoryIds === "string") {
            relation.CategoryIds = relation.CategoryIds.split(",").map(Number);
        }
        if (relation.DistributionListIds && typeof relation.DistributionListIds === "string") {
            relation.DistributionListIds = relation.DistributionListIds.split(",").map(Number);
        }
        relation.TSUPD = moment();



        fetch('/IR/SaveRelation/' + FIDIdentity, {
            body: JSON.stringify(relation),
            method: 'POST'
        }).then(this.handleErrors).then(res => res.json())
            .then(result => {
                var props = Object.getOwnPropertyNames(relation);
                for (var i = 0; i < props.length; i++) {

                    if (typeof relation[props[i]] === "string")
                        relation[props[i]] = "";
                    else
                        relation[props[i]] = undefined;
                }
                this.setState({ relation: relation, isRelationEdit: false, relationTab: 0 }, () => {
                    //var newoffice = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, FiscalNumber: data.FiscalNumber };
                    //this.setState({ office: newoffice }, () => {
                    //this.grdRelations.current.refresh();
                    //this.relations.current.refresh();
                    //});
                });
                if (callback) {
                    callback();
                }
            }).catch(result => {
                console.log(result);
            });
    }
    handleSaveRelation = (event, caller, callback) => {

        if ((!this.state.relationTab || this.state.relationTab == 0) && this.relation.current) {
            var { isValid, requiredFields, optionalFields } = this.relation.current.validate();
            if (!this.state.relation.FIDRelation) {
                isValid = false;
                requiredFields.unshift("FIDRelation");
            }
            if (!isValid) {
                this.showErrorMessage(requiredFields, optionalFields, () => {
                    this.doSaveRelation(callback);
                });
                return;
            }
            else {
                this.doSaveRelation(callback);
            }
        }
        else {
            this.doSaveRelation(callback);
        }

    }
    handleSaveData = (event, caller, callback) => {
        //this.setState({ isLoading: true }, () => {
        if (this.state.isOfficeEdit) {
            this.handleSaveOffice(event, caller, callback);
        }
        else if (this.state.isRelationEdit) {
            this.handleSaveRelation(event, caller, callback);
        }
        else {
            var dataIsValid = true;

            if (this.standardFields.current) {
                var { isValid, requiredFields, optionalFields } = this.standardFields.current.validate();
                if (!isValid) {
                    this.showErrorMessage(requiredFields, optionalFields, () => {
                        this.doSaveData(callback);
                    });
                    this.tabs.current.goToTab(0);
                    return;
                }
            }


            if (dataIsValid) {
                this.doSaveData(callback);
            }
            else {
                this.tabs.current.goToTab(0);
            }
        }
        //});
    }
    doSaveData = (callback) => {
        var currentTab = this.tabs.current.getCurrentTab();
        this.setState({ isLoading: true }, () => {
            var { data } = this.state;
            var FIDIdentity = this.state.FIDIdentity;
            if (!FIDIdentity) {
                FIDIdentity = 0;
            }


            if (data.TypologyIds && typeof data.TypologyIds === "string") {
                data.TypologyIds = data.TypologyIds.split(",").map(Number);
            }
            if (data.CategoryIds && typeof data.CategoryIds === "string") {
                data.CategoryIds = data.CategoryIds.split(",").map(Number);
            }
            if (data.DistributionListIds && typeof data.DistributionListIds === "string") {
                data.DistributionListIds = data.DistributionListIds.split(",").map(Number);
            }

            fetch('/IR/SaveIR/' + FIDIdentity, {
                body: JSON.stringify(data),
                method: 'POST'
            }).then(this.handleErrors).then(res => res.json()).then((data) => {
                this.props.setTitle(data.Denomination);
                if (this.state.FIDIdentity === undefined || parseInt(this.state.FIDIdentity, 10) === 0) {
                    if (callback) {
                        callback(data);
                    }
                    if (!this.props.noRedirect) {
                        this.context.router.history.push('/IREdit/' + data.ID);
                    }
                }
                this.setState({ firstLoad: true }, () => {
                    this.loadData();
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                        if (callback) {
                            callback(data);
                        }
                    });
                });
                
            })
                .catch(error => {
                    console.log(error);
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                    });
                });
        });
    }
    handleCancelData = (event, caller) => {
        var FIDIdentity = this.state.FIDIdentity;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.setState({ dialog: {} });

                    this.props.setTitle(undefined);
                    fetch('/IR/DeleteIR', {
                        method: 'POST',
                        body: JSON.stringify([FIDIdentity])
                    }).then(res => {
                        this.context.router.history.push('/IR');
                    });
                }
            },
            {
                label: "NO",
                action: () => {
                    this.setState({ dialog: {} });
                }
            },
        ];

        this.showDialog(buttons, "Cancellazione", "Confermi la cancellazione dell'anagrafica?");


    }

    doNewData = () => {
        if (this.context.router.route.location.pathname === '/IRNew') {
            fetch('/IR/ClearSession', {
                method: 'POST'
            }).then(res => {
                this.setState({ data: {} });
            });
        }
        else {
            this.clearSession(() => {
                this.props.setTitle(undefined);
                this.context.router.history.push('/IRNew');
            });
        }
    }

    checkForChanges = (event, caller,callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
        fetch('/IR/HasChanges/', {
            method: 'POST'
        }).then(res => res.json())
            .then(data => {
                if (data.HasChanges || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
                    var buttons = [
                        {
                            label: "SI",
                            action: () => {
                                this.setState({ dialog: {} });
                                callbackYes();
                            }
                        },
                        {
                            label: "NO",
                            action: () => {
                                this.setState({ dialog: {} });
                                callbackNo();
                            }
                        },
                        {
                            label: "ANNULLA",
                            action: () => {
                                this.setState({ dialog: {} });
                                callbackCancel();
                            }
                        },
                    ];

                    this.showDialog(buttons, "Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?");
                }
                else {
                    callbackNoChanges();
                }
            });
    }

    handleNewData = (event, caller) => {
        this.checkForChanges(event, caller,
            this.doNewData,
            () => {
                if (this.state.isOfficeEdit && this.state.isRelationEdit) {
                    this.handleSaveData(event, this, () => {
                        this.handleSaveData(event, this, () => {
                            this.handleSaveData(event, this, () => {
                                this.doNewData();
                            });
                        });
                    });
                }
                else if (!this.state.isOfficeEdit && !this.state.isRelationEdit) {
                    this.handleSaveData(event, this, () => {
                        this.handleSaveData(event, this, () => {
                            this.doNewData();
                        });
                    });
                }
                else {
                    this.handleSaveData(event, this, () => {
                        this.doNewData();
                    });
                }
            }, this.doNewData,
            () => {
            }
        );
    }
    handleRelationDoubleClick = (event, data) => {
        var FIDIdentity = this.state.FIDIdentity;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        fetch('/IR/GetIRRelation/' + data.ID + '?FIDIdentity=' + FIDIdentity).then(res => res.json()).then(relation => {
            this.setState({ relation, isRelationEdit: true }, () => {
                //this.grdDestinations.current.refresh();
                //this.relation.current.refresh();
                this.loadExtrasRelation();
            });
        });
    }
    handleOfficeNew = (event) => {
        this.setState(state => {
            var { data } = state;
            var office = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, Taxcode: data.Taxcode, FIDGroup: data.FIDGroup, TypologyIds: data.TypologyIds, CategoryIds: data.CategoryIds, Name: data.Name, Surname: data.Surname };
            state.office = office;
            state.isOfficeEdit = true;
            return state;
        });
        //this.setState({ isOfficeEdit: true });
    }
    handleOfficeNewRelation = (event) => {
        this.setState(state => {
            var { relation } = state;
            var office = { Denomination: relation.Denomination, Alias: relation.Alias, Vatnumber: relation.Vatnumber, Taxcode: relation.Taxcode, FIDGroup: relation.FIDGroup, TypologyIds: relation.TypologyIds, CategoryIds: relation.CategoryIds, Name: relation.Name, Surname: relation.Surname };
            state.officeRelation = office;
            state.isOfficeEditRelation = true;
            return state;
        });
        //this.setState({ isOfficeEdit: true });
    }
    handleRelationNew = (event) => {
        this.setState({ isRelationEdit: true });
    }
    handleDataChange = (event, value, key, data) => {
        this.setState((state, props) => {
            state.data[key] = value;

            state.data = this.geographicAreaFix(data, state.data, key);
            //if (key === "Denomination" && !state.data["Alias"]) {
            //    state.data["Alias"] == value;
            //}
            return state;
        }, () => {
            if (["FIDGroup", "TypologyIds","CategoryIds"].includes(key)) {
                this.loadExtras();
            }
        });
    }

    geographicAreaFix = (data,db,key) => {

        if (["FIDZip", "FIDCity", "FIDProvince", "FIDRegion"].includes(key) && data && data.ID > 0) {
            db["FIDCountry"] = data.FIDCountry;
        }
        if (["FIDZip", "FIDCity", "FIDProvince"].includes(key) && data && data.ID > 0) {
            db["FIDRegion"] = data.FIDRegion;
        }
        if (["FIDZip", "FIDCity"].includes(key) && data && data.ID > 0) {
            db["FIDProvince"] = data.FIDProvince;
        }
        if (["FIDZip"].includes(key) && data && data.ID > 0) {
            db["FIDCity"] = data.FIDCity;
        }
        if (["FIDCity", "FIDProvince", "FIDRegion", "FIDCountry"].includes(key)) {
            if (db["FIDZip"] >= 0)
                db["FIDZip"] = undefined;
        }
        if (["FIDProvince", "FIDRegion", "FIDCountry"].includes(key)) {
            if (db["FIDCity"] >= 0)
                db["FIDCity"] = undefined;
        }
        if (["FIDRegion", "FIDCountry"].includes(key)) {
            if (db["FIDProvince"] >= 0)
                db["FIDProvince"] = undefined;
        }
        if (["FIDCountry"].includes(key)) {
            if (db["FIDRegion"] >= 0)
                db["FIDRegion"] = undefined;
        }
        return db;
    }

    handleDestinationChange = (event, value, key, data) => {
        this.setState((state, props) => {
            state.office[key] = value;
            state.office = this.geographicAreaFix(data, state.office, key);
            
            //if (key === "Denomination" && !state.data["Alias"]) {
            //    state.office["Alias"] == value;
            //}
            return state;
        });
    }
    handleDestinationChangeRelation = (event, value, key, data) => {
        this.setState((state, props) => {
            state.officeRelation[key] = value;
            state.officeRelation = this.geographicAreaFix(data, state.officeRelation, key);

            //if (key === "Denomination" && !state.data["Alias"]) {
            //    state.office["Alias"] == value;
            //}
            return state;
        });
    }
    handleRelationChange = (event, value, key, data) => {
        this.setState((state, props) => {
            state.relation[key] = value;

            state.relation = this.geographicAreaFix(data, state.relation, key);
            
            //if (key === "Denomination" && !state.data["Alias"]) {
            //    state.relation["Alias"] == value;
            //}
            return state;
        }, () => {
            if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
                this.loadExtrasRelation();
            }
        });
    }
    handleDataBlur = (event, key) => {
        if (key === "Denomination" && !this.state.data.Alias) {
            this.setState((state) => {
                state.data.Alias = state.data.Denomination;
                return state;
            });
        }
        if (key === "Vatnumber" && !this.state.data.Taxcode) {
            this.setState((state) => {
                state.data.Taxcode = state.data.Vatnumber;
                return state;
            });
        }
    }
    handleDestinationBlur = (event, key) => {
        if (key === "Denomination" && !this.state.office.Alias) {
            this.setState((state) => {
                state.office.Alias = state.office.Denomination;
                return state;
            });
        }
        if (key === "Vatnumber" && !this.state.office.Taxcode) {
            this.setState((state) => {
                state.office.Taxcode = state.office.Vatnumber;
                return state;
            });
        }
    }
    handleDestinationBlurRelation = (event, key) => {
        if (key === "Denomination" && !this.state.officeRelation.Alias) {
            this.setState((state) => {
                state.officeRelation.Alias = state.officeRelation.Denomination;
                return state;
            });
        }
        if (key === "Vatnumber" && !this.state.officeRelation.Taxcode) {
            this.setState((state) => {
                state.officeRelation.Taxcode = state.officeRelation.Vatnumber;
                return state;
            });
        }
    }
    handleRelationBlur = (event, key) => {
        if (key === "Denomination" && !this.state.relation.Alias) {
            this.setState((state) => {
                state.relation.Alias = state.relation.Denomination;
                return state;
            });
        }
        if (key === "Vatnumber" && !this.state.relation.Taxcode) {
            this.setState((state) => {
                state.relation.Taxcode = state.relation.Vatnumber;
                return state;
            });
        }

    }
    handleDestinationAddExistingClick = (event) => {
        this.setState({ isOfficeAddExisting: true });
    }
    handleDestinationAddExistingClickRelation = (event) => {
        this.setState({ isOfficeAddExistingRelation: true });
    }
    handleDestinationAddExistingCancelClick = () => {
        this.setState({ isOfficeAddExisting: false });
    }
    handleDestinationAddExistingCancelClickRelation = () => {
        this.setState({ isOfficeAddExistingRelation: false });
    }
    handleDestinationAddExistingSelectDoubleClick = (_, selectedData) => {
        var FIDIdentity = this.state.FIDIdentity;
        var data = [selectedData.ID];
        fetch('/IR/AddExistingDestination/' + FIDIdentity, {
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                this.setState({ isOfficeAddExisting: false });
            });
    }
    handleDestinationAddExistingSelectClick = (event) => {
        var data = this.grdExistingDestinations.current.getSelectedData();
        var FIDIdentity = this.state.FIDIdentity;
        fetch('/IR/AddExistingDestination/' + FIDIdentity, {
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                this.setState({ isOfficeAddExisting: false });
            });
    }
    handleDestinationAddExistingSelectDoubleClickRelation = (_, selectedData) => {
        var FIDIdentity = this.state.relation.ID;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        var data = [selectedData.ID];
        fetch('/IR/AddExistingDestination/' + FIDIdentity + '?isRelation=true', {
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                this.setState({ isOfficeAddExistingRelation: false });
            });
    }
    handleDestinationAddExistingSelectClickRelation = (event) => {
        var data = this.grdExistingDestinationsRelation.current.getSelectedData();
        var FIDIdentity = this.state.relation.ID;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        fetch('/IR/AddExistingDestination/' + FIDIdentity + '?isRelation=true', {
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                this.setState({ isOfficeAddExistingRelation: false });
            });
    }
    renderTab2 = () => {
        var { office, data } = this.state;
        var customActions = [(
            <Tooltip title="Aggiungi esistenti">
                <IconButton aria-label="Aggiungi esistenti" onClick={this.handleDestinationAddExistingClick} >
                    <HomePlus />
                </IconButton>
            </Tooltip>
        )];
        var existingCustomActions = [(
            <Tooltip title="Annulla">
                <IconButton aria-label="Annulla" onClick={this.handleDestinationAddExistingCancelClick} >
                    <Close />
                </IconButton>
            </Tooltip>
        )];
        var existingCustomSelectedActions = [(
            <Tooltip title="Seleziona">
                <IconButton aria-label="Seleziona" onClick={this.handleDestinationAddExistingSelectClick} >
                    <Check />
                </IconButton>
            </Tooltip>
        )];


        var destinationHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} sm={6}>
                <img alt="" src="" width="100%" />
            </Grid>
            <Grid item xs={12} sm={6} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={6}><XSelect field={{ Required: 1 }} label="Tipo indirizzo" id="FIDDestinationType" urlData="/IR/DestinationType" urlCreate="/IR/DestinationType" value={office.FIDDestinationType} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onDestinationTypeChange} /></Grid>
                    <Grid item xs={12} md={6}><XField label="Annotazione" id="Annotation" value={office.Annotation} caller={this} dataKey="office" onChange={this.onDestinationAnnotationChange} /></Grid>
                </Grid>
            </Grid>
        </Grid>);

        var panels = [];
        panels.push({
            icon: "people",
            label: "Tipo indirizzo",
            content: destinationHeadFields,
        });

        var customContent = undefined;
        var showColumnsSelector = true;
        var showFilterActivator = true;
        var onNewButton = this.handleOfficeNew;
        var label = undefined;
        var key = "tblIRDestinations";
        if (this.state.isOfficeEdit) {
            customContent = (
                <Fragment>
                    <XExpansionPanel panels={panels} />
                    <IRStandardFields ref={this.destination} data={office} dataKey="office" id={office.ID} caller={this} showCodes={false} isDestination={true} onChange={this.handleDestinationChange} onBlur={this.handleDestinationBlur} />
                </Fragment>
            );

            showColumnsSelector = false;
            showFilterActivator = false;
            customActions = [
                <Tooltip title="Indietro">
                    <IconButton aria-label="Indietro" onClick={this.handleCancelOffice} >
                        <ArrowLeft />
                    </IconButton>
                </Tooltip>,
                <Tooltip title="Salva">
                    <IconButton aria-label="Salva" onClick={this.handleSaveOffice} >
                        <Save />
                    </IconButton>
                </Tooltip>
            ];
            key = "frmIRDestinations";
            onNewButton = undefined;
            label = "Nuovo indirizzo"
        }

        var ui = <XGrid
            label={label}
            key={key}
            innerRef={this.grdDestinations}
            dataRoute={"/IR/GetIRDestinations/" + data.ID + "?grid=tblIRDestinations"}
            dataRouteColumns="/Base/GetColumns?grid=tblIRDestinations"
            dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRDestinations"
            dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRDestinations"
            onDoubleClick={this.handleOfficeDoubleClick}
            dataRouteDelete={"/IR/DeleteDestinations/" + data.ID}
            onNewButton={onNewButton}
            onEditButton={this.handleOfficeDoubleClick}
            customContent={customContent}
            actions={customActions}
            showColumnsSelector={showColumnsSelector}
            showFilterActivator={showFilterActivator}
        />;

        ui = this.state.isOfficeAddExisting
            ?
            (
                <XGrid
                    label="Seleziona anagrafiche esistenti"
                    key="tblIRExistingDestinations"
                    innerRef={this.grdExistingDestinations}
                    dataRoute={"/IR/GetIdentities/" + data.ID + "?grid=tblIRExistingDestinations&filterParam=D"}
                    dataRouteColumns="/Base/GetColumns?grid=tblIRExistingDestinations"
                    dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRExistingDestinations"
                    dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRExistingDestinations"
                    onDoubleClick={this.handleDestinationAddExistingSelectDoubleClick}
                    //dataRouteDelete={"/IR/DeleteDestinations/" + data.ID}
                    //onNewButton={this.handleOfficeNew}
                    //onEditButton={this.handleOfficeDoubleClick}
                    actions={existingCustomActions}
                    selectedActions={existingCustomSelectedActions}
                />
            ) : ui;

        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
    renderTabDestinationsRelation = () => {
        var { officeRelation, relation } = this.state;
        var customActions = [(
            <Tooltip title="Aggiungi esistenti">
                <IconButton aria-label="Aggiungi esistenti" onClick={this.handleDestinationAddExistingClickRelation} >
                    <HomePlus />
                </IconButton>
            </Tooltip>
        )];
        var existingCustomActions = [(
            <Tooltip title="Annulla">
                <IconButton aria-label="Annulla" onClick={this.handleDestinationAddExistingCancelClickRelation} >
                    <Close />
                </IconButton>
            </Tooltip>
        )];
        var existingCustomSelectedActions = [(
            <Tooltip title="Seleziona">
                <IconButton aria-label="Seleziona" onClick={this.handleDestinationAddExistingSelectClickRelation} >
                    <Check />
                </IconButton>
            </Tooltip>
        )];

        var destinationHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} sm={6}>
                <img alt="" src="" width="100%" />
            </Grid>
            <Grid item xs={12} sm={6} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={6}><XSelect field={{ Required: 1 }} label="Tipo indirizzo" id="FIDDestinationType" urlData="/IR/DestinationType" urlCreate="/IR/DestinationType" value={officeRelation.FIDDestinationType} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onDestinationRelationTypeChange} /></Grid>
                    <Grid item xs={12} md={6}><XField label="Annotazione" id="Annotation" value={officeRelation.Annotation} caller={this} dataKey="officeRelation" onChange={this.onDestinationRelationAnnotationChange} /></Grid>
                </Grid>
            </Grid>
        </Grid>);

        var panels = [];
        panels.push({
            icon: "people",
            label: "Tipo indirizzo",
            content: destinationHeadFields,
        });

        var customContent = undefined;
        var showColumnsSelector = true;
        var showFilterActivator = true;
        var onNewButton = this.handleOfficeNewRelation;
        var label = undefined;
        var key = "tblIRDestinationsRelation";
        if (this.state.isOfficeEditRelation) {
            customContent = (
                <Fragment>
                    <XExpansionPanel panels={panels} />
                    <IRStandardFields ref={this.destinationRelation} data={officeRelation} dataKey="officeRelation" id={officeRelation.ID} caller={this} showCodes={false} isDestination={true} onChange={this.handleDestinationChangeRelation} onBlur={this.handleDestinationBlurRelation} />
                </Fragment>
            );
            showColumnsSelector = false;
            showFilterActivator = false;
            customActions = [
                <Tooltip title="Indietro">
                    <IconButton aria-label="Indietro" onClick={this.handleCancelOfficeRelation} >
                        <ArrowLeft />
                    </IconButton>
                </Tooltip>,
                <Tooltip title="Salva">
                    <IconButton aria-label="Salva" onClick={this.handleSaveOfficeRelation} >
                        <Save />
                    </IconButton>
                </Tooltip>
            ];
            key = "frmIRDestinationsRelation";
            onNewButton = undefined;
            label = "Nuovo indirizzo"
        }

        var FIDIdentity = relation.ID;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }

        var ui = <XGrid
            label={label}
            key={key}
            innerRef={this.grdDestinationsRelation}
            dataRoute={"/IR/GetIRDestinations/" + FIDIdentity + "?grid=tblIRDestinations&isRelation=true"}
            dataRouteColumns="/Base/GetColumns?grid=tblIRDestinations"
            dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRDestinations"
            dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRDestinations"
            onDoubleClick={this.handleOfficeDoubleClickRelation}
            dataRouteDelete={"/IR/DeleteDestinations/" + FIDIdentity + "?isRelation=true"}
            onNewButton={onNewButton}
            onEditButton={this.handleOfficeDoubleClickRelation}
            customContent={customContent}
            actions={customActions}
            showColumnsSelector={showColumnsSelector}
            showFilterActivator={showFilterActivator}
        />;

        ui = this.state.isOfficeAddExistingRelation
            ?
            (
                <XGrid
                    label="Seleziona anagrafiche esistenti"
                    key="tblIRExistingDestinations"
                    innerRef={this.grdExistingDestinationsRelation}
                    dataRoute={"/IR/GetIdentities/" + FIDIdentity + "?grid=tblIRExistingDestinations&filterParam=D"}
                    dataRouteColumns="/Base/GetColumns?grid=tblIRExistingDestinations"
                    dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRExistingDestinations"
                    dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRExistingDestinations"
                    onDoubleClick={this.handleDestinationAddExistingSelectDoubleClick}
                    actions={existingCustomActions}
                    selectedActions={existingCustomSelectedActions}
                />
            ) : ui;

        return (
            <TabContainer padding={0}>
                {ui}
            </TabContainer>
        );
    }
    onAnnotationChange = (key) => (event,value) => {
        this.setState((state, props) => {
            state.relation.Annotation = value;
            return state;
        });
    }
    onRelationTypeChange = (key) => (event, data) => {
        if (data && data.length > 0) {
            this.setState((state, props) => {
                state.relation.FIDRelation = data[0].ID;
                return state;
            });
        }
        else {
            this.setState(state => {
                state.relation.FIDRelation = undefined;
                return state;
            });
        }
    }

    onDestinationTypeChange = (key) => (event, data) => {
        if (data && data.length > 0) {
            this.setState((state, props) => {
                state.office.FIDDestinationType = data[0].ID;
                return state;
            });
        }
        else {
            this.setState(state => {
                state.office.FIDDestinationType = undefined;
                return state;
            });
        }
    }

    onDestinationAnnotationChange = (key) => (event, value) => {
        this.setState((state, props) => {
            state.office.Annotation = value;
            return state;
        });
    }

    onDestinationRelationTypeChange = (key) => (event, data) => {
        if (data && data.length > 0) {
            this.setState((state, props) => {
                state.officeRelation.FIDDestinationType = data[0].ID;
                return state;
            });
        }
        else {
            this.setState(state => {
                state.officeRelation.FIDDestinationType = undefined;
                return state;
            });
        }
    }

    onDestinationRelationAnnotationChange = (key) => (event, value) => {
        this.setState((state, props) => {
            state.officeRelation.Annotation = value;
            return state;
        });
    }
    handleRelationAddExistingClick = () => {
        this.setState({ isRelationAddExisting: true, relationAddStep1: true, relationAddStep2: false, relation: {} });

    }
    handleRelationAddExistingCancelClick = () => {
        this.setState({ isRelationAddExisting: false, relationAddStep1: false, relationAddStep2: false, relation: {} });

    }
    handleRelationAddExistingNextClick = () => {
        this.setState({ relationAddStep1: false, relationAddStep2: true });
    }
    handleRelationAddExistingPreviousClick = () => {
        this.setState({ relationAddStep1: true, relationAddStep2: false });
    }


    handleRelationAddExistingSelectDoubleClick = (_, selectedData) => {
        var { relation } = this.state;
        var FIDIdentity = this.state.FIDIdentity;
        var data = [{
            FIDIdentityRelation: selectedData.ID,
            FIDRelation: relation.FIDRelation,
            Annotation: relation.Annotation
        }];
        fetch('/IR/AddExistingRelation/' + FIDIdentity, {
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                this.setState({ isRelationAddExisting: false, relationAddStep1: false, relationAddStep2: false, relation: {} });
            });
    }

    handleRelationAddExistingSelectClick = () => {
        var { relation } = this.state;
        var data = this.grdExistingRelations.current.getSelectedData();
        var FIDIdentity = this.state.FIDIdentity;

        data = data.map(item => {
            var relItem = {
                FIDIdentityRelation: item,
                FIDRelation: relation.FIDRelation,
                Annotation: relation.Annotation
            }
            return relItem;
        });

        fetch('/IR/AddExistingRelation/' + FIDIdentity , {
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                this.setState({ isRelationAddExisting: false, relationAddStep1: false, relationAddStep2: false, relation: {} });
            });

    }
    renderTab3 = () => {
        var { relation, data } = this.state;
        var customActions = [(
            <Tooltip title="Aggiungi esistenti">
                <IconButton aria-label="Aggiungi esistenti" onClick={this.handleRelationAddExistingClick} >
                    <AccountMultiplePlus />
                </IconButton>
            </Tooltip>
        )];
        var existingCustomActions = [(
            <Tooltip title="Annulla">
                <IconButton aria-label="Annulla" onClick={this.handleRelationAddExistingCancelClick} >
                    <Close />
                </IconButton>
            </Tooltip>
        )];
        var existingCustomSelectedActions = [(
            <Tooltip title="Seleziona">
                <IconButton aria-label="Seleziona" onClick={this.handleRelationAddExistingSelectClick} >
                    <Check />
                </IconButton>
            </Tooltip>
        )];
        if (this.state.relationAddStep1) {
            existingCustomActions.push((
                <Tooltip title="Avanti">
                    <IconButton aria-label="Avanti" onClick={this.handleRelationAddExistingNextClick} >
                        <ArrowRight />
                    </IconButton>
                </Tooltip>
            ));
        }
        else {
            existingCustomActions.push((
                <Tooltip title="Indietro">
                    <IconButton aria-label="Indietro" onClick={this.handleRelationAddExistingPreviousClick} >
                        <ArrowLeft />
                    </IconButton>
                </Tooltip>
            ));
        }

        var relationHeadFields = (<Grid container spacing={2} style={{width:"100%"}}>
            <Grid item xs={12} sm={6}>
                <img alt="" src="" width="100%" />
            </Grid>
            <Grid item xs={12} sm={6} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={6}><XSelect field={{Required:1}} label="Relazione" id="FIDRelation" urlData="/Base/GetIRRelations" urlCreate="/IR/createrelationtype" value={relation.FIDRelation} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onRelationTypeChange} /></Grid>
                    <Grid item xs={12} md={6}><XField label="Annotazione" id="Annotation" value={relation.Annotation} caller={this} dataKey="relation" onChange={this.onAnnotationChange} /></Grid>
                </Grid>
            </Grid>
        </Grid>);

        var panels = [];
        panels.push({
            icon: "people",
            label: "Relazione",
            content: relationHeadFields,
        });

        var tabs = undefined;
        var selectedTab = undefined;
        var onTabChange = undefined;

        var customContent = undefined;
        var showColumnsSelector = true;
        var showFilterActivator = true;
        var onNewButton = this.handleRelationNew;
        var label = undefined;
        var key = "tblIRRelations";
        if (this.state.isRelationEdit) {
            var { extratabsRelations, extragroupsRelation, extrasRelation } = this.state;
            showColumnsSelector = false;
            showFilterActivator = false;
            customActions = [
                <Tooltip title="Indietro">
                    <IconButton aria-label="Indietro" onClick={this.handleCancelRelation} >
                        <ArrowLeft />
                    </IconButton>
                </Tooltip>,
                <Tooltip title="Salva">
                    <IconButton aria-label="Salva" onClick={this.handleSaveRelation} >
                        <Save />
                    </IconButton>
                </Tooltip>
            ];
            key = "frmIRRelations";
            onNewButton = undefined;
            label = "Nuova relazione";

            tabs = [
                { tabName: 'Generale' },//0
                { tabName: 'Indirizzi' },//1
                { tabName: 'Files' },//2
                { tabName: 'Contratti' },//3
                { tabName: 'Profilo' },//4
            ];
            if (extratabsRelations) {
                var FIDTabs = extrasRelation.map(m => m.FIDExtraTab).filter((value, index, self) => self.indexOf(value) === index);
                tabs = tabs.concat(extratabsRelations.filter(f => FIDTabs.includes(f.ID)).map((item, index) => ({
                    tabName: item.Description,
                    //tabContent: this.renderExtraTab(item),
                })));
                var extrasNoTab = extrasRelation.filter(m => !m.FIDExtraTab);
                if (extrasNoTab.length > 0) {
                    tabs = tabs.concat({
                        tabName: 'CUSTOM',
                    });
                }
            }

            selectedTab = this.state.relationTab || 0;

            switch (selectedTab) {
                case 0:
                    customContent = (<Fragment>
                        <XExpansionPanel panels={panels} />
                        <IRStandardFields ref={this.relation} data={relation} dataKey="relation" id={relation.ID} caller={this} showCodes={false} isRelation={true} onChange={this.handleRelationChange} onBlur={this.handleRelationBlur} />
                    </Fragment>);
                    break;
                case 1:
                    customContent = this.renderTabDestinationsRelation();
                    break;
                case 2:
                    customContent = this.renderTabFilesRelation();
                    break;
                case 3:
                    customContent = this.renderTabGDPRRelation();
                    break;
                case 4:
                    customContent = <div></div>;
                    break;
                default:
                    var item = extratabsRelations.filter(f => FIDTabs.includes(f.ID))[selectedTab - 5];
                    customContent = this.renderExtraTabRelation(item);
                    break;
            }
    
    
            onTabChange = (_, value) => {
                if (!this.state.relationTab || this.state.relationTab === 0) {
                    if (this.relation.current) {
                        var { isValid, requiredFields, optionalFields } = this.relation.current.validate();
                        if (!this.state.relation.FIDRelation) {
                            isValid = false;
                            requiredFields.unshift("FIDRelation");
                        }
                        if (!isValid) {
                            this.showErrorMessage(requiredFields, optionalFields, () => {
                                this.setState({ relationTab: value });
                            });
                            return;
                        }
                        else {
                            this.setState({ relationTab: value });
                        }
                    }
                }
                else {
                    this.setState({ relationTab: value });
                }
            };
        }
        var ui = <XGrid
            key={key}
            innerRef={this.grdRelations}
            dataRoute={"/IR/GetIRRelations/" + data.ID + "?grid=tblIRRelations"}
            dataRouteColumns="/Base/GetColumns?grid=tblIRRelations"
            dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRRelations"
            dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRRelations"
            onDoubleClick={this.handleRelationDoubleClick}
            dataRouteDelete={"/IR/DeleteRelation/" + data.ID}
            onNewButton={onNewButton}
            onEditButton={this.handleRelationDoubleClick}
            actions={customActions}
            customContent={customContent}
            actions={customActions}
            showColumnsSelector={showColumnsSelector}
            showFilterActivator={showFilterActivator}
            tabs={tabs}
            selectedTab={selectedTab}
            onTabChange={onTabChange}
        />;
        if (this.state.isRelationAddExisting) {
            var customGrid = undefined;
            var label = "Seleziona anagrafiche esistenti";
            if (this.state.relationAddStep1) {
                customGrid = relationHeadFields;
                label = "Compila i dati e prosegui";
            }
            ui = <XGrid
                label={label}
                key="tblIRExistingRelations"
                innerRef={this.grdExistingRelations}
                dataRoute={"/IR/GetIdentities/" + data.ID + "?grid=tblIRExistingRelations&filterParam=R"}
                dataRouteColumns="/Base/GetColumns?grid=tblIRExistingRelations"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRExistingRelations"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRExistingRelations"
                onDoubleClick={this.handleRelationAddExistingSelectDoubleClick}
                actions={existingCustomActions}
                selectedActions={existingCustomSelectedActions.map((item, key) => item)}
                customContent={customGrid}
            />
            
        }

        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );

    }

    onFileSelectChange = (key) => (event, data) => {
        //alert(key);
        //alert(JSON.stringify(data));

        this.setState(state => {
            if (data.length > 0) {
                state.file[key] = data[0].ID;
            }
            else {
                state.file[key] = undefined;
            }
            return state;
        });
    }
    onFileRelationSelectChange = (key) => (event, data) => {
        //alert(key);
        //alert(JSON.stringify(data));

        this.setState(state => {
            if (data.length > 0) {
                state.fileRelation[key] = data[0].ID;
            }
            else {
                state.fileRelation[key] = undefined;
            }
            return state;
        });
    }
    onFileChange = (key) => (event, data) => {
        this.setState(state => {
            if (data) {
                state.file[key] = data;
            }
            else {
                state.file[key] = undefined;
            }
            return state;
        });
    }
    onFileRelationChange = (key) => (event, data) => {
        this.setState(state => {
            if (data) {
                state.fileRelation[key] = data;
            }
            else {
                state.fileRelation[key] = undefined;
            }
            return state;
        });
    }
    handleFileNew = () => {
        this.setState({ isFileEdit: true, file: {} });
    }
    handleFileRelationNew = () => {
        this.setState({ isFileEditRelation: true, fileRelation: {} });
    }
    handleCancelFile = () => {
        this.setState({ isFileEdit: false, file: {} });
    }
    handleCancelFileRelation = () => {
        this.setState({ isFileEditRelation: false, fileRelation: {} });
    }
    saveFile = () => {

        var { file } = this.state;

        var FIDIdentity = this.state.FIDIdentity;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }

        this.setState({ isUploading: true, uploadProgress: 0 });

        const data = new FormData();

        data.append('file', file.File, file.File.name);
        data.append('FIDFileTypology', file.FIDFileTypology);
        data.append('Default', file.Default ? true : false);
        axios
            .post('/IR/UploadFile/' + FIDIdentity, data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({ uploadProgress: (ProgressEvent.loaded / ProgressEvent.total * 100) });
                },
                cancelToken: new CancelToken(c => {
                    this.cancelUpload = c;
                })
            })
            .then(res => {
                this.setState({ isFileEdit: false, file: {}, isUploading: false });
                this.grdFiles.current.refresh();
            })
            .catch(err => {
                this.setState({ isUploading: false });
            });

    }
    saveFileRelation = () => {

        var { fileRelation,relation } = this.state;

        var FIDIdentity = relation.ID;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }

        this.setState({ isUploadingRelation: true, uploadProgressRelation: 0 });

        const data = new FormData();

        data.append('file', fileRelation.File, fileRelation.File.name);
        data.append('FIDFileTypology', fileRelation.FIDFileTypology);
        data.append('Default', fileRelation.Default ? true : false);
        axios
            .post('/IR/UploadFile/' + FIDIdentity + '?isRelation=true', data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({ uploadProgressRelation: (ProgressEvent.loaded / ProgressEvent.total * 100) });
                },
                cancelToken: new CancelToken(c => {
                    this.cancelUploadRelation = c;
                })
            })
            .then(res => {
                this.setState({ isFileEditRelation: false, fileRelation: {}, isUploadingRelation: false });
                this.grdFilesRelation.current.refresh();
            })
            .catch(err => {
                this.setState({ isUploadingRelation: false });
            });

    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }
    handleDownloadFile = () => {
        var FIDFIles = this.grdFiles.current.getSelectedData();
        for (var i = 0; i < FIDFIles.length; i++) {
            window.open("/IR/DownloadFile/" + FIDFIles[i]);
        }
        this.grdFiles.current.clearSelection();
    }
    handleDownloadAgreement = () => {
        var FIDAgreements = this.grdAgreements.current.getSelectedData();
        for (var i = 0; i < FIDAgreements.length; i++) {
            fetch("/IR/DownloadAgreement/" + FIDAgreements[i]).then(res => res.blob()).then(blob => {
                //blob.type = "application/pdf";
                window.open(window.URL.createObjectURL(blob));
            });
            //window.open("/IR/DownloadAgreement/" + FIDAgreements[i]);
        }
        this.grdAgreements.current.clearSelection();
    }
    handleDownloadAgreementRelation = () => {
        var FIDAgreements = this.grdAgreementsRelation.current.getSelectedData();
        for (var i = 0; i < FIDAgreements.length; i++) {
            fetch("/IR/DownloadAgreement/" + FIDAgreements[i]).then(res => res.blob()).then(blob => {
                //blob.type = "application/pdf";
                window.open(window.URL.createObjectURL(blob));
            });
            //window.open("/IR/DownloadAgreement/" + FIDAgreements[i]);
        }
        this.grdAgreementsRelation.current.clearSelection();
    }
    handledeleteAgreement = () => {
        var FIDAgreements = this.grdAgreements.current.getSelectedData();
        var FIDIdentity = this.state.FIDIdentity;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        fetch('/IR/DeleteAgreement/' + FIDIdentity, {
            method: 'POST',
            body: JSON.stringify(FIDAgreements)
        }).then(res => {
            //this.context.router.history.push('/IR');
            this.grdAgreements.current.refresh();
        });
        //for (var i = 0; i < FIDAgreements.length; i++) {
        //    //window.open("/IR/DownloadAgreement/" + FIDAgreements[i]);
        //}
        this.grdAgreements.current.clearSelection();
    }
    handledeleteAgreementRelation = () => {
        var FIDAgreements = this.grdAgreementsRelation.current.getSelectedData();
        var FIDIdentity = this.state.relation.ID;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        fetch('/IR/DeleteAgreement/' + FIDIdentity, {
            method: 'POST',
            body: JSON.stringify(FIDAgreements)
        }).then(res => {
            //this.context.router.history.push('/IR');
            this.grdAgreementsRelation.current.refresh();
        });
        //for (var i = 0; i < FIDAgreements.length; i++) {
        //    //window.open("/IR/DownloadAgreement/" + FIDAgreements[i]);
        //}
        this.grdAgreementsRelation.current.clearSelection();
    }
    handleSaveFile = () => {
        var { file } = this.state;
        if (file.File === {}) {
            return;
        }
        var FIDIdentity = this.state.FIDIdentity;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }

        if (file.Default) {
            var tempFile = {
                ID:file.ID,
                FIDFileTypology: file.FIDFileTypology
            };
            if (!tempFile.ID) {
                tempFile.ID = 0;
            }
            fetch('/IR/CheckDefaultFiles/' + FIDIdentity, {
                body: JSON.stringify(tempFile),
                method: 'POST'
            }).then(res => res.json())
                .then(result => {
                    if (result.HasDefault) {
                        var buttons = [
                            {
                                label: "SI",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    this.saveFile();
                                }
                            },
                            {
                                label: "NO",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    //this.saveContact(FIDIdentity, isDestination, isRelation, data);
                                }
                            },
                        ];

                        this.showDialog(buttons, "File predefinito", "È già presente un record predefinito con questa tipologia. Confermi inserimento?");
                    }
                    else {
                        this.saveFile();
                    }
                });
        }
        else {
            this.saveFile();
        }
        
    }
    handleSaveFileRelation = () => {
        var { fileRelation, relation } = this.state;
        if (fileRelation.File === {}) {
            return;
        }
        var FIDIdentity = relation.ID;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }

        if (fileRelation.Default) {
            var tempFile = {
                ID: fileRelation.ID,
                FIDFileTypology: fileRelation.FIDFileTypology
            };
            if (!tempFile.ID) {
                tempFile.ID = 0;
            }
            fetch('/IR/CheckDefaultFiles/' + FIDIdentity + '?isRelation=true', {
                body: JSON.stringify(tempFile),
                method: 'POST'
            }).then(res => res.json())
                .then(result => {
                    if (result.HasDefault) {
                        var buttons = [
                            {
                                label: "SI",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    this.saveFileRelation();
                                }
                            },
                            {
                                label: "NO",
                                action: () => {
                                    this.setStateRelation({ dialog: {} });
                                    //this.saveContact(FIDIdentity, isDestination, isRelation, data);
                                }
                            },
                        ];

                        this.showDialog(buttons, "File predefinito", "È già presente un record predefinito con questa tipologia. Confermi inserimento?");
                    }
                    else {
                        this.saveFileRelation();
                    }
                });
        }
        else {
            this.saveFileRelation();
        }

    }
    handleFileDoubleClick = (event, data) => {
        this.setState({ file: data, isFileEdit: true });
    };
    handleFileRelationDoubleClick = (event, data) => {
        this.setState({ fileRelation: data, isFileEditRelation: true });
    };

    renderTabFilesRelation = () => {
        const { classes } = this.props;
        var { fileRelation, relation, isUploadingRelation, uploadProgressRelation } = this.state;


        var fileHeadFields = (
            <Grid container spacing={2} style={{ width: "100%" }}>
                <Grid item xs={12} container>
                    <Grid item xs={12} container direction="row" spacing={2}>
                        <Grid item xs={12} md={3}><XSelect label="Tipologia" id="FIDFileTypology" urlData="/Base/GetFileTypologies" urlCreate="/Base/CreateFileTypology" value={fileRelation.FIDFileTypology} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onFileRelationSelectChange} /></Grid>
                        <Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={fileRelation.Default} onChange={this.onFileRelationChange} /></Grid>
                        <Grid item xs={12} md={3}><XUpload label="File" id="File" value={fileRelation.File} onChange={this.onFileRelationChange} /></Grid>
                    </Grid>
                </Grid>
            </Grid>
        );

        var panels = [];
        panels.push({
            icon: "attachment",
            label: "Files",
            content: fileHeadFields,
        });
        var customContent = undefined;

        var doubleClick = this.handleFileRelationDoubleClick;
        var newClick = this.handleFileRelationNew;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];
        var selectedActions = [(
            <Tooltip title="Salva">
                <IconButton aria-label="Salva" onClick={this.handleDownloadFileRelation} >
                    <Download />
                </IconButton>
            </Tooltip>
        ),];
        if (this.state.isFileEditRelation) {
            customContent = (<Fragment>
                <XExpansionPanel panels={panels} />
                {
                    //<XButton color="primary" onClick={this.handleCancelFile}><Icon>cancel</Icon> Annulla</XButton>
                    //<XButton color="primary" onClick={this.handleSaveFile}><Icon>done</Icon> Salva</XButton>
                }
            </Fragment>);
            doubleClick = undefined;
            newClick = undefined;
            showFilterActivator = false;
            showColumnSelector = false;
            selectedActions = [];
            customActions = [
                (
                    <Tooltip title="Salva">
                        <IconButton aria-label="Salva" onClick={this.handleSaveFileRelation} >
                            <Check />
                        </IconButton>
                    </Tooltip>
                ),
                (
                    <Tooltip title="Annulla">
                        <IconButton aria-label="Annulla" onClick={this.handleCancelFileRelation} >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )
            ];
        }
        var customCells = {
            Preview: (data, column) => {
                return <img style={{
                    height: "auto", width: "auto", maxHeight: "103px", maxWidth: column.width - 10
                }} src={"data:image/png;base64, " + data.base64file} />
            }
        };
        var ui = (
            <XGrid
                innerRef={this.grdFilesRelation}
                dataRoute={"/IR/GetIRFiles/" + relation.ID + "?grid=tblFiles&isRelation=true"}
                dataRouteColumns="/Base/GetColumns?grid=tblFiles"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblFiles"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblFiles"
                //onDoubleClick={doubleClick}
                dataRouteDelete={"/IR/DeleteFile/" + relation.ID + "?isRelation=true"}
                onNewButton={newClick}
                //onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
                selectedActions={selectedActions}
                customCells={customCells}
                rowHeight={105}
            />
        )

        return (
            <TabContainer padding={0}>
                <XProgress progress={uploadProgressRelation} total={100} label="Caricamento file in corso..." onCancel={() => {
                    if (this.cancelUploadRelation) {
                        this.cancelUploadRelation();
                        this.setState({ isUploadingRelation: false });
                    }
                }} cancelLabel="Annulla" show={isUploadingRelation} />
                {ui}
            </TabContainer>
        );
    }
    renderTab4 = () => {
        const { classes } = this.props;
        var { file, data, isUploading, uploadProgress } = this.state;


        var fileHeadFields = (
            <Grid container spacing={2} style={{ width: "100%" }}>
                <Grid item xs={12} container>
                    <Grid item xs={12} container direction="row" spacing={2}>
                        <Grid item xs={12} md={3}><XSelect label="Tipologia" id="FIDFileTypology" urlData="/Base/GetFileTypologies" urlCreate="/Base/CreateFileTypology" value={file.FIDFileTypology} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onFileSelectChange} /></Grid>
                        <Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={file.Default} onChange={this.onFileChange} /></Grid>
                        <Grid item xs={12} md={3}><XUpload label="File" id="File" value={file.File} onChange={this.onFileChange} /></Grid>
                    </Grid>
                </Grid>
            </Grid>
        );

        var panels = [];
        panels.push({
            icon: "attachment",
            label: "Files",
            content: fileHeadFields,
        });
        var customContent = undefined;

        var doubleClick = this.handleFileDoubleClick;
        var newClick = this.handleFileNew;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];
        var selectedActions = [(
            <Tooltip title="Salva">
                <IconButton aria-label="Salva" onClick={this.handleDownloadFile} >
                    <Download />
                </IconButton>
            </Tooltip>
        ),];
        if (this.state.isFileEdit) {
            customContent = (<Fragment>
                <XExpansionPanel panels={panels} />
                {
                    //<XButton color="primary" onClick={this.handleCancelFile}><Icon>cancel</Icon> Annulla</XButton>
                    //<XButton color="primary" onClick={this.handleSaveFile}><Icon>done</Icon> Salva</XButton>
                }
            </Fragment>);
            doubleClick = undefined;
            newClick = undefined;
            showFilterActivator = false;
            showColumnSelector = false;
            selectedActions = [];
            customActions = [
                (
                    <Tooltip title="Salva">
                        <IconButton aria-label="Salva" onClick={this.handleSaveFile} >
                            <Check />
                        </IconButton>
                    </Tooltip>
                ),
                (
                    <Tooltip title="Annulla">
                        <IconButton aria-label="Annulla" onClick={this.handleCancelFile} >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )
            ];
        }
        var customCells = {
            Preview: (data, column) => {
                return <img style={{
                    height: "auto", width: "auto", maxHeight: "103px", maxWidth: column.width - 10
                }} src={"data:image/png;base64, " + data.base64file} />
            }
        };
        var ui = (
            <XGrid
                innerRef={this.grdFiles}
                dataRoute={"/IR/GetIRFiles/" + data.ID + "?grid=tblFiles"}
                dataRouteColumns="/Base/GetColumns?grid=tblFiles"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblFiles"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblFiles"
                //onDoubleClick={doubleClick}
                dataRouteDelete={"/IR/DeleteFile/" + data.ID}
                onNewButton={newClick}
                //onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
                selectedActions={selectedActions}
                customCells={customCells}
                rowHeight={105}
            />
        )

        return (
            <TabContainer>
                <XProgress progress={uploadProgress} total={100} label="Caricamento file in corso..." onCancel={() => {
                    if (this.cancelUpload) {
                        this.cancelUpload();
                        this.setState({ isUploading: false });
                    }
                }} cancelLabel="Annulla" show={isUploading} />
                {ui}
            </TabContainer>
        );

    }
    renderTabGDPRRelation = () => {
        var { relation } = this.state;
        var selectedActions = [(
            <Tooltip title="Download">
                <IconButton aria-label="Download" onClick={this.handleDownloadAgreementRelation} >
                    <Download />
                </IconButton>
            </Tooltip>
        ), (
            <Tooltip title="Elimina">
                <IconButton aria-label="Elimina" onClick={this.handledeleteAgreementRelation} >
                    <TrashCan />
                </IconButton>
            </Tooltip>
        ),];
        var ui = (
            <XGrid
                innerRef={this.grdAgreementsRelation}
                dataRoute={"/IR/GetIRAgreements/" + relation.ID + "?grid=tblIRAgreements"}
                dataRouteColumns="/Base/GetColumns?grid=tblIRAgreements"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRAgreements"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRAgreements"
                //onDoubleClick={doubleClick}
                //dataRouteDelete={"/IR/DeleteFile/" + data.ID}
                //onNewButton={newClick}
                //onEditButton={doubleClick}
                //customContent={customContent}
                //showColumnsSelector={showColumnSelector}
                //showFilterActivator={showFilterActivator}
                //actions={customActions}
                selectedActions={selectedActions}
            //customCells={customCells}
            //rowHeight={105}
            />
        )
        return (
            <TabContainer padding={0}>
                {ui}
            </TabContainer>
        );
    }
    renderTabGDPR = () => {
        var { data } = this.state;
        var selectedActions = [(
            <Tooltip title="Download">
                <IconButton aria-label="Download" onClick={this.handleDownloadAgreement} >
                    <Download />
                </IconButton>
            </Tooltip>
        ), (
            <Tooltip title="Elimina">
                    <IconButton aria-label="Elimina" onClick={this.handledeleteAgreement} >
                    <TrashCan />
                </IconButton>
            </Tooltip>
        ),];
        var ui = (
            <XGrid
                innerRef={this.grdAgreements}
                dataRoute={"/IR/GetIRAgreements/" + data.ID + "?grid=tblIRAgreements"}
                dataRouteColumns="/Base/GetColumns?grid=tblIRAgreements"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRAgreements"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRAgreements"
                //onDoubleClick={doubleClick}
                //dataRouteDelete={"/IR/DeleteFile/" + data.ID}
                //onNewButton={newClick}
                //onEditButton={doubleClick}
                //customContent={customContent}
                //showColumnsSelector={showColumnSelector}
                //showFilterActivator={showFilterActivator}
                //actions={customActions}
                selectedActions={selectedActions}
                //customCells={customCells}
                //rowHeight={105}
            />
        )
        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
    renderTabProfile = () => {
        return (
            <TabContainer>
                <Profile id={this.state.FIDIdentity} />
            </TabContainer>
        );
    }
    renderTabSells = () => {
        return (
            <TabContainer>
                <IdentitySells id={this.state.FIDIdentity} width={this.props.width}/>
            </TabContainer>
        );
    }

    handleUserNew = () => {
        this.setState({ isUserEdit: true, user: {} });
    }
    handleUserCancel = () => {
        this.setState({ isUserEdit: false, user: {} });
        this.grdUsers.current.refresh();
    }

    handleUserSave = () => {
        var { user } = this.state;
        var error = false;
        var msgs = [];
        if (!user.PlatformIDs || !user.FIDUserType || !user.Email || !user.Username || !user.Password || !user.SecretQuestion || !user.SecretAnswer) {
            error = true;
            msgs.push(<Typography>Verificare e compilare tutti i campi obbligatori prima di procedere!</Typography>);
        }
        if (user.Password !== user.ConfirmPassword) {
            error = true;
            msgs.push(<Typography>La password di verifica non coincide con la password inserita!</Typography>);
        }
        if (user.PIN && user.PIN.length !== 8) {
            error = true;
            msgs.push(<Typography>Il PIN inserito non è valido!</Typography>);
        }
        if (user.PIN && user.PIN !== user.ConfirmPIN) {
            error = true;
            msgs.push(<Typography>Il PIN di verifica non coincide con il PIN inserito!</Typography>);
        }
        if (!error) {
            this.saveUser();
        }
        else {
            this.MessageBox.current.showMessageOk('Inserimento/Modifica utente', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
        }
                               
    }

    handleUserGridDoubleClick = (event, data) => {
        this.grdUsers.current.clearSelection();
        this.setState({ user: data, isUserEdit: true });
    }
    saveUser = () => {

        var { user } = this.state;

        var FIDIdentity = this.state.FIDIdentity;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }

        if (user.PlatformIDs && typeof user.PlatformIDs === "string") {
            user.PlatformIDs = user.PlatformIDs.split(",").map(Number);
        }
        if (user.DestinationIDs && typeof user.DestinationIDs === "string") {
            user.DestinationIDs = user.DestinationIDs.split(",").map(Number);
        }
        fetch('/IR/SaveUser/' + FIDIdentity, {
            body: JSON.stringify(user),
            method: 'POST'
        })
            .then(res => res.json()).then(data => {
                this.setState({ isUserEdit: false, user: {} });
                this.grdUsers.current.refresh();
            });

    }

    onUserSelectChange = (key) => (event, data) => {
        this.setState(state => {
            if (data.length > 0) {
                state.user[key] = data[0].ID;
            }
            else {
                state.user[key] = undefined;
            }
            return state;
        });

    }
    onUserChange = (key) => (event, data) => {
        this.setState(state => {
            if (data) {
                state.user[key] = data;
            }
            else {
                state.user[key] = undefined;
            }
            return state;
        });
    }
    onUserDataChange = (key) => (data) => {
        this.setState(state => {
            if (data) {
                state.user[key] = data;
            }
            else {
                state.user[key] = undefined;
            }
            return state;
        });
    }

    renderTabUsers = () => {
        var { user, data, isUserEdit } = this.state;
        var Datestart = user.Datestart;
        if (!Datestart) {
            Datestart = new Date();
        }
        var pwdError = user.Password !== user.ConfirmPassword;
        var pinCError = user.PIN !== user.ConfirmPIN;
        var pinError = false;
        if (user.PIN && user.PIN.length !== 8) {
            pinError = true;
        }

        var fields = [
            { Field: "PlatformIDs", Visibility: true, Required: 2 },
            { Field: "FIDUserType", Visibility: true, Required: 2 },
            { Field: "FIDTypology", Visibility: true, Required: 3 },
            { Field: "Email", Visibility: true, Required: 2 },
            { Field: "Username", Visibility: true, Required: 2 },
            { Field: "Password", Visibility: true, Required: 2 },
            { Field: "ConfirmPassword", Visibility: true, Required: 2 },
            { Field: "ChangePassword", Visibility: true, Required: 3 },
            { Field: "PIN", Visibility: true, Required: 2 },
            { Field: "ConfirmPIN", Visibility: true, Required: 2 },
            { Field: "ChangePIN", Visibility: true, Required: 3 },
            { Field: "SecretQuestion", Visibility: true, Required: 3 },
            { Field: "SecretAnswer", Visibility: true, Required: 3 },
            { Field: "Datestart", Visibility: true, Required: 2 },
            { Field: "Dateend", Visibility: true, Required: 3 },
            { Field: "DestinationIDs", Visibility: true, Required: 2 },
        ];

        console.log(user.Email);
        var fieldPlatformsID = getXTag(user, fields, 12, 3, "PlatformIDs", "Piattaforme", this.onUserChange, "/GC/platform");
        var fieldIdentity = getXTag(user, fields, 12, 3, "DestinationIDs", "Destinazione", this.onUserChange, "/IR/GetIRDestinationSelect", "",{ filters: [{ key: "FIDIdentity", value: data.ID }] });
        var fieldFIDUserType = getXSelect(user, fields, 12, 3, "FIDUserType", "Autorizzazione", this.onUserSelectChange, "/GC/usertype");
        var fieldFIDProfile = getXSelect(user, fields, 12, 3, "FIDTypology", "Profilo", this.onUserSelectChange, "/Base/GetIRTypologies");
        var fieldEmail = getXField(user, fields, 12, 3, "Email", "Email", this.onUserChange);
        var fieldUsername = getXField(user, fields, 12, 3, "Username", "Username", this.onUserChange);

        var userHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    {fieldIdentity}
                    {fieldPlatformsID}
                    {fieldFIDUserType}
                    {fieldFIDProfile}
                    {fieldEmail}
                    {fieldUsername}
                    <Grid item xs={12} md={6}></Grid>

                    <Grid item xs={12} md={4} xl={3}><XField field={{ Field: "Password", Visibility: true, Required: 2 }} label="Password" type="password" id="Password" value={user.Password} onChange={this.onUserChange} /></Grid>
                    <Grid item xs={12} md={4} xl={3}><XField field={{ Field: "ConfirmPassword", Visibility: true, Required: 2 }} label="Verifica password" type="password" id="ConfirmPassword" value={user.ConfirmPassword} onChange={this.onUserChange} hasError={pwdError} /></Grid>
                    <Grid item xs={12} md={4} xl={3}><XSwitch label="Cambio prossimo accesso" id="ChangePassword" value={user.ChangePassword} onChange={this.onUserChange} /></Grid>
                    <Grid item xs={12} md={12} xl={3}></Grid>

                    <Grid item xs={12} md={4} xl={3}><XField label="PIN" type="password" id="PIN" value={user.PIN} onChange={this.onUserChange} hasError={pinError} /></Grid>
                    <Grid item xs={12} md={4} xl={3}><XField label="Verifica PIN" type="password" id="ConfirmPIN" value={user.ConfirmPIN} onChange={this.onUserChange} hasError={pinCError} /></Grid>
                    <Grid item xs={12} md={4} xl={3}><XSwitch label="Cambio prossimo accesso" id="ChangePIN" value={user.ChangePIN} onChange={this.onUserChange} /></Grid>
                    <Grid item xs={12} md={12} xl={3}></Grid>

                    <Grid item xs={12} md={4}><XField field={{ Field: "SecretQuestion", Visibility: true, Required: 2 }} label="Domanda segreta" id="SecretQuestion" value={user.SecretQuestion} onChange={this.onUserChange} /></Grid>
                    <Grid item xs={12} md={4}><XField field={{ Field: "SecretAnswer", Visibility: true, Required: 2 }} label="Risposta" id="SecretAnswer" value={user.SecretAnswer} onChange={this.onUserChange} /></Grid>
                    <Grid item xs={12} md={2}><XDateField field={{ Field: "Datestart", Visibility: true, Required: 2 }} label="Data inizio attività" type="date" id="Datestart" value={Datestart} onChange={this.onUserDataChange} /></Grid>
                    <Grid item xs={12} md={2}><XDateField label="Data fine attività" type="date" id="Dateend" value={user.Dateend} onChange={this.onUserDataChange} /></Grid>
                </Grid>
            </Grid>
        </Grid>);
        var panels = [];
        panels.push({
            icon: "attachment",
            label: "Utenti",
            content: userHeadFields,
        });
        var customContent = undefined;

        var doubleClick = this.handleUserGridDoubleClick;
        var newClick = this.handleUserNew;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];
        if (isUserEdit) {
            customContent = (<XExpansionPanel panels={panels} />);
            doubleClick = undefined;
            newClick = undefined;
            showFilterActivator = false;
            showColumnSelector = false;
            customActions = [
                (
                    <Tooltip title="Salva">
                        <IconButton aria-label="Salva" onClick={this.handleUserSave} >
                            <Check />
                        </IconButton>
                    </Tooltip>
                ),
                (
                    <Tooltip title="Annulla">
                        <IconButton aria-label="Annulla" onClick={this.handleUserCancel} >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )
            ];
        }
        var ui = (
            <XGrid
                innerRef={this.grdUsers}
                dataRoute={"/IR/GetIRUsers/" + data.ID + "?grid=grdIRUsers"}
                dataRouteColumns="/Base/GetColumns?grid=grdIRUsers"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdIRUsers"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdIRUsers"
                onDoubleClick={doubleClick}
                dataRouteDelete={"/IR/DeleteUser/" + data.ID}
                onNewButton={newClick}
                onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
            />
        )

        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }

    doExit = () => {

        fetch('/IR/ClearSession', {
            method: 'POST'
        }).then(res => {
            this.props.setTitle(undefined);
            var url = '/IR/';
            if (this.isUsers()) {
                url = '/Users';
            }

            this.context.router.history.push(url);
        });
    }
    save = (event, callback) => {
        return new Promise(resolve => {
            if (this.state.isOfficeEdit && this.state.isRelationEdit) {
                this.handleSaveData(event, this, () => {
                    this.handleSaveData(event, this, () => {
                        this.handleSaveData(event, this, (data) => {
                            callback();
                            resolve(data);
                        });
                    });
                });
            }
            else if (!this.state.isOfficeEdit ^ !this.state.isRelationEdit) {
                this.handleSaveData(event, this, () => {
                    this.handleSaveData(event, this, (data) => {
                        callback();
                        resolve(data);
                    });
                });
            }
            else {
                this.handleSaveData(event, this, (data) => {
                    callback();
                    resolve(data);
                });
            }
        });
        
    }
    handleExit = (event) => {
        if (this.props.onExit) {
            this.props.onExit();
            return;
        }
        this.checkForChanges(event, this,
            this.doExit,
            () => {
                this.save(event,this.doExit);
            }, 
            this.doExit,
            () => { }
        );
    }
    handeExtraFieldChangeGeneric = (data, extras, FIDExtra, value) => {

        var extraField = extras.filter(f => f.ID === FIDExtra)[0];

        var extra = {
            BoolValue: null,
            ComboValue: null,
            DateTimeValue: null,
            ExtraID: FIDExtra,
            ExtraType: extraField.Type,
            GroupValue: null,
            IntValue: null,
            StringValue: null,
            TagValue: null
        };
        if (data.Extras) {
            var extraFieldVal = data.Extras.filter(f => f.ExtraID === FIDExtra);
            if (extraFieldVal.length > 0) {
                extra = extraFieldVal[0];
            }
        }
        var index = -1;
        if (data.Extras) {
            index = data.Extras.indexOf(extra);
        }
        else {
            data.Extras = [];
        }
        switch (extra.ExtraType) {
            case 0://string
                extra.StringValue = value;
                break;
            case 1://integer
                extra.IntValue = value;
                break;
            case 2://decimal
                extra.DecimalValue = value;
                break;
            case 3://datetime
                if (value) {
                    extra.DateTimeValue = value.toDate();
                }
                else {
                    extra.DateTimeValue = value;
                }
                break;
            case 4://boolean
                extra.BoolValue = value;
                break;
            case 5://combobox
                if (value.length > 0) {
                    extra.ComboValue = value[0].ID;
                }
                else {
                    extra.ComboValue = null;
                }
                break;
            case 6://tagbox
                extra.TagValue = value;
                break;
            case 7://component
                //extra.StringValue = value;
                break;
            case 8://componentcustomfields
                //extra.StringValue = value;
                break;
            default:
                break;
        }
        return { extra, index };
    }
    handleExtraFieldChange = (FIDExtra, isData) => (id) => (event, value) => {
        this.setState((state, props) => {
            var { data, extras } = state;
            var { extra, index } = this.handeExtraFieldChangeGeneric(data, extras, FIDExtra, value);
            if (index >= 0) {
                state.data.Extras[index] = extra;
            }
            else {
                if (!state.data.Extras) {
                    state.data.Extras = [];
                }
                state.data.Extras = state.data.Extras.concat(extra);
            }
            return state;
        });
    }
    handleExtraFieldChangeRelation = (FIDExtra, isData) => (id) => (event, value) => {
        this.setState((state, props) => {
            var { relation, extrasRelation } = state;
            var { extra, index } = this.handeExtraFieldChangeGeneric(relation, extrasRelation, FIDExtra, value);
            if (index >= 0) {
                state.relation.Extras[index] = extra;
            }
            else {
                if (!state.relation.Extras) {
                    state.relation.Extras = [];
                }
                state.relation.Extras = state.relation.Extras.concat(extra);
            }
            return state;
        });
    }
    renderExtraField = (field, index, saveUrl, loadGridUrl, loadDataUrl, deleteDataUrl) => {
        var { data } = this.state;
        var id = this.state.FIDIdentity;

        var extra = [];
        if (data.Extras) {
            extra = data.Extras.filter(f => f.ExtraID === field.ID);
        }
        return (<CustomField key={index} field={field} extra={extra} saveUrl={saveUrl} mainId={id} loadGridUrl={loadGridUrl} loadDataUrl={loadDataUrl} deleteDataUrl={deleteDataUrl} handleExtraFieldChange={this.handleExtraFieldChange} />);
    }
    renderExtraFieldRelation = (field, index, saveUrl, loadGridUrl, loadDataUrl, deleteDataUrl) => {
        var { relation } = this.state;
        var id = relation.ID;

        var extra = [];
        if (relation.Extras) {
            extra = relation.Extras.filter(f => f.ExtraID === field.ID);
        }
        return (<CustomField key={index} field={field} extra={extra} saveUrl={saveUrl} mainId={id} loadGridUrl={loadGridUrl} loadDataUrl={loadDataUrl} deleteDataUrl={deleteDataUrl} handleExtraFieldChange={this.handleExtraFieldChangeRelation} />);
    }
    renderExtraGroup = (tab, group) => {
        var { extras } = this.state;
        return (
            <Grid container spacing={2} >
                {extras.filter(f => ((tab && f.FIDExtraTab === tab.ID) || (!tab && !f.FIDExtraTab)) && ((group && f.FIDExtraGroup === group.ID) || (!group && !f.FIDExtraGroup))).map((item, index) => {
                    return this.renderExtraField(item, index, '/IR/SaveTableCF','/IR/GetIRTableCFData/','/IR/GetIRTableCFRecords/','/IR/DeleteIRTableCFRecords/');
                })}
            </Grid>
        );
    }
    renderExtraGroupRelation = (tab, group) => {
        var { extrasRelation } = this.state;
        return (
            <Grid container spacing={2} >
                {extrasRelation.filter(f => ((tab && f.FIDExtraTab === tab.ID) || (!tab && !f.FIDExtraTab)) && ((group && f.FIDExtraGroup === group.ID) || (!group && !f.FIDExtraGroup))).map((item, index) => {
                    return this.renderExtraFieldRelation(item, index, '/IR/SaveTableCF?isRelation=true', '/IR/GetIRTableCFData/?isRelation=true', '/IR/GetIRTableCFRecords/?isRelation=true', '/IR/DeleteIRTableCFRecords/?isRelation=true');
                })}
            </Grid>
        );
    }
    renderExtraTab = (tab) => {
        var { extragroups, extras } = this.state;
        var FIDGroups = extras.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && m.FIDExtraGroup).map(m => m.FIDExtraGroup).filter((value, index, self) => self.indexOf(value) === index);
        var panels = extragroups.filter(f => FIDGroups.includes(f.ID)).map((item, index) => (
            {
                icon: "people",
                label: item.Description,
                content: this.renderExtraGroup(tab, item),
            }
        ));

        var extrasNoGroup = extras.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && !m.FIDExtraGroup);
        if (extrasNoGroup.length > 0) {
            panels = panels.concat({
                icon: "people",
                label: 'Custom',
                content: this.renderExtraGroup(tab, null),
            });
        }

        return (<TabContainer><XExpansionPanel panels={panels} /></TabContainer>);
    };
    renderExtraTabRelation = (tab) => {
        var { extragroupsRelation, extrasRelation } = this.state;
        var FIDGroups = extrasRelation.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && m.FIDExtraGroup).map(m => m.FIDExtraGroup).filter((value, index, self) => self.indexOf(value) === index);
        var panels = extragroupsRelation.filter(f => FIDGroups.includes(f.ID)).map((item, index) => (
            {
                icon: "people",
                label: item.Description,
                content: this.renderExtraGroupRelation(tab, item),
            }
        ));

        var extrasNoGroup = extrasRelation.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && !m.FIDExtraGroup);
        if (extrasNoGroup.length > 0) {
            panels = panels.concat({
                icon: "people",
                label: 'Custom',
                content: this.renderExtraGroupRelation(tab, null),
            });
        }

        return (<TabContainer padding={0}><XExpansionPanel panels={panels} /></TabContainer>);
    };
    handleClose = value => {
        this.setState({ showMessage: false });
    }
    isUsers = () => {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var Users = url.searchParams.get('Users');
        return Users;
    }
    render() {
        if (this.state.isLoading) {
            //return (<div>Loading...</div>);

            //return <XOXLoader />
            return <XGrid usePrimaryColor={true}
                customContent={<XOXLoader />}
            />;
        }
        const { classes,headerColor, showSave, showNew,showDelete,showExit, topMargin } = this.props;
        const {  extratabs, extras, isUploading, uploadProgress } = this.state;
        //var id = this.state.FIDIdentity;

        var loctabs = [
            {
                tabName: "Generale",
                tabContent: this.renderTab1(),

            },
            {
                tabName: "Indirizzi",
                tabContent: this.renderTab2(),
            },
            {
                tabName: "Relazioni",
                tabContent: this.renderTab3(),
            },
            {
                tabName: "Files",
                tabContent: this.renderTab4(),
            },
            {
                tabName: "Contratti",
                tabContent: this.renderTabGDPR(),
            },
            {
                tabName: "Utenti",
                tabContent: this.renderTabUsers(),
            },
            {
                tabName: "Profilo",
                tabContent: this.renderTabProfile()
            }
        ];
        if (extratabs) {
            var FIDTabs = extras.map(m => m.FIDExtraTab).filter((value, index, self) => self.indexOf(value) === index);
            loctabs = loctabs.concat(extratabs.filter(f => FIDTabs.includes(f.ID)).map((item, index) => ({
                tabName: item.Description,
                tabContent: this.renderExtraTab(item),
            })));
            var extrasNoTab = extras.filter(m => !m.FIDExtraTab);
            if (extrasNoTab.length > 0) {
                loctabs = loctabs.concat({
                    tabName: 'CUSTOM',
                    tabContent: this.renderExtraTab(null)
                });
            }
        }
        if (this.props.userProfile) {
            loctabs = [loctabs[0]];
            //extratabs = [];
        }
        var initialTab = undefined;

        var Users = this.isUsers();
        if (Users) {
            initialTab = 5;
        }
        return (
            <div className={classes.root}>
                
                <Tabs
                    innerRef={this.tabs}
                    headerColor={headerColor}
                    plainTabs={true}
                    onExitClick={showExit && this.handleExit}
                    onSaveClick={showSave && !this.props.userProfile && this.handleSaveData}
                    onDeleteClick={showDelete && !this.props.userProfile && this.handleCancelData}
                    onNewClick={showNew && !this.props.userProfile && this.handleNewData}
                    validateTab={this.validate}
                    tabs={loctabs}
                    topMargin={topMargin}
                    isSecondary={this.props.isSecondary}
                    initialTab={initialTab}
                />
                <SimpleDialogWrapped message={this.state.errorMessage} title="Alcuni campi non sono stati compilati." open={this.state.showMessage} onClose={this.handleClose} />
                <XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
                <XMessageBox ref={this.MessageBox} />
            </div>
        );
    }
}

IREdit.propTypes = {
    classes: PropTypes.object.isRequired,
};
IREdit.defaultProps = {
    headerColor: "primary",
    showExit: true,
    showSave: true,
    showDelete: true,
    showNew: true,
    noRedirect: false,
    topMargin: "72.5px",
}

const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);

export default enhance(IREdit);
