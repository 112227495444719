import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
});

class CartOffOutline extends Component {
    render() {
        return <SvgIcon {...this.props}>
            <path d="M7,18c-1.11,0-2,0.891-2,2c0,1.1,0.89,2,2,2c1.104,0,2-0.896,2-2S8.104,18,7,18z"/>
<path d="M17,18c-1.109,0-2,0.891-2,2c0,1.1,0.891,2,2,2c1.104,0,2-0.896,2-2S18.104,18,17,18z"/>
<path d="M18.779,6L16,11h-2.386l1.936,1.934c0.75,0,1.41-0.41,1.75-1.029l3.58-6.471c0.073-0.147,0.109-0.302,0.115-0.458
	C20.981,4.435,20.544,4,20,4H6.606l2.002,2H18.779z"/>
<path d="M22.73,22.73L1.27,1.271L0,2.541l4.39,4.39l2.21,4.66l-1.35,2.451C5.09,14.32,5,14.65,5,15c0,1.105,0.896,2,2,2h7.46
	l1.38,1.381C15.34,18.74,15,19.33,15,20c0,1.105,0.896,2,2,2c0.67,0,1.26-0.33,1.62-0.84L21.46,24L22.73,22.73 M7.42,15
	c-0.138,0-0.25-0.111-0.25-0.25l0.03-0.119L8.1,13h2.36l2,2H7.42 M7,18c-1.104,0-2,0.896-2,2c0,1.105,0.896,2,2,2s2-0.895,2-2
	C9,18.896,8.104,18,7,18z"/>
            </SvgIcon>
    }
}

export default withStyles(style)(CartOffOutline);