import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import { DateTimePicker  } from "material-ui-pickers";

import styles from "assets/jss/material-dashboard-pro-react/customTextFieldStyle.jsx"


class XDateTimeField extends Component {

    handleChange = (value) => {
        var { id, onChange } = this.props;

        if (onChange) {
            onChange(id)(value);
        }
        //var { id, caller, dataKey } = this.props;
        //var data = caller.state[dataKey];
        //data[id] = event.target.value;
        //caller.setState((state, props) => {
        //    state[dataKey] = data;
        //    return state;
        //});
        //caller.setState({ data });
    }
    handleBlur = (event) => {
        var { id,onBlur } = this.props;
        if (onBlur) {
            onBlur(id)(event);
        }
    }
    render() {
        var { classes, id, value, label, field, style } = this.props;

        if (value === undefined || value === null) {
            value = "";
        }
        //var error = false;
        var errorClass = classes.error;
        if (field) {
            if ((field.Required === 2 || (field.Required === 1)) && !value) {
                //error = true;
                if (field.Required === 1) {
                    errorClass = classes.errorOptional;
                }
            }
        }

        return (
            <DateTimePicker
                clearable
                keyboard
                ampm={false}
                id={id}
                name={id}
                label={label}
                value={value}
                onChange={this.handleChange}
                format="DD/MM/YYYY HH:mm"
                style={style}
                InputLabelProps={{
                    shrink: true,
                    classes: {
                        root: classes.textFieldLabel,
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.textFieldInput,
                        error: errorClass
                    }
                }}
                onBlur={this.handleBlur}
                className={classes.textField}
                //mask={value =>
                //    value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                //}
            />
        );
    }
}
XDateTimeField.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
};
export default withStyles(styles)(XDateTimeField);