import React, { Component } from 'react';

import PropTypes from 'prop-types';
import XGrid from '../../components/XGrid';
import XSelect from '../../components/XSelect';
import XSwitch from '../../components/XSwitch';
import XField from '../../components/XField';
import XDialog from '../../components/XDialog';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { ArrowLeft } from 'mdi-material-ui'
import { Save } from '@material-ui/icons';
import { Tooltip, IconButton } from '@material-ui/core';

const contactStyles = theme => ({
    root: {
        width: "100%",
    },
    button: {
        marginTop: 16,
        marginBottom: 14,
    }
});
class IRContacts extends Component {
    constructor(props) {
        super(props);
        this.grid = React.createRef();

        this.handleTypologyChange.bind(this);
    }
    state = {
        data: {
            FIDContactTypology: null,
            Value: "",
            Notes: "",
            Default: false,
        },
        isEditing: false,
        contactLabel: 'Recapito',
        dialog: {},
    };
    handleTypologyChange = (key) => (event, data) => {
        if (data) {
            //this.setState({ contactLabel: data[0].Denomination });
            this.setState((state, props) => {
                var newData = state.data;
                if (data.length > 0) {
                    newData.FIDContactTypology = data[0].ID;
                    return { data: newData, contactLabel: data[0].Denomination };
                }
                else {

                    newData.FIDContactTypology = undefined;
                    this.setState({ contactLabel: 'Recapito' });
                }
                
            });
        }
        else {
            this.setState({ contactLabel: 'Recapito' });
        }
    }


    showDialog = (buttons,title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }

    saveContact = (FIDIdentity, isDestination, isRelation, data) => {
        fetch('/IR/SaveContact/' + FIDIdentity + (isDestination ? "?IsDestination=true" : "") + (isRelation ? "?IsRelation=true" : ""), {
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                var props = Object.getOwnPropertyNames(data);
                for (var i = 0; i < props.length; i++) {
                    if (typeof data[props[i]] === "string")
                        data[props[i]] = "";
                    else
                        data[props[i]] = undefined;
                }
                this.setState({ data: data, contactLabel: 'Recapito', isEditing: false });
                //this.grid.current.refresh();
            });
    }

    handleSaveContact = (event, caller) => {
        var { data } = this.state;
        var { FIDIdentity, isDestination, isRelation } = this.props;
        if (data.FIDContactTypology && data.Value) {

            if (data.Default) {
                fetch('/IR/CheckDefaultContact/' + FIDIdentity + (isDestination ? "?IsDestination=true" : "") + (isRelation ? "?IsRelation=true" : ""), {
                    body: JSON.stringify(data),
                    method: 'POST'
                }).then(res => res.json())
                    .then(result => {
                        if (result.HasDefault) {
                            //ask
                            var buttons = [
                                {
                                    label: "SI",
                                    action: () => {
                                        this.setState({ dialog: {} });
                                        this.saveContact(FIDIdentity, isDestination, isRelation, data);
                                    }
                                },
                                {
                                    label: "NO",
                                    action: () => {
                                        this.setState({ dialog: {} });
                                        //this.saveContact(FIDIdentity, isDestination, isRelation, data);
                                    }
                                },
                            ];

                            this.showDialog(buttons, "Contatto predefinito", "È già presente un record predefinito con questa tipologia. Confermi inserimento?");
                        }
                        else {
                            this.saveContact(FIDIdentity, isDestination, isRelation, data);
                        }
                    });
            }
            else {
                this.saveContact(FIDIdentity, isDestination, isRelation, data);
            }
            

            
        }
    }

    handleFieldChange = (key) => (event, value) => {
        //this.props.onChange(event, value, key);
        this.setState((state, props) => {
            state.data[key] = value;
            return state;
        });
    }
    handleGridDoubleClick = (event, data) => {
        this.setState({ data, isEditing: true });
    }
    handleGridEditButton = (event, data) => {
        this.handleGridDoubleClick(event, data);
    }
    handleCancelContact = (event) => {
        this.setState({ isEditing: false, data: {} });
    }
    handleGridNewButton = (event) => {
        this.setState({ isEditing: true });
    }
    refresh = () => {
        this.grid.current.refresh();
    }
    render() {
        var { data, contactLabel } = this.state;
        var { FIDIdentity, isDestination, isRelation } = this.props;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        var customActions = [];
        var style = { margin: '-28px', width: 'calc(100% + ' + (28 * 2) + 'px)' };
        var divStyle = { width: 'calc(100% - 16px)', padding: 16 };
        var customContent = undefined; var showColumnsSelector = true;
        var showFilterActivator = true;
        var onNewButton = this.handleGridNewButton;
        var key = "grdIRContacts";
        var label = undefined;
        if (this.state.isEditing) {
            customContent = <div style={divStyle}><Grid item container spacing={2} direction="row" sx={12}>
                <Grid item xs={12} sm={6} md={3}><XSelect label="Tipologia recapito" id="FIDContactTypology" urlData="/Base/GetIRContactTypologies" urlCreate="/IR/CreateContactTypology" value={data.FIDContactTypology} idKey="ID" valueKey="Denomination" onChange={this.handleTypologyChange} /></Grid>
                <Grid item xs={12} sm={6} md={3}><XField label={contactLabel} id="Value" value={data.Value} onChange={this.handleFieldChange} /></Grid>
                <Grid item xs={12} sm={6} md={3}><XField label="Annotazione" id="Notes" value={data.Notes} onChange={this.handleFieldChange} /></Grid>
                <Grid item xs={4} sm={2} md={1}><XSwitch label="Predefinito" id="Default" value={data.Default} onChange={this.handleFieldChange} /></Grid>
            </Grid></div>;

            key = "frmIRContact";
            showColumnsSelector = false;
            showFilterActivator = false;
            customActions = [
                <Tooltip title="Indietro">
                    <IconButton aria-label="Indietro" onClick={this.handleCancelContact}>
                        <ArrowLeft />
                    </IconButton>
                </Tooltip>,
                <Tooltip title="Salva">
                    <IconButton aria-label="Salva" onClick={this.handleSaveContact}>
                        <Save />
                    </IconButton>
                </Tooltip>
            ];
            onNewButton = undefined;
            label = "Nuovo recapito"
        }
        var ui = <Grid item xs={12}>
            <XGrid
                key={key}
                label={label}
                innerRef={this.grid}
                dataRoute={"/IR/GetIRContacts/" + FIDIdentity + "?grid=tblIRContacts" + (isDestination ? "&IsDestination=true" : "") + (isRelation ? "&IsRelation=true" : "")}
                dataRouteColumns="/Base/GetColumns?grid=tblIRContacts"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRContacts"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRContacts"
                dataRouteDelete={"/IR/DeleteContacts/" + FIDIdentity + "?grid=tblIRContacts" + (isDestination ? "&IsDestination=true" : "") + (isRelation ? "&IsRelation=true" : "")}
                onDoubleClick={this.handleGridDoubleClick}
                onEditButton={this.handleGridEditButton}
                onNewButton={onNewButton}
                customContent={customContent}
                actions={customActions}
                showColumnsSelector={showColumnsSelector}
                showFilterActivator={showFilterActivator}
            />
        </Grid>;

        return (
            <Grid container spacing={2} style={style}>
                {ui}  
                <XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
            </Grid>
        );
    }
}


IRContacts.propTypes = {
    classes: PropTypes.object.isRequired,
    FIDIdentity: PropTypes.number.isRequired,
    isIdentity: PropTypes.bool,
    isDestination: PropTypes.bool,
    isRelation: PropTypes.bool,
};


export default withStyles(contactStyles)(IRContacts);
