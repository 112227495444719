const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";
const CHECK_LOGIN = "CHECK_LOGIN";
const initialState = { isLoggedIn: false, isFetching: false, username: undefined , error: undefined};

export const actionCreators = {
    login: (username, password) => {
        return { type: LOGIN, username: username, password: password }
    },
    logout: () => ({ type: LOGOUT }),
    loginSuccess: (username, password, json) => {
        return { type: LOGIN_SUCCESS, username, password, data: json }
    },
    loginFailure: (username, password, json) =>  {
        return { type: LOGIN_FAILURE, username, password, data: json }
    },
    checkLogin: () => ({ type: CHECK_LOGIN }),
}

export const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = document.cookie;//decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const setCookie = (cname, cvalue) => {
    var d = new Date();
    d.setTime(d.getTime() + (60 * 60 * 1000));
    document.cookie = cname + "=" + cvalue + ";expires=" + d.toUTCString() + ";path=/";
}

export const deleteCookie = (cname) => {
    var d = new Date();
    d.setTime(d.getTime() - (1000 * 24 * 60 * 60 * 1000));
    document.cookie = cname + "=;expires=" + d.toUTCString() + ";path=/";
}
const doLogin = async (action) => {
    var result = await fetch('/Base/Login', {
        method: 'POST',
        body: JSON.stringify({ Username: action.username, Password: action.password })
    }).then(res => res.json())
    return result;
}
export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === LOGIN) {
        //var result = doLogin(action);
        //console.log(result);
        //if (result.success) {
        //    return { ...state, isLoggedIn: true, username: action.username, error: undefined };
        //}
        //else {
        //    return { ...state, isLoggedIn: false, error: result.errorMessage };
        //}


        if ((action.username === "ePlay" && action.password === "NYf6hq6tcpCGcS6sdzCt") || 
            (action.username.toLowerCase() === "danilo" && action.password === "xox") || 
            (action.username.toLowerCase() === "silvio" && action.password === "xox") || 
            (action.username.toLowerCase() === "marco" && action.password === "xox") ||
            (action.username.toLowerCase() === "mhendra" && action.password === "jaipur2018") ||
            (action.username.toLowerCase() === "pushpu" && action.password === "Pushpu1234") ||
            (action.username.toLowerCase() === "milvia" && action.password === "Mikymill97") ||
            (action.username.toLowerCase() === "zironi" && action.password === "mauro") ||
            (action.username.toLowerCase() === "ivan2" && action.password === "Miry89!!")
        ) {
            setCookie("username", action.username);
            return { ...state, isLoggedIn: true, username: action.username, error: undefined };
        }
        else {
            return { ...state, isLoggedIn: false, error: 'Username o password errati' };
        }
        //else {
        //    return function (dispatch) {
        //        dispatch(reducer(actionCreators.login(action.username, action.password)));
                
        //        return fetch('/Base/Login', {
        //            method: 'POST',
        //            body: JSON.stringify({ Username: action.username, Password: action.password })
        //        }).then(res => res.json()).then(data => {
        //            if (data.success) {
        //                dispatch(reducer(actionCreators.loginSuccess(action.username, action.password, data)));
        //            }
        //            else {
        //                dispatch(reducer(actionCreators.loginFailure(action.username, action.password, data)));
        //            }
        //        });
        //    }
        //}
    }
    if (action.type === LOGOUT) {
        deleteCookie("username");
        return { ...state, isLoggedIn: false, username: undefined };
    }
    if (action.type === LOGIN_FAILURE) {
        return { ...state, isLoggedIn: false, error: action.data.errorMessage };
    }
    if (action.type === LOGIN_SUCCESS) {
        setCookie("username", action.username);
        return { ...state, isLoggedIn: true, username: action.username, error: undefined };
    }
    //if (action.type === LOGIN_SUCCESS) {
    //    if (action.action.success) {

    //        return { ...state, isLoggedIn: true, username: action.action.denomination, error: undefined };
    //    }
    //    else {
    //        return { ...state, error: "Wrong username or password" };
    //    }
    //}
    if (action.type === CHECK_LOGIN) {
        var username = getCookie('username');
        if (username) {
            setCookie("username", username);
            return { ...state, isLoggedIn: true, username: username, error: undefined };
        }
    }

    return state;
}
