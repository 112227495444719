// ##############################
// // // Header styles
// #############################

import {
    containerFluid,
    defaultFont,
    primaryColor,
    defaultBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    grayColor,
    colorForGrayBackground,
    colorForPrimaryBackground
} from "assets/jss/material-dashboard-pro-react.jsx";

const headerStyle = theme => ({
    appBar: {
        backgroundColor: grayColor,
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "sticky",
        width: "100%",
        paddingTop: "10px",
        zIndex: "1150",
        color: colorForGrayBackground,
        border: "0",
        borderRadius: "0px",
        padding: "10px 0",
        transition: "all 150ms ease 0s",
        minHeight: "50px",
        display: "block",
        height: "72.5px",
    },
    container: {
        ...containerFluid,
        minHeight: "50px"
    },
    flex: {
        flex: 1
    },
    title: {
        ...defaultFont,
        lineHeight: "30px",
        fontSize: "18px",
        borderRadius: "3px",
        textTransform: "none",
        color: "inherit",
        paddingTop: "0.625rem",
        paddingBottom: "0.625rem",
        margin: "0 !important",
        "&:hover,&:focus": {
            background: "transparent"
        }
    },
    primary: {
        backgroundColor: primaryColor,
        color: colorForPrimaryBackground,
        ...defaultBoxShadow
    },
    info: {
        backgroundColor: infoColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    success: {
        backgroundColor: successColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    warning: {
        backgroundColor: warningColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    danger: {
        backgroundColor: dangerColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    sidebarMinimize: {
        float: "left",
        padding: "0 0 0 15px",
        display: "block",
        color: "#555555"
    },
    sidebarMinimizeRTL: {
        padding: "0 15px 0 0 !important"
    },
    sidebarMiniIcon: {
        width: "20px",
        height: "17px"
    }
});

export default headerStyle;
