import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { FileCopy, Save, Print } from '@material-ui/icons';
import { Cart, Share, Delete } from 'mdi-material-ui';


import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { grayColor, colorForGrayBackground } from '../assets/jss/material-dashboard-pro-react';


const styles = theme => ({
    root: {
        position: "relative",
    },
    fab: {
        width: 48,
        height: 48,
        margin: 8,
    },
    actions: {
        padding: 0,
    },
    action: {
        margin: '10px 8px',
    },
    actionsDown: {
        padding: 0,
        position: "absolute",
        top: 48,
    },
    actionSelected: {
        margin: '12px 8px',
        backgroundColor: grayColor,
        color: colorForGrayBackground,
        '&:hover': {
            backgroundColor: colorForGrayBackground,
            color: grayColor,
        }
    },
});
class XNavigation extends Component {
    state = {
        open: false,
        hidden: false,
    };

    handleVisibility = () => {
        this.setState(state => ({
            open: false,
            hidden: !state.hidden,
        }));
    };

    handleClick = () => {
        this.setState(state => ({
            open: !state.open,
        }));
    };
    handleActionClick = (action) => () => {
        this.setState(state => ({
            open: !state.open,
        }), action.action);
    };

    handleOpen = () => {
        if (!this.state.hidden) {
            this.setState({
                open: true,
            });
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };
    render() {
        const { classes, actions } = this.props;
        var { direction } = this.props;
        const { open } = this.state;
        var hidden = !actions || actions.length === 0;
        if (!direction) {
            direction = "right";
        }
        var clsActions = classes.actions;
        if (direction === "down") {
            clsActions = classes.actionsDown;
        }

        return <SpeedDial ariaLabel='' direction={direction}
            classes={{
                root: classes.root,
                actions: clsActions,
                fab: classes.fab,
            }}
            hidden={hidden}
            icon={<SpeedDialIcon />}
            onBlur={this.handleClose}
            onClick={this.handleClick}
            onClose={this.handleClose}
            onFocus={this.handleOpen}
            onMouseEnter={this.handleOpen}
            onMouseLeave={this.handleClose}
            open={open}
        >
            {actions.map(action => {
                var btnCls = classes.action;
                if (action.selected) {
                    btnCls = classes.actionSelected;
                }
                return (
                    <SpeedDialAction
                        classes={{
                            button: btnCls,
                            buttonClosed: classes.buttonClosed,
                        }}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipPlacement="bottom"
                        onClick={this.handleActionClick(action)}
                    />
                );
            })}
        </SpeedDial>;
    }
}

export default withStyles(styles)(XNavigation);