import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class Line extends Component {
    constructor(props) {
        super(props);

        this.Editor = React.createRef();
    }
    checkUsed = async (FIDLine) => {
        return await fetch('/BOP/LineIsUsed/' + FIDLine).then(res => res.json());
        
    }
    deleteLine = (FIDLine) => {
        fetch('/BOP/LineDelete/' + FIDLine, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = () => {
        var id = this.props.match.params.id;

        var isUsed = this.checkUsed(id);

        var label = "Confermi la cancellazione della linea?";
        if (isUsed) {
            label = "La linea è utilizzata nelle distinte oppure ha delle Serie collegate. Continuando verranno cancellato le Serie collegate e sulle distinte veranno tolte le informazioni su Linea e Serie. " + label;
        }

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteBrand(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/BOP/SaveLine/'
            urlDelete='/BOP/DeleteLine/'
            urlRedirect='/Line/'
            urlUploadFiles='/BOP/LineFileUpload/'
            urlDeleteFiles='/BOP/DeleteLineFile/'
            urlGetFiles='/BOP/GetLineFiles/'
            urlCheckDefaultFiles='/BOP/CheckDefaultLineFiles/'
            urlExit='/Lines/'
            urlNew="/line/new"
            urlGetData='/BOP/GetLine/'
            urlCheckChanges='/BOP/LineHasChanges/'
            urlClearSession='/BOP/ClearSession/'
            onDeleteClick={this.onDeleteClick}
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Line);
