import React, { Component} from 'react';
import { withStyles } from '@material-ui/core/styles';


import Grid from '@material-ui/core/Grid';

import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';
import XDialog from 'components/XDialog';
import XGrid from 'components/XGrid';
import XExpansionPanel from 'components/XExpansionPanel';
import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XTag from 'components/XTag';
import XSwitch from 'components/XSwitch';
import XButton from './XButton';


const styles = theme => ({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        zIndex: "1100",
        background: "rgba(0,0,0,0.5)",
        transform: "translate(-50%,-50%)",
        width: "100%",
        height: "100%",
    },
    container: {

        width: "100%",
        maxWidth: "900px",
        left: "50%",
        transform: "translate(-50%,-50%)",
        position: "fixed",
        top: "50%",
        background: "#fff",
        padding: 5,
        boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
    },
});
class DocumentRowEditor extends Component {
    constructor(props) {
        super(props);
        this.cboFIDVat = React.createRef();
    }
    state = {
        data: {},
        setVat: false,
    };
    componentDidMount() {
        this.setState(state => {
            var { data } = this.props;
            state.data = this.calculateTotals(data);
            return state;
        });
        //this.setState({ data: this.props.data });
    }
    onBlur = (key) => (event, value) => {
        if (key === "Code") {
            if (!this.state.data.CatalogCode) {
                this.setState(state => {
                    state.data.CatalogCode = state.data.Code;
                    return state;
                });
            }
        }
        else if (key === "Description") {
            if (!this.state.data.ExtendedDescription) {
                this.setState(state => {
                    state.data.ExtendedDescription = state.data.Description;
                    return state;
                });
            }
        }
    }
    onChange = (key) => (event, value) => {
        this.setState(state => {
            state.data[key] = value;
            return state;
        }, () => {
            if (key === "TaxablePrice") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateTotals(data);
                    return state;
                });
            }
            else if (key === "SellPrice") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateRetailPrice(data);
                    return state;
                });
            }
            else if (key === "RetailDiscount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateRetailPrice(data);
                    return state;
                });
            }
            else if (key === "Discount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountedTotals(data);
                    return state;
                });
            }
            else if (key === "TaxableDiscount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountedTotals2(data);
                    return state;
                });
            }
            else if (key === "DiscountedTaxablePrice") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountTaxable(data);
                    return state;
                });
            }
            else if (key === "DiscountedTotalPrice") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountTotal(data);
                    return state;
                });
            }
            else if (key === "TotalPrice") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateTaxablePrice(data);
                    return state;
                });
            }
            else if (key === "FIDVat") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateTotals(data);
                    return state;
                });
            }
            else if (key === "Qty1") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateAll(data);
                    return state;
                });
            }
            else if (key === "TotalTaxableAmount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateTotals(data, true);
                    return state;
                });
            }
            else if (key === "TotalAmount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateTaxablePrice(data, true);
                    return state;
                });
            }
            else if (key === "TotalDiscount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountedTotals2(data, true);
                    return state;
                });
            }
            else if (key === "TotalDiscountedTaxableAmount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountTaxable(data, true);
                    return state;
                });
            }
            else if (key === "TotalDiscountedAmount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountTotal(data, true);
                    return state;
                });
            }

        });
    }
    onSelectChange = (id) => (event, value) => {
        console.log([id, event, value]);
        this.setState(state => {
            if (value && value.length > 0) {
                state.data[id] = value[0].ID;
            }
            else {
                state.data[id] = undefined;
            }
            return state;
        }, () => {
                if (id === "FIDVat") {
                this.setState(state => {
                    var { data } = state;
                    data.VatPerc = value[0].Percentage;
                    state.data = this.calculateTotals(data);
                    return state;
                });
            }
        });
    }
    calculateAll = (data) => {
        data.TotalTaxableAmount = (data.TaxablePrice * data.Qty1).toFixed(2);
        data.TotalAmount = (data.TotalPrice * data.Qty1).toFixed(2);
        data.TotalDiscountedTaxableAmount = (data.DiscountedTaxablePrice * data.Qty1).toFixed(2);
        data.TotalDiscountedAmount = (data.DiscountedTotalPrice * data.Qty1).toFixed(2);
        data.TotalDiscount = (data.TaxableDiscount * data.Qty1).toFixed(2);
        return data;
    }
    calculateRetailPrice = (data) => {
        data.TaxablePrice = (data.SellPrice * (100 - data.RetailDiscount) / 100).toFixed(2);
        data = this.calculateTotals(data);
        return data;
    }
    calculateTotals = (data, multiple = false) => {
        var tempTotalTaxableAmount = data.TotalTaxableAmount;
        if (multiple) {
            data.TaxablePrice = (data.TotalTaxableAmount / data.Qty1).toFixed(2);
        }
        data.TotalPrice = (data.TaxablePrice * (100 + parseFloat(data.VatPerc)) / 100).toFixed(2)
        if (!data.Discount) {
            data.Discount = 0;
        }
        if (data.Discount > 0) {
            data.DiscountedTaxablePrice = (data.TaxablePrice * (100 - data.Discount) / 100).toFixed(2);
            data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * (100 + parseFloat(data.VatPerc)) / 100).toFixed(2);
        }
        else {
            data.DiscountedTaxablePrice = data.TaxablePrice;
            data.DiscountedTotalPrice = data.TotalPrice;
        }
        data = this.calculateDiscounts(data);
        data = this.calculateAll(data);
        if (multiple) {
            data.TotalTaxableAmount = tempTotalTaxableAmount;
        }
        return data;
    }
    calculateDiscountedTotals = (data, recalculateDiscounts = true) => {
        if (!data.Discount) {
            data.Discount = 0;
        }
        if (data.Discount > 0) {
            data.DiscountedTaxablePrice = (data.TaxablePrice * (100 - data.Discount) / 100).toFixed(2);
            data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * (100 + parseFloat(data.VatPerc)) / 100).toFixed(2);
        }
        else {
            data.DiscountedTaxablePrice = data.TaxablePrice;
            data.DiscountedTotalPrice = data.TotalPrice;
        }
        if (recalculateDiscounts) {
            data = this.calculateDiscounts(data);
        }
        data = this.calculateAll(data);
        return data;
    }
    calculateDiscountedTotals2 = (data, multiple = false) => {
        var tempTotalDiscount = data.TotalDiscount;
        if (multiple) {
            if (!data.TotalDiscount) {
                data.TotalDiscount = 0;
            }
            data.TaxableDiscount = data.TotalDiscount / data.Qty1;
        }
        if (!data.TaxableDiscount) {
            data.TaxableDiscount = 0;
        }
        data.Discount = (100 * data.TaxableDiscount / data.TaxablePrice).toFixed(5);
        console.log(data.Discount);
        data = this.calculateAll(data);
        data = this.calculateDiscountedTotals(data, false);
        if (multiple) {
            data.TotalDiscount = tempTotalDiscount;
        }
        return data;
    }
    calculateDiscountTaxable = (data, multiple = false) => {
        var tempTotalDiscountedTaxableAmount = data.TotalDiscountedTaxableAmount;
        if (multiple) {
            data.DiscountedTaxablePrice = data.TotalDiscountedTaxableAmount / data.Qty1;
        }
        if (!data.Discount) {
            data.Discount = 0;
        }
        data.Discount = ((data.TaxablePrice - data.DiscountedTaxablePrice) * 100 / data.TaxablePrice).toFixed(5);
        
        data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * (100 + parseFloat(data.VatPerc)) / 100).toFixed(2);
        if (data.Discount > 0) {
            data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * (100 + parseFloat(data.VatPerc)) / 100).toFixed(2);
        }
        else {
            data.DiscountedTotalPrice = data.TotalPrice;
        }
        data = this.calculateDiscounts(data);
        data = this.calculateAll(data);
        if (multiple) {
            data.TotalDiscountedTaxableAmount = tempTotalDiscountedTaxableAmount;
        }
        return data;
    }
    calculateDiscountTotal = (data, multiple = false) => {
        var tempTotalDiscountedAmount = data.TotalDiscountedAmount;
        if (multiple) {
            data.DiscountedTotalPrice = data.TotalDiscountedAmount / data.Qty1;
        }
        if (!data.Discount) {
            data.Discount = 0;
        }
        data.Discount = ((data.TotalPrice - data.DiscountedTotalPrice) * 100 / data.TotalPrice).toFixed(5);
        if (data.Discount > 0) {
            data.DiscountedTaxablePrice = (data.TaxablePrice * (100 - data.Discount) / 100).toFixed(2);
        }
        else {
            data.DiscountedTaxablePrice = data.TaxablePrice;
        }
        data = this.calculateDiscounts(data);
        data = this.calculateAll(data);
        if (multiple) {
            data.TotalDiscountedAmount = tempTotalDiscountedAmount;
        }
        return data;
    }
    calculateTaxablePrice = (data, multiple = false) => {
        var tempTotalAmount = data.TotalAmount;
        if (multiple) {
            data.TotalPrice = (data.TotalAmount / data.Qty1).toFixed(2);
        }
        if (!data.Discount) {
            data.Discount = 0;
        }
        data.TaxablePrice = (data.TotalPrice * 100 / (100 + parseFloat(data.VatPerc))).toFixed(2);
        if (data.Discount > 0) {
            data.DiscountedTaxablePrice = (data.TaxablePrice * (100 - data.Discount) / 100).toFixed(2);
            data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * (100 + parseFloat(data.VatPerc)) / 100).toFixed(2);
        }
        else {
            data.DiscountedTaxablePrice = data.TaxablePrice;
            data.DiscountedTotalPrice = data.TotalPrice;
        }
        data = this.calculateDiscounts(data);
        data = this.calculateAll(data);
        if (multiple) {
            data.TotalAmount = tempTotalAmount;
        }
        return data;
    }
    calculateDiscounts = (data) => {
        data.TaxableDiscount = (data.TaxablePrice - data.DiscountedTaxablePrice).toFixed(2);
        return data;
    }
    getData = () => {
        return this.state.data;
    }
    render() {
        var { data } = this.state;

        var pricelistsUrl = "/GC/pricelist/";
        if (data.FIDBOP && data.FIDBOP > 0) {
            pricelistsUrl += "?FIDBOP=" + data.FIDBOP;
        }

        var pricelistHeadFields = (<Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
            <Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={2}><XField disabled={data.FIDBOP > 0} label="Codice" id="Code" value={data.Code} onChange={this.onChange} onBlur={this.onBlur}/></Grid>
                    <Grid item xs={12} md={2}><XField disabled={data.FIDBOP > 0} label="Codice commerciale" id="CatalogCode" value={data.CatalogCode} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={4}><XField disabled={data.FIDBOP > 0} label="Descrizione" id="Description" value={data.Description} onChange={this.onChange} onBlur={this.onBlur} /></Grid>
                    <Grid item xs={12} md={4}><XField disabled={data.FIDBOP > 0} label="Descrizione estesa" id="ExtendedDescription" value={data.ExtendedDescription} onChange={this.onChange} /></Grid>


                    <Grid item xs={12} md={2}><XField label="Q.tà 1" type="number" id="Qty1" value={data.Qty1} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={2}><XSelect label="U.M." id="FIDMeasureUnit1" urlData="/Base/GetGCMeasureUnits" value={data.FIDMeasureUnit1} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onSelectChange} /></Grid>
                    <Grid item xs={12} md={2}><XField label="Q.tà 2" type="number" id="Qty2" value={data.Qty2} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={2}><XSelect label="U.M." id="FIDMeasureUnit2" urlData="/Base/GetGCMeasureUnits" value={data.FIDMeasureUnit2} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onSelectChange} /></Grid>
                    <Grid item xs={12} md={2}></Grid>
                    <Grid item xs={12} md={2}></Grid>

                    <Grid item xs={12} md={4}><XSelect label="Listino vendita" id="FIDPricelist" urlData={pricelistsUrl} value={data.FIDPricelist} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onSelectChange} /></Grid>
                    <Grid item xs={12} md={1}><XField label="Prezz vendita ivato" type="number" id="SellPrice" value={data.SellPrice} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={1}><XField label="Sc % retail" type="number" id="RetailDiscount" value={data.RetailDiscount} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={4}><XSelect innerRef={this.cboFIDVat} label="IVA" id="FIDVat" urlData="/GC/vat" value={data.FIDVat} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onSelectChange} /></Grid>
                    <Grid item xs={12} md={2}></Grid>

                    {
                        //<Grid item xs={12} md={2}><XField label="IVA" type="number" id="VatPerc" value={data.VatPerc} onChange={this.onChange} /></Grid>
                    }

                    <Grid item xs={12} md={1}><XField label={data.Qty1 === 1 ? "Costo netto" : "Prezzo unitario"} type="number" id="TaxablePrice" value={data.TaxablePrice} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={1}><XField label={data.Qty1 === 1 ? "Costo ivato" : "Prezzo unitario ivato"} type="number" id="TotalPrice" value={data.TotalPrice} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={1}><XField label="Sconto %" type="number" id="Discount" value={data.Discount} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={1}><XField label="Sconto netto" type="number" id="TaxableDiscount" value={data.TaxableDiscount} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={1}><XField label={data.Qty1 === 1 ? "Imponibile" : "Imponibile unitario"} type="number" id="DiscountedTaxablePrice" value={data.DiscountedTaxablePrice} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={1}><XField label={data.Qty1 === 1 ? "Totale ivato" : "Unitario ivato"} type="number" id="DiscountedTotalPrice" value={data.DiscountedTotalPrice} onChange={this.onChange} /></Grid>
                    <Grid item xs={12} md={6}></Grid>
                    {data.Qty1 > 1 && <Grid item xs={12} md={1}><XField label="Costo netto" type="number" id="TotalTaxableAmount" value={data.TotalTaxableAmount} onChange={this.onChange} /></Grid>}
                    {data.Qty1 > 1 && <Grid item xs={12} md={1}><XField label="Costo Ivato" type="number" id="TotalAmount" value={data.TotalAmount} onChange={this.onChange} /></Grid>}
                    {data.Qty1 > 1 && <Grid item xs={12} md={1}><XField label="Sconto %" type="number" id="Discount" value={data.Discount} onChange={this.onChange} /></Grid>}
                    {data.Qty1 > 1 && <Grid item xs={12} md={1}><XField label="Sconto totale" type="number" id="TotalDiscount" value={data.TotalDiscount} onChange={this.onChange} /></Grid>}
                    {data.Qty1 > 1 && <Grid item xs={12} md={1}><XField label="Imponibile" type="number" id="TotalDiscountedTaxableAmount" value={data.TotalDiscountedTaxableAmount} onChange={this.onChange} /></Grid>}
                    {data.Qty1 > 1 && <Grid item xs={12} md={1}><XField label="Totale ivato" type="number" id="TotalDiscountedAmount" value={data.TotalDiscountedAmount} onChange={this.onChange} /></Grid>}
                    {
                        //<XButton label="Salva" onClick={onSave(data)} />
                        //<XButton label="Annulla" onClick={onCancel} />
                    }
                </Grid>
            </Grid>
        </Grid>);
        return pricelistHeadFields;
        //return (
        //    <div className={classes.root}>
        //        <div className={classes.container}>
        //            {pricelistHeadFields}
        //        </div>
        //    </div>
        //);

    }
}
    
export default withStyles(styles)(DocumentRowEditor);