import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import XGrid from '../../components/XGrid';
import { IconButton, Grid, Card, CardContent } from '@material-ui/core';
import { MoreHoriz, MoreVert } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { Close, Pencil, ContentSave, Delete, Plus } from 'mdi-material-ui';
import withWidth from '@material-ui/core/withWidth';

import ReactQuill, { Quill } from 'react-quill';
import { ImageUpload } from 'quill-image-upload';
import ImageResize from 'quill-image-resize';

import { Collection } from 'react-virtualized';
import XExpansionPanel from '../../components/XExpansionPanel';
//import PlaceholderImage from './placeholder.png';

import { getXSelect, getXTag, getXDateTimeField, getScrollbarWidth } from 'utils';

import 'react-quill/dist/quill.snow.css';
import XUpload from '../../components/XUpload';
import axios from 'axios';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';
import XMessageBox from '../../components/XMessageBox';
import classNames from 'classnames';
import moment from 'moment';

const styles = theme => ({
    root: {
        minHeight: document.getElementById('mainContent').offsetHeight - 64,
        maxHeight: document.getElementById('mainContent').offsetHeight - 64,
        overflowY: 'auto',
        fontSize: 0,
    },
    advRoot: {
        minHeight: document.getElementById('mainContent').offsetHeight - 64,
        maxHeight: document.getElementById('mainContent').offsetHeight - 64,
        overflowY: 'auto',
        fontSize: 0,
    },
    news: {
        width: '100%',
        maxWidth: 900,
        margin: '5px auto',
        padding: "0 25px",
        '&:after': {
            content: '""',
            display: 'block',
            width: 'calc(100% + 50px)',
            height: 1,
            background: theme.palette.secondary.light,
            margin: "10px -25px",
        }
    },
    adv: {
        width: '100%',
        margin: '5px auto',
        padding: "0 25px",
    },
    date: {
        width: '100%',
        display: 'inline-block',
        color: theme.palette.primary.main,
        margin: '25px 0 15px',
        fontSize: 14,
        position: 'relative',
    },
    newPost: {
        '&:before': {
            content: '""',
            position: 'absolute',
            display: "block",
            width: 10,
            height: 10,
            borderRadius: '50%',
            background: theme.palette.primary.main,
            top: 5,
            left: -15,
        },
    },
    title: {
        width: '100%',
        display: 'inline-block',
        fontSize: 20,
        margin: '0 0 15px',
    },
    content: {
        width: '100%',
        display: 'inline-block',
        color: theme.palette.secondary.light,
        marginBottom: 10,
    },
    post: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '100%',
        maxWidth: 900,
        margin: '5px 0',
        padding: "0 25px",
    },
    postLeft: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '100%',
        maxWidth: 'calc(calc(100% - 900px) / 2)',
        padding: 10,
    },
    postRight: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '100%',
        maxWidth: 'calc(calc(100% - 900px) / 2)',
        padding: 10,
    },
    postBanner: {
        width: '100%',
        paddingTop: '33%',
        //background: theme.palette.secondary.light,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        '& > *': {
            position: 'absolute',
            bottom: 5,
            left: 5
        }
    },
    postCover: {
        width: '100%',
        paddingTop: '66%',
        //background: theme.palette.secondary.light,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        '& > *': {
            position: 'absolute',
            bottom: 5,
            left: 5
        }
    },
    postTitle: {

    },
    postContent: {
    },
    editor: {
        border: '1px solid #ddd',
        cursor: 'text',
        padding: 16,
        borderRadius: 2,
        marginBottom: '2em',
        boxShadow: 'inset 0px 1px 8px -3px #ABABAB',
        background: '#fefefe',
        '&:global(.public-DraftEditor-content)': {
            minHeight: 140,
        }
    },
    headlineButtonWrapper: {
        display: 'inline-block',
    },

    headlineButton: {
        background: '#fbfbfb',
        color: '#888',
        fontSize: 18,
        border: 0,
        paddingTop: 5,
        verticalAlign: 'bottom',
        height: 34,
        width: 36,
        '&:hover': {
            background: '#f3f3f3',
        },
        '&:focus': {
            background: '#f3f3f3',
        }
    },
    advCard: {
        position: 'relative',
    },
    advTitle: {
        fontSize: 20,
        maxHeight: 22,
        minHeight: 22,
        overflow: 'hidden',
        display: 'inline-block',
        width: '90%',
        position: 'relative',
    },
    newAdv: {
        width: 'calc(90% - 20px)',
        paddingLeft: 20,
        '&:before': {
            content: '""',
            position: 'absolute',
            display: "block",
            width: 10,
            height: 10,
            borderRadius: '50%',
            background: theme.palette.primary.main,
            top: 5,
            left: 0,
        },
    },
    advMore: {
        position: 'absolute',
        top: 5,
        right: 5,
    },
    advCover: {
        width: "90%",
        margin: "10px auto",
        paddingTop: "100%",
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
    }
});
window.Quill = Quill;

class Counter {
    constructor(quill, options) {
        this.quill = quill;
        this.options = options;
        this.container = document.querySelector(options.container);
        quill.on('text-change', this.update.bind(this));
        this.update();  // Account for initial contents
    }

    calculate() {
        let text = this.quill.getText();
        if (this.options.unit === 'word') {
            text = text.trim();
            // Splitting empty text returns a non-empty array
            return text.length > 0 ? text.split(/\s+/).length : 0;
        } else {
            return text.length;
        }
    }

    update() {
        var length = this.calculate();
        var label = this.options.unit;
        if (length !== 1) {
            label += 's';
        }
        this.container.innerText = length + ' ' + label;
    }
}

class Post extends Component {
    constructor(props) {
        super(props);
        this.TitleEditor = React.createRef();
        this.MessageBox = React.createRef();
    }
    
    state = {
        posts: [],
        post: false,
        text: '',
        readOnly: true,
        firstLoad: true,
    };
    componentWillMount() {
        Quill.register('modules/imageUpload', ImageUpload);
        Quill.register('modules/imageResize', ImageResize);

        Quill.scrollingContainer = document.getElementById('mainContent');

        var Link = Quill.import('formats/link');
        Link.sanitize = function (url) {
            if (!url.startsWith('http')) {
                url = "http://" + url;
            }
            return url;
        }
    }
    componentDidMount() {
        this.loadPosts();
    }
    checkShowPost = () => {
        if (this.state.firstLoad) {
            this.setState({ firstLoad: false }, () => {
                var { posts } = this.state;
                var url_string = window.location.href;
                var url = new URL(url_string);
                var id = url.searchParams.get('id');
                var edit = url.searchParams.get('edit') === "true";
                console.log([url.searchParams.get('edit'), edit]);
                if (id) {
                    var post = posts.filter(item => item.ID === parseInt(id))[0];
                    if (post) {

                        this.onShowPost(post, !edit)();
                    }
                }
            });
        }
    }
    loadPosts = () => {
        var { postType } = this.props;
        fetch('/CRM/GetPosts?postType=' + postType + '&grid=tblCRMNews&filters=[]&sortColumn=Date&sortDirection=Desc').then(res => res.json()).then(data => this.setState({ posts: data.data }, this.checkShowPost));
    }
    onShowPost = (post, readOnly = true) => () => {
        fetch('/CRM/GetPost/' + post.ID).then(res => res.json()).then(data => this.setState({ post: data, readOnly }));
    }
    onClosePost = () => {
        if (this.state.readOnly || (!this.state.readOnly && this.state.post && this.state.post.ID === 0)) {
            this.setState({ post: false, readOnly: true }, this.loadPosts);
        }
        else {
            this.setState({ readOnly: true });
        }
    }
    onEditPost = () => {
        this.setState({ readOnly: false }, () => {
            if (this.TitleEditor.current) {
                this.TitleEditor.current.editor.format('header', 'h1');
            }
        });
    }
    onSavePost = () => {
        var { postType } = this.props;
        var { post } = this.state;
        if (post.DistributionListIds && typeof post.DistributionListIds === "string") {
            post.DistributionListIds = post.DistributionListIds.split(",").map(Number);
        }
        fetch('/CRM/SavePost/' + post.ID + '?postType=' + postType, {
            method: 'POST',
            body: JSON.stringify(post)
        }).then(res => res.json()).then(data => {
            console.log(data);
            this.onShowPost(data)();
        });
    }
    onNewPost = () => {

        var post = {
            ID: 0,
            Title: '',
            Content: '',
            DateStart: moment(),//Date.now(),
            Status: 1,
        };
        this.setState({ post, readOnly: false }, () => {
            if (this.TitleEditor.current) {
                this.TitleEditor.current.editor.format('header', 'h1');
            }
        });
    }
    onDeletePost = () => {
        this.MessageBox.current.showMessageYesNo('Cancellazione', 'Confermi la cancellazione dell\'articolo corrente?', () => {
            fetch('/CRM/DeletePost/' + this.state.post.ID, {
                method: 'POST'
            }).then(res => {
                this.setState({ post: false, readOnly: true }, this.loadPosts);
            });
        });
    }


    handleChange = (key) => (value) => {
        this.setState(state => {
            if (state.post) {
                state.post[key] = value;
            }
            return state;
        }, () => {
            if (key === "Title") {
                //if (this.TitleEditor.current) {
                //    this.TitleEditor.current.editor.format('header', 'h1');
                //    setTimeout(this.TitleEditor.current.editor.focus,500);
                //}
            }
        });
    }


    handleDataChange = (event, value, key, dt) => {
        this.setState(state => {
            state.post[key] = value;
            return state;
        });
    }
    handleSelectChange = (key) => (event, value) => {
        console.log([key, event, value]);
        if (value.length > 0) {
            this.handleDataChange(event, value[0].ID, key, value[0]);
        }
        else {
            this.handleDataChange(event, undefined, key);
        }
    }
    handleDataFieldChange = (key) => (value) => {
        this.handleDataChange(null, value, key);
    }

    handleFieldChange = (key) => (event, value) => {
        this.handleDataChange(event, value, key);
    }

    onUploadBanner = (id) => (event, file) => {
        if (id === 'filCover') {
            this.onUploadCover(id)(event, file);
            return;
        }
        const data = new FormData()
        data.append('file', file, file.name)

        axios
            .post('/CRM/UpdateBanner', data, {
                onUploadProgress: ProgressEvent => {
                    console.log(ProgressEvent.loaded / ProgressEvent.total * 100);
                },
            })
            .then(res => res.data).then(data => {
                this.setState(state => {
                    state.post.FIDBannerFile = data.ID;
                    return state;
                });
            });
    }
    onUploadCover = (id) => (event, file) => {
        console.log(id);
        if (id === 'filBanner') {
            this.onUploadBanner(id)(event, file);
            return;
        }
        const data = new FormData()
        data.append('file', file, file.name)

        axios
            .post('/CRM/UpdateCover', data, {
                onUploadProgress: ProgressEvent => {
                    console.log(ProgressEvent.loaded / ProgressEvent.total * 100);
                },
            })
            .then(res => res.data).then(data => {
                this.setState(state => {
                    state.post.FIDCoverFile = data.ID;
                    return state;
                });
            });
    }
    getNumberOfCols = () => {
        var cols = 4;
        switch (this.props.width) {
            case "xl":
                cols = 4;
                break;
            case "lg":
                cols = 3;
                break;
            case "md":
            case "sm":
                cols = 2;
                break;
            case "xs":
                cols = 1;
                break;
            default:
                break;
        }
        return cols;
    }
    getCardSize = () => {
        var mainContent = document.getElementById('mainContent');

        var contentWidth = mainContent.clientWidth;
        var cols = this.getNumberOfCols();
        var tempWidth = 394.75;
        var tempHeight = 455.75;
        var width = (contentWidth - getScrollbarWidth()) / cols;

        var height = width * tempHeight / tempWidth;
        return { width, height };
    }

    getRowTop = (row, cols, index) => {
        var sum = 0;

        var { height } = this.getCardSize();
        var maxRow = height;
        var lastIndex = (index - (index % cols));
        for (var i = 0; i < lastIndex; i++) {
            if (i % cols === 0) {
                sum += maxRow;
                maxRow = height;
            }
        }
        sum += maxRow - height;

        return sum;
    }
    renderAdvContent = (post, key, style) => {
        var { classes } = this.props;
        var elTitle = document.createElement('div');
        elTitle.innerHTML = post.Title;
        var title = elTitle.firstElementChild && elTitle.firstElementChild.innerText;

        var sCover = {

        };
        if (post.Cover) {
            sCover.backgroundImage = 'url(/Base/Image/' + post.Cover + ')';
        }
        else {
            sCover.backgroundImage = 'url(./placeholder.png)';
            sCover.backgroundSize = 'cover';
        }


        var content = <CardContent className={classes.advCard}>
            <span className={classNames(classes.advTitle, { [classes.newAdv]: post.NewPost })}>{title}</span>
            <div style={sCover} className={classes.advCover} onClick={this.onShowPost(post)}></div>
        </CardContent>;

        return <Card style={style}>
            {content}
        </Card>;
    }
    renderAdv = (posts) => {
        var height = 100;
        var mainContent = document.getElementById('mainContent');

        var width = mainContent.clientWidth;
        if (mainContent) {
            height = mainContent.clientHeight - 64;
            //width = mainContent.clientWidth;
        }

        //height = window.innerHeight - 72.5 - 64 - 64;
        var collection = <Collection
            onScroll={event => {
                //this.setState({ scrollTop: event.scrollTop });
            }}
            //scrollTop={this.state.scrollTop}
            scrollToCell={this.state.currentIndex}
            ref={this.CardsCollection}
            cellCount={posts.length}
            cellRenderer={({ index, key, style }) => {
                var item = posts[index];
                key = item.ID;
                if (!key) {
                    key = index;
                }
                style.margin = 8;
                style.height = undefined;
                return this.renderAdvContent(item, key, style);
            }}
            cellSizeAndPositionGetter={({ index }) => {
                var cols = this.getNumberOfCols();

                //var tempWidth = 405.75;
                //var tempHeight = 434.61;
                //var width = (contentWidth - getScrollbarWidth()) / cols;
                //var height = width * tempHeight / tempWidth;
                var { width, height } = this.getCardSize();
                var top = this.getRowTop((index - (index % cols)), cols, index);
                return {
                    height: height - 16,
                    width: width - 16,
                    x: (index % cols) * width,
                    y: top,//((index - (index % cols)) / cols) * height
                };
            }}
            height={height}
            width={width}
        />;



        return collection;
    }
    renderPost = (post, index) => {
        var { classes } = this.props;
        var content = post.Content;
        var html = document.createElement('div');
        html.innerHTML = content;
        content = html.firstChild && html.firstChild.outerHTML;
        var style = {

        }
        if (post.Cover) {
            style.width = 'calc(100% - 100px)';
        }

        return <div className={classes.news} key={index}>
            <span style={style} className={classNames(classes.date, { [classes.newPost]: post.NewPost })}>{(new Date(post.Date)).toLocaleString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</span>
            <ReactQuill style={style} theme={null} value={post.Title} readOnly={true} modules={{ toolbar: false }} />
            <ReactQuill style={style} theme={null} value={content} readOnly={true} modules={{ toolbar: false }} />
            <IconButton aria-label="Leggi" onClick={this.onShowPost(post)}>
                <MoreHoriz />
            </IconButton>
        </div>;
    }
    renderCover = () => {
        var { classes } = this.props;
        var { post, readOnly } = this.state;
        var cover = false;
        var innerCover = false;
        var sCover = {

        };
        if (post.FIDCoverFile) {
            sCover.backgroundImage = 'url(/Base/Image/' + post.FIDCoverFile + ')';
        }
        else {
            sCover.backgroundImage = 'url(./placeholder.png)';
        }
        if (!readOnly) {
            innerCover = <XUpload key="filCover" id="filCover" onChange={this.onUploadCover} />
        }

        cover = <div key={post.FIDCoverFile} style={sCover} className={classes.postCover}>{innerCover}</div>;
        return <Grid container spacing={2}>
            {cover}
        </Grid>;
    }
    renderSettings = () => {
        var { post } = this.state;
        var fields = [
            {
                Field: 'Status',
                Visibility: true,
                Required: 2,
            },
            {
                Field: 'DateStart',
                Visibility: true,
                Required: 2,
            },
            {
                Field: 'DateEnd',
                Visibility: true,
                Required: 0,
            },
            {
                Field: 'DistributionListIds',
                Visibility: true,
                Required: 0,
            },
        ];

        var fieldStatus = getXSelect(post, fields, 12, 12, "Status", "Stato", this.handleSelectChange, "/enums/crmpoststatus");
        var fieldDateStart = getXDateTimeField(post, fields, 12, 12, "DateStart", "Data inizio", this.handleDataFieldChange);
        var fieldDateEnd = getXDateTimeField(post, fields, 12, 12, "DateEnd", "Data fine", this.handleDataFieldChange);
        var fieldDistribution = getXTag(post, fields, 12, 12, "DistributionListIds", "Liste distribuzione", this.handleFieldChange, "/ir/distributionlist");

        return <Grid container spacing={2}>
            {fieldStatus}
            {fieldDateStart}
            {fieldDateEnd}
            {fieldDistribution}
        </Grid>;
    }
    renderSinglePost = (post) => {
        var { classes } = this.props;
        var { readOnly } = this.state;
        var modules = {};
        if (readOnly) {

            modules.toolbar = false;
        }
        else {
            modules.toolbar = [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'align': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image','video'],
                ['clean'],
            ];
            modules.imageUpload = {
                url: '',
                method: 'POST',
                name: 'image',
                withCredentials: false,
                headers: {}
            };
            modules.imageResize = {
                handleStyles: {
                    backgroundColor: 'black',
                    border: 'none',
                    color: 'white'
                }
            };

        }
        var theme = readOnly ? null : "snow";

        var banner = false;
        var showBanner = false;
        var innerBanner = false;
        var sBanner = {

        };

        var renderCover = this.renderCover();

        if (post.FIDBannerFile) {
            showBanner = true;
            sBanner.backgroundImage = 'url(/Base/Image/' + post.FIDBannerFile + ')';
        }
        else {
            if (!readOnly) {
                showBanner = true;
                sBanner.backgroundImage = 'url(./placeholder.png)';
                sBanner.backgroundSize = 'cover';
            }
        }
        if (!readOnly) {
            innerBanner = <XUpload key="filBanner" id="filBanner" onChange={this.onUploadBanner} />
        }
        banner = showBanner && <div key={post.FIDBannerFile} style={sBanner} className={classes.postBanner}>{innerBanner}</div>;


        return <div className={classes.root}>
            <div className={classes.postLeft}>
                {!readOnly && <XExpansionPanel panels={[
                    {
                        icon: 'code',
                        label: 'Cover',
                        content: renderCover
                    }
                ]} />}

                <div id="counter"></div>
            </div>
            <div className={classes.post} key={post.ID}>
                {banner}
                {readOnly && <span className={classes.date}>{(new Date(post.DateStart)).toLocaleString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</span>}
                <ReactQuill
                    ref={this.TitleEditor}
                    theme={theme}
                    value={post.Title}
                    onChange={this.handleChange("Title")}
                    readOnly={readOnly}
                    placeholder="Titolo"
                    modules={{
                        toolbar: false,
                    }}
                />
                <ReactQuill
                    theme={theme}
                    value={post.Content}
                    onChange={this.handleChange("Content")}
                    readOnly={readOnly}
                    modules={modules}
                />
            </div>
            <div className={classes.postRight}>
                {!readOnly && <XExpansionPanel panels={[
                    {
                        icon: 'code',
                        label: 'Impostazioni',
                        content: this.renderSettings()
                    }
                ]} />}
            </div>
        </div>;
    }

    renderMainUI() {
        var { classes, postType } = this.props;
        var { posts } = this.state;
        if (postType === 1) {
            return (<div className={classes.root}>
                {posts.map((item, index) => this.renderPost(item, index))}
            </div>);
        }
        else {
            return (
                <div className={classes.advRoot}>
                    {this.renderAdv(posts)}
                </div>
            );
        }
    }
    render() {
        var { post, readOnly } = this.state;
        var customActions = [];
        var ui = this.renderMainUI();

        var allowNew = this.props.menus.includes('crmnew');
        var allowEdit = this.props.menus.includes('crmedit');

        customActions = [];
        if (allowNew) {
            customActions.push(<Tooltip title="Nuovo" key={"btnNew"}>
                <IconButton aria-label="Nuovo" onClick={this.onNewPost}>
                    <Plus />
                </IconButton>
            </Tooltip>);
        }
        if (post) {
            ui = this.renderSinglePost(post);
            if (allowEdit) {
                if (readOnly) {
                    customActions.push(<Tooltip title="Modifica" key={"btnEdit"}>
                        <IconButton aria-label="Modifica" onClick={this.onEditPost}>
                            <Pencil />
                        </IconButton>
                    </Tooltip>);
                }
                else {
                    customActions.push(<Tooltip title="Salva" key={"btnSave"}>
                        <IconButton aria-label="Salva" onClick={this.onSavePost}>
                            <ContentSave />
                        </IconButton>
                    </Tooltip>);
                    customActions.push(<Tooltip title="Elimina" key={"btnDelete"}>
                        <IconButton aria-label="Elimina" onClick={this.onDeletePost}>
                            <Delete />
                        </IconButton>
                    </Tooltip>);
                }
            }
            customActions.push(<Tooltip title="Chiudi" key={"btnClose"}>
                <IconButton aria-label="Chiudi" onClick={this.onClosePost}>
                    <Close />
                </IconButton>
            </Tooltip>);
        }

        return <Fragment>
            <XGrid
                usePrimaryColor={true}
                showFilterActivator={false}
                showColumnsSelector={false}
                customContent={ui}
                actions={customActions}
            />
            <XMessageBox ref={this.MessageBox} />
        </Fragment>;
    }
}
    
    
const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withWidth(),
    withStyles(styles)
);
    
    
export default enhance(Post);