import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { CalendarRange, DotsHorizontal,FilePdf, SquareEditOutline } from 'mdi-material-ui';

import ReactHtmlParser from 'react-html-parser';
import classNames from 'classnames';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';

import {
    successColor,
    tooltip,
    cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";
import { IconButton } from '@material-ui/core';

const fullHeight = (window.innerHeight - 72.5 - 64);

const styles = theme => ({
    root: {
        maxHeight: fullHeight/2-60,
        minHeight: fullHeight / 2 - 60,
    },
    cardBody: {
        maxHeight: 'calc(100% - 56px)',
        overflowY: 'auto',
    },
    cardCategory: {
        color: "#999999",
        fontSize: 26,
        paddingTop: 17,
        marginBottom: "0",
        marginTop: "0",
        margin: "0"
    },
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px"
    },
    stats: {
        color: "#999999",
        fontSize: "12px",
        lineHeight: "22px",
        display: "inline-flex",
        "& svg": {
            position: "relative",
            top: "4px",
            width: "16px",
            height: "16px",
            marginRight: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            position: "relative",
            top: "4px",
            fontSize: "16px",
            marginRight: "3px"
        }
    },
    post: {
        //cursor: 'pointer',
        padding: "0 5px",
        '&:hover': {
            //background: '#eee',
        },
        '&:not(:last-child):after': {
            content: '""',
            display:'block',
            width: 'calc(100% + 10px)',
            height:'1px',
            background: '#ccc',
            marginTop: 5,
            marginLeft: -5,
        },
        '&:not(:first-child)': {
            marginTop: 5,
        }
    },
    date: {
        //display: 'block',
        width: '100%',
        display: 'inline-block',
        color: theme.palette.primary.main,
        margin: '25px 0 15px',
        fontSize: 10,
        position: 'relative',
        margin: 0,
    },
    title: {
        cursor: 'pointer',
        display: 'block',
        '& *': {
            margin: '5px 0 10px',
            fontSize: 18,
            fontWeight: 'bold',
            color: '#555555',
    },
    content: {
        cursor: 'pointer',
        display: 'block',
        '& *': {
            margin: 0,
            fontSize: 12,
            maxHeight: 38,
            overflow: 'hidden',
            minHeight: 38,
        }
        }
    },
    new: {
        paddingLeft: 12,
        '&:before': {
            content: '""',
            position: 'absolute',
            display: "block",
            width: 7,
            height: 7,
            borderRadius: '50%',
            background: theme.palette.primary.main,
            top: 7,
            left: 0,
        },
    },
    actions: {
        float: 'right',
        fontSize: 10,
    }
});

class PostsPreview extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    state = {
        posts: undefined,
    }

    componentDidMount() {
        var { postType } = this.props;
        fetch('/CRM/GetPostsPreview/?postType=' + postType).then(res => res.json()).then(data => {
            this.setState({ posts: data });
        });
    }
    onPostClick = (link) => () => {
        this.context.router.history.push(link);
    }
    renderItem = (post) => {
        var { classes, postType } = this.props;
        var link = (postType === 1 ? '/news/?id=' : '/advertising/?id=') + post.ID;
        var allowEdit = this.props.menus.includes('crmedit');

        var content = post.Content;
        var html = document.createElement('div');
        html.innerHTML = content;
        content = html.firstChild && html.firstChild.outerHTML;

        return (
            <div className={classes.post}>
                <span className={classNames(classes.date, { [classes.new]: post.NewPost })}>
                    {(new Date(post.Date)).toLocaleString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                    <span className={classes.actions}>
                        <IconButton size="small" onClick={this.onPostClick(link)}><DotsHorizontal /></IconButton>
                        <IconButton size="small" disabled><FilePdf /></IconButton>
                        {allowEdit && <IconButton size="small" onClick={this.onPostClick(link + '&edit=true')}><SquareEditOutline /></IconButton>}
                    </span>
                </span>
                <span className={classes.title} onClick={this.onPostClick(link)}>{ReactHtmlParser(post.Title)}</span>
                <span className={classes.content} onClick={this.onPostClick(link)}>{ReactHtmlParser(content)}</span>
            </div>
        );
    }

    renderBody = () => {
        var { posts } = this.state;
        if (!posts) {
            return false;
        }
        return (
            <div>
                {posts.map((item, index) => {
                    return this.renderItem(item);
                })}
            </div>
            );
    }
    render() {
        var { classes, label, icon, color } = this.props;
        return <Card className={classes.root}>
            <CardHeader color={color} stats icon>
                <CardIcon color={color}>
                    {icon}
                </CardIcon>
                <p className={classes.cardCategory}>{label}</p>
                <h3 className={classes.cardTitle}></h3>
            </CardHeader>
            <CardBody className={classes.cardBody}>
                {this.renderBody()}
            </CardBody>
        </Card>;
    }
}

const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(PostsPreview);