import React, { Fragment } from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import CustomAppBar from '../CustomAppBar/CutomAppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Icon from '@material-ui/core/Icon';


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    tab: {
        width: "100%",
    }
});

class CustomTabs extends React.Component {
    state = {
        value: 0
    };
    componentDidMount() {
        if (this.props.initialTab) {
            this.setState({ value: this.props.initialTab });
        }
    }

    handleChange = (event, value) => {
        var isValid = true;
        if (this.props.validateTab) {
            isValid = this.props.validateTab(this.state.value, value, () => {
                this.setState({ value });
            });
        }
        if (isValid) {
            this.setState({ value });
        }
    };
    getCurrentTab = () => {
        return this.state.value;
    }
    goToTab = (tab) => {
        var { tabs } = this.props;
        if (tab >= tabs.length) {
            return false;
        }
        this.setState({ value: tab });
        return true;
    }
    render() {
        const {
            classes,
            tabs,
            headerColor,
            isSecondary,
        } = this.props;

        var uiAppBar = <CustomAppBar headerColor={headerColor} position="sticky" isSecondary={isSecondary}>
            <Toolbar>
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    className={classes.tab}
                >
                    {tabs.map((prop, key) => {
                        return (
                            <Tab
                                key={key}
                                label={prop.tabName}
                            />
                        );
                    })}
                </Tabs>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                    {tabs[this.state.value].actions}
                    {this.props.onNewClick && (<IconButton color="inherit" onClick={this.props.onNewClick}>
                        <Icon>add</Icon>
                    </IconButton>)}
                    {this.props.onSaveClick && (<IconButton color="inherit" onClick={this.props.onSaveClick}>
                        <Icon>save</Icon>
                    </IconButton>)}
                    {this.props.onDeleteClick && (<IconButton color="inherit" onClick={this.props.onDeleteClick}>
                        <Icon>delete</Icon>
                    </IconButton>)}
                    {this.props.onExitClick && (<IconButton color="inherit" onClick={this.props.onExitClick}>
                        <Icon>exit_to_app</Icon>
                    </IconButton>)}
                </div>
            </Toolbar>
        </CustomAppBar>;
        if (isSecondary) {
            uiAppBar = false;
        }
        return (
            <Fragment>
                {uiAppBar}
                {tabs.map((prop, key) => {
                    if (key === this.state.value) {
                        return <div key={key}>{prop.tabContent}</div>;
                    }
                    return null;
                })}
            </Fragment>
        );
    }
}

CustomTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    headerColor: PropTypes.oneOf([
        "warning",
        "success",
        "danger",
        "info",
        "primary",
        "rose"
    ]),
    title: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            tabName: PropTypes.string.isRequired,
            tabIcon: PropTypes.func,
            tabContent: PropTypes.node.isRequired
        })
    ),
    rtlActive: PropTypes.bool,
    plainTabs: PropTypes.bool
};

CustomTabs.defaultProps = {
    topMargin: "72.5px",
}


export default withStyles(styles)(CustomTabs);
