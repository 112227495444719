import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';


import Button from "components/CustomButtons/Button.jsx";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";



class XButton extends Component {
    render() {
        var { children, ...all } = this.props;
        return (<Button {...all}>{children}</Button>)
    }
}

export default withStyles(styles)(XButton);